import React, { useState } from "react";
import DivIconExt from '../utils/divIcon';


let BTruckicon = 'BTruck.png';
let GTruckicon = 'GTruck.png';
let GRTruckicon = 'GRTruck.png';
let OTruckicon = 'OTruck.png';
let RTruckicon = 'RTruck.png';

let BBikeicon = 'BBike.png';
let GBikeicon = 'GBike.png';
let GRBikeicon = 'GRBike.png';
let OBikeicon = 'OBike.png';
let RBikeicon = 'RBike.png';


let BBusicon = 'BBus.png';
let GBusicon = 'GBus.png';
let GRBusicon = 'GRBus.png';
let OBusicon = 'OBus.png';
let RBusicon = 'RBus.png';

let BCaricon = 'BCar.png';
let GCaricon = 'GCar.png';
let GRCaricon = 'GRCar.png';
let OCaricon = 'OCar.png';
let RCaricon = 'RCar.png';


let BPickupsicon = 'BPickups.png';
let GPickupsicon = 'GPickups.png';
let GRPickupsicon = 'GRPickups.png';
let OPickupsicon = 'OPickups.png';
let RPickupsicon = 'RPickups.png';

let BTaxiicon = 'bc.png';
let GTaxiicon = 'grc.png';
let GRTaxiicon = 'gc.png';
let OTaxiicon = 'oc.png';
let RTaxiicon = 'rc.png';
  
export const MapContext = React.createContext({});
export const MapContextProvider = ({ children }) => {
    const [mapData, setMapData] = useState();
  
    return (
        <MapContext.Provider value={{ mapData, setMapData }}>
            {children}
        </MapContext.Provider>
    );
};

export const findUpdateMarker = (mapData, item) => {
       return mapData.map((element, index) => {
        element.highlight = element?.id === item?.id ? true : false;
        return element;
    });
}

export const findAddressMarker = (mapData, item) => {
    return mapData.map((element, index) => {       
        element['address'] = element?.id === item?.id ? item?.address : null;
        return element;
    });
}

export const getMarkerPopup = (item) => {
    return "<div><b>Vehicle Number:</b>" + item?.name + "<br/>" + "<b>Chassis Number:</b>" + item?.chasis_no + "<br/>" + "<b>IMEI Number:</b>" + item?.imei_no + "<br/>" + "<b>Permit Holder Name:</b>" + item?.permit_holder + "<br/>"+ "<b>Contact Number:</b>" + item?.contact + "<br/>" + "<b>RTO Code:</b>" + item?.rto + "<br/>" + "<b>Company Name:</b>" + item?.company + "</div>";
}

const buildHtml = (highlight, course, iconUrl)=> {
    let img = "<img src='" +  iconUrl + "' style='transform: rotate(" + course + "deg)' />";
    return highlight ? "<div class='border-div'>" + img + "</div>" : "<div class='borderless-div'>" + img + "</div>"
}

export const mapIcon = (item) =>
{
    const {status,type,highlight,course} = item;
    let iconUrl = GTruckicon

    if(type === "Bus")
    {
        if(status === "online")
            iconUrl = GRBusicon
        else if(status === "idle")
            iconUrl = OBusicon
        else if(status === "stopped")
            iconUrl = RBusicon
        else if(status === "other")
            iconUrl = BBusicon
        else if(status === "offline")
            iconUrl = GBusicon
        else if(status === "moving" || status === "towing")
            iconUrl = GRBusicon
        else
            iconUrl = GBusicon

    }

    else if(type === "Truck")
    {
        if(status === "online")
            iconUrl = GRTruckicon
        else if(status === "idle")
            iconUrl = OTruckicon
        else if(status === "stopped")
            iconUrl = RTruckicon
        else if(status === "other")
            iconUrl = BTruckicon
        else if(status === "offline")
            iconUrl = GTruckicon
        else if(status === "moving" || status === "towing")
            iconUrl = GRTruckicon
        else
            iconUrl = GTruckicon
        
    }

    else if(type === "Bike")
    {
        if(status === "online")
            iconUrl = GRBikeicon
        else if(status === "idle")
            iconUrl = OBikeicon
        else if(status === "stopped")
            iconUrl = RBikeicon
        else if(status === "other")
            iconUrl = BBikeicon
        else if(status === "offline")
            iconUrl = GBikeicon
        else if(status === "moving" || status === "towing")
            iconUrl = GRBikeicon
        else
            iconUrl = GBikeicon
        
    }

    else if(type === "Pickups")
    {
        if(status === "online")
            iconUrl = GRPickupsicon
        else if(status === "idle")
            iconUrl = OPickupsicon
        else if(status === "stopped")
            iconUrl = RPickupsicon
        else if(status === "other")
            iconUrl = BPickupsicon
        else if(status === "offline")
            iconUrl = GPickupsicon
        else if(status === "moving" || status === "towing")
            iconUrl = GRPickupsicon
        else
            iconUrl = GPickupsicon
            
    }

    else if(type === "Car")
    {
        if(status === "online")
            iconUrl = GRCaricon
        else if(status === "idle")
            iconUrl = OCaricon
        else if(status === "stopped")
            iconUrl = RCaricon
        else if(status === "other")
            iconUrl = BCaricon
        else if(status === "offline")
            iconUrl = GCaricon
        else if(status === "moving" || status === "towing")
            iconUrl = GRCaricon
        else    
            iconUrl = GCaricon
    
    }
    else if(type === "Taxi")
    {
        if(status === "online")
            iconUrl = GRTaxiicon
        else if(status === "idle")
            iconUrl = OTaxiicon
        else if(status === "stopped")
            iconUrl = RTaxiicon
        else if(status === "other")
            iconUrl = BTaxiicon
        else if(status === "offline")
            iconUrl = GTaxiicon
        else if(status === "moving" || status === "towing")
            iconUrl = GRTaxiicon
        else    
            iconUrl = GTaxiicon
    }
    else
    {
        if(status === "online")
            iconUrl = GRTruckicon
        else if(status === "idle")
            iconUrl = OTruckicon
        else if(status === "stopped")
            iconUrl = RTruckicon
        else if(status === "other")
            iconUrl = BTruckicon
        else if(status === "offline")
            iconUrl = GTruckicon
        else if(status === "moving" || status === "towing")
            iconUrl = GRTruckicon
        else
            iconUrl = GTruckicon

    }

    iconUrl = "https://jmcweblink.blob.core.windows.net/jmcfilelink/images/vts/" + iconUrl

    return iconUrl
}