import React, { useState } from "react";
  
export const TitleContext = React.createContext({});
export const TitleContextProvider = ({ children }) => {
    const [title, setTitle] = useState();
  
    return (
        <TitleContext.Provider value={{ title, setTitle }}>
            {children}
        </TitleContext.Provider>
    );
};