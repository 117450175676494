import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import "./header.css";
import { Avatar, Button, Card, CardActions } from "@mui/material";
import Logo from "../../images/logo.svg";
import TransportLogo from "../../images/transport_department_logo.jpeg";
import Grid from "@mui/material/Grid";
import { Form, TextInput } from "react-admin";
import Box from "@mui/material/Box";
import ArrowIcon from "@mui/icons-material/ArrowCircleLeft";
import SendNotification from "../../utils/sendNotification";
import { useLocation } from "react-router-dom";
import { verifyForgotPasswordOTP } from "../../providers/dataProvider";
import { useNavigate } from "react-router-dom";
import { sendForgotPasswordOTP } from "../../providers/dataProvider";

const Otpverfication = () => {
  const [notifyData, setNotifyData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const [otpInput, setOtpInput] = useState(new Array(6).fill(""));
  const location = useLocation();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    if (location?.state?.email != "") {
      setNotifyData({
        open: true,
        message: "OTP SENT TO EMAIL SUCCESSFULLY!",
        severity: "success",
        duration: 4000,
      });
    }
  }, []);

  useEffect(() => {
    const otpFieldValue = otpInput.join("");
    otpFieldValue?.length == 6 ? setDisabled(false) : setDisabled(true);
  }, [otpInput]);

  const handleSubmit = async () => {
    let result = {};
    const values = {
      email: location?.state?.email,
      otp: otpInput.join(""),
    };
    await verifyForgotPasswordOTP(values, result);
    if (result?.res?.status == 200) {
      localStorage.setItem("passwordToken", result?.res?.json?.passwordToken);
      navigate("/reset-password", { state: "SUCCESS" });
    } else if (result?.res == "FAILED") {
      setNotifyData({
        open: true,
        message: "FAILED TO VERIFY, TRY AGAIN !",
        severity: "warning",
        duration: 4000,
      });
    }
  };

  const handleKeyDown = (e,index) => {

    if (e.key === 'Backspace' || e.key === 'Delete') {
      setOtpInput([
        ...otpInput?.map((d, idx) => (idx == index ? "" : d)),
      ]);

      switch (e.target.tabIndex) {
        case 1:
          document.getElementById("OTP1").focus();
          break;
        case 2:
          document.getElementById("OTP1").focus();
          break;
        case 3:
          document.getElementById("OTP2").focus();
          break;
          case 4:
          document.getElementById("OTP3").focus();
          break;
          case 5:
          document.getElementById("OTP4").focus();
          break;
          case 6:
            document.getElementById("OTP5").focus();
            break;
      }

    }
    else{
      if (isNaN(e.key)) return false;
      setOtpInput([
        ...otpInput?.map((d, idx) => (idx == index ? e.key : d)),
      ]);

    switch (e.target.tabIndex) {
      case 1:
        document.getElementById("OTP2").focus();
        break;
      case 2:
        document.getElementById("OTP3").focus();
        break;
      case 3:
        document.getElementById("OTP4").focus();
        break;
        case 4:
        document.getElementById("OTP5").focus();
        break;
        case 5:
        document.getElementById("OTP6").focus();
        break;
    }

    }
  };

  const resendotp = async() => {
    let result = {};
    await sendForgotPasswordOTP(location?.state, result);
    if (result?.res == "SUCCESS") {
      setNotifyData({
        open: true,
        message: "RESEND THE OTP TO THE MAIL !",
        severity: "success",
        duration: 4000,
      });
      setMinutes(1);
      setSeconds(30);
    } else if (result?.res == "FAILED") {
      setNotifyData({
        open: true,
        message: "FAILED TO SENT OTP !",
        severity: "warning",
        duration: 4000,
      });
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
  
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
  
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleRedirect = () =>{
    navigate("/forgot-password");
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "flex-start",
            flexGrow: 1,
          }}
          className="login-sec forgotpwd"
        >
          <Grid container spacing={2}>
            <Grid item xs={7}>
              
              <Card
                sx={{ height: "60vh", marginTop: "6em", boxShadow: "none" }}
                className="bg-image"
              ></Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                sx={{
                  minWidth: 424,
                  marginTop: "2em",
                  padding: "18px 42px !important",
                }}
              >
                <Box
                  sx={{
                    margin: "1em",
                    justifyContent: "center",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={1}>
                      <div style={{ display: "flex",position: "relative",right: "45px" }} className="backtopage" onClick={handleRedirect}>
                        <ArrowIcon className="arrow-icon" />
                        <Typography color="textSecondary" variant="h" component="h4">
                          Back
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={10} className="logo-box">
                      <img src={TransportLogo} />
                      <Typography variant="h5" component="h2" align="center">
                        Vehicle Tracking System
                      </Typography>
                      <Typography variant="p" component="p" align="center">
                        Transport Department, Chhattisgarh
                      </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    margin: "1em",
                    justifyContent: "center",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Typography variant="h5" component="h4" align="center">
                        Enter OTP
                      </Typography>
                      <Typography
                        variant="p"
                        component="p"
                        align="center"
                        className="f-text"
                      >
                        Enter the 6 digit code that you received on your mail
                      </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Box>
                <Box id="OTP" className="otp-field">
                  {otpInput?.map((data, index) => {
                    return (
                      <Box sx={{ marginTop: "1em", paddingTop: "10px" }}>
                        <input
                          type="text"
                          id={`OTP${index + 1}`}
                          name="otp"
                          value={data}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          maxLength="1"
                          key={index}
                          tabIndex={index + 1}
                        />
                      </Box>
                    );
                  })}
                </Box>
                <CardActions
                  sx={{ padding: "0 1em 1em 1em", marginTop: "2rem" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                    className={disabled ? "layout-disable" : ""}
                    disabled={disabled}
                  >
                    Verify
                  </Button>
                </CardActions>
                <p className="resend-p" style={{textAlign:"left",marginLeft:"15px"}}>
                  {seconds > 0 || minutes > 0 ? (
                          <>Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                            </>
                        ) : (
                          <>Didn't received yet? </>
                        )}
                  <a onClick={(e) => resendotp()} style={{float:"right",cursor:"pointer",marginRight:"18px"}}>Resend</a>
                </p>
              </Card>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Box>
      </Form>
      <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
    </>
  );
};

export default Otpverfication;
