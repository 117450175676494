import React, { useEffect, useState, useRef } from 'react';
import { Button, useRefresh } from "react-admin";
import { Box, Typography } from '@mui/material';
import {
  Grid,
  Card
} from '@mui/material';
import vtsDataProvider from './../providers/dataProvider';
import FormField from '@mui/material/TextField';
import "../views/geofence/geofence.css";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CircleIcon from '@mui/icons-material/Circle';
import PointerIcon from '@mui/icons-material/AddLocation';
import PolygonIcon from '@mui/icons-material/Diamond';
import Modal from '@mui/material/Modal';
import SendNotification from "../utils/sendNotification";
import CloseIcon from '@mui/icons-material/HighlightOffOutlined';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { GeofenceMap } from '../components/GeofenceMap'
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { validateLatLong } from "../utils/validate"

const errorstyle = {
  color: "red",
  fontSize: "12px",
};


const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

let date = new Date();
const defaultValues = {
  attributes: {
    speedLimit: "",
    loadingPoint: false,
    unloadingPoint: false
  },
  calendarId: 0,
  name: "",
  description: "",
  area: "",
  createdon: date,
  latitude: "",
  longitude: "",
  geofencetype: "",
  radius: "",
  button: 0
}

export const Creategeofence = ({ Geofopen, handleGeofClose, listContext }) => {
  const [notifyData, setNotifyData] = useState({});
  const [geofencedata, setGeofenceData] = useState(defaultValues)
  const [formValues, setFormValues] = useState(defaultValues);
  const [opened, openTextbox] = useState(false);
  const [position, setPosition] = useState([]);
  const [formErrors, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [coordValue, setcoordinates] = useState({});
  const [buttonChange, setButtonChange] = useState(false);
  const [radiusvalue, setRadiusValue] = React.useState(100);
  const [raddisplayvalue, setRadDisplayValue] = React.useState({ type: "km", value: 0.1 });
  const [drawmode, setDrawMode] = useState(false);
  const mapRef = useRef();
  const refresh = useRefresh();


  const handleSliderChange = (event, newValue) => {
    if (validateLatLong(formValues.latitude * 1, formValues.longitude * 1)) {
      setRadiusValue(newValue);
      setFormValues({ ...formValues, radius: newValue });
    }
  };

  const handleRadiusChange = (event) => {
    if (validateLatLong(formValues.latitude * 1, formValues.longitude * 1)) {
      let value = event.target.value === '' ? '' : Number(event.target.value) * 1000
      setRadiusValue(value);
      setFormValues({ ...formValues, radius: value });
    }
  };

  const handleBlur = () => {
    if (radiusvalue <= 0) {
      setRadiusValue(100);
      setFormValues({ ...formValues, radius: 100 });
    } else if (radiusvalue > 80000) {
      setRadiusValue(80000);
      setFormValues({ ...formValues, radius: 80000 });
    }
  };

  useEffect(() => {

    if (radiusvalue > 0 && validateLatLong(formValues.latitude * 1, formValues.longitude * 1)) {
      setRadDisplayValue({ type: "km", value: radiusvalue / 1000 })


      circlegoClick();
    }
  }, [radiusvalue, formValues.latitude, formValues.longitude])

  useEffect(() => {
    setDrawMode(false);
  }, [handleGeofClose])

  useEffect(() => {
    setRadiusValue(100);
    setRadDisplayValue({ type: "km", value: 0.1 });
  }, [Geofopen])

  useEffect(() => {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1;
    setRadiusValue(100);
    setRadDisplayValue({ type: "km", value: 0.1 });
  }, [])

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let regex = /^[A-Za-z0-9-_ ]+$/
    if (name == "name") {
      if (value == "" || regex.test(value)) {
        setFormValues({
          ...formValues,
          [name]: value,
        });
      }
    }
    else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };


  const changebutton = (type) => {
    setButtonChange(prev => {
      if (buttonChange == true) return false
      return true
    })

    setDrawMode(true);
  };

  const circlegoClick = () => {
    setcoordinates(
      {
        type: formValues.geofencetype,
        coordarray: [],
        lat: formValues.latitude,
        lng: formValues.longitude,
        radius: formValues.radius
      })
  }

  const handleAttributesChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      attributes: {
        ...formValues.attributes,
        [name]: type === 'checkbox' ? checked : value,
      }
    });
  };

  useEffect(() => {
    if (opened === false) {
      setFormValues({ ...formValues, latitude: "", longitude: "", radius: "" });
    }
  }, [opened])



  useEffect(() => {
    if (formValues.geofencetype === "polygon" || formValues.geofencetype === "polyline") {
      let area = ""
      let latlngs;
      if (position) {

        let posi = ""

        position?.map((latlng, index) => {
          if (posi !== "") posi = posi + ","
          posi = posi + "" + latlng.lat + " " + latlng.lng
        })

        if (formValues.geofencetype === "polygon")
          area = (formValues.geofencetype).toUpperCase() + "((" + posi + "))"

        if (formValues.geofencetype === "polyline")
          area = "LINESTRING(" + posi + ")"


        setFormValues({ ...formValues, area: area })
      }
      else {
        setFormValues({ ...formValues, area: "" })
      }
    }
    if (formValues.geofencetype === "circle") {
      if (position) {
        setFormValues({
          ...formValues,
          latitude: position.lat,
          longitude: position.lng,
          radius: position.radius,
          area: (formValues.geofencetype).toUpperCase() + "(" + position.lat + " " + position.lng + "," + position.radius + ")"
        })
      }
      else {
        setFormValues({
          ...formValues,
          latitude: "",
          longitude: "",
          radius: "",
          area: ""
        })
      }
    }
  }, [position])

  const transferformValues = (formValues) => {
    const formtransferdata = {
      attributes: {
        speedLimit: formValues.attributes.speedLimit * 1,
        loadingPoint: formValues.attributes.loadingPoint,
        unloadingPoint: formValues.attributes.unloadingPoint
      },
      calendarId: 0,
      name: formValues.name,
      description: formValues.description,
      area: formValues.area,
      createdon: formValues.createdon,
    }
    return formtransferdata
  }

  useEffect(() => {
    if (geofencedata.area !== "") {
      vtsDataProvider.create('geofences', geofencedata, setNotifyData, "Geofence Created Successfully", "Failed to Create Geofence");
      setFormValues(defaultValues)
      setGeofenceData(defaultValues)
      setFormError({});
      setIsSubmit(false);
      handleGeofClose();
    }
    else {
      setNotifyData("Please draw your position")
    }
  }, [geofencedata])


  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])

  const validate = (values) => {
    const errors = {};
    const numberregex = "^[1-9]+[0-9]*$";
    if (values.attributes.speedLimit !== "" && values.attributes.speedLimit !== undefined) {
      if (!values.attributes.speedLimit.match(numberregex))
        errors.speedLimit = "Enter the numbers";
    }

    if (!values.name) errors.name = "Geofence Name is required!";
    if (values.area === "") errors.area = "Draw shapes on the map!";
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      const formtransferdata = transferformValues(formValues);
      setGeofenceData(formtransferdata)
    }
  }, [formErrors])

  const CreateGeofence = (event) => {
    event.preventDefault();
    setFormError(validate(formValues));
    setIsSubmit(true);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  const handleGeofenceClose = () => {
    setFormValues(defaultValues)
    handleGeofClose()
  }

  return (<>
    <Modal
      open={Geofopen}
      onClose={handleGeofenceClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description" className="AddgeofModal"
    >
      <form onSubmit={CreateGeofence}>
        <Box sx={style} className="geof-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create Geofence
          </Typography>
          <span className="close-btn"><CloseIcon onClick={handleGeofenceClose} /></span>
          <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            <Card
              sx={{
                minHeight: 52,
                minWidth: 250,
                display: 'flex',
                width: '30%',
                height: '500px',
                overflow: "auto",
                flexDirection: 'column',
                '& a': {
                  textDecoration: 'none',
                  color: 'inherit',
                },
              }}
            >
              <Box textAlign="left" className='audit-left geofence-left'>
                <Typography color="textSecondary" variant="h" component="h4" className='Card-Title'
                  sx={{
                    marginBottom: '15px'
                  }}>Create New Geofence
                </Typography>
                <Box sx={{ marginTop: '1em' }}>
                  <FormControl>
                    <FormLabel>Geofence Name</FormLabel>
                    <FormField
                      name="name"
                      placeholder="Enter Name"
                      type="text"
                      value={formValues.name}
                      onChange={handleInputChange}
                    />
                    <div style={errorstyle}>
                      {formErrors.name}
                    </div>
                  </FormControl>
                </Box>

                <Box sx={{ marginTop: '1em' }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <div style={errorstyle}>
                      {formErrors.area}
                    </div>
                    <FormControl>
                      <FormLabel>Geofence Type</FormLabel>
                    </FormControl>
                    <Grid container spacing={2}>
                      <Grid item xs={4} sx={{ display: "flex", flexDirection: "column" }}>
                        <div className='button' onClick={() => { openTextbox(true); }}>
                          <input type="radio" name="geofencetype" value="circle" onChange={handleInputChange} onClick={() => { changebutton("circle"); }} />
                          <label class="btn btn-default" for="a25"><CircleIcon /><span>Circle</span></label>
                        </div>
                      </Grid>
                      <Grid item xs={4} sx={{ display: "flex", flexDirection: "column", paddingLeft: "1px !important" }}>
                        <div className='button' onClick={() => { openTextbox(false); setcoordinates({}); }}>
                          <input type="radio" name="geofencetype" value="polyline" onChange={handleInputChange} onClick={() => { changebutton("polyline") }} />
                          <label class="btn btn-default" for="a25"><PointerIcon /><span>Polyline</span></label>
                        </div>
                      </Grid>
                      <Grid item xs={4} sx={{ display: "flex", flexDirection: "column", paddingLeft: "1px !important" }}>
                        <div className='button' onClick={() => { openTextbox(false); setcoordinates({}); }}>
                          <input type="radio" name="geofencetype" value="polygon" onChange={handleInputChange} onClick={() => { changebutton("polygon") }} />
                          <label class="btn btn-default" for="a25"><PolygonIcon /><span>Polygon</span></label>
                        </div>
                      </Grid>

                    </Grid>
                  </Box>
                </Box>
                {opened === true ?
                  <>
                    <Box sx={{ marginTop: '1em' }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={5} sx={{ display: "flex", flexDirection: "column" }}>
                            <FormLabel>Latitude</FormLabel>
                            <FormField
                              name="latitude"
                              placeholder="Enter Latitude"
                              type="text"
                              value={formValues.latitude}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={5} sx={{ display: "flex", flexDirection: "column" }}>
                            <FormLabel>Longitude</FormLabel>
                            <FormField
                              name="longitude"
                              placeholder="Enter Longitude"
                              type="text"
                              value={formValues.longitude}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Button onClick={() => { circlegoClick(); }} className='show-btn add'
                              disabled={formValues.latitude == "" || formValues.longitude == ""}>
                              Go
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <hr></hr>
                    <Box className="range-slider" sx={{ marginTop: '1em' }}>
                      <FormLabel>Move the slider to set radius for this zone</FormLabel>
                      <div class="range-field">
                        <span>100 mtr</span>
                        <span>80 km</span>
                      </div>
                      <PrettoSlider
                        className='slider'
                        aria-label="pretto slider"
                        defaultValue={100}
                        step={100}
                        marks
                        min={100}
                        max={80000}
                        value={typeof radiusvalue === 'number' ? radiusvalue : 0}
                        onChange={handleSliderChange}
                      />
                      <div class="field">
                        <span>Selected range area</span>
                        <input type="number"
                          class="input-min"
                          value={raddisplayvalue.value}
                          size="medium"
                          onChange={handleRadiusChange}
                          onBlur={handleBlur}
                          max="80"
                          min="0.1"
                          step="0.1"
                        />
                        <span>{raddisplayvalue.type}</span>
                      </div>
                    </Box>
                  </>
                  :
                  <></>
                }

                <Box sx={{ marginTop: '1em' }}>
                  <FormControl>
                    <FormLabel>Description</FormLabel>
                    <TextareaAutosize
                      name="description"
                      aria-label="empty textarea"
                      placeholder='Description'
                      value={formValues.description}
                      onChange={handleInputChange} />
                  </FormControl>
                </Box>

                <Box sx={{ marginTop: '1em' }} >
                  <FormLabel>Choose Area Type</FormLabel>
                  <Box sx={{ display: "flex" }} className="left-checkbox">
                    <Box sx={{ marginTop: '10px', marginRight: "8px" }}>
                      <input type="checkbox" name="loadingPoint" checked={formValues.attributes.loadingPoint} onChange={handleAttributesChange} />
                      <FormLabel>Loading Point</FormLabel>
                    </Box>
                    <Box sx={{ marginTop: '10px', marginRight: "8px" }}>
                      <input type="checkbox" name="unloadingPoint" checked={formValues.attributes.unloadingPoint} onChange={handleAttributesChange} />
                      <FormLabel>Unloading point</FormLabel>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ marginTop: '1em' }}>
                  <FormControl>
                    <FormLabel>Max Speed(km)</FormLabel>
                    <FormField
                      name="speedLimit"
                      placeholder="Enter Value"
                      type="text"
                      value={formValues.attributes.speedLimit}
                      onChange={handleAttributesChange}
                    />
                    <div style={errorstyle}>
                      {formErrors.speedLimit}
                    </div>
                  </FormControl>
                </Box>

                <Box sx={{ marginTop: '1em' }}>
                  <Button type="submit" className='show-btn'>
                    Create
                  </Button>
                </Box>
              </Box>
            </Card>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
                height: '500px',
                marginLeft: '10px',
                '& a': {
                  textDecoration: 'none',
                  color: 'inherit',
                },
              }}
            >
              <>
                <GeofenceMap drawData={formValues} setPosition={setPosition} circlePoints={coordValue} buttonChange={buttonChange} drawmode={drawmode} setFormValues={setFormValues} setDrawMode={setDrawMode} />
              </>
            </Card>

          </Box>
        </Box>
      </form>
    </Modal>
    <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
  </>

  );

}