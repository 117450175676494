import React, { useState, useEffect } from "react";
import {
  Datagrid,
  TextField,
  useRecordContext,
  DateField,
  Button,
  useListController,
  ListContextProvider,
  TextInput,
  Pagination,
  ListToolbar,
  TopToolbar,
  SET_FILTER,
} from "react-admin";
import moment from "moment";
import { Box, Typography, Card } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import { LinearProgress } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField2 from "@mui/material/TextField";
import MiscReportType from "../views/reports/miscreportType";
import { default as ReactSelect } from "react-select";
import vtsDataProvider, {
  fetchDeviceDataMakeModel,
  fetchDeviceList,
  fetchGeofenceData,
  fetchGroupList,
  getData,
} from "../providers/dataProvider";


const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexRow: { display: "flex", flexDirection: "row" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const defaultValues = {
  geofenceId: null,
  deviceId: null,
  groupId: null,
  vltdMake: null,
  vltdModel: null,
  selectedGeofence: null,
  selectedVltdMake: null,
  selectedVltdModel: null,
  selectedDevice: null,
  selectedGroup: null,
  name: null,
  type: null,
  from: null,
  to: null,
  logType: null
}

export const Miscellaneous = (props) => {
  let todate = new Date();
  let prvDate = new Date(
    todate.getFullYear(),
    todate.getMonth() - 1,
    todate.getDate()
  );

  const { misgeofencereport, mispanicreport, misrepvioreport, misgenpanreport, misdevstareport, misdevhisreport, misvltdOemreport } = props;
  const [fromDate, setFromDate] = useState(prvDate);
  const [toDate, setToDate] = useState(new Date());

  const [formValues, setFormValues] = useState(defaultValues);

  const [formError, setFormError] = useState({
    disabled: true,
  });

  const [viewReport, setViewReport] = useState({
    data: {},
    expanded: false,
  });


  const [filteredData, setFilteredData] = useState([
    {
      label: "Select a Group to proceed!",
      value: "0",
      showlabel: "Select a Group to proceed!",
    },
  ]);

  const [deviceMakeRecords, setDeviceMakeRecords] = useState([]);
  const [deviceModelRecords, setDeviceModelRecords] = useState([]);
  const [geofenceRecords, setGeofenceRecords] = useState([]);
  const [deviceRecords, setDeviceRecords] = useState([]);
  const [groupRecords, setGroupRecords] = useState([]);

  const fromDatePickerRef = React.useRef(null);
  const toDatePickerRef = React.useRef(null);

  const handleFromDatePicker = () => {
    const dp = fromDatePickerRef.current;
    dp.setOpen(true);
  };

  const handleToDatePicker = () => {
    const dpt = toDatePickerRef.current;
    dpt.setOpen(true);
  };

  const [reportType, setReportType] = useState("Select");

  const handleReport = (event) => {
    setViewReport({
      data: [],
      expanded: false,
    });
    setFormValues(defaultValues)
    setFromDate(prvDate)
    setToDate(new Date())
    setReportType(event.target.value);
  };

  const [devicelogType, setDevicelogType] = useState("Select");

  const handleDevicelog = (event) => {
    setDevicelogType(event.target.value);
  };

  useEffect(() => {
    setFormValues({ ...formValues, logType: devicelogType })
  }, [devicelogType])

  const handleFromDate = (dates) => {
    setFromDate(dates);
  };

  const handleToDate = (dates) => {
    setToDate(dates);
  };

  const handleVltdMakeChange = (e) => {
    setFormValues({
      ...formValues,
      selectedVltdMake: e,
    });
  };

  const handleVltdModelChange = (e) => {
    setFormValues({
      ...formValues,
      selectedVltdModel: e,
    });
  };

  const handleGeofenceChange = (e) => {
    setFormValues({
      ...formValues,
      selectedGeofence: e,
    });
  };

  const handleDeviceChange = (e) => {
    setFormValues({
      ...formValues,
      selectedDevice: e,
    });
  };

  const handleGroupChange = (e) => {
    setFormValues({
      ...formValues,
      selectedGroup: e,
    });
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const formatDeviceMake = (list) => {
    var te = list?.map((data) => ({
      label: data?.make,
      value: data?.id,
    }));
    return te;
  };

  const formatDeviceModel = (list) => {
    var te = list?.map((data) => ({
      label: data?.model,
      value: data?.id,
    }));
    return te;
  };

  const formatGeofence = (list) => {
    var te = list?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
    return te;
  };

  const formatVehicle = (list) => {
    var te = list?.map((data) => ({
      label: data?.name,
      value: data?.id,
      groupId: data?.groupId,
    }));
    return te;
  };

  const formatGroup = (list) => {
    var te = list?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
    return te;
  };

  useEffect(() => {
    if (reportType !== "Device Logs") setDevicelogType("Select")

    const getDeviceData = async () => {
      const res = await fetchDeviceDataMakeModel("makedrop");
      if (!res) return;
      const dta = formatDeviceMake(res);
      const dtaModel = formatDeviceModel(res);
      setDeviceMakeRecords(dta);
      setDeviceModelRecords(dtaModel);
    };
    const getGeofenceData = async () => {
      const res = await fetchGeofenceData("geofences");
      if (!res) return;
      const dta = formatGeofence(res?.data);
      setGeofenceRecords(dta);
    };

    const getPanicData = async () => {
      const grps = await fetchGroupList("groupsdata");
      const gp = formatGroup(grps);
      setGroupRecords(gp);
      const res = await fetchDeviceList("vehicledata");
      const dta = formatVehicle(res);
      setDeviceRecords(dta);
      setFilteredData(dta);
    };
    const getDeviceLogData = async () => {
      const res = await fetchDeviceList("vehicledata");
      const dta = formatVehicle(res);
      setDeviceRecords(dta);
    };

    if (reportType == "Geofence Report") getGeofenceData();
    if (reportType == "SOS Panic Report") getPanicData();
    if (reportType == "Search by vltd Make and Model") getDeviceData();
    if (reportType === "Device Logs") getDeviceLogData();
  }, [reportType]);

  useEffect(() => {
    var queryvalue = document.querySelectorAll(".list-content");
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    const fe = { ...formError }
    if (reportType == "Geofence Report") {
      fe['disabled'] = false
      if (formValues['selectedGeofence'] == null) fe['disabled'] = true
      setFormError(fe)
    }
    if (reportType == "SOS Panic Report") {
      fe['disabled'] = false
      if (formValues['selectedDevice'] == null) fe['disabled'] = true
      setFormError(fe)
    }
    if (reportType == "Search by vltd Make and Model") {
      fe['disabled'] = false
      if (formValues['selectedVltdMake'] == null || formValues['selectedVltdModel'] == null) fe['disabled'] = true
      setFormError(fe)
    }
    if (reportType == "Login statistics") {
      fe['disabled'] = false
      if (formValues['name'] == null) fe['disabled'] = true
      setFormError(fe)
    }
    if (reportType == "Reported Violation" || reportType == "Generated Violation") {
      fe['disabled'] = false
      setFormError(fe)
    }
    if (reportType == "Device Logs") {
      fe['disabled'] = false
      if (formValues['selectedDevice'] == null || formValues['logType'] == "Select") fe['disabled'] = true
      setFormError(fe)
    }
  }, [reportType, formValues])


  useEffect(() => {
    if (reportType == "Login statistics") {
      setFormValues({
        ...formValues,
        selectedGeofence: null,
        selectedDevice: null,
        selectedGroup: null,
        selectedVltdMake: null,
        selectedVltdModel: null,
        logType: null
      })
    }
    if (reportType == "Search by vltd Make and Model") {
      setFormValues({
        ...formValues,
        selectedGeofence: null,
        selectedDevice: null,
        selectedGroup: null,
        name: null,
        logType: null
      })
    }
    if (reportType == "SOS Panic Report") {
      setFormValues({
        ...formValues,
        selectedGeofence: null,
        name: null,
        selectedVltdMake: null,
        selectedVltdModel: null,
        logType: null
      })
    }
    if (reportType == "Geofence Report") {
      setFormValues({
        ...formValues,
        name: null,
        selectedVltdMake: null,
        selectedVltdModel: null,
        selectedDevice: null,
        selectedGroup: null,
        logType: null
      })
    }

    if (reportType == "Geofence Report") {
      setFormValues({
        ...formValues,
        name: null,
        selectedVltdMake: null,
        selectedVltdModel: null,
        selectedDevice: null,
        selectedGroup: null,
        logType: null
      })
    }

    if (reportType == "Device Logs") {
      setFormValues({
        ...formValues,
        name: null,
        selectedVltdMake: null,
        selectedVltdModel: null,
        selectedGroup: null,
        selectedGeofence: null,
      })
    }
  }, [formError?.disabled])

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = { ...formValues };
    let formatFromDate = moment(fromDate).format("YYYY-MM-DD hh:mm");
    let formatToDate = moment(toDate).format("YYYY-MM-DD hh:mm");
    formData["from"] = moment.utc(formatFromDate).format();
    formData["to"] = moment.utc(formatToDate).format();

    if (reportType == "Geofence Report") {
      formData["type"] = "geofences";
      formData["geofenceId"] = formValues?.selectedGeofence?.value;
    }
    if (reportType == "SOS Panic Report") {
      formData["type"] = "panics";
      formData["deviceId"] = formValues?.selectedDevice?.value;
      formData["groupId"] = formValues?.selectedGroup?.value;
    }
    if (reportType == "Login statistics") {
      formData["type"] = "loginstats";
    }
    if (reportType == "Search by vltd Make and Model") {
      formData["type"] = "vltdmake";
      formData["vltdMake"] = formValues?.selectedVltdMake?.label;
      formData["vltdModel"] = formValues?.selectedVltdModel?.label;
    }
    if (reportType == "Reported Violation") {
      formData["type"] = "reportedViolation";
    }
    if (reportType == "Generated Violation") {
      formData["type"] = "generatedViolation";
    }
    if (reportType == "Device Logs") {
      formData["type"] = "devicelogs";
      formData["deviceId"] = formValues?.selectedDevice?.value;
    }


    setViewReport({
      data: formData,
      expanded: true,
    });
  };

  useEffect(() => {
    if (formValues?.selectedGroup != null) {
      const vh = deviceRecords?.filter(
        (obj) => obj?.groupId == formValues?.selectedGroup?.value
      );
      if (vh?.length == 0) {
        const cc = [
          {
            label: "NO VEHICLES IN THIS GROUP!",
            value: "0",
            showlabel: "NO VEHICLES IN THIS GROUP!",
          },
        ];

        setFormValues({
          ...formValues,
          selectedDevice: null
        });
        setFilteredData(cc);
      } else {
        setFilteredData(vh);
        setFormValues({
          ...formValues,
          selectedDevice: null
        });
      }
    }
  }, [formValues?.selectedGroup]);

  return (
    <>
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="miscellaneous-page"
      >
        <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
          <Card
            sx={{
              minHeight: 300,
              minWidth: 350,
              display: "flex",
              width: "25%",
              height: '78vh',
              overflow: "auto",
              flexDirection: "column",
              "& a": {
                textDecoration: "none",
                color: "inherit",
              },
            }}
          >
            <Box
              sx={{
                overflow: "inherit",
                padding: "16px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "& .icon": {
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "inherit" : "#dc2440",
                },
              }}
            >
              <Box textAlign="left" className="audit-left miscellaneous" height={"70vh"}>
                <Typography
                  color="textSecondary"
                  variant="h"
                  component="h4"
                  className="Card-Title"
                  sx={{
                    marginBottom: "0px",
                  }}
                >
                  Miscellaneous Reports
                </Typography>
                <Box sx={{ height: "380px" }}>
                  <Box sx={{ marginTop: "2px" }}>
                    <FormControl>
                      <FormLabel>Report Type</FormLabel>
                      <Select
                        sx={{ background: "#f0f0ff" }}
                        name="report"
                        value={reportType}
                        onChange={handleReport}
                      >
                        <MenuItem key={0} value="Select" selected>
                          Select
                        </MenuItem>
                        {misgeofencereport && (<MenuItem key={1} value="Geofence Report">
                          Geofence Report
                        </MenuItem>)}
                        {mispanicreport && (<MenuItem key={2} value="SOS Panic Report">
                          SOS Panic Report
                        </MenuItem>)}
                        {misdevstareport && (<MenuItem key={3} value="Login statistics">
                          Login statistics
                        </MenuItem>)}
                        {misvltdOemreport && (<MenuItem key={4} value="Search by vltd Make and Model">
                          Search by vltd Make and Model
                        </MenuItem>)}
                        {misrepvioreport && (<MenuItem key={5} value="Reported Violation">
                          Reported Violation
                        </MenuItem>)}
                        {misgenpanreport && (<MenuItem key={6} value="Generated Violation">
                          Generated Violation
                        </MenuItem>)}
                        {misdevhisreport && (<MenuItem key={7} value="Device Logs">
                          Device Logs
                        </MenuItem>)}
                      </Select>
                    </FormControl>
                  </Box>
                  {reportType == "Geofence Report" ? (
                    <>
                      <Box sx={{ marginTop: "2px" }} className="text-field select-component">
                        <FormLabel>Geofence</FormLabel>
                        <ReactSelect
                          name="geofenceId"
                          options={geofenceRecords}
                          value={formValues?.selectedGeofence}
                          onChange={handleGeofenceChange}
                        />
                      </Box>
                      <Box className="date-range" sx={{ marginTop: "2px" }}>
                        <FormControl>
                          <FormLabel>Date From</FormLabel>
                          <DatePicker
                            selected={fromDate}
                            onChange={(date) => handleFromDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="dd/MM/yyyy hh:mm aa"
                            ref={fromDatePickerRef}
                            maxDate={new Date()}
                          />
                          <IconButton
                            className="cal-icon"
                            onClick={handleFromDatePicker}
                          >
                            <CalendarIcon />
                          </IconButton>
                        </FormControl>
                      </Box>
                      <Box className="date-range" sx={{ marginTop: "2px" }}>
                        <FormControl>
                          <FormLabel>Date To</FormLabel>
                          <DatePicker
                            selected={toDate}
                            onChange={(date) => handleToDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="dd/MM/yyyy hh:mm aa"
                            ref={toDatePickerRef}
                            maxDate={new Date()}
                          />
                          <IconButton
                            className="cal-icon"
                            onClick={handleToDatePicker}
                          >
                            <CalendarIcon />
                          </IconButton>
                        </FormControl>
                      </Box>
                    </>
                  ) : reportType == "SOS Panic Report" ? (
                    <>
                      <Box sx={{ marginTop: "1em" }} className="text-field select-component">
                        <FormLabel>Group</FormLabel>
                        <ReactSelect
                          name="groupId"
                          options={groupRecords}
                          value={formValues?.selectedGroup}
                          onChange={handleGroupChange}
                        />
                      </Box>
                      <Box sx={{ marginTop: "1em" }} className="text-field select-component">
                        <FormLabel>Device</FormLabel>
                        <ReactSelect
                          name="deviceId"
                          options={filteredData}
                          value={formValues?.selectedDevice}
                          onChange={handleDeviceChange}
                        />
                      </Box>

                      <Box className="date-range top-down" sx={{ marginTop: "1em" }}>
                        <FormControl>
                          <FormLabel>Date From</FormLabel>
                          <DatePicker
                            selected={fromDate}
                            onChange={(date) => handleFromDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="dd/MM/yyyy hh:mm aa"
                            ref={fromDatePickerRef}
                            maxDate={new Date()}
                          />
                          <IconButton
                            className="cal-icon"
                            onClick={handleFromDatePicker}
                          >
                            <CalendarIcon />
                          </IconButton>
                        </FormControl>
                      </Box>
                      <Box className="date-range" sx={{ marginTop: "2px" }}>
                        <FormControl>
                          <FormLabel>Date To</FormLabel>
                          <DatePicker
                            selected={toDate}
                            onChange={(date) => handleToDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="dd/MM/yyyy hh:mm aa"
                            ref={toDatePickerRef}
                            maxDate={new Date()}
                          />
                          <IconButton
                            className="cal-icon"
                            onClick={handleToDatePicker}
                          >
                            <CalendarIcon />
                          </IconButton>
                        </FormControl>
                      </Box>
                    </>
                  ) : reportType == "Login statistics" ? (
                    <Box sx={{ marginTop: "2px" }} className="text-field">
                      <FormLabel>Name/Email</FormLabel>
                      <TextField2
                        name="name"
                        placeholder="Enter Name"
                        type="text"
                        value={formValues?.name}
                        onChange={handleChange}
                      />
                    </Box>
                  ) : reportType == "Search by vltd Make and Model" ? (
                    <>
                      <Box
                        sx={{ marginTop: "6px" }}
                        className="select-component"
                      >
                        <FormLabel>Vltd Make</FormLabel>
                        <ReactSelect
                          name="vltdMake"
                          options={deviceMakeRecords}
                          value={formValues?.selectedVltdMake}
                          onChange={handleVltdMakeChange}
                        />
                      </Box>
                      <Box
                        sx={{ marginTop: "6px" }}
                        className="select-component"
                      >
                        <FormLabel>Vltd Model</FormLabel>
                        <ReactSelect
                          name="vltdModel"
                          options={deviceModelRecords}
                          value={formValues?.selectedVltdModel}
                          onChange={handleVltdModelChange}
                        />
                      </Box>
                    </>
                  ) : reportType == "Reported Violation" ? (
                    <>

                      <Box className="date-range" sx={{ marginTop: "1em" }}>
                        <FormControl>
                          <FormLabel>Date From</FormLabel>
                          <DatePicker
                            selected={fromDate}
                            onChange={(date) => handleFromDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="dd/MM/yyyy hh:mm aa"
                            ref={fromDatePickerRef}
                            maxDate={new Date()}
                          />
                          <IconButton
                            className="cal-icon"
                            onClick={handleFromDatePicker}
                          >
                            <CalendarIcon />
                          </IconButton>
                        </FormControl>
                      </Box>
                      <Box className="date-range" sx={{ marginTop: "2px" }}>
                        <FormControl>
                          <FormLabel>Date To</FormLabel>
                          <DatePicker
                            selected={toDate}
                            onChange={(date) => handleToDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="dd/MM/yyyy hh:mm aa"
                            ref={toDatePickerRef}
                            maxDate={new Date()}
                          />
                          <IconButton
                            className="cal-icon"
                            onClick={handleToDatePicker}
                          >
                            <CalendarIcon />
                          </IconButton>
                        </FormControl>
                      </Box>
                    </>
                  ) : reportType == "Generated Violation" ? (
                    <>

                      <Box className="date-range" sx={{ marginTop: "1em" }}>
                        <FormControl>
                          <FormLabel>Date From</FormLabel>
                          <DatePicker
                            selected={fromDate}
                            onChange={(date) => handleFromDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="dd/MM/yyyy hh:mm aa"
                            ref={fromDatePickerRef}
                            maxDate={new Date()}
                          />
                          <IconButton
                            className="cal-icon"
                            onClick={handleFromDatePicker}
                          >
                            <CalendarIcon />
                          </IconButton>
                        </FormControl>
                      </Box>
                      <Box className="date-range" sx={{ marginTop: "2px" }}>
                        <FormControl>
                          <FormLabel>Date To</FormLabel>
                          <DatePicker
                            selected={toDate}
                            onChange={(date) => handleToDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="dd/MM/yyyy hh:mm aa"
                            ref={toDatePickerRef}
                            maxDate={new Date()}
                          />
                          <IconButton
                            className="cal-icon"
                            onClick={handleToDatePicker}
                          >
                            <CalendarIcon />
                          </IconButton>
                        </FormControl>
                      </Box>
                    </>
                  ) : reportType == "Device Logs" ? (
                    <>
                      <Box sx={{ marginTop: "1em" }} className="text-field select-component">
                        <FormLabel>Vehicle Number</FormLabel>
                        <ReactSelect
                          name="deviceId"
                          options={deviceRecords}
                          value={formValues?.selectedDevice}
                          onChange={handleDeviceChange}
                        />
                      </Box>
                      <Box sx={{ marginTop: "2px" }}>
                        <FormControl>
                          <FormLabel>Type</FormLabel>
                          <Select
                            sx={{ background: "#f0f0ff" }}
                            name="type"
                            value={devicelogType}
                            onChange={handleDevicelog}
                          >
                            <MenuItem key={0} value="Select" selected>
                              Select
                            </MenuItem>
                            <MenuItem key={1} value="History">
                              History
                            </MenuItem>
                            <MenuItem key={2} value="Live">
                              Live
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}

                  {devicelogType == "History" ? (
                    <>
                      <Box className="date-range top-down" sx={{ marginTop: "1em" }}>
                        <FormControl>
                          <FormLabel>Date From</FormLabel>
                          <DatePicker
                            selected={fromDate}
                            onChange={(date) => handleFromDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="dd/MM/yyyy hh:mm aa"
                            ref={fromDatePickerRef}
                            maxDate={new Date()}
                          />
                          <IconButton
                            className="cal-icon"
                            onClick={handleFromDatePicker}
                          >
                            <CalendarIcon />
                          </IconButton>
                        </FormControl>
                      </Box>
                      <Box className="date-range top-down" sx={{ marginTop: "1em" }}>
                        <FormControl>
                          <FormLabel>Date To</FormLabel>
                          <DatePicker
                            selected={toDate}
                            onChange={(date) => handleToDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="dd/MM/yyyy hh:mm aa"
                            ref={toDatePickerRef}
                            maxDate={new Date()}
                          />
                          <IconButton
                            className="cal-icon"
                            onClick={handleToDatePicker}
                          >
                            <CalendarIcon />
                          </IconButton>
                        </FormControl>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                  <Box sx={{ marginTop: "1em" }}>
                    <Button
                      className={`${reportType == "Select" || formError["disabled"]
                          ? "disable"
                          : ""
                        } show-btn`}
                      disabled={reportType == "Select" || formError["disabled"]}
                      onClick={handleSubmit}
                    >
                      Show
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
          {viewReport?.expanded ? (
            <MiscReportType viewReport={viewReport?.data} />
          ) : (
            false
          )}
        </Box>
      </Box>
    </>
  );
};
