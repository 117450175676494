// import * as React from "react";
import React, { useEffect, useState } from "react";
import {
  TopToolbar,
  useGetList,
  Button,
  useGetOne,
  useRefresh,
} from "react-admin";
import Box from "@mui/material/Box";
import MButton from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Tabs, Tab } from "@mui/material";
import Grid from "@mui/material/Grid";
import vtsDataProvider from "./../providers/dataProvider";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import EyeIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import "../views/users/users.css";
import SendNotification from "../utils/sendNotification";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { TabPanel } from "./../utils/TabPanel";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { calendarPickerSkeletonClasses } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const successstyle = {
  color: "blue",
  fontSize: "14px",
};

const errorstyle = {
  color: "red",
  fontSize: "12px",
};

let date = new Date();
const defaultValues = {
  name: "",
  phone: "",
  email: "",
  password: "",
  roleid: 0,
  map: "",
  latitude: "",
  longitude: "",
  zoom: "",
  poiLayer: "",
  coordinateFormat: "",
  twelveHourFormat: "",
  expirationTime: "",
  deviceLimit: "",
  userLimit: "",
  disabled: false,
  administrator: false,
  readonly: false,
  deviceReadonly: false,
  limitCommands: false,
  createdon: date,
  loginFrom: "00:00",
  loginTo: "00:00",
};

export const AddUserDetails = ({
  listContext,
  Accountopen,
  EditUserId,
  handleAccountClose,
}) => {
  const [notifyData, setNotifyData] = useState({});
  const [open, setOpen] = useState(false);
  const [Tabvalue, setTabValue] = React.useState(0);
  const [roleValue, setRoleValue] = useState(null);
  const [formErrors, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [formValues, setFormValues] = useState(defaultValues);
  const [userroles, setUserrole] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [opendrp, setOpendrp] = useState(false);
  const [newtab, setNewTab] = useState(0);
  const refresh = useRefresh();
  const loading = open && userroles?.length === 0;
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(false);

  var today = new Date();
  today = new Date(today.setDate(today.getDate() + 1))
    .toISOString()
    .split("T")[0];

  const { data: userrole, total, isLoading } = useGetList("roledrop");
  const { data: EditUserData } = useGetOne(
    "users",
    { id: EditUserId },
    { enabled: EditUserId !== 0 }
  );

  useEffect(() => {
    if (Accountopen === false) {
      setConfirmPassword("");
      setFormError({});
      setIsSubmit(false);
      setNewTab(0);
    } else {
      if (EditUserId === 0) {
        setFormValues(defaultValues);
        setRoleValue(null);
      }
    }
  }, [Accountopen]);

  useEffect(() => {
    const getuserdata = () => {
      if (userrole) {
        const list = format_drpdown(userrole);
        setUserrole(list);
      }
    };
    getuserdata();
  }, [userrole]);

  const format_drpdown = (list) => {
    var te = list?.map((data, index) => ({
      label:
        (data?.id).toString() +
        " - " +
        (data?.rolename === null ? "" : data?.rolename.toString()),
      value: data?.id,
      showlabel: data?.rolename,
    }));
    return te;
  };

  useEffect(() => {
    const getEditUserData = () => {
      if (EditUserData) {
        setFormValues({
          id: EditUserData.id,
          name: EditUserData.name,
          email: EditUserData.email,
          phone: EditUserData.phone,
          map: EditUserData.map,
          latitude: EditUserData?.latitude === 0 ? "" : EditUserData?.latitude,
          longitude:
            EditUserData?.longitude === 0 ? "" : EditUserData?.longitude,
          zoom: EditUserData?.zoom === 0 ? "" : EditUserData?.zoom,
          twelveHourFormat: EditUserData.twelveHourFormat,
          coordinateFormat: EditUserData.coordinateFormat,
          poiLayer: EditUserData.poiLayer,
          disabled: EditUserData.disabled,
          administrator: EditUserData.administrator,
          readonly: EditUserData.readonly,
          deviceReadonly: EditUserData.deviceReadonly,
          limitCommands: EditUserData.limitCommands,
          expirationTime: EditUserData.expirationTime
            ? moment(EditUserData.expirationTime).format("YYYY-MM-DD")
            : null,
          userLimit:
            EditUserData?.userLimit === 0 ? "" : EditUserData?.userLimit,
          deviceLimit:
            EditUserData?.deviceLimit === 0 ? "" : EditUserData?.deviceLimit,
          roleid: EditUserData.roleid,
          createdon: EditUserData.createdon,
          lastUpdate: date,
          loginFrom: EditUserData.loginFrom,
          loginTo: EditUserData.loginTo,
        });

        setTimeout(() => {
          var value = userroles.filter(function (item) {
            return item.value === EditUserData.roleid;
          });

          if (value) setRoleValue(value[0]);
          else setRoleValue(null);
        }, 500);
        EditUserId = 0;
      }
    };
    getEditUserData();
  }, [EditUserData, userroles, EditUserId]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let regex = /^[A-Za-z0-9-_ ]+$/;
    if (name == "name") {
      if (value == "" || regex.test(value)) {
        setFormValues({
          ...formValues,
          [name]: type === "checkbox" ? checked : value,
        });
      }
    } else if (name == "phone" || name == "zoom") {
      const reg = /^[0-9\b]+$/;
      if (value == "" || reg.test(value)) {
        setFormValues({
          ...formValues,
          [name]: type === "checkbox" ? checked : value,
        });
      }
    } else if (name == "email") {
      let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      setInvalidEmail(false);
      if (value != null || value != "") {
        if (!value.match(regexEmail)) {
          setInvalidEmail(true);
        }
      }
      setFormValues({
        ...formValues,
        [name]: type === "checkbox" ? checked : value,
      });
    } else if (name == "password") {
      setInvalidPassword(false);
      let pwregex =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{10,20}$/;

      if (value == "" || !pwregex.test(value)) {
        setInvalidPassword(true);
      }
      setFormValues({
        ...formValues,
        [name]: type === "checkbox" ? checked : value,
      });
    } else if (name == "deviceLimit" || name == "userLimit") {
      let regexnumber = /^[0-9]*\.*\-?[0-9]+$/;
      if (value == "" || regexnumber.test(value)) {
        setFormValues({
          ...formValues,
          [name]: type === "checkbox" ? checked : value,
        });
      }
    } else {
      setFormValues({
        ...formValues,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (formValues?.id > 0) {
        vtsDataProvider.update(
          "users",
          formValues,
          setNotifyData,
          "User Updated Successfully",
          "Failed to Update User"
        );
      } else {
        vtsDataProvider.create(
          "users",
          formValues,
          setNotifyData,
          "User Created Successfully",
          "Failed to create user"
        );
      }
      setConfirmPassword("");
      setFormError({});
      setIsSubmit(false);
      handleAccountClose();
    } else {
      setIsSubmit(false);
      if (
        "name" in formErrors ||
        "phone" in formErrors ||
        "email" in formErrors ||
        "password" in formErrors ||
        "confirmPassword" in formErrors ||
        "rolename" in formErrors
      )
        setTabValue(0);
      else if (
        "latitude" in formErrors ||
        "longitude" in formErrors ||
        "zoom" in formErrors
      )
        setTabValue(1);
      else if ("deviceLimit" in formErrors || "userLimit" in formErrors)
        setTabValue(2);
      else setTabValue(newtab);
    }
  }, [formErrors]);

  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormError(validate(formValues));
    setIsSubmit(true);
  };

  const validate = (values) => {
    const errors = {};
    const phoneregex = "^\\d{10}$";
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let regexPassword =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&#])([a-zA-Z0-9@$!%*?&]{10,})$/;
    let regexnumber = /\+?\d+/;
    let pattern = new RegExp("^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}");
    if (!values.name) errors.name = "Name is required!";
    if (!values.phone) errors.phone = "Phone is required!";
    if (!values.email) errors.email = "Email is required!";
    if (
      formValues?.id === 0 ||
      formValues?.id === undefined ||
      formValues?.id === null
    ) {
      if (!values.password) errors.password = "Password is required!";
      if (values.password !== "") {
        if (!values.password.match(regexPassword))
          errors.password =
            "This is not a valid. Password should be Minimum ten characters, at least one Capital letter, one number and one special character. '#' and '.' are not allowed!";
      }
      if (!confirmPassword) errors.confirmPassword = "Enter Confirm Password!";
    }
    if (roleValue === null) errors.rolename = "Role is required!";
    if (values.latitude !== "") {
      if (!pattern.test(values.latitude))
        errors.latitude = "This is not a valid latitude!";
    }
    if (values.longitude !== "") {
      if (!pattern.test(values.longitude))
        errors.longitude = "This is not a valid longitude!";
    }
    if (values.email !== "") {
      if (!values.email.match(regexEmail))
        errors.email = "This is not a valid email format!";
    }
    if (values.phone !== "") {
      if (!values.phone.match(phoneregex))
        errors.phone = "Phone Number must be 10 digit!";
    }
    if (values.password !== "" && confirmPassword !== "") {
      if (values.password !== confirmPassword)
        errors.confirmPassword = "Passwords don't match!";
    }
    if (values?.deviceLimit !== "") {
      if (!String(values?.deviceLimit).match(regexnumber))
        errors.deviceLimit = "Numbers only allowed!";
    }
    if (values?.zoom !== "") {
      if (!String(values?.zoom).match(regexnumber))
        errors.zoom = "Numbers only allowed!";
    }
    if (values?.userLimit !== "") {
      if (!String(values?.userLimit).match(regexnumber))
        errors.userLimit = "Numbers only allowed!";
    }
    return errors;
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const Tabhandle = (event, newValue) => {
    setTabValue(newValue);
  };

  const EntitytabChange = (newValue) => {
    setNewTab(newValue);
    setFormError(validate(formValues));
  };

  const totaluser =
    typeof listContext?.total === "undefined"
      ? 0
      : listContext?.total?.toString();
  return (
    <>
      <Modal
        open={Accountopen}
        onClose={handleAccountClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="AdduserModal"
      >
        <Box sx={style} className="modal-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {formValues?.id > 0 ? "Edit User" : "Add User"}
          </Typography>
          <span className="close-btn" onClick={handleAccountClose}>
            <CloseIcon />
          </span>
          <form onSubmit={handleSubmit}>
            <Box sx={{ flexGrow: 1, marginTop: "2em" }}>
              <Grid
                container
                spacing={2}
                sx={{ marginLeft: "-7px !important" }}
              >
                <Grid item xs={3} className="left-side">
                  <Tabs
                    value={Tabvalue}
                    onChange={Tabhandle}
                    className="Addrole-tabs"
                  >
                    <Tab label="Personal Information" />
                    <Tab label="Preference" />
                    <Tab label="Permission" />
                  </Tabs>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sx={{ paddingLeft: "5px" }}
                  className="right-side"
                >
                  <TabPanel value={Tabvalue} index={0}>
                    <Box sx={{ flexGrow: 1 }} className="div-box">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6" component="h4">
                            User Details
                          </Typography>
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "15px" }}>
                                  <FormLabel>Name</FormLabel>
                                  <TextField
                                    name="name"
                                    placeholder="Enter Name"
                                    type="text"
                                    value={formValues.name}
                                    onChange={handleInputChange}
                                  />
                                  <div style={errorstyle}>
                                    {formErrors.name}
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "15px" }}>
                                  <FormLabel>Phone</FormLabel>
                                  <TextField
                                    name="phone"
                                    placeholder="10 Digit Mobile No"
                                    type="tel"
                                    value={formValues.phone}
                                    onChange={handleInputChange}
                                    inputProps={{ maxLength: 10 }}
                                  />
                                  <div style={errorstyle}>
                                    {formErrors.phone}
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "0px" }}>
                                  <FormLabel>Email Address</FormLabel>
                                  <TextField
                                    name="email"
                                    placeholder="Enter Email Address"
                                    type="email"
                                    autoComplete="false"
                                    value={formValues.email}
                                    onChange={handleInputChange}
                                    error={invalidEmail}
                                    helperText={
                                      invalidEmail ? "Email not valid!" : ""
                                    }
                                  />
                                  <div style={errorstyle}>
                                    {formErrors.email}
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "0px" }}>
                                  <FormControl sx={{ marginTop: "0px" }}>
                                    <FormLabel>Role</FormLabel>
                                    <Autocomplete
                                      isOptionEqualToValue={(option, value) =>
                                        option.value === value?.value
                                      }
                                      disablePortal
                                      id="roleid"
                                      name="roleid"
                                      className="select-auto"
                                      options={userroles}
                                      renderOption={(props, option) => (
                                        <li {...props} key={option.value}>
                                          {option.showlabel}
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Select Role"
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <React.Fragment>
                                                {loading ? (
                                                  <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                  />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                              </React.Fragment>
                                            ),
                                          }}
                                        />
                                      )}
                                      open={opendrp}
                                      onOpen={() => {
                                        setOpendrp(true);
                                      }}
                                      onClose={() => {
                                        setOpendrp(false);
                                      }}
                                      loading={loading}
                                      getOptionLabel={(option) =>
                                        option.showlabel
                                      }
                                      onChange={(event, newValue) => {
                                        setRoleValue(newValue);
                                        setFormValues({
                                          ...formValues,
                                          roleid: newValue.value,
                                        });
                                      }}
                                      value={roleValue}
                                    />
                                  </FormControl>
                                  <div style={errorstyle}>
                                    {formErrors.rolename}
                                  </div>
                                </Box>
                              </Grid>
                              {formValues?.id > 0 ? (
                                ""
                              ) : (
                                <>
                                  {" "}
                                  <Grid item xs={12} sx={{ marginTop: "0px" }}>
                                    <Typography variant="h6" component="h4">
                                      Set Password
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Box>
                                      <FormLabel>Enter Password</FormLabel>
                                      <TextField
                                        name="password"
                                        placeholder="Enter Password"
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        autoComplete="false"
                                        value={formValues.password}
                                        onChange={handleInputChange}
                                        error={invalidPassword}
                                        helperText={
                                          invalidPassword
                                            ? "Password should be Minimum ten characters, max 20, at least one Capital letter, one number and one special character required!"
                                            : ""
                                        }
                                        inputProps={{ maxLength: 20 }}
                                      />

                                      {showPassword ? (
                                        <EyeIcon
                                          onClick={togglePassword}
                                          sx={{
                                            position: "relative",
                                            left: "212px",
                                            bottom: "33px",
                                            width: "0.8em !important",
                                          }}
                                        />
                                      ) : (
                                        <VisibilityOffIcon
                                          onClick={togglePassword}
                                          sx={{
                                            position: "relative",
                                            left: "212px",
                                            bottom: "33px",
                                            width: "0.8em !important",
                                          }}
                                        />
                                      )}
                                      <div
                                        style={errorstyle}
                                        className="error-style"
                                      >
                                        {formErrors.password}
                                      </div>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Box>
                                      <FormLabel>Confirm Password</FormLabel>
                                      <TextField
                                        name="confirmPassword"
                                        placeholder="Enter Password"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPassword}
                                      />
                                      <div style={errorstyle}>
                                        {formErrors.confirmPassword}
                                      </div>
                                    </Box>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <div style={{ marginTop: "1em" }} className="modal-footer">
                      <MButton
                        variant="contained"
                        color="primary"
                        onClick={() => EntitytabChange(1)}
                      >
                        Next
                      </MButton>
                    </div>
                  </TabPanel>
                  <TabPanel value={Tabvalue} index={1}>
                    <Box sx={{ flexGrow: 1 }} className="div-box">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6" component="h4">
                            Enter Preferences
                          </Typography>
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>Map Layer</FormLabel>
                                  <Select
                                    sx={{ background: "#f0f0ff" }}
                                    placeholder="Select"
                                    name="map"
                                    value={formValues.map}
                                    onChange={handleInputChange}
                                  >
                                    <MenuItem value="custom">Custom</MenuItem>
                                    <MenuItem value="osm">osm</MenuItem>
                                  </Select>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>Latitude</FormLabel>
                                  <TextField
                                    name="latitude"
                                    placeholder="Latitude"
                                    type="tel"
                                    value={formValues.latitude}
                                    onChange={handleInputChange}
                                  />
                                  <div style={errorstyle}>
                                    {formErrors.latitude}
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>Longitude</FormLabel>
                                  <TextField
                                    name="longitude"
                                    placeholder="Enter"
                                    type="text"
                                    autoComplete="false"
                                    value={formValues.longitude}
                                    onChange={handleInputChange}
                                  />
                                  <div style={errorstyle}>
                                    {formErrors.longitude}
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormControl>
                                    <FormLabel>Zoom</FormLabel>
                                    <TextField
                                      name="zoom"
                                      placeholder="Enter"
                                      type="tel"
                                      autoComplete="false"
                                      value={formValues.zoom}
                                      onChange={handleInputChange}
                                    />
                                  </FormControl>
                                  <div style={errorstyle}>
                                    {formErrors.zoom}
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>POI Layer</FormLabel>
                                  <TextField
                                    name="poiLayer"
                                    placeholder="Enter"
                                    type="text"
                                    autoComplete="false"
                                    value={formValues.poiLayer}
                                    onChange={handleInputChange}
                                  />
                                  <div style={errorstyle}></div>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormControl>
                                    <FormLabel>Coordinate Format</FormLabel>
                                    <TextField
                                      name="coordinateFormat"
                                      placeholder="Enter"
                                      type="text"
                                      autoComplete="false"
                                      value={formValues.coordinateFormat}
                                      onChange={handleInputChange}
                                    />
                                  </FormControl>
                                  <div style={errorstyle}></div>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{ display: "flex" }}
                                  className="left-checkbox"
                                >
                                  <Box
                                    sx={{
                                      marginTop: "10px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="twelveHourFormat"
                                      checked={formValues.twelveHourFormat}
                                      onChange={handleInputChange}
                                    />
                                    <FormLabel>12 Hour Format</FormLabel>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <div style={{ marginTop: "1em" }} className="modal-footer">
                      <MButton
                        variant="contained"
                        color="primary"
                        onClick={() => EntitytabChange(2)}
                      >
                        Next
                      </MButton>
                    </div>
                  </TabPanel>

                  <TabPanel value={Tabvalue} index={2}>
                    <Box sx={{ flexGrow: 1 }} className="div-box">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6" component="h4">
                            Set Permissions
                          </Typography>
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>Expiration</FormLabel>
                                  <input
                                    name="expirationTime"
                                    type="date"
                                    value={formValues.expirationTime}
                                    onChange={handleInputChange}
                                    min={today}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>Device Limit</FormLabel>
                                  <TextField
                                    name="deviceLimit"
                                    placeholder="Enter"
                                    type="text"
                                    value={formValues.deviceLimit}
                                    onChange={handleInputChange}
                                  />
                                  <div style={errorstyle}>
                                    {formErrors.deviceLimit}
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>User limit</FormLabel>
                                  <TextField
                                    name="userLimit"
                                    placeholder="Enter"
                                    type="tel"
                                    autoComplete="false"
                                    value={formValues.userLimit}
                                    onChange={handleInputChange}
                                  />
                                  <div style={errorstyle}>
                                    {formErrors.userLimit}
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item xs={6}></Grid>
                              <Grid item xs={12}>
                                <Box
                                  sx={{ display: "flex" }}
                                  className="left-checkbox"
                                >
                                  <Box
                                    sx={{
                                      marginTop: "10px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="disabled"
                                      checked={formValues.disabled}
                                      onChange={handleInputChange}
                                    />
                                    <FormLabel>Disabled</FormLabel>
                                  </Box>
                                  <Box
                                    sx={{
                                      marginTop: "10px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="limitCommands"
                                      checked={formValues.limitCommands}
                                      onChange={handleInputChange}
                                    />
                                    <FormLabel>Limit Commands</FormLabel>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ flexGrow: 1 }}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                      <Box sx={{ marginTop: "1em" }}>
                                        <FormLabel>Login From</FormLabel>
                                        <input
                                          type="time"
                                          id="loginFrom"
                                          name="loginFrom"
                                          value={formValues?.loginFrom}
                                          onChange={handleInputChange}
                                          required
                                        />
                                      </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Box sx={{ marginTop: "1em" }}>
                                        <FormLabel>Login To</FormLabel>
                                        <input
                                          type="time"
                                          id="loginTo"
                                          name="loginTo"
                                          value={formValues?.loginTo}
                                          onChange={handleInputChange}
                                          required
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <div style={{ marginTop: "1em" }} className="modal-footer">
                      <MButton
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {formValues.id > 0 ? "Update" : "Create"}
                      </MButton>
                      <MButton
                        variant="contained"
                        onClick={handleAccountClose}
                        className="cancel-btn"
                        color="primary"
                        sx={{ marginRight: "1em" }}
                      >
                        Cancel
                      </MButton>
                    </div>
                  </TabPanel>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Modal>
      <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
    </>
  );
};
