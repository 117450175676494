import React, { useEffect } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Tabs, Tab } from '@mui/material';
import { UserList } from '../../components/UsersList';
import { UserroleList } from '../../components/UserRoleList';
import "./users.css";
import useTabPermissions from "../../hooks/useTabPermissions";

const TabPanel = props => {

  const { index, value, children } = props;
  return (
    <React.Fragment>
      {value === index && (

        <Typography>{children}</Typography>

      )}
    </React.Fragment>
  )
}
export const UsersDetails = () => {
  const [value, setValue] = React.useState(0);
  const tabView = useTabPermissions('users')
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Tabs value={value} onChange={handleChange}>
          {tabView && (tabView?.viewUser) && <Tab label="User" />}
          {tabView && (tabView?.viewUserRole) && <Tab label="Role" />}
        </Tabs>
        <TabPanel value={value} index={0}>
          {tabView && (tabView?.readUser) &&
            (<UserList
              createper={tabView.createUser}
              updateper={tabView.updateUser}
              deleteper={tabView.deleteUser} />)}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {tabView && (tabView?.readUserRole) &&
            (
              <UserroleList
                createper={tabView.createUserRole}
                updateper={tabView.updateUserRole}
                deleteper={tabView.deleteUserRole}
              />)}
        </TabPanel>
      </Box>
    </>
  );
}
