import React, { useState } from "react";
import { useRecordContext } from "react-admin";
import VehicleIcon from "@mui/icons-material/AirportShuttle";
import { Tooltip } from "@mui/material";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

const VehicleIconField = (props) => {
  const { source } = props;
  const record = useRecordContext();
  const [hover, setHover] = useState(false);

  const getVehicleIcon = (record) => {
    switch (record[source]) {
      case "Truck":
        return (
          <LocalShippingIcon
            sx={{ fill: "black", fontSize: "12px", marginRight: "2px" }}
          />
        );
      case "Bike":
        return (
          <TwoWheelerIcon
            sx={{ fill: "black", fontSize: "12px", marginRight: "2px" }}
          />
        );
      case "Bus":
        return (
          <DirectionsBusIcon
            sx={{ fill: "black", fontSize: "12px", marginRight: "2px" }}
          />
        );
      case "Taxi":
        return (
          <LocalTaxiIcon
            sx={{ fill: "black", fontSize: "12px", marginRight: "2px" }}
          />
        );
      default:
        return (
          <VehicleIcon
            sx={{ fill: "black", fontSize: "12px", marginRight: "2px" }}
          />
        );
    }
  };

  const icon = getVehicleIcon(record);

  return (
    <>
      {record[source] && (
        <Tooltip
          title={record[source]}
          open={hover}
          onClose={() => setHover(false)}
          onOpen={() => setHover(true)}
        >
          <span>{icon}</span>
        </Tooltip>
      )}
    </>
  );
};

export default VehicleIconField;
