import React, { useEffect, useState } from 'react';
import { Button, useGetList, useListController } from "react-admin";
import moment from 'moment';
import {
    Box, Typography,
    Card
} from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import CarIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import RouteIcon from '@mui/icons-material/RouteOutlined';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';
import PlayIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import FormLabel from "@mui/material/FormLabel";
import ArrowIcon from '@mui/icons-material/ArrowBackOutlined';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import '../views/trails/trail.css';
import CardActions from '@mui/material/CardActions';
import vtsDataProvider, { getTriptrails, getVehicleLocation } from './../providers/dataProvider';
import TripsTrailRouting from './../components/TripsTrailRouting';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Autocomplete from "@mui/material/Autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import IconButton from '@mui/material/IconButton';
import CircleIcon from "@mui/icons-material/Circle";


const errorstyle = {
    color: "red",
    fontSize: "12px",
};

const defaultValues = {
    from: "",
    to: "",
    deviceId: ""
}
const defaultValues1 = {
    filter: {
        from: "",
        to: "",
        deviceId: ""
    }
}

const data = [
    {
        Ignition: 20,
        Speed: 5,
        amt: 20
    },
    {
        Ignition: 40,
        Speed: 10,
        amt: 40
    },
    {
        Ignition: 60,
        Speed: 20,
        amt: 60
    },
    {
        Ignition: 80,
        Speed: 40,
        amt: 80
    },
    {
        Ignition: 100,
        Speed: 60,
        amt: 100
    },
    {
        Ignition: 120,
        Speed: 80,
        amt: 120
    },
    {
        Ignition: 140,
        Speed: 100,
        amt: 140
    }
];



export const TripTrails = () => {
    const [devices, setDevices] = React.useState([]);
    const [formValues, setFormValues] = React.useState(defaultValues);
    const [expanded, setExpanded] = React.useState(false);
    const [TrailsRouteView, setTrailsRouteView] = React.useState(false);
    const [filter, setFilter] = React.useState(defaultValues1);
    const [trailsData, setTrailsData] = React.useState([]);
    const [playRoutesData, setPlayRoutesData] = React.useState([]);
    const [tripdetails, settripDetails] = useState([]);
    const [DeviceValue, setDeviceValue] = useState(null);
    const [ReportsRoute, setReportsRoute] = useState([]);
    const [RouteData, setRouteData] = useState([]);
    const [linecheck, setLineCheck] = useState(false);
    const [formError, setFormError] = useState({});
    const [open, setOpen] = useState(false);
    const loading = open && devices?.length === 0;
    let todate = new Date();
    let fromdate = addDays(new Date(), -90)
    const [dateRange, setDateRange] = React.useState([fromdate, todate]);
    const [startDate, endDate] = dateRange;
    const datePickerRef = React.useRef(null)
    const { data: devicelist } = useListController({
        resource: 'devices'
    });
    const [isLoading, setLoading] = useState(false);
    const [dateselect, setDateSelect] = useState("Select")

    let prvDate = new Date(
        todate.getFullYear(),
        todate.getMonth() - 1,
        todate.getDate()
    );

    const [fromDate, setFromDate] = useState(prvDate);
    const [toDate, setToDate] = useState(new Date());


    var date = new Date();
    var formatedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    var StaringDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
    var placeholderDate = StaringDate + " - " + formatedDate;


    const fromDatePickerRef = React.useRef(null);
    const toDatePickerRef = React.useRef(null);

    const handleFromDatePicker = () => {
        const dp = fromDatePickerRef.current;
        dp.setOpen(true);
    };

    const handleToDatePicker = () => {
        const dpt = toDatePickerRef.current;
        dpt.setOpen(true);
    };

    const handleFromDate = (dates) => {
        setFromDate(dates);
    };

    const handleToDate = (dates) => {
        setToDate(dates);
    };



    useEffect(() => {
        setExpanded(false)
        setTrailsRouteView(false)
    }, [])

    const handleSelectDate = (event) => {
        setDateSelect(event.target.value);
    };

    useEffect(() => {
        if (devicelist) {
            const list = formatDevices(devicelist);
            setDevices(list);
        }
    }, [loading]);

    const handleDatePicker = () => {
        const dp = datePickerRef.current
        dp.setOpen(true)
    }

    function addDays(date, days) {
        date.setDate(date.getDate() + days);
        return date;
    }

    function addHours(date, hours) {
        date.setHours(date.getHours() + hours);
        return date;
    }

    useEffect(() => {
        if (DeviceValue) {
            setFormValues({
                ...formValues,
                deviceId: DeviceValue.value,
                devicename: DeviceValue.showlabel,
            });
        }
    }, [DeviceValue])

    const formatDevices = (list) => {
        var te = list?.map((data, index) => ({
            label: (data?.id).toString() + " - " + (data?.name).toString(),
            value: data?.id,
            showlabel: data?.name,
        }));
        return te;
    };

    const convert = (str) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const Dateformat = (source) => {
        const dt = (source !== null) ? moment(source).format("DD MMM YYYY hh:mm:ss") : "";
        return dt;
    };

    const msToTime = (duration) => {
        var milliseconds = parseInt((duration % 1000) / 100),
            seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return hours + ":" + minutes + ":" + seconds;

        // + "." + milliseconds;
    }

    const diffdays = (date1, date2) => {
        // To calculate the time difference of two dates
        var Difference_In_Time = date2.getTime() - date1.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        return Difference_In_Days;
    }


    const formTrackSubmit = async () => {
        if (formValues.deviceId === "") {
            setFormError({ deviceId: "Select Device" })
        }
        else if (dateselect === "Select") {
            setFormError({ dateselect: "Select Date Range" })
        }
        else {
            if (dateselect === "custom") {
                if (diffdays(startDate, endDate) > 90) {
                    setFormError({ datemsg: "Select 90 days difference between from and to date" })
                    return;
                }
            }
            setFormError({})
            let todate = ""
            let fromdate = ""

            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

            if (dateselect === "last8h") {
                todate = new Date();
                fromdate = addHours(new Date(), -8)

                fromdate = (new Date(fromdate - tzoffset)).toISOString()
                todate = (new Date(todate - tzoffset)).toISOString()

            }
            else if (dateselect === "last7d") {
                todate = new Date();
                fromdate = addDays(new Date(), -7)

                fromdate = (new Date(fromdate - tzoffset)).toISOString()
                todate = (new Date(todate - tzoffset)).toISOString()
            }
            else if (dateselect === "last90d") {
                todate = new Date();
                fromdate = addDays(new Date(), -90)

                fromdate = (new Date(fromdate - tzoffset)).toISOString()
                todate = (new Date(todate - tzoffset)).toISOString()
            }
            else if (dateselect === "custom") {

                let formatFromDate = moment(fromDate).format("YYYY-MM-DD hh:mm");
                let formatToDate = moment(toDate).format("YYYY-MM-DD hh:mm");
                fromdate = moment.utc(formatFromDate).format();
                todate = moment.utc(formatToDate).format();
            }

            const filter = {
                filter: {
                    deviceId: formValues.deviceId,
                    from: fromdate,
                    to: todate
                }
            }

            const { data: trailsdata, total } = await vtsDataProvider.getList("triptrails", filter)
            setTrailsData(trailsdata)
            setExpanded(true);
        }

    }

    const playTrails = (playData) => {
        setPlayRoutesData(playData);
        setTrailsRouteView(true);
    }

    const playTrailsBack = () => {
        setTrailsRouteView(false);
        setPlayRoutesData([])
        setExpanded(true)
    }


    return (<>
        {TrailsRouteView === false ?
            <>
                <Box className="map-content">
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                            <Card
                                sx={{
                                    minHeight: 52,
                                    minWidth: 250,
                                    display: 'flex',
                                    width: '33%',
                                    flexDirection: 'column',
                                    marginRight: '10px',
                                    '& a': {
                                        textDecoration: 'none',
                                        color: 'inherit',
                                    },
                                }}
                            >

                                <Box
                                    sx={{
                                        overflow: 'inherit',
                                        padding: '16px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        '& .icon': {
                                            color: theme =>
                                                theme.palette.mode === 'dark'
                                                    ? 'inherit'
                                                    : '#dc2440',
                                        },
                                    }}
                                >
                                    {expanded === false ?
                                        <Box textAlign="left">
                                            <Typography color="textSecondary" variant="h" component="h4" className='Card-Title'
                                                sx={{
                                                    marginBottom: '15px'
                                                }}>Trip Trails
                                            </Typography>
                                            <Box sx={{ height: "380px" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Box sx={{ marginTop: '1em' }} className="select-device">
                                                            <FormLabel>Device</FormLabel>
                                                            <Autocomplete
                                                                isOptionEqualToValue={(option, value) =>
                                                                    option.value === value.value
                                                                }
                                                                disablePortal
                                                                id="deviceId"
                                                                name="deviceId"
                                                                options={devices}
                                                                renderOption={(props, option) => (
                                                                    <li {...props} key={option.value}>
                                                                        {option.showlabel}
                                                                    </li>
                                                                )}

                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Select Device"
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                                <React.Fragment>
                                                                                    {loading ? (
                                                                                        <CircularProgress color="inherit" size={20} />
                                                                                    ) : null}
                                                                                    {params.InputProps.endAdornment}
                                                                                </React.Fragment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                )}
                                                                open={open}
                                                                onOpen={() => {
                                                                    setOpen(true);
                                                                }}
                                                                onClose={() => {
                                                                    setOpen(false);
                                                                }}
                                                                loading={loading}
                                                                getOptionLabel={(option) => option.showlabel}
                                                                onChange={(event, newValue) => {
                                                                    setDeviceValue(newValue);
                                                                }}
                                                                value={DeviceValue}
                                                            />
                                                            <div style={errorstyle}>
                                                                {formError.deviceId}
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box sx={{ marginTop: '12px !important' }} className="select-device datefilter">
                                                            <FormLabel>Date Filter</FormLabel>
                                                            <Select
                                                                sx={{ background: "#f0f0ff", width: "100% !important", padding: "0!important" }}
                                                                name="dateselect"
                                                                value={dateselect}
                                                                onChange={handleSelectDate}
                                                            >
                                                                <MenuItem key={0} value="Select" selected>
                                                                    Select
                                                                </MenuItem>
                                                                <MenuItem key={1} value="last8h">
                                                                    Last 8 hours
                                                                </MenuItem>
                                                                <MenuItem key={2} value="last7d">
                                                                    Last 7 days
                                                                </MenuItem>
                                                                <MenuItem key={2} value="last90d">
                                                                    Last 90 days
                                                                </MenuItem>
                                                                <MenuItem key={2} value="custom">
                                                                    Custom
                                                                </MenuItem>
                                                            </Select>
                                                            <div style={errorstyle}>
                                                                {formError.dateselect}
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                    {dateselect === "custom" ?
                                                        <> <Grid item xs={12}>
                                                            <Box className="date-range top-down" sx={{ marginTop: '0em !important' }}>
                                                                <FormControl sx={{ marginTop: '0em !important' }}>
                                                                    <FormLabel>Date From</FormLabel>
                                                                    <DatePicker
                                                                        selected={fromDate}
                                                                        onChange={(date) => handleFromDate(date)}
                                                                        showTimeSelect
                                                                        timeFormat="HH:mm"
                                                                        timeIntervals={1}
                                                                        timeCaption="Time"
                                                                        dateFormat="dd/MM/yyyy hh:mm aa"
                                                                        ref={fromDatePickerRef}
                                                                        maxDate={new Date()}
                                                                    />
                                                                    <IconButton
                                                                        className="cal-icon"
                                                                        onClick={handleFromDatePicker}
                                                                    >
                                                                        <CalendarIcon />
                                                                    </IconButton>
                                                                </FormControl>
                                                            </Box></Grid>
                                                            <Grid item xs={12}>
                                                                <Box className="date-range top-down" sx={{ marginTop: '0em !important' }}>
                                                                    <FormControl sx={{ marginTop: '0em !important' }}>
                                                                        <FormLabel>Date To</FormLabel>
                                                                        <DatePicker
                                                                            selected={toDate}
                                                                            onChange={(date) => handleToDate(date)}
                                                                            showTimeSelect
                                                                            timeFormat="HH:mm"
                                                                            timeIntervals={1}
                                                                            timeCaption="Time"
                                                                            dateFormat="dd/MM/yyyy hh:mm aa"
                                                                            ref={toDatePickerRef}
                                                                            maxDate={new Date()}
                                                                        />
                                                                        <IconButton
                                                                            className="cal-icon"
                                                                            onClick={handleToDatePicker}
                                                                        >
                                                                            <CalendarIcon />
                                                                        </IconButton>
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid></>
                                                        : ""}
                                                    <Grid item xs={12}>
                                                        <Box sx={{ marginTop: '1em' }}>
                                                            <CardActions disableSpacing className='expand-btn' sx={{ paddingLeft: "0 !important" }}>
                                                                <Button label="Track" className='show-btn' expand={expanded}
                                                                    aria-expanded={expanded}
                                                                    aria-label="show" onClick={formTrackSubmit}></Button>
                                                            </CardActions>

                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>

                                        :

                                        <Box textAlign="left" className='trail-track'>
                                            <div style={{ display: "flex" }}>
                                                <ArrowIcon className='arrow-icon' onClick={() => setExpanded(false)} sx={{ marginBottom: "15px" }} />
                                            </div>
                                            <div className="scrollbar">
                                                {
                                                    isLoading === true ? <div>...Loading</div> :
                                                        trailsData.length > 0 ?
                                                            trailsData.map((trailsroute, index) => {
                                                                return (<>
                                                                    <div style={{ display: "flex" }}>
                                                                        <CarIcon sx={{ fontSize: "18px !important" }} />
                                                                        <Typography color="textSecondary" variant="h" component="h4" className='Card-Title'
                                                                            sx={{
                                                                                marginBottom: '15px'
                                                                            }}>{trailsroute?.deviceName}
                                                                        </Typography>
                                                                    </div>
                                                                    <Box>
                                                                        <Grid item xs={12}>
                                                                            <ul class="progress">
                                                                                <li class="progress__item progress__item--completed">
                                                                                    <p class="progress__title">{Dateformat(trailsroute.startTime)}</p>
                                                                                    <p class="progress__info">{trailsroute?.tripLegs[0]?.addressFrom}</p>
                                                                                </li>
                                                                                <li class="progress__item progress__item--active">
                                                                                    <p class="progress__title">{Dateformat(trailsroute.endTime)}</p>
                                                                                    <p class="progress__info">{trailsroute?.tripLegs[(trailsroute?.tripLegs?.length) - 1]?.addressTo}</p>
                                                                                </li>
                                                                            </ul>
                                                                        </Grid>
                                                                    </Box>
                                                                    <div className="trail-track-det">
                                                                        <Box sx={{ display: 'flex', padding: "10px 0" }}>
                                                                            <RouteIcon />
                                                                            <Typography color="textSecondary" variant="h" component="p"
                                                                                sx={{ fontSize: '14px', width: "46px", marginRight: "5px" }}>Distance
                                                                            </Typography>
                                                                            <Typography color="textSecondary" variant="h" component="h4"
                                                                                sx={{
                                                                                    marginLeft: '53px'
                                                                                }}>{trailsroute?.tripLegs[0]?.distanceTraveled !== undefined ? (trailsroute?.tripLegs[0]?.distanceTraveled).toFixed(2) : 0} Km

                                                                            </Typography>
                                                                        </Box>
                                                                        <Box sx={{ display: 'flex', padding: "10px 0" }}>
                                                                            <TimeIcon />
                                                                            <Typography color="textSecondary" variant="h" component="p"
                                                                                sx={{ fontSize: '14px', width: "46px", marginRight: "5px" }}>Duration
                                                                            </Typography>
                                                                            <Typography color="textSecondary" variant="h" component="h4"
                                                                                sx={{
                                                                                    marginLeft: '53px'
                                                                                }}>{msToTime(trailsroute?.tripLegs[0]?.durationTraveled)} Hrs
                                                                            </Typography>
                                                                        </Box>
                                                                    </div>
                                                                    <Box sx={{ marginBottom: "1rem" }}>
                                                                        <Button label="Play trails on map" onClick={() => playTrails(trailsroute)} className='play-btn' startIcon={<PlayIcon />}></Button>
                                                                    </Box>
                                                                </>
                                                                )

                                                            })
                                                            : <div style={errorstyle}>Trails Not Available</div>
                                                }
                                            </div>
                                        </Box>
                                    }
                                </Box>
                            </Card>

                            {/* Map view */}
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '73%',
                                    '& a': {
                                        textDecoration: 'none',
                                        color: 'inherit',
                                    },
                                }}
                            >
                                <TripsTrailRouting />
                            </Card>

                        </Box>
                    </Box>
                </Box>
            </>

            :

            <div>
                <div style={{ display: "flex", paddingTop: "12px" }}>
                    <ArrowIcon className='arrow-icon' onClick={() => playTrailsBack()} sx={{ fontSize: "18px", marginBottom: "15px" }} />
                    <Box className='route-indicators'>
                        <Typography color="textSecondary" className="green-color">
                            <CircleIcon /> Planned Route
                        </Typography>
                        <Typography color="textSecondary" className="red-color">
                            <CircleIcon /> Actual Route
                        </Typography>
                    </Box>
                </div>
                <Box className="map-content play-trail-map">
                    <TripsTrailRouting playRoutesData={playRoutesData} />
                </Box>
            </div>


        }
    </>
    );
}