import React, { useState, useEffect } from "react";
import {
  Datagrid,
  ListContextProvider,
  ListToolbar,
  TopToolbar,
  TextInput,
  Button,
  TextField,
  useListController,
  Pagination,
  useRecordContext,
} from "react-admin";

import { Box, LinearProgress, Card, Typography } from "@mui/material";
import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import useReport from "../../hooks/useReport";
import ExportReportData from "./exportReport";
import FormLabel from "@mui/material/FormLabel";
import moment from "moment";
import { ViolationList } from "../../utils/ViolationList";
import useMediaQuery from '@mui/material/useMediaQuery';
import useDeviceLog from '../../hooks/useDeviceLog';
import urls from '../../constants';
import { TableCell, TableRow, Checkbox } from '@mui/material';


const Buttonstyle = {
  position: "absolute",
  background: "blue",
  color: "#fff",
  zIndex: 1,
  textTransform: "capitalize",
  width: "150px",
};

const errorstyle = {
  color: "red",
  fontSize: "12px",
};

export default function MiscReportType({ viewReport }) {
  const [exportReport, setExportReport] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [devicelogerr, devicelog, setDeviceLog, setSocketUrl] = useDeviceLog();

  let wsUrl = urls.DEVICE_WEBSOCKET.replace(/http/, 'ws');

  useEffect(() => {
    var queryvalue = document.querySelectorAll(".list-content");
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  }, []);

  const matches = useMediaQuery('(min-width:1800px)');

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPerPage(event.target.value);
  };

  const { data, isLoading, page, setPage, setPerPage, ...listContext } =
    useListController({
      resource: "miscellaneous",
      filter: {
        reportData: viewReport,
      },
    });


  useEffect(() => {
    setDeviceLog([]);
    if (viewReport?.type == "devicelogs") {
      if (viewReport?.logType == "Live") {
        setSocketUrl(wsUrl.concat('?deviceId=' + viewReport.deviceId));
      }
    }
  }, [viewReport])
  const Actions = () => (
    <TopToolbar>
      <Typography
        variant="h5"
        component="h2"
        align="left"
        sx={{ fontSize: "14px", marginTop: "20px", fontWeight: "600" }}
        className="top-tlcount"
      >
        Total count{" "}
        <Button
          label=""
          sx={{
            background: "none",
            border: "1px solid blue",
            color: "black",
            fontWeight: "600",
            borderRadius: "10px",
            padding: "2px",
            marginLeft: "15px",
            minWidth: "48px",
            marginBottom: "10px",
          }}
        >
          {listContext?.total == undefined
            ? "0"
            : listContext?.total == 0
              ? "0"
              : listContext?.total}
        </Button>
      </Typography>
      {(viewReport?.type == "geofences" || viewReport?.type == "panics" || viewReport?.type == "reportedViolation" || viewReport?.type == "generatedViolation" || viewReport?.type == "devicelogs") &&
        listContext?.total > 0 && (
          <div className="show-column-bar">
            <Button target="_blank"
              label="Export"
              className="show-btn"
              onClick={handleExport}
            />
          </div>
        )}
    </TopToolbar>
  );

  const CustomEmpty = () => (
    <Typography variant="h6" align="center" className="no-records">
      No Records
    </Typography>
  );

  function handleExport() {
    if (viewReport?.type == "devicelogs") {
      window.location.href = urls.MISC_REPORT_EXPORT_URL + viewReport.deviceId + "&from=" + viewReport.from + "&to=" + viewReport.to + ""
    }
    else {
      setExportReport(true);
    }
  }

  const columnData = [
    {
      label: "Action",
      value: "action",
    },
    {
      label: "DeviceId",
      value: "deviceId",
    },
    {
      label: "Geofence Name",
      value: "geofenceName",
    },
    {
      label: "Group Name",
      value: "groupName",
    },
    {
      label: "Device Name",
      value: "deviceName",
    },
    {
      label: "Id",
      value: "id",
    },
    {
      label: "Type",
      value: "type",
    },
    {
      label: "Event Time",
      value: "eventTime",
    },
    {
      label: "Latitude",
      value: "latitude",
    },
    {
      label: "Longitude",
      value: "longitude",
    },
    {
      label: "PositionId",
      value: "positionId",
    },
    {
      label: "GeofenceId",
      value: "geofenceId",
    },
    {
      label: "User ID",
      value: "userId",
    },
    {
      label: "Name",
      value: "name",
    },
    {
      label: "Email",
      value: "email",
    },
    {
      label: "Role",
      value: "role",
    },
    {
      label: "Login At",
      value: "loginAt",
    },
    {
      label: "Last Seen At",
      value: "lastSeenAt",
    },
    {
      label: "Status",
      value: "status",
    },
    {
      label: "Location",
      value: "location",
    },
    {
      label: "Remarks",
      value: "remark",
    },
    {
      label: "Vehicle Number",
      value: "vehicleNumber",
    },
    {
      label: "Reported By",
      value: "reportedBy",
    },
    {
      label: "Reported Name",
      value: "reportedName",
    },
    {
      label: "Violations",
      value: "violations",
    },
    {
      label: "Created At",
      value: "createdAt",
    },
    {
      label: "Phone",
      value: "phone",
    },
    {
      label: "Total Penalty",
      value: "totalPenalty",
    },
    {
      label: "Vehicle Number",
      value: "deivceName",
    },
    {
      label: "Time",
      value: "createdOn",
    },
    {
      label: "Content",
      value: "content",
    },
    {
      label: "Hex Code",
      value: "hexCode",
    },
    {
      label: "IP",
      value: "ip",
    },
  ];

  const deviceColumnData = [
    {
      label: "Id",
      value: "id",
    },
    {
      label: "Name",
      value: "name",
    },
    {
      label: "Unique ID",
      value: "uniqueId",
    },
    {
      label: "Status",
      value: "status",
    },
    {
      label: "Last Update",
      value: "lastUpdate",
    },
    {
      label: "PositionId",
      value: "positionId",
    },
    {
      label: "GeofenceId",
      value: "geofenceId",
    },
    {
      label: "Phone",
      value: "phone",
    },
    {
      label: "Vehicle Model",
      value: "vehicleModel",
    },
    {
      label: "Contact",
      value: "contact",
    },
    {
      label: "Category",
      value: "category",
    },
    {
      label: "Model",
      value: "vltdmodel",
    },
    {
      label: "Disabled",
      value: "disabled",
    },
    {
      label: "Vltd Make",
      value: "vltd_make",
    },
    {
      label: "Serial No",
      value: "serialNo",
    },
    {
      label: "Iccid",
      value: "iccid",
    },
    {
      label: "CreatedOn",
      value: "createdon",
    },
    {
      label: "SimNo1",
      value: "simno1",
    },
    {
      label: "RtoCode",
      value: "rto_code",
    },
    {
      label: "ChassisNo",
      value: "chasisno",
    },
    {
      label: "EngineNo",
      value: "engineno",
    },
    {
      label: "PermitHolder",
      value: "permit_holder",
    },
  ];

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt =
      record[source] !== null
        ? moment(record[source]).format("DD MMM YYYY, HH:mm:ss")
        : "";
    return <span>{dt}</span>;
  };

  const tableDataFilter =
    viewReport?.type == "panics"
      ? columnData.filter((col) => {
        return (
          col?.value == "id" ||
          col?.value == "type" ||
          col?.value == "eventTime" ||
          col?.value == "positionId" ||
          col?.value == "geofenceId" ||
          col?.value == "maintenanaceId" ||
          col?.value == "latitude" ||
          col?.value == "longitude" ||
          col?.value == "status" ||
          col?.value == "location" ||
          col?.value == "permitHolder" ||
          col?.value == "permitHolderPhone" ||
          col?.value == "vehicleNo" ||
          col?.value == "lastUpdate"
        );
      })
      : viewReport?.type == "devicelogs"
        ? columnData.filter((col) => {
          return (
            col?.value == "deivceName" ||
            col?.value == "createdOn" ||
            col?.value == "content" ||
            col?.value == "hexCode" ||
            col?.value == "ip"
          );
        })
        : viewReport?.type == "geofences"
          ? columnData.filter((col) => {
            return (
              col?.value == "id" ||
              col?.value == "type" ||
              col?.value == "geofenceName" ||
              col?.value == "eventTime" ||
              col?.value == "latitude" ||
              col?.value == "longitude" ||
              col?.value == "groupName" ||
              col?.value == "deviceName"
            );
          })
          : viewReport?.type == "loginstats"
            ? columnData.filter((col) => {
              return (
                col?.value == "id" ||
                col?.value == "userId" ||
                col?.value == "name" ||
                col?.value == "email" ||
                col?.value == "role" ||
                col?.value == "loginAt" ||
                col?.value == "lastSeenAt" ||
                col?.value == "status"
              );
            })
            : viewReport?.type == "reportedViolation"
              ? columnData.filter((col) => {
                return (
                  col?.value == "vehicleNumber" ||
                  col?.value == "violationId" ||
                  col?.value == "remark" ||
                  col?.value == "status" ||
                  col?.value == "reportedBy" ||
                  col?.value == "violations" ||
                  col?.value == "reportedName" ||
                  col?.value == "createdAt" ||
                  col?.value == "phone"
                );
              })
              : viewReport?.type == "generatedViolation"
                ? columnData.filter((col) => {
                  return (
                    col?.value == "vehicleNumber" ||
                    col?.value == "remark" ||
                    col?.value == "status" ||
                    col?.value == "reportedBy" ||
                    col?.value == "reportedName" ||
                    col?.value == "createdAt" ||
                    col?.value == "phone" ||
                    col?.value == "totalPenalty"
                  );
                })
                : deviceColumnData;

  if (isLoading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  } else if (viewReport?.type === 'devicelogs' && viewReport?.logType === "Live") {
    return (<>
      <Box className="grid-box" sx={{ padding: "0 0 0 20px", width: "67.5%" }}>
        <Box className="list-content">
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Device Name</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Content</TableCell>
            <TableCell>Hex Code</TableCell>
            <TableCell>Ip</TableCell>
          </TableRow>
          {devicelog.map((record, index) => {

            return (
              <TableRow>
                <TableCell>
                  {(index * 1) + 1}
                </TableCell>
                <TableCell>
                  {record.deivceName}
                </TableCell>
                <TableCell>
                  {record.createdOn}
                </TableCell>
                <TableCell>
                  {record.content}
                </TableCell>
                <TableCell>
                  {record.hexCode}
                </TableCell>
                <TableCell>
                  {record.ip}
                </TableCell>
              </TableRow>
            )
          })}
          <TableRow><TableCell colspan="6" style={errorstyle}>{devicelogerr.errmessage}</TableCell></TableRow>
        </Box> </Box></>);
  } else
    return (
      <>
        <Box className="grid-box" sx={{ padding: "0 0 0 20px", width: "67.5%" }}>
          <ListContextProvider
            debounce={8000}
            value={{ data, page, setPage, setPerPage, ...listContext }}
          >
            <ListToolbar actions={<Actions />} className="reptype-toolbar" />
            {data === undefined ||
              (Array.isArray(data) === true && data.length === 0) ? (
              <>
                <div className="no-records">No Records</div>
              </>
            ) : (
              viewReport?.type != 'generatedViolation' ?
                <>
                  {matches == true ? (
                    <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />

                  ) : (
                    <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
                  )}
                  <Box className="list-content">
                    <Datagrid empty={<CustomEmpty />} bulkActionButtons={false}>
                      {tableDataFilter?.length > 0 &&
                        tableDataFilter?.map((col) => {
                          if (
                            col?.value == "eventTime" ||
                            col?.value == "createdAt"
                          ) {
                            return (
                              <DateSetter
                                label={`${col?.label}`}
                                source={`${col?.value}`}
                              />
                            );
                          } else {
                            return (
                              <TextField
                                label={`${col?.label}`}
                                source={`${col?.value}`}
                              />
                            );
                          }
                        })}
                    </Datagrid>
                  </Box>
                </>
                : <>
                  {matches == true ? (
                    <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />

                  ) : (
                    <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
                  )}
                  <Box className="list-content">
                    <Datagrid empty={<CustomEmpty />} bulkActionButtons={false} expand={<ViolationList />}>
                      {tableDataFilter?.length > 0 &&
                        tableDataFilter?.map((col) => {
                          if (
                            col?.value == "eventTime" ||
                            col?.value == "createdAt"
                          ) {
                            return (
                              <DateSetter
                                label={`${col?.label}`}
                                source={`${col?.value}`}
                              />
                            );
                          } else {
                            return (
                              <TextField
                                label={`${col?.label}`}
                                source={`${col?.value}`}
                              />
                            );
                          }
                        })}
                    </Datagrid>
                  </Box>

                </>
            )}
          </ListContextProvider>
        </Box>
        {exportReport ? (
          <ExportReportData
            viewReport={viewReport}
            setExport={setExportReport}
          />
        ) : (
          false
        )}
      </>
    );
}
