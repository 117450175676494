import moment from "moment";
import React, { useContext, useState, useLayoutEffect } from "react";
import { stringify } from "query-string";
import urls from "../constants";
import httpClient from "../utils/httpclient";
import { exists, isEmpty } from "../utils/validate";

let apiUrl = urls.VEHICLE_SUMMARY_URL;
/**
 * @param {*} obj 
 */
function transform(json, obj, index) {
  let res = {};
  if ("device" in obj && exists(obj.device)) {
    if ("id" in obj.device) {
      res["id"] = obj.device.id + Math.floor(Math.random() * 1001);
      res["device_id"] = obj.device.id;
    } else {
      res["id"] = "0" + Math.floor(Math.random() * 1001);
      res["device_id"] = res["id"];
    }
    if ("category" in obj.device) res["type"] = obj.device.category;
    if ("status" in obj.device) res["status"] = obj.device.status;
    if ("groupId" in obj.device) res["group_id"] = obj.device.groupId;
    if ("name" in obj.device) res["name"] = obj.device.name;

    if ("contact" in obj.device) res["contact"] = obj.device.contact;
    if ("uniqueId" in obj.device) res["imei"] = obj?.device?.uniqueId;
    if ("chasisno" in obj.device) res["chasisno"] = obj.device.chasisno;
    if ("permit_holder" in obj.device)
      res["permit_holder"] = obj?.device?.permit_holder;
    if ("uniqueidno" in obj.device) res["uniqueidno"] = obj?.device?.uniqueidno;
    if ("make" in obj.device) res["make"] = obj?.device?.make;
    if ("rto_code" in obj.device) res["rto_code"] = obj?.device?.rto_code;
    if ("lastUpdate" in obj.device) res["lastUpdate"] = obj.device.lastUpdate;
  } else {
    json.splice(index, 1);
    return;
  }
  if ("position" in obj && exists(obj.position)) {
    if ("speed" in obj.position) res["speed"] = obj.position.speed;
    if ("deviceTime" in obj.position) res["gps_time"] = obj.position.deviceTime;
    if ("attributes" in obj.position) {
      if ("sat" in obj.position.attributes)
        res["gps"] = obj.position.attributes.sat;
      if ("distance" in obj.position.attributes)
        res["distance"] = obj.position.attributes.distance;
      if ("ignition" in obj?.position?.attributes)
        res["ignition"] = obj?.position?.attributes?.ignition;
      if ("totalDistance" in obj.position.attributes)
        res["totalDistance"] = obj.position.attributes.totalDistance;
      if ("battery" in obj.position.attributes)
        res["battery"] = obj?.position?.attributes?.battery
          ? obj?.position?.attributes?.battery
          : null;
      if ("batteryLevel" in obj.position.attributes)
        res["batteryLevel"] = obj?.position?.attributes?.batteryLevel
          ? obj?.position?.attributes?.batteryLevel
          : null;
      if ("power" in obj.position.attributes)
        res["power"] = obj?.position?.attributes?.power
          ? obj?.position?.attributes?.power
          : null;
    }
    res["latitude"] = obj.position.latitude;
    res["longitude"] = obj.position.longitude;
    res["course"] = obj.position.course;
    res["altitude"] = obj.position.altitude;
  }
  if ("driver" in obj && exists(obj.driver)) {
    if ("name" in obj.driver) res["driver"] = obj.driver.name;
  }

  json[index] = res;

}

const transformPanic = (json) => {
  const test = [];
  json?.bar_chart.forEach((obj, index) => {
    if (!test[index]) {
      const newObj = {
        ...obj,
        id: Math.floor(Math.random() * 1001),
      };

      test.push(newObj);
    }
  });
  return test;
};

const transformstoptype = (json) => {
  const test = [];
  if (json) test.push(json);
  return test;
};

const transformJSONData = (json) => {
  const test = [];
  const newObj = {
    ...json,
    id: Math.floor(Math.random() * 1001),
  };
  test.push(newObj);
  return test;
};

const transformalarmlogJSONData = (json) => {
  const test = [];
  const newObj = {
    ...json,
  };
  test.push(newObj);
  return test;
};

const transformRAssignData = (json) => {
  const newObj = {
    data: {
      ...json,
      id: Math.floor(Math.random() * 1001),
    },
  };
  return newObj;
};

const transformDataID = (json) => {
  const newData = json.map((obj, ind) => {
    return {
      ...obj,
      id: ind + 1,
    };
  });
  return newData;
};

const vtsDataProvider = {

  getList: async (resource, params) => {

    var windsize = window.screen.width;
    if (params.pagination) {
      if (windsize >= 1800 && params.pagination.perPage == 10) {
        params.pagination = { page: params.pagination.page, perPage: 25 };

      }
    }
    if (
      resource === "audittrails" &&
      params?.filter?.reportData?.length == 0
    ) {
      return { data: [{ id: 1, audit: 'noData' }], total: 0 };
    }

    if (resource === "controlpanel" && params?.filter?.controlId == null) {
      return { data: [], total: 0 };
    }
    else {
      const apiUrl = getApiUrl(resource, params);
      const url = `${apiUrl}`;
      return await httpClient(url).then(({ headers, json }) => {
        let data = json?.data;
        if (
          resource === "alarmtype" ||
          resource === "state" ||
          resource === "vehiclemake" ||
          resource === "makedrop" ||
          resource === "rtocode" ||
          resource === "simprovider" ||
          resource === "vehiclecategory" ||
          resource === "alarmnotificators"
        )
          data = json;
        if (resource === "stoptype") data = transformstoptype(json);
        if (Array.isArray(data)) data = data.filter((d) => d);
        if (resource === "vehicles" || resource === "vehicledata")
          data.forEach((obj, index) => transform(data, obj, index));
        else if (resource === "panic") {
          data = transformPanic(json);
        } else if (resource === "overview") data = transformJSONData(json);
        else if (resource === "panicstatus") data = transformJSONData(json);
        else if (resource === "alarmstatus") data = transformJSONData(json);
        else if (resource === "communicationstatus")
          data = transformDataID(json);
        else if (resource === "controlpanel")
          data = transformDataID(json?.data);
        else if (resource === "vehiclesummary")
          data = transformDataID(json?.data);
        else if (resource === "routeassign") data = transformDataID(json?.data);
        else if (
          resource === "alarmlog" &&
          params?.filter?.id > 0 &&
          !exists(params.filter.q)
        )
          data = transformalarmlogJSONData(json);
        else if (resource === "ongoingtrips")
          data = transformDataID(json?.data);
        let total = data?.length;
        if (exists(params.pagination))
          total = json?.noRecords ? json?.noRecords : json?.totalRecord;
        return { data: data, total: total };
      });
    }
  },

  getOne: async (resource, params) => {
    const apiUrl = getApiUrl(resource, params);
    let url = `${apiUrl}/${params.id}`;
    if (resource === "deviceinfovahan") url = `${apiUrl}`;
    if (params.id.length === 0 || params.id === 0)
      return await Promise.resolve({ data: { id: 0 } });
    return await httpClient(url).then(({ json }) => {
      if (resource === "otpsend") return json;
      else if (resource === "routeassign") return transformRAssignData(json);
      else return { data: json };
    }).catch(({ body }) => {
      return transformRAssignData(body);
    });
  },

  getMany: (resource, params) => {
    const apiUrl = getApiUrl(resource, params);

    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${apiUrl}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json.data }));
  },

  getManyReference: (resource, params) => {
    const apiUrl = getApiUrl(resource, params);
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  create: (resource, params, setNotifyData, successmsg, failuremsg) => {
    const apiUrl = getApiUrl(resource, params);
    httpClient(`${apiUrl}`, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then(({ json }) => {
        setNotifyData({
          open: true,
          message: successmsg,
          severity: "success",
          duration: 4000,
          backendmessage: ""
        });
        return {
          data: {
            ...params,
            id: json.id,
          },
        };
      })
      .catch(({ body }) => {

        setNotifyData({
          open: true,
          message: failuremsg,
          severity: "warning",
          backendmessage: checknull(body?.error) !== "" ? body?.error : body?.errMessage,
        });
      });
  },
  update: (resource, params, setNotifyData, successmsg, failuremsg) => {
    const apiUrl = getApiUrl(resource, params);
    httpClient(`${apiUrl}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params),
    })
      .then(({ json }) => {
        setNotifyData({
          open: true,
          message: successmsg,
          severity: "success",
          duration: 3000,
        });
        return {
          data: json,
        };
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setNotifyData({
          open: true,
          message: failuremsg,
          severity: "warning",
        });
      });
  },

  updateMany: (resource, params) => {
    const apiUrl = getApiUrl(resource, params);
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource, params, setNotifyData, successmsg, failuremsg) => {
    const apiUrl = getApiUrl(resource, params);
    httpClient(`${apiUrl}/${params.id}`, {
      method: "DELETE",
    })
      .then(({ json }) => {
        setNotifyData({
          open: true,
          message: successmsg,
          severity: "success",
          duration: 4000,
        });
        return { data: json };
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setNotifyData({
          open: true,
          message: failuremsg,
          severity: "warning",
        });
      });
  },

  deleteMany: (resource, params) => {
    const apiUrl = getApiUrl(resource, params);

    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};

export default vtsDataProvider;
const getApiUrl = (resource, params) => {
  const query = {};
  if (
    resource === "vehicles" &&
    exists(params.filter?.statusType) &&
    typeof params?.filter?.statusType == "object"
  ) {
    apiUrl = urls.DEVICE_VEHICLE_SEARCH;
  }
  else if (
    resource === "vehicles" &&
    exists(params.filter?.statusType) &&
    params.filter?.statusType != null
  ) {
    apiUrl = urls.DEVICE_VEHICLE_LIST_URL;
    query["vehicleStatus"] = params.filter?.statusType;
  } else if (
    resource === "vehicles" &&
    exists(params.filter) &&
    exists(params.filter.group_id)
  ) {
    apiUrl = urls.DEVICE_VEHICLE_SEARCH;
  } else if (
    resource === "vehicles" &&
    exists(params.filter) &&
    (exists(params.filter.rtoName) || exists(params.filter.category))
  ) {
    apiUrl = urls.DEVICE_VEHICLE_SEARCH;
  } else if (
    resource === "panicsos" &&
    exists(params.filter) &&
    exists(params.filter.selected)
  ) {
    apiUrl = urls.PANIC_SOS_SERVICES_URL;
  } else if (
    resource === "panicsos" &&
    exists(params.filter) &&
    exists(params.filter.type == "dashboard")
  ) {
    apiUrl = urls.PANIC_SOS_SERVICES_URL;
  } else if (
    exists(params.filter) &&
    "q" in params.filter &&
    exists(params.filter.q)
  ) {
    if (resource === "vehicles") apiUrl = urls.DEVICE_VEHICLE_SEARCH;
    else if (resource === "users") apiUrl = urls.USERS_SEARCH_URL;
    else if (resource === "userrole") apiUrl = urls.USERROLE_SEARCH_URL;
    else if (resource === "alarm") apiUrl = urls.ALARM_NOTIFICATION_SEARCH;
    else if (resource === "alarmlog") apiUrl = urls.EVENT_NOTI_LOGS;
    else if (resource === "reportsschedule")
      apiUrl = urls.REPORTS_SCHEDULE_SEARCH;
    else if (resource === "groups") {
      apiUrl = urls.ENTITY_LIST_SEARCH;
    } else if (resource === "groupsdata") apiUrl = urls.GROUP_SEARCH;
    else if (resource === "groupslist") apiUrl = urls.GROUP_SEARCH;
    else if (resource === "geofences") apiUrl = urls.GEOOFENCE_SEARCH;
    else if (resource === "panicsos") apiUrl = urls.PANIC_SOS_SERVICES_URL;
    else if (resource === "vehiclesummary") {
      apiUrl = urls.VEHICLE_SUMMARY_URL;
    } else if (resource === "devices") apiUrl = urls.ENTITY_LIST_SEARCH;
    else if (resource === "route") apiUrl = urls.ROUTES_SEARCH;
    else if (resource === "routeassign") apiUrl = urls.ROUTE_ASSGIN;
  }
  else if (
    resource === "audittrails" &&
    exists(params.filter.reportData) &&
    params.filter.reportData?.component != null &&
    params.filter.reportData?.from != null &&
    params.filter.reportData?.to != null &&
    params.filter.reportData?.search == null
  ) {
    apiUrl = urls.AUDIT_DATE_SEARCH
    query['from'] = params.filter.reportData?.from
    query['to'] = params.filter.reportData?.to
    query['component'] = params.filter.reportData?.component
  }
  else if (resource === "reporttypes" && exists(params.filter.type)) {
    apiUrl = urls.REPORT_TYPES_URL;
    query["dropDownKey"] = params.filter.type;
  } else if (
    resource === "reportsvehicle" &&
    exists(params.filter.reportData)
  ) {
    apiUrl = `${urls.REPORTS_LIST_URL}/${params.filter.reportData?.reporturl}`;
    query["from"] = params.filter.reportData?.from;
    query["to"] = params.filter.reportData?.to;
    query["deviceId"] = params.filter.reportData?.deviceId;
  } else if (
    resource === "groupsdata" &&
    exists(params.filter) &&
    "q" in params.filter &&
    exists(params.filter.q)
  ) {
    apiUrl = urls.GROUP_SEARCH;
  } else if (
    resource === "groupslist" &&
    exists(params.filter) &&
    "q" in params.filter &&
    exists(params.filter.q)
  ) {
    apiUrl = urls.GROUP_SEARCH;
  } else if (resource === "otpsend") apiUrl = urls.ENTITY_OTP;
  else if (resource === "untagotpsend") apiUrl = urls.UNTAG_ENTITY_OTP;
  else if (resource === "deviceinfovahan" && exists(params.id)) {
    apiUrl = urls.DEVICE_VEHICLE_INFO_VAHAN;
    query["vltdno"] = params.id;
  } else if (resource === "controlpanel" && params?.filter?.controlId != null) {
    //owner user
    if (params?.filter?.owner == "user") {
      apiUrl = `${urls.CONTROL_PANEL_URL}/${params?.filter?.owner}/mapping`;
      query["userId"] = params.filter.controlId;
      query["propertyName"] = params.filter.property;
    }
    //owner device
    if (params?.filter?.owner == "device") {
      apiUrl = `${urls.CONTROL_PANEL_URL}/${params?.filter?.owner}/mapping`;
      query["deviceId"] = params.filter.controlId;
      query["propertyName"] = params.filter.property;
    }
    //owner group
    if (params?.filter?.owner == "group") {
      apiUrl = `${urls.CONTROL_PANEL_URL}/${params?.filter?.owner}/mapping`;
      query["groupId"] = params.filter.controlId;
      query["propertyName"] = params.filter.property;
    }
  } else if (resource === "cpanelpermissions") {
    apiUrl = urls.CONTROL_PANEL_PERMISSIONS;
  } else if (resource === "users") apiUrl = urls.USERS_URL;
  else if (resource === "vehicles") {
    apiUrl = urls.DEVICE_VEHICLE_LIST_URL;
  } else if (resource === "userrole") apiUrl = urls.USERROLE_LIST;
  else if (resource === "geofences") apiUrl = urls.GEOFENCE_LIST;
  else if (resource === "panic") apiUrl = urls.PANIC_CHART_URL;
  else if (resource === "panicsos") apiUrl = urls.PANIC_SOS_SERVICES_URL;
  else if (resource === "alarm") apiUrl = urls.ALARM_CONFIG_LIST;
  else if (resource === "alarmlog") apiUrl = urls.ALARM_LOG_LIST;
  else if (resource === "alarmtype") apiUrl = urls.ALARM_TYPE;
  else if (resource === "alarmnotificators") apiUrl = urls.ALARM_NOTIFICATORS;
  else if (resource === "reportsschedule") apiUrl = urls.REPORTS_SCHEDULE_URL;
  else if (resource === "groupsdata") apiUrl = urls.GROUPS_LIST;
  else if (resource === "groupslist") apiUrl = urls.GROUPS_LIST;
  else if (resource === "vehicledata") apiUrl = urls.DEVICE_VEHICLE_LIST_URL;
  else if (resource === "overview") apiUrl = urls.VEHICLE_STATUS_URL;
  else if (resource === "panicstatus") apiUrl = urls.PANIC_STATUS_URL;
  else if (resource === "alarmstatus") apiUrl = urls.ALARM_STATUS_URL;
  else if (resource == 'groups' && exists(params?.id)) {
    if (params?.id) {
      apiUrl = urls.ENTITY_LIST;
    }
  }
  else if (resource === "groups" && !exists(params?.id)) {
    if (
      exists(params.filter.device) &&
      params.filter.device?.vltdMake != null &&
      params.filter.device?.vltdModel != null
    ) {
      apiUrl = urls.DEVICE_LIST;
    } else {
      apiUrl = urls.ENTITY_LIST;
    }
    query["all"] = true;
  } else if (resource === "addentity") apiUrl = urls.ADD_ENTITY;
  else if (resource === "communicationstatus")
    apiUrl = urls.COMMUNICATION_STATUS_URL;
  else if (resource === "geofences") apiUrl = urls.GEOOFENCE_LIST;
  else if (resource === "vehiclesummary") {
    apiUrl = urls.VEHICLE_SUMMARY_URL;
  } else if (resource === "trailsreport") apiUrl = urls.TRAILS_REPORT;
  else if (resource === "reportsroute") apiUrl = urls.REPORTS_ROUTE;
  else if (resource === "trailsstoppoints") apiUrl = urls.TRAILS_STOP_POINTS;
  else if (resource === "devices") apiUrl = urls.DEVICE_LIST;
  else if (resource === "route") apiUrl = urls.ROUTES_LIST;
  else if (resource === "routedropdown") apiUrl = urls.ROUTES_LIST;
  else if (resource === "routeassign") apiUrl = urls.ROUTE_ASSGIN;
  else if (resource === "notifypopup") apiUrl = urls.NOTIFI_POPUP;
  else if (resource === "stoptype" && exists(params.filter.type)) {
    apiUrl = urls.STOP_TYPE;
    query["dropDownKey"] = params.filter.type;
  } else if (resource === "geofencesdrop") apiUrl = urls.GEOFENCE_LIST;
  else if (resource === "triptrails") apiUrl = urls.TRIP_TRAILS;
  else if (resource === "state") apiUrl = urls.STATE_LIST;
  else if (resource === "vehiclemake") apiUrl = urls.VEHICLE_MAKE_DROPDOWN;
  else if (resource === "rtocode") apiUrl = urls.RTO_LIST;
  else if (resource === "simprovider") apiUrl = urls.SIM_PROVIDER;
  else if (resource === "vehiclecategory") apiUrl = urls.VEHICLE_CATEGORY;
  else if (resource === "makedrop") apiUrl = urls.MAKE_DROPDOWN;
  else if (resource === "ongoingtrips") {
    apiUrl = urls.ONGOING_TRIPS;
  } else if (resource === "roledrop") apiUrl = urls.ROLE_DROPDOWN;

  if (exists(params.filter)) {
    if (apiUrl === urls.DEVICE_VEHICLE_SEARCH) {
      if (exists(params.filter?.statusType) &&
        typeof params?.filter?.statusType == "object") {
        query["deviceId"] = params.filter.statusType.vehicleNo;
      }

      if ("q" in params.filter) {
        query["deviceId"] = params.filter.q;
        query["from"] = params.filter.from;
      }
      if ("group_id" in params.filter) {
        if (params.filter.group_id != "")
          query["groupId"] = params.filter.group_id;
      }
      if ("rtoName" in params.filter) {
        if (params.filter.rtoName != "")
          query["rtoName"] = params.filter.rtoName;
      }
      if ("category" in params.filter) {
        if (params.filter.category != "")
          query["category"] = params.filter.category;
      }
    } else if (apiUrl === urls.USERS_SEARCH_URL) {
      if ("q" in params.filter) query["search_key"] = params.filter.q;
    } else if (apiUrl === urls.USERROLE_SEARCH_URL) {
      if ("q" in params.filter) query["search_key"] = params.filter.q;
    } else if (apiUrl === urls.ENTITY_LIST_SEARCH) {
      if ("q" in params.filter) query["search_key"] = params.filter.q;
      if (
        params?.filter?.device?.vltdMake != null &&
        params?.filter?.device?.vltdModel != null
      ) {
        query["vltdMake"] = params?.filter?.device?.vltdMake;

        query["vltdModel"] = params?.filter?.device?.vltdModel;
      }
    } else if (apiUrl === urls.PANIC_SOS_SERVICES_URL) {
      if ("q" in params.filter) query["search_key"] = params.filter.q;
      if ("selected" in params.filter) query["status"] = params.filter.selected?.status;
      if ("date" in params.filter && (params?.filter?.selected?.from_page == null || params?.filter?.selected?.from_page == "chart")) {
        if (params.filter.date[0] && params.filter.date[1]) {
          let formatFromDate = moment(params.filter.date[0]).format(
            "YYYY-MM-DD hh:mm"
          );
          let formatToDate = moment(params.filter.date[1]).format(
            "YYYY-MM-DD hh:mm"
          );
          query["from"] = moment.utc(formatFromDate).format();
          query["to"] = moment.utc(formatToDate).format();
        }
      }
      if (query["status"] == "all") delete query["status"];
      if ("type" in params.filter) query["status"] = "pending";

    } else if (apiUrl === urls.ALARM_NOTIFICATION_SEARCH) {
      if ("q" in params.filter) query["search_key"] = params.filter.q;
    } else if (apiUrl === urls.EVENT_NOTI_LOGS) {
      if ("q" in params.filter) query["search_key"] = params.filter.q;
    } else if (apiUrl === urls.REPORTS_SCHEDULE_SEARCH) {
      if ("q" in params.filter) query["search_key"] = params.filter.q;
    }
    else if (apiUrl === urls.GROUP_SEARCH) {
      query["search_key"] = params.filter.q;
    } else if (apiUrl === urls.GEOOFENCE_SEARCH) {
      query["search_key"] = params.filter.q;
    } else if (apiUrl === urls.VEHICLE_SUMMARY_URL) {
      if ("q" in params.filter) query["searchKey"] = params.filter.q;
      if ("group_id" in params.filter) {
        if (params.filter.group_id != undefined || params.filter.group_id != "")
          query["groupId"] = params.filter.group_id;
      }
      if ("date" in params.filter) {
        if (params.filter.date[0] && params.filter.date[1]) {
          let formatFromDate = moment(params.filter.date[0]).format(
            "YYYY-MM-DD hh:mm"
          );
          let formatToDate = moment(params.filter.date[1]).format(
            "YYYY-MM-DD hh:mm"
          );
          query["from"] = moment.utc(formatFromDate).format();
          query["to"] = moment.utc(formatToDate).format();
        }
      }
    } else if (apiUrl === urls.TRAILS_REPORT) {
      if ("deviceId" in params.filter)
        query["deviceId"] = params.filter.deviceId;

      if (params?.filter?.from != null && params?.filter?.to != null) {
        query["from"] = params?.filter?.from;
        query["to"] = params?.filter?.to;
      }
    } else if (apiUrl === urls.REPORTS_ROUTE) {
      if ("deviceId" in params.filter)
        query["deviceId"] = params.filter.deviceId;

      if (params?.filter?.from != null && params?.filter?.to != null) {
        query["from"] = params?.filter?.from;
        query["to"] = params?.filter?.to;
      }
    } else if (apiUrl === urls.TRAILS_STOP_POINTS) {
      if ("deviceId" in params.filter)
        query["deviceId"] = params.filter.deviceId;

      if (params?.filter?.from != null && params?.filter?.to != null) {
        query["from"] = params?.filter?.from;
        query["to"] = params?.filter?.to;
      }
    } else if (apiUrl === urls.ENTITY_LIST_SEARCH) {
      if ("q" in params?.filter && exists(params?.filter?.q)) {
        query["search_key"] = params?.filter?.q;
      }
    } else if (apiUrl === urls.ROUTES_SEARCH) {
      if ("q" in params?.filter && exists(params?.filter?.q)) {
        query["search_key"] = params?.filter?.q;
      }
    } else if (apiUrl === urls.ROUTE_ASSGIN) {
      if ("q" in params?.filter && exists(params?.filter?.q)) {
        query["search_key"] = params?.filter?.q;
      }
    } else if (apiUrl === urls.NOTIFI_POPUP) {
      var currentdate = new Date();
      var localTime = moment(currentdate).format("YYYY-MM-DD"); // store localTime

      query["from"] = localTime + "T00:00:00Z";
      query["to"] = localTime + "T23:59:59Z";
    } else if (apiUrl === urls.ALARM_LOG_LIST && params?.filter?.id > 0) {
      query["eventLogId"] = params.filter.id;
    } else if (apiUrl === urls.TRIP_TRAILS) {
      if ("deviceId" in params.filter)
        query["deviceId"] = params.filter.deviceId;

      if (params?.filter?.from != null && params?.filter?.to != null) {
        query["from"] = params?.filter?.from;
        query["to"] = params?.filter?.to;
      }
    } else if (
      apiUrl === urls.DEVICE_LIST &&
      resource == "groups" &&
      params?.filter.device?.vltdMake != null &&
      params?.filter.device?.vltdModel != null
    ) {
      query["vltdMake"] = params?.filter?.device?.vltdMake;
      query["vltdModel"] = params?.filter?.device?.vltdModel;
    } else {
      if ("from" in params.filter) query["from"] = params.filter.from;
      if ("to" in params.filter) query["to"] = params.filter.to;
    }
  }

  if (exists(params.pagination)) {
    if (
      (resource == "users" && params.filter.searchKey == "userspanel") ||
      (resource == "vehicles" && params.filter.searchKey == "devicespanel") ||
      (resource == "groupsdata" && params.filter.searchKey == "groupspanel") ||
      resource === "vehicledata" ||
      resource === "groupsdata" ||
      (resource === "controlpanel" && params?.filter?.controlId != null) ||
      resource === "devices" ||
      resource === "geofencesdrop" ||
      resource === "routedropdown" ||
      resource === "roledrop"
    ) {
      if ("page" in params.pagination)
        query["currentPage"] = params.pagination.page;
      if ("perPage" in params.pagination)
        query["pageSize"] = 9999; //to remove pagination
    } else {
      if ("page" in params.pagination)
        query["currentPage"] = params.pagination.page;
      if ("perPage" in params.pagination)
        query["pageSize"] = params.pagination.perPage;
    }

    if (resource === "panicsos") {
      query["asc"] = false;
      query["sortedField"] = "eventTime";
    }
    else {
      query["asc"] = false;
      query["sortedField"] = "id";
    }
  }
  if (!isEmpty(query)) apiUrl = `${apiUrl}?${stringify(query)}`;
  return apiUrl;
};

export const getData = async (resource, params, setOtpResponse) => {
  const query = {};

  if (resource === "otpsend") {
    apiUrl = urls.ENTITY_OTP;
    query["deviceId"] = params.deviceId;
    query["permitHolderName"] = params.permitHolderName;
    query["clientPhoneNo"] = params.clientPhoneNo;
    if (!isEmpty(query)) apiUrl = `${apiUrl}?${stringify(query)}`;
  }
  if (resource === "simdata") {
    apiUrl = urls.GET_SIM_DATA;
    query["simCompany"] = params.simCompany;
    query["iccId"] = params.iccId;
    query["uniqueId"] = params.uniqueId;
    if (!isEmpty(query)) apiUrl = `${apiUrl}?${stringify(query)}`;
  }

  if (resource === "untagotpsend") {
    apiUrl = urls.UNTAG_ENTITY_OTP;
    query["deviceId"] = params.deviceId;
    if (!isEmpty(query)) apiUrl = `${apiUrl}?${stringify(query)}`;
  }

  await httpClient(apiUrl).then(({ headers, json }) => {
    setOtpResponse(json);
    return json;
  }).catch((error) => {
    setOtpResponse({ "Error": error?.message });
  });
};

export const getTriptrails = async (resource, params, temparr) => {
  const apiUrl = getApiUrl(resource, params);
  let url = `${apiUrl}/${params.id}`;
  return await httpClient(url).then(({ json }) => {
    temparr.push(json);
    if (resource === "routeassign") return transformRAssignData(json);
  });
};

export const getRouteAddress = async (resource, params, legs, index) => {
  const query = {};
  const filter = params.filter.details;
  let apiUrl = urls.ROUTE_ADDRESS;
  query["latitude"] = filter.latitude;
  query["longitude"] = filter.longitude;
  if (!isEmpty(query)) apiUrl = `${apiUrl}?${stringify(query)}`;

  await httpClient(apiUrl).then(({ headers, body }) => {
    legs[index].address = body;
    return body;
  });
};

export const ValidDeviceOtp = async (resource, params, setValidOTP) => {
  const apiUrl = getApiUrl(resource, params);

  await httpClient(`${apiUrl}`, {
    method: "PUT",
    body: JSON.stringify(params),
  })
    .then(({ json }) => {
      setValidOTP(json);
    })
    .catch((error) => {
      setValidOTP({ "Error": error?.message })
    });
};

export const getRoutelinePoints = async (resource, params, legs, index) => {
  let apiUrl = urls.LEG_DETAILS;
  await httpClient(`${apiUrl}`, {
    method: "POST",
    body: JSON.stringify(params),
  })
    .then(({ json }) => {
      legs[index].subRoutePoints = json;
    })
    .catch((error) => { });
};

export const getCPanelUserPermissions = async (
  resource,
  createArr,
  setCPanelPermissions
) => {
  const apiUrl = getApiUrl(resource, createArr);
  await httpClient(`${apiUrl}`, {
    method: "POST",
    body: JSON.stringify(createArr),
  })
    .then((res) => {
      if (res?.status == 204)
        setCPanelPermissions({
          status: "SUCCESS",
        });
    })
    .catch((error) => {
      setCPanelPermissions({
        status: "ERROR",
      });
    });
};

export const fetchGroupList = async (resource) => {
  const apiUrl = urls.GROUPS_LIST;
  return await httpClient(apiUrl).then(({ json }) => {
    return json?.data;
  });
};

export const fetchDeviceList = async (resource) => {
  const apiUrl = urls.DEVICE_LIST;
  return await httpClient(apiUrl).then(({ json }) => {
    return json?.data;
  });
};

export const fetchRtoList = async (resource) => {
  const apiUrl = urls.RTO_LIST;
  return await httpClient(apiUrl).then(({ json }) => {
    return json?.data;
  });
};

export const mapCPanelUserPermissions = async (
  resource,
  deleteArr,
  createArr,
  setCPanelPermissions
) => {
  if (deleteArr?.length > 0) {
    const apiUrl = urls.CONTROL_PANEL_PERMISSIONS;
    await httpClient(`${apiUrl}`, {
      method: "DELETE",
      body: JSON.stringify(deleteArr),
    })
      .then((res) => {
        if (res?.status == 204)
          createArr?.length > 0 &&
            getCPanelUserPermissions(resource, createArr, setCPanelPermissions);
      })
      .catch((error) => {
        console.error("#DELETE ERR", error);
      });
  } else getCPanelUserPermissions(resource, createArr, setCPanelPermissions);
};

export const getVehicleLocation = async (item, addr) => {
  const query = {};
  let apiUrl = urls.ROUTE_ADDRESS;
  query["latitude"] = item.latitude;
  query["longitude"] = item.longitude;
  if (!isEmpty(query)) apiUrl = `${apiUrl}?${stringify(query)}`;

  await httpClient(apiUrl).then(({ headers, body }) => {
    addr["address"] = body;
    return body;
  });
};

export const deleteGroupDevicePermissions = async (
  resource,
  deleteGroupArr,
  createArr,
  groupArr,
  setCPanelPermissions
) => {
  const apiUrl = urls.GROUP_DEVICE_PERMISSIONS;
  await httpClient(`${apiUrl}`, {
    method: "DELETE",
    body: JSON.stringify(deleteGroupArr),
  })
    .then((res) => {
      if (
        res?.status == 204 &&
        (createArr?.length > 0 || groupArr?.length > 0)
      ) {
        getCPanelUserGroupPermissions(
          resource,
          createArr,
          groupArr,
          setCPanelPermissions
        );
      }
    })
    .catch((error) => {
      console.error("#DELETE ERR", error);
    });
};

export const mapGroupDevicePermissions = async (
  resource,
  groupArr,
  setCPanelPermissions
) => {
  const apiUrl = urls.GROUP_DEVICE_PERMISSIONS;
  await httpClient(`${apiUrl}`, {
    method: "POST",
    body: JSON.stringify(groupArr),
  })
    .then((res) => {
      if (res?.status == 204)
        setCPanelPermissions({
          status: "SUCCESS",
        });
    })
    .catch((error) => {
      setCPanelPermissions({
        status: "ERROR",
      });
    });
};

export const getCPanelUserGroupPermissions = async (
  resource,
  createArr,
  groupArr,
  setCPanelPermissions
) => {
  if (createArr?.length > 0 && groupArr?.length > 0) {
    const apiUrl = getApiUrl(resource, createArr);
    await httpClient(`${apiUrl}`, {
      method: "POST",
      body: JSON.stringify(createArr),
    })
      .then((res) => {
        if (res?.status == 204) {
          mapGroupDevicePermissions(resource, groupArr, setCPanelPermissions);
        }
      })
      .catch((error) => {
        setCPanelPermissions({
          status: "ERROR",
        });
      });
  }
  else if (createArr?.length > 0) {
    const apiUrl = getApiUrl(resource, createArr);
    await httpClient(`${apiUrl}`, {
      method: "POST",
      body: JSON.stringify(createArr),
    })
      .then((res) => {
        if (res?.status == 204)
          setCPanelPermissions({
            status: "SUCCESS",
          });
      })
      .catch((error) => {
        setCPanelPermissions({
          status: "ERROR",
        });
      });
  }
  else if (groupArr?.length > 0) {
    mapGroupDevicePermissions(resource, groupArr, setCPanelPermissions);
  }
};

export const mapCPanelUserGroupPermissions = async (
  resource,
  deleteArr,
  deleteGroupArr,
  createArr,
  groupArr,
  setCPanelPermissions
) => {
  if (deleteArr?.length > 0 && deleteGroupArr?.length > 0) {
    const apiUrl = urls.CONTROL_PANEL_PERMISSIONS;
    await httpClient(`${apiUrl}`, {
      method: "DELETE",
      body: JSON.stringify(deleteArr),
    })
      .then((res) => {
        if (res?.status == 204)
          deleteGroupDevicePermissions(
            resource,
            deleteGroupArr,
            createArr,
            groupArr,
            setCPanelPermissions
          );
      })
      .catch((error) => {
        console.error("#DELETE ERR", error);
      });
  }
  else if (deleteArr?.length > 0) {
    const apiUrl = urls.CONTROL_PANEL_PERMISSIONS;
    await httpClient(`${apiUrl}`, {
      method: "DELETE",
      body: JSON.stringify(deleteArr),
    })
      .then((res) => {
        if (
          res?.status == 204 &&
          (createArr?.length > 0 || groupArr?.length > 0)
        ) {
          getCPanelUserGroupPermissions(
            resource,
            createArr,
            groupArr,
            setCPanelPermissions
          );
        }
      })
      .catch((error) => {
        console.error("#DELETE ERR", error);
      });
  }
  else if (deleteGroupArr?.length > 0) {
    const apiUrl = urls.GROUP_DEVICE_PERMISSIONS;
    await httpClient(`${apiUrl}`, {
      method: "DELETE",
      body: JSON.stringify(deleteGroupArr),
    })
      .then((res) => {
        if (
          res?.status == 204 &&
          (createArr?.length > 0 || groupArr?.length > 0)
        ) {
          getCPanelUserGroupPermissions(
            resource,
            createArr,
            groupArr,
            setCPanelPermissions
          );
        }
      })
      .catch((error) => {
        console.error("#DELETE ERR", error);
      });
  } else
    getCPanelUserGroupPermissions(
      resource,
      createArr,
      groupArr,
      setCPanelPermissions
    );
};

export const sendForgotPasswordOTP = async (formValue, result) => {
  const userEmail = { email: formValue?.email };
  const apiUrl = urls.FORGOT_PASSWORD_OTP;
  var formBody = [];
  for (var property in userEmail) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(userEmail[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  await httpClient(`${apiUrl}`, {
    method: "POST",
    body: formBody,
    headers: "password",
  })
    .then((res) => {
      return (result["res"] = "SUCCESS");
    })
    .catch((error) => {
      console.error("#ERR", error);
      return (result["res"] = "FAILED");
    });
};

export const verifyForgotPasswordOTP = async (data, result) => {
  const apiUrl = urls.VERIFY_OTP;
  var formBody = [];
  for (var property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  await httpClient(`${apiUrl}`, {
    method: "POST",
    body: formBody,
    headers: "password",
  })
    .then((res) => {
      return (result["res"] = res);
    })
    .catch((error) => {
      console.error("#ERR", error);
      return (result["res"] = "FAILED");
    });
};

export const updatePassword = async (data, result) => {
  const apiUrl = urls.UPDATE_PASSWORD;
  var formBody = [];
  for (var property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  await httpClient(`${apiUrl}`, {
    method: "POST",
    body: formBody,
    headers: "password",
  })
    .then((res) => {
      return (result["res"] = res);
    })
    .catch((error) => {
      return (result["res"] = "FAILED");
    });
};

export const newRegister = async (data, setRegisterSuccess) => {
  const apiUrl = urls.NEW_REGISTER;
  await httpClient(`${apiUrl}`, {
    method: "POST",
    body: JSON.stringify(data),
  })
    .then((res) => {
      res?.status == 201 &&
        setRegisterSuccess({
          status: "SUCCESS",
        });
    })
    .catch((error) => {
      setRegisterSuccess({
        status: "ERROR",
        message: `${error?.message}`,
      });
    });
};

export const changePassword = async (data, setUpdateSuccess) => {
  const apiUrl = urls.CHANGE_PASSWORD;
  var formBody = [];
  for (var property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  await httpClient(`${apiUrl}`, {
    method: "POST",
    body: formBody,
    headers: "password",
  })
    .then((res) => {
      res?.status == 200 &&
        setUpdateSuccess({
          status: "SUCCESS",
        });
    })
    .catch((error) => {
      setUpdateSuccess({
        status: "ERROR",
      });
    });
};

export const UploadExcelFile = async (resource, data, setUpdateStatus) => {
  let apiUrl = "";
  if (resource === "deviceUpload") apiUrl = urls.UPLOAD_ENTITY;

  if (resource === "geofence") apiUrl = urls.GEOFENCE_UPLOAD;

  await httpClient(`${apiUrl}`, {
    method: "POST",
    body: data,
  })
    .then((res) => {
      res?.status == 200 && setUpdateStatus(res.json);
    })
    .catch((error) => {
      setUpdateStatus({ message: "ERROR TO UPLOAD" });
    });
};

export const sendDeviceCommand = async (obj, setResponseStatus) => {
  const apiUrl = urls.DEVICE_COMMAND;
  await httpClient(`${apiUrl}`, {
    method: "POST",
    body: JSON.stringify(obj),
  })
    .then((res) => {
      let msg = "";
      if (res?.status == 200) msg = "Command sent Successfully";
      else if (res?.status == 202)
        msg = "Command sent Successfully,  is in queue";
      else if (res?.status == 401) msg = "Failed to send Command";
      setResponseStatus({
        open: true,
        status: msg,
        msg: "SUCCESS",
        deviceId: obj.deviceId
      });
    })
    .catch((error) => {
      console.error("#ERR", error);
      setResponseStatus({
        open: true,
        status: "error",
        msg: "ERROR",
        deviceId: obj.deviceId
      });
    });
};

export const fetchDeviceDataMakeModel = async (resource) => {
  const apiUrl = urls.MAKE_DROPDOWN;
  return await httpClient(apiUrl).then(({ json }) => {
    return json;
  });
};

export const fetchGeofenceData = async (resource) => {
  const apiUrl = urls.GEOFENCE_LIST;
  return await httpClient(apiUrl).then(({ json }) => {
    return json;
  });
};

export const fetchEventTypeData = async (resource) => {
  const apiUrl = urls.ALARM_TYPE;
  return await httpClient(apiUrl).then(({ json }) => {
    return json;
  });
};

export const sendNewRegisterOTP = async (data, setNewUserRegister) => {
  const query = {}
  let apiUrl = urls.NEW_REGISTER_OTP
  query["phone"] = data?.phone;
  if (!isEmpty(query)) apiUrl = `${apiUrl}?${stringify(query)}`;

  await httpClient(apiUrl).then((res) => {
    res?.status == 200 &&
      setNewUserRegister({
        message: res?.json?.status,
        phone: res?.json?.phone,
        status: "SUCCESS",
        user: data
      });
  }).catch((err) => {
    setNewUserRegister({
      message: "FAILED TO REGISTER!",
      status: "ERROR",
      phone: null,
      user: null
    });
  })
};
export const verifyNewRegisterOTP = async (data, setOtpVerify) => {
  const query = {}
  let apiUrl = urls.NEW_REGISTER_OTP
  query["otp"] = data?.otp;
  query["phone"] = data?.phone;
  await httpClient(`${apiUrl}?${stringify(query)}`, {
    method: "PUT",
  })
    .then((res) => {
      setOtpVerify({
        status: "SUCCESS",
        message: res?.json,
        user: data
      })
    })
    .catch((error) => {
      setOtpVerify({
        status: "ERROR",
        message: "Invalid OTP"
      })
    });
};

export const userRegister = async (json, data, setRegisterSuccess) => {
  const apiUrl = urls.NEW_REGISTER;
  data['user']['token'] = json?.token
  data['user']['isPublic'] = true

  await httpClient(`${apiUrl}`, {
    method: "POST",
    body: JSON.stringify(data?.user),
  })
    .then((res) => {
      setRegisterSuccess({
        status: "SUCCESS",
        message: res?.json?.message
      });
    })
    .catch((error) => {
      setRegisterSuccess({
        status: "ERROR",
        message: `${error?.message}`,
      });
    });
};
const checknull = (pvalue) => {
  if (pvalue === "" || pvalue === undefined || pvalue === null) return ""
  return pvalue
}

export const getMMIToken = async (setToken) => {
  const apiUrl = urls.MMI_Token;
  return await httpClient(apiUrl).then(({ json }) => {
    setToken(json);
  });
};