import React, { useState } from "react";
  
export const PositionContext = React.createContext({});
export const PositionContextProvider = ({ children }) => {
    const [positions, setPositions] = useState([]);
  
    return (
        <PositionContext.Provider value={{ positions, setPositions }}>
            {children}
        </PositionContext.Provider>
    );
};