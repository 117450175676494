import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./vehicle.css";
import {
  TextField,
  Datagrid,
  DateTimeInput,
  TextInput,
  SelectInput,
  TopToolbar,
  Button,
  useListController,
  ListContextProvider,
  Pagination,
  ListToolbar,
  useRecordContext,
  useListContext,
  useRefresh, usePermissions,
  useGetList,
} from "react-admin";
import moment from "moment";
import { styled, alpha } from "@mui/material/styles";
import { Tabs, Tab, Box, Typography, Card } from "@mui/material";
import MapView from "../../components/mapview";
import CardListView from "../../components/cardListView";
import IconTextField from "../../components/iconTextField";
import InputField from "@mui/material/TextField";
import {
  containsLatLong,
  validateLatLong,
  exists,
  isEmpty,
} from "../../utils/validate";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";
import { MapContextProvider } from "../../utils/mapContext";
import VTSListToolbar from "../../components/VTSListToolbar";
import VehicleIconField from "../../components/vehicleIconField";
import LinearProgress from "@mui/material/LinearProgress";
import { PositionContextProvider } from "../../utils/PositionContext";
import Icon6 from "../../images/Viewmap.svg";
import { TitleContext } from "../../utils/TitleContext";
import { GroupContext } from "../../utils/GroupContext";
import {
  fetchGroupList,
  getVehicleLocation,
  sendDeviceCommand,
} from "../../providers/dataProvider";
import CircleIcon from "@mui/icons-material/Circle";
import { default as ReactSelect } from "react-select";
import Grid from "@mui/material/Grid";
import useTabPermissions from "../../hooks/useTabPermissions";
import { AutocompleteInput } from "react-admin";
import ExportIcon from "@mui/icons-material/GetAppOutlined";
import SendIcon from "@mui/icons-material/SendOutlined";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import ModalCloseIcon from "@mui/icons-material/HighlightOffOutlined";
import CheckIcon from '@mui/icons-material/TaskAltOutlined';
import TimeIcon from '@mui/icons-material/ScheduleOutlined';
import { Vehiclecommand } from "../../components/Vehiclecommand";


let fromDate = moment().subtract(1, "months").format("DD MMM YY hh:mm A");
let toDate = moment().format("DD MMM YY hh:mm A");
let fromUTC = moment(fromDate).utc().toISOString();
let toUTC = moment(toDate).utc().toISOString();

const TabPanel = (props) => {
  const { index, value, children } = props;
  return (
    <React.Fragment>
      {value === index && <Typography>{children}</Typography>}
    </React.Fragment>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const VehicleList = () => {
  const [value, setValue] = useState(0);
  const [filter, setFilter] = useState("");
  const [bounds, setBounds] = useState();
  const [ispanclick, setPanClick] = useState(null);
  const [boundsTotal, setBoundsTotal] = useState(0);
  const { groupList, setGroupList } = React.useContext(GroupContext);
  const [rtoCodeList, setRtoCodeList] = useState([]);

  const tabView = useTabPermissions("vehicles");
  const auth = usePermissions();
  const limitcommand = auth?.permissions?.user?.limitCommands;

  const [groupdrop, setgroupdrop] = useState([]);
  const [groupId, setGroupId] = useState();
  const [rtoCode, setRtoCode] = useState();
  const [category, setCategory] = useState();
  const [vehicleCategorydrp, setVehicleCategory] = useState([]);
  const location = useLocation();
  const { setTitle } = React.useContext(TitleContext);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const refresh = useRefresh();
  const [record, setRecord] = useState(null);

  const [commandopen, setCommandOpen] = useState({
    open: false,
    device: null,
  });
  const [driveropen, setDriverOpen] = useState({
    open: false
  });
  const [succommentopen, setSucCommentOpen] = useState({
    open: false
  });

  const [commandMessage, setCommandMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState({
    open: false,
    status: "",
    msg: "",
  });
  const [successDialog, setSuccessDialog] = useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    if (location?.state?.type == "map") {
      setValue(1);
    }
  }, [location?.state]);

  const { data: rtocodeList } = useGetList("rtocode");
  const { data: vehiclecategoryList } = useGetList("vehiclecategory");

  useEffect(() => {
    if (rtocodeList) {
      const list = formatrtodropdown(rtocodeList);
      setRtoCodeList(list);
    }
  }, [rtocodeList]);

  useEffect(() => {
    if (vehiclecategoryList) {
      const list = formatdropdown(vehiclecategoryList);
      setVehicleCategory(list);
    }
  }, [vehiclecategoryList]);

  const formatdropdown = (list) => {
    var te = list?.map((data, index) => ({
      label: data?.name,
      value: data?.id
    }));
    return te;
  };

  const formatrtodropdown = (list) => {
    var te = list?.map((data, index) => ({
      label: (data?.name).toString() + " (" + (data?.code).toString() + ")",
      value: data?.code,
      showlabel: (data?.name).toString() + " (" + (data?.code).toString() + ")",
      rtoName: data?.name
    }));
    return te;
  };

  const fetchRtoList = async () => {
    const res = await fetchRtoList("rtocode");
  };

  const fetchGroupData = async () => {
    const res = await fetchGroupList("groupsdata");
    const list = formatDevices(res);
    setgroupdrop(list);
  };

  useEffect(() => {
    setTitle("Vehicles");
    fetchGroupData();
  }, []);

  const formatDevices = (list) => {
    var te = list?.map((data) => ({
      value: data?.id,
      label: data?.name,
    }));
    return te;
  };

  const matches = useMediaQuery('(min-width:1800px)');

  const handlegroupChange = (selectedOption) => {
    setGroupId(selectedOption);
  };

  const handleRtoChange = (selectedOption) => {
    setRtoCode(selectedOption);
  };

  const handleCategoryChange = (selectedOption) => {
    setCategory(selectedOption);
  };

  const handleGroupChange = (value) => {
    setGroupId(value);
  };

  const SendsucessOpen = async () => {
    let commandObj = {
      deviceId: commandopen?.device?.device_id,
      type: "custom",
      attributes: { data: commandMessage },
    };
    await sendDeviceCommand(commandObj, setResponseStatus);
    setCommandOpen({
      open: false,
      device: null,
    });

  };

  const SendsucessClose = () => {
    setResponseStatus({
      open: false,
      status: "",
      msg: "",
    });
  };

  const perPage = 10;
  const filters = [
    <Box className="sel-group" alwaysOn>
      <ReactSelect
        name="groupId"
        source="group_id"
        options={groupdrop}
        placeholder="Select Group"
        onChange={handlegroupChange}
        value={groupId}

      />
    </Box>,
    <Box className="sel-group" alwaysOn>
      <ReactSelect
        name="rto_code"
        source="rto_code"
        options={rtoCodeList}
        placeholder="Select RTO"
        onChange={handleRtoChange}
        value={rtoCode}
      />
    </Box>,
    <Box className="sel-group" alwaysOn>
      <ReactSelect
        name="category"
        source="category"
        options={vehicleCategorydrp}
        placeholder="Select Category"
        onChange={handleCategoryChange}
        value={category}
      />
    </Box>,
    <TextInput label="Search" source="q" alwaysOn />,
  ];

  const ListActions = () => (
    <>
      <TopToolbar className="vehicle-toolbar">
      </TopToolbar>
    </>
  );

  const MapActions = () => (
    <TopToolbar className="vehicle-toolbar">
      <Typography
        variant="h5"
        component="h2"
        align="left"
        sx={{ fontSize: "14px", marginTop: "20px", fontWeight: "600" }}
        className="top-tlcount"
      >
        Total Count{" "}
        <Button
          label=""
          sx={{
            background: "none !important",
            border: "1px solid blue !important",
            color: "#4646f2 !important",
            fontWeight: "600 !important",
            borderRadius: "6px !important",
            padding: "2px !important",
            marginRight: "115px",
            width: "48px !important",
            marginBottom: "0px",
            marginLeft: "4px"
          }}
        >
          {record != null ? '1' : listContext?.total == undefined ? '0' : listContext?.total}
        </Button>
      </Typography>
    </TopToolbar>
  );

  var vehicleStatus = null;

  if (location?.state?.status === "Moving Vehicle") vehicleStatus = "moving";
  else if (location?.state?.status === "Idle Vehicle") vehicleStatus = "Idle";
  else if (location?.state?.status === "Stopped Vehicle")
    vehicleStatus = "stopped";
  else if (location?.state?.status === "Other Vehicle") vehicleStatus = "other";
  else if (location?.state?.status === "Inactive Vehicle")
    vehicleStatus = "inactive";
  else if (location?.state?.title === "trip") {
    vehicleStatus = location?.state?.status;
  }
  else if (location?.state?.status === "panic") {
    vehicleStatus = "emergency"
  }
  else if (location?.state?.title === "panicMessage") {
    vehicleStatus = location?.state?.status;
  }
  else vehicleStatus = null;



  const handleRedirect = (record) => {
    setRecord(record);
  };

  useEffect(() => {
    if (vehicleStatus === null) {
      listContext.setFilters({});
      listContext.filterValues = "";
    }
  }, [vehicleStatus]);



  const { data, isLoading, page, setPage, setPerPage, ...listContext } =
    useListController({
      resource: "vehicles",
      filter: { statusType: vehicleStatus, group_id: groupId?.value, rtoName: rtoCode?.rtoName, category: category?.label },
    });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPerPage(event.target.value);
  };

  useEffect(() => {
    let bt = 0;
    if (isEmpty(data)) {
      setBoundsTotal(bt);
      return;
    }
    data.map((item, index) => {
      if (
        validateLatLong(item.latitude, item.longitude) &&
        containsLatLong(item.latitude, item.longitude, bounds)
      )
        bt += 1;
    });
    setBoundsTotal(bt);
  }, [data, bounds]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setFilters = (filters) => setFilter(filters.q);
  const sort = { field: "id", order: "ASC" };
  const filterValues = { q: filter };

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const [hover, setHover] = useState(false);
    const formatDate = moment(record[source]).format("DD-MM-YYYY HH:mm:ss");
    const updateDate = moment(formatDate, "DD-MM-YYYY HH:mm:ss").fromNow();
    const dt =
      record[source] !== null
        ? moment(record[source]).format("DD MMM YYYY, HH:mm:ss")
        : "";
    return (
      <Tooltip
        title={updateDate}
        open={hover}
        onClose={() => setHover(false)}
        onOpen={() => setHover(true)}
      >
        <span>{dt}</span>
      </Tooltip>
    );
  };

  const VehicleNameField = () => {
    const record = useRecordContext();
    return (
      <span
        sx={{ fill: "black", fontSize: "12px", marginRight: "2px" }}
        className="veh-name"
      >
        {record?.latitude != 0 && record?.longitude != 0 ? (
          <Button
            variant="contained"
            className="Card-Icons"
            sx={{
              border: "none !important",
            }}
            onClick={() => handleRedirect(record)}
          >
            <img src={Icon6} />
          </Button>
        ) : (
          <Button
            variant="contained"
            className="Card-Icons"
            sx={{
              border: "none !important",
              visibility: "hidden",
            }}
            onClick={() => handleRedirect(record)}
          >
            <img src={Icon6} />
          </Button>
        )}
        {record?.name ? record?.name : record?.vehicleNo ? record?.vehicleNo : "Not Available"}
      </span>
    );
  };
  const DriverNameField = () => {
    const record = useRecordContext();
    return (
      <><span>Driver name</span><EditIcon sx={{ marginLeft: "5px", cursor: "pointer" }} /></>
    )
  }
  const DriverNumberField = () => {
    const record = useRecordContext();
    return (
      <><span>9999999999</span><EditIcon sx={{ marginLeft: "5px", cursor: "pointer" }} /></>
    )
  }


  const ListStatus = () => {
    const record = useRecordContext();
    return (
      <span
        className="status-list"
        status={record?.status}
        title={record?.status}
      >
        <CircleIcon />
      </span>
    );
  };

  useEffect(() => {
    if (record != null) setValue(1);
  }, [record]);

  useEffect(() => {
    value == 0 && setRecord(null);
  }, [value]);

  useEffect(() => {
    var queryvalue = document.querySelectorAll(".list-content");
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }

  });

  const CustomEmpty = () => <div className="no-records">No Records</div>;

  const totalRecord = record == null ? boundsTotal : 1;

  const handleAddress = async (record) => {
    const address = {};
    if (!record?.latitude && !record?.longitude) return;
    await getVehicleLocation(record, address);
    document
      .getElementById(`addr_${record?.id}`)
      ?.classList?.remove("address_styling");
    document.getElementById(`addr_${record?.id}`).innerText = address?.address;
  };

  const AddressField = ({ source }) => {
    const record = useRecordContext();
    return (
      <>
        {record?.latitude != 0 && record?.longitude != 0 && (
          <span
            id={`addr_${record?.id}`}
            className="address_styling"
            onClick={() => handleAddress(record)}
          >
            Show Address
          </span>
        )}
      </>
    );
  };

  const handleCommandOpen = (record) => {
    setCommandOpen({
      open: true,
      device: record,
    });
  };
  const handleCommandClose = () => {
    setCommandOpen({
      open: false,
      device: null,
    });

  };

  const handleCommentClose = () => {
    setResponseStatus({
      open: false,
      status: "",
      msg: "",
    });
  };

  const handleDriverOpen = () => {
    setDriverOpen({
      open: true
    });
  };
  const handleDriverClose = () => {
    setDriverOpen({
      open: false
    });
  };

  const DriverField = () => {
    const record = useRecordContext();

    return (
      <>
        <span>
          Driver <EditIcon onClick={() => handleDriverOpen()} />
        </span>
      </>
    );
  };

  const ActionField = () => {
    const record = useRecordContext();

    return (<>
      {limitcommand === true ? "" : <span
        className="action-field"
        data-title="Send command"
        onClick={() => handleCommandOpen(record)}
      >
        <SendIcon />
      </span>}</>
    );
  };

  const handleCommandChange = (e) => {
    setCommandMessage(e.target.value);
  };


  if (isLoading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  } else
    return (
      <Box sx={{ width: "100%", typography: "body1" }} className="vehicle-sec">
        <ListContextProvider

          value={{
            data,
            filterValues,
            page,
            setPage,
            setPerPage,
            setFilters,
            ...listContext,
          }}
        >
          <Tabs value={value} onChange={handleChange}>
            {tabView && tabView?.viewVehicleList && <Tab label="List" />}
            {tabView && tabView?.viewVehicleMap && <Tab label="Map" className="maptab" />}
          </Tabs>


          <TabPanel value={value} index={0}>
            {tabView && tabView?.viewVehicleList && (
              <> <VTSListToolbar
                filters={filters}
                actions={<ListActions />}
                setGroupId={setGroupId}
                setRtoCode={setRtoCode}
                setCategory={setCategory}
              />
                {matches == true ? (
                  <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />

                ) : (
                  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
                )}
                <Box className="list-content">
                  <Datagrid bulkActionButtons={false} empty={<CustomEmpty />}>
                    <VehicleIconField label="Type" source="type" />
                    <VehicleNameField label="Vehicle Name" source="vehiclename" />
                    <DateSetter label="Last Update" source="lastUpdate" />
                    <IconTextField label="GPS" source="gps" />
                    <ListStatus label="Status" source="status" />
                    <TextField label="Permit Holder" source="permit_holder" />
                    <TextField label="Contact" source="contact" />
                    <TextField label="Chassis No" source="chasisno" />
                    <TextField label="IMEI" source="imei" />
                    <TextField label="RTO Code" source="rto_code" />
                    <AddressField label="Address" />
                    <ActionField label="Command" />
                  </Datagrid>
                </Box>
              </>)}
          </TabPanel>


          <TabPanel value={value} index={1}>
            {tabView && tabView?.viewVehicleMap && (<><VTSListToolbar
              filters={filters}
              actions={<MapActions />}
              setGroupId={setGroupId}
              setRtoCode={setRtoCode}
              setCategory={setCategory} />
              <Box className="mapview-content">
                <Box
                  sx={{ display: "flex", flexDirection: "row", height: "100%" }}
                  className="map-cards"
                >
                  <PositionContextProvider>
                    <MapContextProvider>
                      {/* Card list */}
                      <CardListView bounds={bounds} record={record} setPanClick={setPanClick} ispanclick={ispanclick} />

                      {/* Map view */}
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          height: "75vh",
                          marginBottom: "25px",
                          marginLeft: "10px",
                          zIndex: 1,
                          "& a": {
                            textDecoration: "none",
                            color: "inherit",
                          },
                        }}
                      >
                        <MapView
                          bounds={bounds}
                          cbBounds={setBounds}
                          record={record}
                          vehicleStatus={vehicleStatus}
                          ispanclick={ispanclick}
                        />
                      </Card>
                    </MapContextProvider>
                  </PositionContextProvider>
                </Box>
              </Box>
            </>)}
          </TabPanel>

        </ListContextProvider>

        <Modal
          open={commandopen?.open}
          onClose={handleCommandClose}
          className="command-modal"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="modal-box">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Command
            </Typography>

            <Grid container spacing={2} sx={{ marginTop: "0.8em" }}>
              <Grid item xs={5}>
                <Typography variant="h6" component="h4">
                  Vehicle Name
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="h6" component="p">
                  {commandopen?.device?.name ? commandopen?.device?.name : ""}
                </Typography>
              </Grid>
              <Grid item xs={5} className="pt-0">
                <Typography variant="h6" component="h4">
                  IMEI
                </Typography>
              </Grid>
              <Grid item xs={7} className="pt-0">
                <Typography variant="h6" component="p">
                  {" "}
                  {commandopen?.device?.imei ? commandopen?.device?.imei : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <FormControl>
                    <FormLabel>Contents</FormLabel>
                    <TextareaAutosize
                      name="Contents"
                      aria-label="empty textarea"
                      placeholder="Write contents..."
                      onChange={(e) => handleCommandChange(e)}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  className="cancel-btn"
                  color="primary"
                  sx={{ marginRight: "1em" }}
                  onClick={handleCommandClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={3} sx={{ paddingLeft: "0 !important" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className="send-btn"
                  onClick={SendsucessOpen}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>


        <Modal
          open={driveropen?.open}
          onClose={handleDriverClose}
          className="command-modal driver-modal"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="modal-box">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Driver
            </Typography>
            <span className="close-btn" onClick={handleDriverClose}>
              <ModalCloseIcon />
            </span>

            <Grid container spacing={2} sx={{ marginTop: "0.8em" }}>
              <Grid item xs={3}>
                <Typography variant="h6" component="h4">
                  Name
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <InputField
                  name="name"
                  placeholder="Enter name"
                  type="text"
                  value=""
                />
              </Grid>
              <Grid item xs={3} className="pt-0">
                <Typography variant="h6" component="h4">
                  Phone
                </Typography>
              </Grid>
              <Grid item xs={9} className="pt-0">
                <Box>
                  <FormControl>
                    <InputField
                      name="phone"
                      placeholder="10 Digit Mobile No"
                      type="text"
                      value=""
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  className="cancel-btn"
                  color="primary"
                  sx={{ marginRight: "1em" }}
                  onClick={handleDriverClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={3} sx={{ paddingLeft: "0 !important" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className="send-btn"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        {responseStatus?.open === true ? <Vehiclecommand responseStatus={responseStatus} handleCommentClose={handleCommentClose} /> : ""}
      </Box>
    );
};
