import React, { useState, useEffect } from "react";
import {
  Datagrid,
  ListContextProvider,
  ListToolbar,
  TopToolbar,
  TextInput,
  Button,
  TextField,
  useListController,
  Pagination,
  useRecordContext,
} from "react-admin";

import { Box, LinearProgress, Card, Typography } from "@mui/material";
import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import useReport from "../../hooks/useReport";
import ExportReportData from "./exportReport";
import FormLabel from "@mui/material/FormLabel";
import moment from "moment";
import useMediaQuery from '@mui/material/useMediaQuery';


const Buttonstyle = {
  position: "absolute",
  background: "blue",
  color: "#fff",
  zIndex: 1,
  textTransform: "capitalize",
  width: "150px",
};

export default function ReportType({ viewReport }) {
  const [exportReport, setExportReport] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  useEffect(() => {
    var queryvalue = document.querySelectorAll(".list-content");
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  }, []);

  const matches = useMediaQuery('(min-width:1800px)');

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPerPage(event.target.value);
  };

  const PostPanel = () => (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",

        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
      <table
        id="expand-table"
        class="display table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th>Point</th>
            <th>Entity</th>
            <th>Stop point</th>
            <th>Planned ETA</th>
            <th>Actual ETA</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>-</td>
            <td>Sarjapur_WE89ASD9</td>
            <td>15 Jan 2022, 07:15:12</td>
            <td>-</td>
          </tr>
          <tr>
            <td>2</td>
            <td>-</td>
            <td>Sarjapur_WE89ASD9</td>
            <td>15 Jan 2022, 07:15:12</td>
            <td>-</td>
          </tr>
          <tr>
            <td>3</td>
            <td>-</td>
            <td>Sarjapur_WE89ASD9</td>
            <td>15 Jan 2022, 07:15:12</td>
            <td>-</td>
          </tr>
        </tbody>
      </table>
    </Card>
  );

  const { data, isLoading, page, setPage, setPerPage, ...listContext } =
    useListController({
      resource: viewReport?.deviceId ? "reportsvehicle" : "reportsgroup",
      filter: {
        reportData: viewReport,
      },
    });

  var tableData = viewReport?.columnNames
    ? viewReport?.columnNames?.sort((a, b) =>
      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    )
    : [];

  if (tableData?.length == 0)
    tableData = viewReport?.columnData?.sort((a, b) =>
      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    );

  const Actions = () => (
    <TopToolbar>
      <Typography
        variant="h5"
        component="h2"
        align="left"
        sx={{ fontSize: "14px", marginTop: "20px", fontWeight: "600" }}
        className="top-tlcount"
      >
        Total count{" "}
        <Button
          label=""
          sx={{
            background: "none",
            border: "1px solid blue",
            color: "black",
            fontWeight: "600",
            borderRadius: "10px",
            padding: "2px",
            marginLeft: "15px",
            minWidth: "48px",
            marginBottom: "10px",
          }}
        >
          {listContext?.total}{" "}
        </Button>
      </Typography>
      <div className="show-column-bar">
        <Button label="Export" className="show-btn" onClick={handleExport} />
      </div>
    </TopToolbar>
  );

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt =
      record[source] !== null
        ? moment(record[source]).format("DD MMM YYYY, HH:mm:ss")
        : "";
    return <span>{dt}</span>;
  };

  const CustomEmpty = () => (
    <Typography variant="h6" align="center" className="no-records">
      No Records
    </Typography>
  );

  function handleExport() {
    setExportReport(true);
  }

  const tableDataFilter =
    tableData?.length > 0 ? tableData.filter((col) => col.value != "id") : [];

  if (isLoading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  } else
    return (
      <>
        <Box className="report-sec">
          <ListContextProvider
            value={{
              data,
              page,
              setPage,
              setPerPage,
              ...listContext,
            }}
          >
            {listContext?.total != undefined && (
              <ListToolbar actions={<Actions />} className="reptype-toolbar" />
            )}
            {data === undefined ||
              (Array.isArray(data) === true && data.length === 0) ? (
              <>
                <div className="no-records">No Records</div>
              </>
            ) : (
              <>
                {matches == true ? (
                  <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />

                ) : (
                  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
                )}
                <Box className="list-content">
                  <Datagrid empty={<CustomEmpty />} bulkActionButtons={false}>
                    {tableDataFilter?.length > 0 &&
                      tableDataFilter?.map((col) => {
                        if (col?.value == "eventTime") {
                          return (
                            <DateSetter
                              label={`${col?.label}`}
                              source={`${col?.value}`}
                            />
                          );
                        } else {
                          return (
                            <TextField
                              label={`${col?.label}`}
                              source={`${col?.value}`}
                            />
                          );
                        }
                      })}
                  </Datagrid>
                </Box>
              </>
            )}
          </ListContextProvider>
        </Box>
        {exportReport ? (
          <ExportReportData
            viewReport={viewReport}
            setExport={setExportReport}
          />
        ) : (
          false
        )}
      </>
    );
}
