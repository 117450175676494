import React, { useRef, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from "recharts";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ArrowCircleIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import MoreIcon from "@mui/icons-material/MoreHorizOutlined";
import { LinearProgress, useGetList } from "react-admin";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { TitleContext } from "../utils/TitleContext";
import Grid from "@mui/material/Grid";


const VerticalLine = () => <hr style={{ backgroundColor: "#707070" }} />;

export const PanicChart = ({handleTabChange}) => {
  const [years, setYears] = useState([]);
  const [chartData, setChartData] = useState({
    data: [],
    year: null,
    panic_summary: null,
  });

  const { setTitle } = React.useContext(TitleContext);
  
  useEffect(() => {
    setTitle('Panic')
  }, [])
  
  const { data, total, isLoading } = useGetList("panic", {});

  
  useEffect(() => {
    if (!data) return;
    formatData(data);
  }, [data]);

  const formatData = (data) => {
    getYears(data);
    let sel = data?.find((obj, ind) => obj?.year == new Date().getFullYear());
    const formattedData = formatMonth(sel);
    setChartData(formattedData);
  };

  const getYears = (data) => {
    let yr = [];
    data?.map((obj, ind) => {
      if (obj?.year) {
        if (!yr[ind]) yr.push(obj?.year);
      }
    });
    setYears(yr);
  };

  const formatMonth = (sel) => {
    sel?.data?.map((obj, index) => {
      if (obj?.tcmonth == 1) {
        obj["tcmonth"] = "Jan";
        obj["num_panic"] = obj?.num_panic;
      }
      if (obj?.tcmonth == 2) {
        obj["tcmonth"] = "Feb";
        obj["num_panic"] = obj?.num_panic;
      }
      if (obj?.tcmonth == 3) {
        obj["tcmonth"] = "Mar";
        obj["num_panic"] = obj?.num_panic;
      }
      if (obj?.tcmonth == 4) {
        obj["tcmonth"] = "Apr";
        obj["num_panic"] = obj?.num_panic;
      }
      if (obj?.tcmonth == 5) {
        obj["tcmonth"] = "May";
        obj["num_panic"] = obj?.num_panic;
      }
      if (obj?.tcmonth == 6) {
        obj["tcmonth"] = "Jun";
        obj["num_panic"] = obj?.num_panic;
      }
      if (obj?.tcmonth == 7) {
        obj["tcmonth"] = "Jul";
        obj["num_panic"] = obj?.num_panic;
      }
      if (obj?.tcmonth == 8) {
        obj["tcmonth"] = "Aug";
        obj["num_panic"] = obj?.num_panic;
      }
      if (obj?.tcmonth == 9) {
        obj["tcmonth"] = "Sept";
        obj["num_panic"] = obj?.num_panic;
      }
      if (obj?.tcmonth == 10) {
        obj["tcmonth"] = "Oct";
        obj["num_panic"] = obj?.num_panic;
      }
      if (obj?.tcmonth == 11) {
        obj["tcmonth"] = "Nov";
        obj["num_panic"] = obj?.num_panic;
      }
      if (obj?.tcmonth == 12) {
        obj["tcmonth"] = "Dec";
        obj["num_panic"] = obj?.num_panic;
      }
      return obj;
    });
    return sel;
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let dt = data?.find((obj, index) => obj?.year == value);
    const formattedData = formatMonth(dt);
    setChartData(formattedData);
  };

  const handleRedirect = (e, status) =>{
   handleTabChange(e, status, 1,chartData?.year)
  }

 
  
  if (isLoading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }else
  return (
    <Paper style={{ width: "96%", marginTop: "15px",marginRight:'15px' }}>
      <div>
        <Typography variant="h8" style={{ marginRight: "15px" }}>
          Year
        </Typography>
        <Select
          sx={{ width: "205px", background: "#f0f0ff" }}
          name="year"
          placeholder="Year"
          value={chartData?.year}
          onChange={(e) => handleChange(e)}
        >
          {years?.length != 0 ? (
            years?.map((year, index) => (
              <MenuItem key={index} value={year}>
                {year}
              </MenuItem>
            ))
          ) : (
            <MenuItem>Select</MenuItem>
          )}
        </Select>
      </div>
      {chartData?.data?.length > 0 ? (
        <div style={{ display: "flex" }}>
          <BarChart width={700} height={350} data={chartData?.data}>
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="tcmonth" />
            <YAxis dataKey="num_panic" />
            <Bar dataKey="num_panic" radius={[20, 20, 0, 0]} />
          </BarChart>

          <VerticalLine />
          <Box textAlign="right" className="chart-right">

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                <Grid item xs={3}></Grid>
                  <Grid item xs={7}>
                    <Typography color="textSecondary" component="h2" textAlign={"center !important"}>
                      {chartData && chartData?.panic_summary?.total_sos}
                    </Typography>
                    <Typography color="textSecondary" component="p" textAlign={"center !important"}>
                      Total
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    <Typography color="textSecondary" component="h2">
                    {chartData && chartData?.panic_summary?.num_sos_acknowledged}
                    </Typography>
                    <Typography
                      color="#4646F2 !important"
                      component="p"
                      className="arrow-right"
                    >
                      Acknowledged
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <ArrowCircleIcon style={{cursor: 'pointer',color:"#4646F2",marginTop:"28px",marginLeft:"-8px"}} onClick={(e) => handleRedirect(e, 'acknowledged')} />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography color="textSecondary" component="h2">
                      {chartData && chartData?.panic_summary?.num_sos_resolved}
                    </Typography>
                    <Typography
                    color="#63CE78 !important"
                    component="p"
                    className="arrow-right"
                  >Resolved 
                  </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <ArrowCircleIcon style={{cursor: 'pointer',color:"#63CE78",marginTop:"28px",marginLeft:"-8px"}} onClick={(e) => handleRedirect(e, 'resolved')}/>
                  </Grid>


                  <Grid item xs={5}>
                    <Typography color="textSecondary" component="h2">
                    {chartData && chartData?.panic_summary?.num_sos_cancelled}
                    </Typography>
                    <Typography
                      color="#ff0000 !important"
                      component="p"
                      className="arrow-right"
                    >
                    Cancelled
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                  <ArrowCircleIcon style={{cursor: 'pointer',color:"#ff0000",marginTop:"28px",marginLeft:"-8px"}} onClick={(e) => handleRedirect(e, 'cancelled')}/>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography color="textSecondary" component="h2">
                      {chartData && chartData?.panic_summary?.num_sos_pending}
                    </Typography>
                    <Typography
                      color="#FA9826 !important"
                      component="p"
                      className="arrow-right"
                    >Pending 
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                  <ArrowCircleIcon style={{cursor: 'pointer',color:"#FA9826",marginTop:"28px",marginLeft:"-8px"}} onClick={(e) => handleRedirect(e, 'pending')}/>
                  </Grid>
                </Grid>
              </Box>
          </Box>
        </div>
      ) : (
        <Typography>No Data</Typography>
      )}
    </Paper>
  );
};
