import React, { useEffect, useState, useRef } from "react";
import { Button, useListController } from "react-admin";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { Grid, Card } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import "../views/cpanel/cpanel.css";
import MultiSelect from "react-select";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { default as ReactSelect } from "react-select";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import VerifiedIcon from "../images/resolved.png";
import { components } from "react-select";
import { mapCPanelUserPermissions } from "../providers/dataProvider";
import WarningIcon from "@mui/icons-material/PriorityHighOutlined";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { TitleContext } from "../utils/TitleContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

export default function UsersPanel() {
  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isSelected ? "#fff" : undefined,
    }),

  };

  const [usersData, setUsersData] = useState([]);
  const [CPanelPermissions, setCPanelPermissions] = useState({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const { setTitle } = React.useContext(TitleContext);
  const [open, setOpen] = useState(false);
  const loading = open && usersData?.length === 0;
  const [notificationInfo, setNotificationInfo] = useState([]);
  const [geofenceInfo, setGeofenceInfo] = useState([]);
  const [routeInfo, setRouteInfo] = useState([]);
  const [deviceInfo, setDeviceInfo] = useState([]);
  const [groupInfo, setGroupInfo] = useState([]);
  const [managedUserInfo, setManagedUserInfo] = useState([]);
  const [scheduleInfo, setScheduleInfo] = useState([]);

  const [routeMapping, setRouteMapping] = useState({
    selected: [],
    deleted: [],
  });
  const [notificationMapping, setNotificationMapping] = useState({
    selected: [],
    deleted: [],
  });
  const [geofenceMapping, setGeofenceMapping] = useState({
    selected: [],
    deleted: [],
  });
  const [deviceMapping, setDeviceMapping] = useState({
    selected: [],
    deleted: [],
  });
  const [groupMapping, setGroupMapping] = useState({
    selected: [],
    deleted: [],
  });
  const [managedUserMapping, setManagedUserMapping] = useState({
    selected: [],
    deleted: [],
  });
  const [scheduleMapping, setScheduleMapping] = useState({
    selected: [],
    deleted: [],
  });

  const [prevSelectedNotif, setPrevSelectedNotif] = useState([]);
  const [prevSelectedRoute, setPrevSelectedRoute] = useState([]);
  const [prevSelectedGeofence, setPrevSelectedGeofence] = useState([]);
  const [prevSelectedGroup, setPrevSelectedGroup] = useState([]);
  const [prevSelectedDevice, setPrevSelectedDevice] = useState([]);
  const [prevSelectedUsers, setPrevSelectedUsers] = useState([]);
  const [prevSelectedSchedule, setPrevSelectedSchedule] = useState([]);

  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    setTitle("Control Panel");
  }, []);

  const handleChange = (event, newValue) => {
    setFormValues(newValue);
  };

  const maxToShow = 3;

  const handlePropertyChange = (pname, selected) => {
    if (pname == "notification") {
      const del = prevSelectedNotif?.filter(
        (item) => !selected?.find((fl) => fl?.value == item?.value)
      );
      const notifCopy = [...notificationInfo];
      notifCopy?.map((itm, index) => {
        notifCopy[index]["hasMapping"] = false;
        if (selected?.find((obj) => obj?.value == itm?.value)) {
          notifCopy[index]["hasMapping"] = true;
        }
      });
      setNotificationMapping({
        selected: selected,
        deleted: del,
      });
      setNotificationInfo(notifCopy);
    }
    if (pname == "route") {
      const del = prevSelectedRoute?.filter(
        (item) => !selected?.find((fl) => fl?.value == item?.value)
      );
      const routeCopy = [...routeInfo];
      routeCopy?.map((itm, index) => {
        routeCopy[index]["hasMapping"] = false;
        if (selected?.find((obj) => obj?.value == itm?.value)) {
          routeCopy[index]["hasMapping"] = true;
        }
      });
      setRouteMapping({
        selected: selected,
        deleted: del,
      });
      setRouteInfo(routeCopy);
    }
    if (pname == "geofence") {
      const del = prevSelectedGeofence?.filter(
        (item) => !selected?.find((fl) => fl?.value == item?.value)
      );
      const geofenceCopy = [...geofenceInfo];
      geofenceCopy?.map((itm, index) => {
        geofenceCopy[index]["hasMapping"] = false;
        if (selected?.find((obj) => obj?.value == itm?.value)) {
          geofenceCopy[index]["hasMapping"] = true;
        }
      });
      setGeofenceMapping({
        selected: selected,
        deleted: del,
      });
      setGeofenceInfo(geofenceCopy);
    }
    if (pname == "device") {
      const del = prevSelectedDevice?.filter(
        (item) => !selected?.find((fl) => fl?.value == item?.value)
      );
      const deviceCopy = [...deviceInfo];
      deviceCopy?.map((itm, index) => {
        deviceCopy[index]["hasMapping"] = false;
        if (selected?.find((obj) => obj?.value == itm?.value)) {
          deviceCopy[index]["hasMapping"] = true;
        }
      });
      setDeviceMapping({
        selected: selected,
        deleted: del,
      });
      setDeviceInfo(deviceCopy);
    }
    if (pname == "group") {
      const del = prevSelectedGroup?.filter(
        (item) => !selected?.find((fl) => fl?.value == item?.value)
      );
      const groupCopy = [...groupInfo];
      groupCopy?.map((itm, index) => {
        groupCopy[index]["hasMapping"] = false;
        if (selected?.find((obj) => obj?.value == itm?.value)) {
          groupCopy[index]["hasMapping"] = true;
        }
      });
      setGroupMapping({
        selected: selected,
        deleted: del,
      });
      setGroupInfo(groupCopy);
    }

    if (pname == "user") {
      const del = prevSelectedUsers?.filter(
        (item) => !selected?.find((fl) => fl?.value == item?.value)
      );
      const managedUserCopy = [...managedUserInfo];
      managedUserCopy?.map((itm, index) => {
        managedUserCopy[index]["hasMapping"] = false;
        if (selected?.find((obj) => obj?.value == itm?.value)) {
          managedUserCopy[index]["hasMapping"] = true;
        }
      });
      setManagedUserMapping({
        selected: selected,
        deleted: del,
      });
      setManagedUserInfo(managedUserCopy);
    }
    if (pname == "schedule") {
      const del = prevSelectedSchedule?.filter(
        (item) => !selected?.find((fl) => fl?.value == item?.value)
      );
      const scheduleCopy = [...scheduleInfo];
      scheduleCopy?.map((itm, index) => {
        scheduleCopy[index]["hasMapping"] = false;
        if (selected?.find((obj) => obj?.value == itm?.value)) {
          scheduleCopy[index]["hasMapping"] = true;
        }
      });
      setScheduleMapping({
        selected: selected,
        deleted: del,
      });
      setScheduleInfo(scheduleCopy);
    }
  };

  const { data: userList, isLoading } = useListController({
    resource: "users",
    filter: { searchKey: "userspanel" },
  });

  const { data: notificationList } = useListController({
    resource: "controlpanel",
    filter: {
      owner: "user",
      property: "notification",
      controlId: formValues?.value,
    },
    enabled: userList?.length > 0 && formValues?.value != null,
  });

  const { data: routeList } = useListController({
    resource: "controlpanel",
    filter: {
      owner: "user",
      property: "route",
      controlId: formValues?.value,
    },
    enabled: userList?.length > 0 && formValues?.value != null,
  });

  const { data: geofenceList } = useListController({
    resource: "controlpanel",
    filter: {
      owner: "user",
      property: "geofence",
      controlId: formValues?.value,
    },
    enabled: userList?.length > 0 && formValues?.value != null,
  });

  const { data: deviceList } = useListController({
    resource: "controlpanel",
    filter: {
      owner: "user",
      property: "device",
      controlId: formValues?.value,
    },
    enabled: userList?.length > 0 && formValues?.value != null,
  });

  const { data: groupList } = useListController({
    resource: "controlpanel",
    filter: {
      owner: "user",
      property: "group",
      controlId: formValues?.value,
    },
    enabled: userList?.length > 0 && formValues?.value != null,
  });

  const { data: managedUserList } = useListController({
    resource: "controlpanel",
    filter: {
      owner: "user",
      property: "user",
      controlId: formValues?.value,
    },
    enabled: userList?.length > null && formValues?.value != null,
  });

  const { data: scheduleList } = useListController({
    resource: "controlpanel",
    filter: {
      owner: "user",
      property: "schedule",
      controlId: formValues?.value,
    },
    enabled: userList?.length > 0 && formValues?.value != null,
  });

  const sleep = (delay = 0) => {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  };

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      await sleep(1e3);
      if (active) {
        const list = formatUsers(userList);
        setUsersData(list);
      }
    })(); //iife

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setUsersData([]);
    }
  }, [open]);

  useEffect(() => {
    if (!notificationList) return;
    const gp = formatDataList(notificationList, "notification");
    setPrevSelectedNotif(gp?.filter((grp) => grp?.hasMapping));
    setNotificationInfo(gp);
  }, [notificationList]);

  useEffect(() => {
    if (!routeList) return;
    const gp = formatDataList(routeList, "route");
    setPrevSelectedRoute(gp?.filter((grp) => grp?.hasMapping));
    setRouteInfo(gp);
  }, [routeList]);

  useEffect(() => {
    if (!geofenceList) return;
    const gp = formatDataList(geofenceList, "geofence");
    setPrevSelectedGeofence(gp?.filter((grp) => grp?.hasMapping));
    setGeofenceInfo(gp);
  }, [geofenceList]);

  useEffect(() => {
    if (!deviceList) return;
    const dtl = formatDataList(deviceList, "device");
    setPrevSelectedDevice(dtl?.filter((dev) => dev?.hasMapping));
    setDeviceInfo(dtl);
  }, [deviceList]);

  useEffect(() => {
    if (!groupList) return;
    const gp = formatDataList(groupList, "group");
    setPrevSelectedGroup(gp?.filter((grp) => grp?.hasMapping));
    setGroupInfo(gp);
  }, [groupList]);

  useEffect(() => {
    if (!managedUserList) return;
    const gp = formatDataList(managedUserList, "user");
    setPrevSelectedUsers(gp?.filter((grp) => grp?.hasMapping));
    setManagedUserInfo(gp);
  }, [managedUserList]);

  useEffect(() => {
    if (!scheduleList) return;
    const gp = formatDataList(scheduleList, "schedule");
    setPrevSelectedSchedule(gp?.filter((grp) => grp?.hasMapping));
    setScheduleInfo(gp);
  }, [scheduleList]);

  const formatUsers = (list) => {
    var te = list?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
    return te;
  };

  const formatDataList = (dataList, pName) => {
    var dt = null;
    if (pName == "notification") {
      dt = dataList?.map((data) => ({
        label: data.name,
        value: data.notificationid,
        hasMapping: data.hasMapping,
      }));
    } else if (pName == "route") {
      dt = dataList?.map((data) => ({
        label: data.name,
        value: data.routeid,
        hasMapping: data.hasMapping,
      }));
    } else if (pName == "geofence") {
      dt = dataList?.map((data) => ({
        label: data.name,
        value: data.geofenceid,
        hasMapping: data.hasMapping,
      }));
    } else if (pName == "device") {
      dt = dataList?.map((data) => ({
        label: data.name,
        value: data.deviceid,
        hasMapping: data.hasMapping,
      }));
    } else if (pName == "group") {
      dt = dataList?.map((data) => ({
        label: data.name,
        value: data.groupid,
        hasMapping: data.hasMapping,
      }));
    } else if (pName == "user") {
      dt = dataList?.map((data) => ({
        label: data.name,
        value: data.manageduserid,
        hasMapping: data.hasMapping,
      }));
    } else if (pName == "schedule") {
      dt = dataList?.map((data) => ({
        label: data.name,
        value: data.scheduleid,
        hasMapping: data.hasMapping,
      }));
    }
    return dt;
  };

  const handleMappingSuccess = () => {
    setFormValues(null);
    setNotificationInfo([]);
    setRouteInfo([]);
    setGeofenceInfo([]);
    setDeviceInfo([]);
    setGroupInfo([]);
    setManagedUserInfo([]);
    setScheduleInfo([]);
    setConfirmDialog(false);
  };

  const RoutesOption = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props?.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const NotificationsOption = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props?.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const GeofenceOption = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props?.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const GroupsOption = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props?.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MoreSelectedBadge = ({ items }) => {
    const style = {
      marginLeft: "auto",
      background: "#d4eefa",
      borderRadius: "4px",
      fontFamily: "Open Sans",
      fontSize: "11px",
      padding: "3px",
      order: 99
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

    return (
      <div style={style} title={title}>
        {label}
      </div>
    );
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 1;
    const overflow = getValue()
      .slice(maxToShow)
      .map((x) => x.label);

    return index < maxToShow ? (
      <components.MultiValue {...props} />
    ) : index === maxToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null;
  };


  const DevicesOption = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props?.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const SchedulesOption = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props?.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const ManagedUsersOption = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props?.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  useEffect(() => {
    if (CPanelPermissions?.status) setConfirmDialog(true);
  }, [CPanelPermissions]);

  const handleSubmit = () => {
    let deleteArr = [];
    let createArr = [];

    if (routeMapping?.deleted?.length > 0) {
      routeMapping?.deleted?.map((item, index) => {
        deleteArr?.push({
          userid: formValues?.value,
          routeid: item?.value,
        });
      });
    }

    if (notificationMapping?.deleted?.length > 0) {
      notificationMapping?.deleted?.map((item, index) => {
        deleteArr?.push({
          userid: formValues?.value,
          notificationid: item?.value,
        });
      });
    }

    if (geofenceMapping?.deleted?.length > 0) {
      geofenceMapping?.deleted?.map((item, index) => {
        deleteArr?.push({
          userid: formValues?.value,
          geofenceid: item?.value,
        });
      });
    }

    if (deviceMapping?.deleted?.length > 0) {
      deviceMapping?.deleted?.map((item, index) => {
        deleteArr?.push({
          userid: formValues?.value,
          deviceid: item?.value,
        });
      });
    }

    if (groupMapping?.deleted?.length > 0) {
      groupMapping?.deleted?.map((item, index) => {
        deleteArr?.push({
          userid: formValues?.value,
          groupid: item?.value,
        });
      });
    }

    if (managedUserMapping?.deleted?.length > 0) {
      managedUserMapping?.deleted?.map((item, index) => {
        deleteArr?.push({
          userid: formValues?.value,
          manageduserid: item?.value,
        });
      });
    }

    if (scheduleMapping?.deleted?.length > 0) {
      scheduleMapping?.deleted?.map((item, index) => {
        deleteArr?.push({
          userid: formValues?.value,
          scheduleid: item?.value,
        });
      });
    }

    if (routeMapping?.selected?.length > 0) {
      routeMapping?.selected?.map((item, index) => {
        createArr?.push({
          userid: formValues?.value,
          routeid: item?.value,
        });
      });
    }

    if (notificationMapping?.selected?.length > 0) {
      notificationMapping?.selected?.map((item, index) => {
        createArr?.push({
          userid: formValues?.value,
          notificationid: item?.value,
        });
      });
    }

    if (geofenceMapping?.selected?.length > 0) {
      geofenceMapping?.selected?.map((item, index) => {
        createArr?.push({
          userid: formValues?.value,
          geofenceid: item?.value,
        });
      });
    }

    if (deviceMapping?.selected?.length > 0) {
      deviceMapping?.selected?.map((item, index) => {
        createArr?.push({
          userid: formValues?.value,
          deviceid: item?.value,
        });
      });
    }

    if (groupMapping?.selected?.length > 0) {
      groupMapping?.selected?.map((item, index) => {
        createArr?.push({
          userid: formValues?.value,
          groupid: item?.value,
        });
      });
    }

    if (managedUserMapping?.selected?.length > 0) {
      managedUserMapping?.selected?.map((item, index) => {
        createArr?.push({
          userid: formValues?.value,
          manageduserid: item?.value,
        });
      });
    }

    if (scheduleMapping?.selected?.length > 0) {
      scheduleMapping?.selected?.map((item, index) => {
        createArr?.push({
          userid: formValues?.value,
          scheduleid: item?.value,
        });
      });
    }
    mapCPanelUserPermissions(
      "cpanelpermissions",
      deleteArr,
      createArr,
      setCPanelPermissions
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
        <Card
          sx={{
            minWidth: 250,
            marginTop: "15px",
            marginBottom: "15px",
            width: "98%",
            height: "75vh",
            flexDirection: "column",
            "& a": {
              textDecoration: "none",
              color: "inherit",
            },
          }}
          className="cpanel-card"
        >
          <Box textAlign="left" className="audit-left" sx={{ height: "80vh !important" }}>
            <Box sx={{ marginTop: "1em" }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <FormLabel sx={{ fontSize: "16px !important" }}>
                    User
                  </FormLabel>
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    disablePortal
                    id="userId"
                    name="userId"
                    options={usersData}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select User"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    loading={loading}
                    getOptionLabel={(option) => option.label}
                    onChange={handleChange}
                    value={formValues}
                  />
                </Grid>
              </Grid>
            </Box>
            <hr />

            <Box sx={{ flexGrow: 1, marginTop: "4em" }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <FormLabel>Device</FormLabel>
                </Grid>
                <Grid item xs={3}>
                  <Box className="Search-drop">
                    <span
                      class="d-inline-block"
                      data-toggle="popover"
                      data-trigger="focus"
                      data-content="Select"
                    >
                      <ReactSelect
                        styles={customStyles}
                        closeMenuOnSelect={false}
                        isMulti
                        hideSelectedOptions={false}
                        options={deviceInfo}
                        onChange={(e) => handlePropertyChange("device", e)}
                        name="device"
                        isDisabled={formValues?.value == null}
                        value={deviceInfo?.filter((obj) => obj?.hasMapping)}
                        components={{
                          Option: DevicesOption,
                          MultiValue
                        }}
                      />

                    </span>
                  </Box>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                  <FormLabel>Managed User</FormLabel>
                </Grid>
                <Grid item xs={3}>
                  <Box className="Search-drop">
                    <span
                      class="d-inline-block"
                      data-toggle="popover"
                      data-trigger="focus"
                      data-content="Selecet"
                    >
                      <ReactSelect
                        styles={customStyles}
                        options={managedUserInfo}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: ManagedUsersOption, MultiValue }}
                        onChange={(e) => handlePropertyChange("user", e)}
                        name="manageduser"
                        isDisabled={formValues?.value == null}
                        value={managedUserInfo?.filter(
                          (obj) => obj?.hasMapping
                        )}
                      />
                    </span>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1, marginTop: "4em" }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <FormLabel>Group</FormLabel>
                </Grid>
                <Grid item xs={3}>
                  <Box className="Search-drop top-down">
                    <span
                      class="d-inline-block"
                      data-toggle="popover"
                      data-trigger="focus"
                      data-content="Selecet"
                    >
                      <ReactSelect
                        styles={customStyles}
                        options={groupInfo}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: GroupsOption, MultiValue }}
                        onChange={(e) => handlePropertyChange("group", e)}
                        name="group"
                        isDisabled={formValues?.value == null}
                        value={groupInfo?.filter((obj) => obj?.hasMapping)}
                      />
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={2}>
                  <FormLabel>Notification</FormLabel>
                </Grid>
                <Grid item xs={3}>
                  <Box className="Search-drop top-down">
                    <span
                      class="d-inline-block"
                      data-toggle="popover"
                      data-trigger="focus"
                      data-content="Selecet"
                    >
                      <ReactSelect
                        styles={customStyles}
                        options={notificationInfo}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: NotificationsOption, MultiValue }}
                        onChange={(e) =>
                          handlePropertyChange("notification", e)
                        }
                        name="notification"
                        isDisabled={formValues?.value == null}
                        value={notificationInfo?.filter(
                          (obj) => obj?.hasMapping
                        )}
                      />
                    </span>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ flexGrow: 1, marginTop: "4em" }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <FormLabel>Route</FormLabel>
                </Grid>
                <Grid item xs={3}>
                  <Box className="Search-drop top-down">
                    <span
                      class="d-inline-block"
                      data-toggle="popover"
                      data-trigger="focus"
                      data-content="Selecet"
                    >
                      <ReactSelect
                        styles={customStyles}
                        options={routeInfo}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: RoutesOption, MultiValue }}
                        onChange={(e) => handlePropertyChange("route", e)}
                        name="route"
                        isDisabled={formValues?.value == null}
                        value={routeInfo?.filter((obj) => obj?.hasMapping)}
                      />
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                  <FormLabel>Schedule Report</FormLabel>
                </Grid>
                <Grid item xs={3}>
                  <Box className="Search-drop top-down">
                    <span
                      class="d-inline-block"
                      data-toggle="popover"
                      data-trigger="focus"
                      data-content="Selecet"
                    >
                      <ReactSelect
                        styles={customStyles}
                        options={scheduleInfo}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: SchedulesOption, MultiValue }}
                        onChange={(e) => handlePropertyChange("schedule", e)}
                        name="schedule"
                        isDisabled={formValues?.value == null}
                        value={scheduleInfo?.filter((obj) => obj?.hasMapping)}
                      />
                    </span>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1, marginTop: "4em" }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <FormLabel>Geo-fence</FormLabel>
                </Grid>
                <Grid item xs={3}>
                  <Box className="Search-drop top-down">
                    <span
                      class="d-inline-block"
                      data-toggle="popover"
                      data-trigger="focus"
                      data-content="Selecet"
                    >
                      <ReactSelect
                        styles={customStyles}
                        options={geofenceInfo}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: GeofenceOption, MultiValue }}
                        onChange={(e) => handlePropertyChange("geofence", e)}
                        name="geofence"
                        isDisabled={formValues?.value == null}
                        value={geofenceInfo?.filter((obj) => obj?.hasMapping)}
                      />
                    </span>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ marginTop: "1em", marginBottom: "2rem" }}>
            <Button type="submit" className="show-btn" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </Card>
      </Box>
      <Modal
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="user-mapModal"
      >
        <Box sx={style} className="modal-box">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          ></Typography>

          <Box>
            {CPanelPermissions?.status == "SUCCESS" ? (
              <img src={VerifiedIcon} className="verified-icon" />
            ) : (
              WarningIcon
            )}

            <Typography variant="h6" component="h4">
              {CPanelPermissions?.status == "SUCCESS"
                ? "User Mapping Successful!"
                : "User Mapping Error!"}
            </Typography>

            <div style={{ marginTop: "1em" }} className="modal-footer">
              <Button
                variant="contained"
                className="continue-btn"
                onClick={handleMappingSuccess}
                color="primary"
                sx={{
                  marginRight: "1em",
                  width: "60px !important",
                  borderRadius: "6px !important",
                }}
              >
                OK
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
