import React, { useEffect } from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const create_value = "8"
const read_value = "4"
const edit_value = "2"
const del_value = "1"
const view_value = "1"

const create_per_value = "8"
const del_per_value = "1"

export function TreeViewReportComponent({ register, watch, setValue }) {
  function reportData(menu, read) {
    return { menu, read };
  }

  const checkAll = (flag) => {
    if (flag == "check") {
      if (watch("roles.report.all") === "4") {
        setValue("roles.report.route", "4");
        setValue("roles.report.trip", "4");
        setValue("roles.report.summary", "4");
        setValue("roles.report.event", "4");
        setValue("roles.report.stop", "4");

        setValue("roles.report.geofence", "4");
        setValue("roles.report.panic", "4");
        setValue("roles.report.reportedPermitViolation", "4");
        setValue("roles.report.generatedPanicViolation", "4");
        setValue("roles.report.deviceStatistics", "4");
        setValue("roles.report.deviceHistoryLogs", "4");
        setValue("roles.report.vltdOem", "4");
      }
      else {
        setValue("roles.report.route", "");
        setValue("roles.report.trip", "");
        setValue("roles.report.summary", "");
        setValue("roles.report.event", "");
        setValue("roles.report.stop", "");

        setValue("roles.report.geofence", "");
        setValue("roles.report.panic", "");
        setValue("roles.report.reportedPermitViolation", "");
        setValue("roles.report.generatedPanicViolation", "");
        setValue("roles.report.deviceStatistics", "");
        setValue("roles.report.deviceHistoryLogs", "");
        setValue("roles.report.vltdOem", "");
      }
    }
    else {
      if (watch("roles.report.route") !== "4" || watch("roles.report.trip") !== "4" ||
        watch("roles.report.summary") !== "4" || watch("roles.report.event") !== "4" ||
        watch("roles.report.stop") !== "4" || watch("roles.report.geofence") !== "4" ||
        watch("roles.report.panic") !== "4" || watch("roles.report.reportedPermitViolation") !== "4" ||
        watch("roles.report.generatedPanicViolation") !== "4" || watch("roles.report.deviceStatistics") !== "4" ||
        watch("roles.report.deviceHistoryLogs") !== "4" || watch("roles.report.vltdOem") !== "4") {
        setValue("roles.report.all", "")
      }
      else { setValue("roles.report.all", "4") }
    }
  }


  const reportrows = [
    reportData('All', <Checkbox value='4' {...register("roles.report.all", {
      onChange: (e) => { checkAll("check") }
    })} checked={watch("roles.report.all").toString().includes(read_value)} />),
    reportData('Route', <Checkbox value='4' {...register("roles.report.route", {
      onChange: (e) => { checkAll("uncheck") },
    })} checked={watch("roles.report.route").toString().includes(read_value)} />),
    reportData('Trip', <Checkbox value='4'  {...register("roles.report.trip", {
      onChange: (e) => { checkAll("uncheck") },
    })} checked={watch("roles.report.trip").toString().includes(read_value)} />),
    reportData('Summary', <Checkbox value='4'  {...register("roles.report.summary", {
      onChange: (e) => { checkAll("uncheck") },
    })} checked={watch("roles.report.summary").toString().includes(read_value)} />),
    reportData('Event', <Checkbox value='4'  {...register("roles.report.event", {
      onChange: (e) => { checkAll("uncheck") },
    })} checked={watch("roles.report.event").toString().includes(read_value)} />),
    reportData('Stop', <Checkbox value='4' {...register("roles.report.stop", {
      onChange: (e) => { checkAll("uncheck") },
    })} checked={watch("roles.report.stop").toString().includes(read_value)} />),
    reportData('Geofence', <Checkbox value='4' {...register("roles.report.geofence", {
      onChange: (e) => { checkAll("uncheck") }
    })} checked={watch("roles.report.geofence").toString().includes(read_value)} />),
    reportData('Panic', <Checkbox value='4' {...register("roles.report.panic", {
      onChange: (e) => { checkAll("uncheck") },
    })} checked={watch("roles.report.panic").toString().includes(read_value)} />),
    reportData('Reported Permit Violation', <Checkbox value='4'  {...register("roles.report.reportedPermitViolation", {
      onChange: (e) => { checkAll("uncheck") },
    })} checked={watch("roles.report.reportedPermitViolation").toString().includes(read_value)} />),
    reportData('Generated Panic Violation', <Checkbox value='4'  {...register("roles.report.generatedPanicViolation", {
      onChange: (e) => { checkAll("uncheck") },
    })} checked={watch("roles.report.generatedPanicViolation").toString().includes(read_value)} />),
    reportData('Device Statistics', <Checkbox value='4'  {...register("roles.report.deviceStatistics", {
      onChange: (e) => { checkAll("uncheck") },
    })} checked={watch("roles.report.deviceStatistics").toString().includes(read_value)} />),
    reportData('Device History Logs', <Checkbox value='4' {...register("roles.report.deviceHistoryLogs", {
      onChange: (e) => { checkAll("uncheck") },
    })} checked={watch("roles.report.deviceHistoryLogs").toString().includes(read_value)} />),
    reportData('VltdOem', <Checkbox value='4' {...register("roles.report.vltdOem", {
      onChange: (e) => { checkAll("uncheck") },
    })} checked={watch("roles.report.vltdOem").toString().includes(read_value)} />),

  ];
  return (
    <TableContainer component={Paper} className="Role-table">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Menu</TableCell>
            <TableCell align="right">Read</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportrows.map((row) => (
            <TableRow
              key={row.menu}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.menu}
              </TableCell>
              <TableCell align="right">{row.read}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}



export function TreeViewAlarmComponent({ watch, register }) {
  function alarmData(menu, read) {
    return { menu, read };
  }

  const alarmrows = [
    alarmData('General', <Checkbox  {...register("roles.alarm.general")} checked={watch("roles.alarm.general")} />),
    alarmData('SOS', <Checkbox  {...register("roles.alarm.sos")} checked={watch("roles.alarm.sos")} />),
    alarmData('Vibration', <Checkbox   {...register("roles.alarm.vibration")} checked={watch("roles.alarm.vibration")} />),
    alarmData('Movement', <Checkbox   {...register("roles.alarm.movement")} checked={watch("roles.alarm.movement")} />),
    alarmData('Low speed', <Checkbox   {...register("roles.alarm.low_speed")} checked={watch("roles.alarm.low_speed")} />),
    alarmData('Over speed', <Checkbox   {...register("roles.alarm.overspeed")} checked={watch("roles.alarm.overspeed")} />),
    alarmData('Fall down', <Checkbox   {...register("roles.alarm.fall_down")} checked={watch("roles.alarm.fall_down")} />),
    alarmData('Low Power', <Checkbox  {...register("roles.alarm.low_power")} checked={watch("roles.alarm.low_power")} />),
    alarmData('Low battery', <Checkbox {...register("roles.alarm.low_battery")} checked={watch("roles.alarm.low_battery")} />),
    alarmData('Fault', <Checkbox  {...register("roles.alarm.fault")} checked={watch("roles.alarm.fault")} />),
    alarmData('Power off', <Checkbox {...register("roles.alarm.power_off")} checked={watch("roles.alarm.power_off")} />),
    alarmData('Power on', <Checkbox {...register("roles.alarm.power_on")} checked={watch("roles.alarm.power_on")} />),
    alarmData('Door', <Checkbox  {...register("roles.alarm.door")} checked={watch("roles.alarm.door")} />),
    alarmData('Lock', <Checkbox  {...register("roles.alarm.lock")} checked={watch("roles.alarm.lock")} />),
    alarmData('Unlock', <Checkbox  {...register("roles.alarm.unlock")} checked={watch("roles.alarm.unlock")} />),
    alarmData('Geofence', <Checkbox  {...register("roles.alarm.geofence")} checked={watch("roles.alarm.geofence")} />),
    alarmData('Geofence enter', <Checkbox  {...register("roles.alarm.geofence_enter")} checked={watch("roles.alarm.geofence_enter")} />),
    alarmData('Geofence exit', <Checkbox  {...register("roles.alarm.geofence_exit")} checked={watch("roles.alarm.geofence_exit")} />),
    alarmData('Gps antenna cut', <Checkbox   {...register("roles.alarm.gps_antenna_cut")} checked={watch("roles.alarm.gps_antenna_cut")} />),
    alarmData('Accident', <Checkbox  {...register("roles.alarm.accident")} checked={watch("roles.alarm.accident")} />),
    alarmData('Tow', <Checkbox  {...register("roles.alarm.tow")} checked={watch("roles.alarm.tow")} />),
    alarmData('Idle', <Checkbox  {...register("roles.alarm.idle")} checked={watch("roles.alarm.idle")} />),
    alarmData('High rpm', <Checkbox  {...register("roles.alarm.high_rpm")} checked={watch("roles.alarm.high_rpm")} />),
    alarmData('Acceleration', <Checkbox  {...register("roles.alarm.acceleration")} checked={watch("roles.alarm.acceleration")} />),
    alarmData('braking', <Checkbox  {...register("roles.alarm.braking")} checked={watch("roles.alarm.braking")} />),
    alarmData('Cornering', <Checkbox  {...register("roles.alarm.cornering")} checked={watch("roles.alarm.cornering")} />),
    alarmData('Lane change', <Checkbox  {...register("roles.alarm.lane_change")} checked={watch("roles.alarm.lane_change")} />),
    alarmData('Fatigue driving', <Checkbox   {...register("roles.alarm.fatigue_driving")} checked={watch("roles.alarm.fatigue_driving")} />),
    alarmData('Power cut', <Checkbox  {...register("roles.alarm.power_cut")} checked={watch("roles.alarm.power_cut")} />),
    alarmData('Power restored', <Checkbox  {...register("roles.alarm.power_restored")} checked={watch("roles.alarm.power_restored")} />),
    alarmData('Jamming', <Checkbox {...register("roles.alarm.jamming")} checked={watch("roles.alarm.jamming")} />),
    alarmData('Temperature', <Checkbox   {...register("roles.alarm.temperature")} checked={watch("roles.alarm.temperature")} />),
    alarmData('Parking', <Checkbox  {...register("roles.alarm.parking")} checked={watch("roles.alarm.parking")} />),
    alarmData('Bonnet', <Checkbox  {...register("roles.alarm.bonnet")} checked={watch("roles.alarm.bonnet")} />),
    alarmData('Foot brake', <Checkbox  {...register("roles.alarm.foot_brake")} checked={watch("roles.alarm.foot_brake")} />),
    alarmData('Fuel leak', <Checkbox {...register("roles.alarm.fuel_leak")} checked={watch("roles.alarm.fuel_leak")} />),
    alarmData('Tampering', <Checkbox {...register("roles.alarm.tampering")} checked={watch("roles.alarm.tampering")} />),
    alarmData('Removing', <Checkbox {...register("roles.alarm.removing")} checked={watch("roles.alarm.removing")} />),
  ];

  return (
    <TableContainer component={Paper} className="Role-table">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Menu</TableCell>
            <TableCell align="right">Read</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alarmrows.map((row) => (
            <TableRow
              key={row.menu}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.menu}
              </TableCell>
              <TableCell align="right">{row.read}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export function TreeViewPermissionComponent({ register, watch }) {
  function tabData(menu, create, read, edit, del) {
    return { menu, create, read, edit, del };
  }

  const tabrows = [
    tabData('Audit Trail', "",
      <Checkbox value='4' {...register("roles.permission.audit_trail")} checked={watch("roles.permission.audit_trail").toString().includes(read_value)} />,
      "", ""),
    tabData('Command',
      <Checkbox value='8' {...register("roles.permission.command")} checked={watch("roles.permission.command").toString().includes(create_value)} />,
      <Checkbox value='4' {...register("roles.permission.command")} checked={watch("roles.permission.command").toString().includes(read_value)} />,
      <Checkbox value='2' {...register("roles.permission.command")} checked={watch("roles.permission.command").toString().includes(edit_value)} />,
      <Checkbox value='1' {...register("roles.permission.command")} checked={watch("roles.permission.command").toString().includes(del_value)} />
    ),
    tabData('Dashboard', "",
      <Checkbox value='4' {...register("roles.permission.dashboard")} checked={watch("roles.permission.dashboard").toString().includes(read_value)} />,
      "", ""),
    tabData('Device',
      <Checkbox value='8' {...register("roles.permission.device")} checked={watch("roles.permission.device").toString().includes(create_value)} />,
      <Checkbox value='4' {...register("roles.permission.device")} checked={watch("roles.permission.device").toString().includes(read_value)} />,
      <Checkbox value='2' {...register("roles.permission.device")} checked={watch("roles.permission.device").toString().includes(edit_value)} />,
      <Checkbox value='1' {...register("roles.permission.device")} checked={watch("roles.permission.device").toString().includes(del_value)} />
    ),
    tabData('Driver',
      <Checkbox value='8' {...register("roles.permission.driver")} checked={watch("roles.permission.driver").toString().includes(create_value)} />,
      <Checkbox value='4' {...register("roles.permission.driver")} checked={watch("roles.permission.driver").toString().includes(read_value)} />,
      <Checkbox value='2' {...register("roles.permission.driver")} checked={watch("roles.permission.driver").toString().includes(edit_value)} />,
      <Checkbox value='1' {...register("roles.permission.driver")} checked={watch("roles.permission.driver").toString().includes(del_value)} />
    ),
    tabData('Event', "",
      <Checkbox value='4' {...register("roles.permission.event")} checked={watch("roles.permission.event").toString().includes(read_value)} />,
      "", ""),
    tabData('Geofence',
      <Checkbox value='8' {...register("roles.permission.geofence")} checked={watch("roles.permission.geofence").toString().includes(create_value)} />,
      <Checkbox value='4' {...register("roles.permission.geofence")} checked={watch("roles.permission.geofence").toString().includes(read_value)} />,
      <Checkbox value='2'{...register("roles.permission.geofence")} checked={watch("roles.permission.geofence").toString().includes(edit_value)} />,
      <Checkbox value='1' {...register("roles.permission.geofence")} checked={watch("roles.permission.geofence").toString().includes(del_value)} />
    ),
    tabData('Group',
      <Checkbox value='8' {...register("roles.permission.group")} checked={watch("roles.permission.group").toString().includes(create_value)} />,
      <Checkbox value='4' {...register("roles.permission.group")} checked={watch("roles.permission.group").toString().includes(read_value)} />,
      <Checkbox value='2' {...register("roles.permission.group")} checked={watch("roles.permission.group").toString().includes(edit_value)} />,
      <Checkbox value='1' {...register("roles.permission.group")} checked={watch("roles.permission.group").toString().includes(del_value)} />
    ),
    tabData('Notification',
      <Checkbox value='8'  {...register("roles.permission.notification")} checked={watch("roles.permission.notification").toString().includes(create_value)} />,
      <Checkbox value='4'  {...register("roles.permission.notification")} checked={watch("roles.permission.notification").toString().includes(read_value)} />,
      <Checkbox value='2'  {...register("roles.permission.notification")} checked={watch("roles.permission.notification").toString().includes(edit_value)} />,
      <Checkbox value='1'  {...register("roles.permission.notification")} checked={watch("roles.permission.notification").toString().includes(del_value)} />
    ),
    tabData('Control panel',
      <Checkbox name='Control panel' value='8' {...register("roles.permission.control_panel")} checked={watch("roles.permission.control_panel").toString().includes(create_per_value)} />,
      "", "",
      <Checkbox name='Control panel' value='1' {...register("roles.permission.control_panel")} checked={watch("roles.permission.control_panel").toString().includes(del_per_value)} />,
    ),
    tabData('Position',
      "",
      <Checkbox value='4' {...register("roles.permission.position")} checked={watch("roles.permission.position").toString().includes(read_value)} />,
      "", ""),
    tabData('Route',
      <Checkbox value='8' {...register("roles.permission.route")} checked={watch("roles.permission.route").toString().includes(create_value)} />,
      <Checkbox value='4' {...register("roles.permission.route")} checked={watch("roles.permission.route").toString().includes(read_value)} />,
      <Checkbox value='2'  {...register("roles.permission.route")} checked={watch("roles.permission.route").toString().includes(edit_value)} />,
      <Checkbox value='1'  {...register("roles.permission.route")} checked={watch("roles.permission.route").toString().includes(del_value)} />
    ),
    tabData('Schedule',
      <Checkbox value='8' {...register("roles.permission.schedule")} checked={watch("roles.permission.schedule").toString().includes(create_value)} />,
      <Checkbox value='4' {...register("roles.permission.schedule")} checked={watch("roles.permission.schedule").toString().includes(read_value)} />,
      <Checkbox value='2' {...register("roles.permission.schedule")} checked={watch("roles.permission.schedule").toString().includes(edit_value)} />,
      <Checkbox value='1' {...register("roles.permission.schedule")} checked={watch("roles.permission.schedule").toString().includes(del_value)} />
    ),
    tabData('Server',
      <Checkbox value='8' {...register("roles.permission.server")} checked={watch("roles.permission.server").toString().includes(create_value)} />,
      <Checkbox value='4' {...register("roles.permission.server")} checked={watch("roles.permission.server").toString().includes(read_value)} />,
      <Checkbox value='2' {...register("roles.permission.server")} checked={watch("roles.permission.server").toString().includes(edit_value)} />,
      <Checkbox value='1' {...register("roles.permission.server")} checked={watch("roles.permission.server").toString().includes(del_value)} />
    ),
    tabData('Sos',
      <Checkbox value='8'  {...register("roles.permission.sos")} checked={watch("roles.permission.sos").toString().includes(create_value)} />,
      <Checkbox value='4'  {...register("roles.permission.sos")} checked={watch("roles.permission.sos").toString().includes(read_value)} />,
      <Checkbox value='2'  {...register("roles.permission.sos")} checked={watch("roles.permission.sos").toString().includes(edit_value)} />,
      <Checkbox value='1'  {...register("roles.permission.sos")} checked={watch("roles.permission.sos").toString().includes(del_value)} />
    ),
    tabData('Statistic',
      "",
      <Checkbox value='4' {...register("roles.permission.statistic")} checked={watch("roles.permission.statistic").toString().includes(read_value)} />,
      "", ""),
    tabData('Trip',
      <Checkbox value='8' {...register("roles.permission.trip")} checked={watch("roles.permission.trip").toString().includes(create_value)} />,
      <Checkbox value='4' {...register("roles.permission.trip")} checked={watch("roles.permission.trip").toString().includes(read_value)} />,
      <Checkbox value='2' {...register("roles.permission.trip")} checked={watch("roles.permission.trip").toString().includes(edit_value)} />,
      <Checkbox value='1' {...register("roles.permission.trip")} checked={watch("roles.permission.trip").toString().includes(del_value)} />
    ),
    tabData('Role',
      <Checkbox value='8' {...register("roles.permission.role")} checked={watch("roles.permission.role").toString().includes(create_value)} />,
      <Checkbox value='4' {...register("roles.permission.role")} checked={watch("roles.permission.role").toString().includes(read_value)} />,
      <Checkbox value='2' {...register("roles.permission.role")} checked={watch("roles.permission.role").toString().includes(edit_value)} />,
      <Checkbox value='1' {...register("roles.permission.role")} checked={watch("roles.permission.role").toString().includes(del_value)} />
    ),
    tabData('User',
      <Checkbox value='8' {...register("roles.permission.user")} checked={watch("roles.permission.user").toString().includes(create_value)} />,
      <Checkbox value='4' {...register("roles.permission.user")} checked={watch("roles.permission.user").toString().includes(read_value)} />,
      <Checkbox value='2' {...register("roles.permission.user")} checked={watch("roles.permission.user").toString().includes(edit_value)} />,
      <Checkbox value='1' {...register("roles.permission.user")} checked={watch("roles.permission.user").toString().includes(del_value)} />
    ),
    tabData('Vehicle registration',
      <Checkbox value='8' {...register("roles.permission.vehicle_registration")} checked={watch("roles.permission.vehicle_registration").toString().includes(create_value)} />,
      <Checkbox value='4' {...register("roles.permission.vehicle_registration")} checked={watch("roles.permission.vehicle_registration").toString().includes(read_value)} />,
      <Checkbox value='2' {...register("roles.permission.vehicle_registration")} checked={watch("roles.permission.vehicle_registration").toString().includes(edit_value)} />,
      <Checkbox value='1' {...register("roles.permission.vehicle_registration")} checked={watch("roles.permission.vehicle_registration").toString().includes(del_value)} />
    ),
    tabData('Generate Permit Violation',
      <Checkbox value='8' {...register("roles.permission.generate_permit_violation")} checked={watch("roles.permission.generate_permit_violation").toString().includes(create_value)} />,
      <Checkbox value='4' {...register("roles.permission.generate_permit_violation")} checked={watch("roles.permission.generate_permit_violation").toString().includes(read_value)} />,
      <Checkbox value='2' {...register("roles.permission.generate_permit_violation")} checked={watch("roles.permission.generate_permit_violation").toString().includes(edit_value)} />,
      <Checkbox value='1' {...register("roles.permission.generate_permit_violation")} checked={watch("roles.permission.generate_permit_violation").toString().includes(del_value)} />
    ),
  ];

  return (
    <TableContainer component={Paper} className="Role-table">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Menu</TableCell>
            <TableCell align="right">Create</TableCell>
            <TableCell align="right">Read</TableCell>
            <TableCell align="right">Edit</TableCell>
            <TableCell align="right">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tabrows.map((row) => (
            <TableRow
              key={row.menu}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.menu}
              </TableCell>
              <TableCell align="right">{row.create}</TableCell>
              <TableCell align="right">{row.read}</TableCell>
              <TableCell align="right">{row.edit}</TableCell>
              <TableCell align="right">{row.del}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export function TreeViewViewComponent({ register, watch }) {
  function tabData(menu, create, read, edit, del) {
    return { menu, create, read, edit, del };
  }

  const tabrows = [
    tabData('Dashboard overall', "",
      <Checkbox value='1' {...register("roles.view.dashboard_overall")} checked={watch("roles.view.dashboard_overall").toString().includes(view_value)} />,
      "", ""),
    tabData('Dashboard', "",
      <Checkbox value='1' {...register("roles.view.dashboard")} checked={watch("roles.view.dashboard").toString().includes(view_value)} />,
      "", ""),
    tabData('Dashboard vehicle summary', "",
      <Checkbox value='1' {...register("roles.view.dashboard_vehicle_summary")} checked={watch("roles.view.dashboard_vehicle_summary").toString().includes(view_value)} />,
      "", ""),
    tabData('Vehicle list', "",
      <Checkbox value='1' {...register("roles.view.vehicle_list")} checked={watch("roles.view.vehicle_list").toString().includes(view_value)} />,
      "", ""),
    tabData('Vehicle map', "",
      <Checkbox value='1' {...register("roles.view.vehicle_map")} checked={watch("roles.view.vehicle_map").toString().includes(view_value)} />,
      "", ""),
    tabData('trails', "",
      <Checkbox value='1' {...register("roles.view.trails")} checked={watch("roles.view.trails").toString().includes(view_value)} />,
      "", ""),
    tabData('Trip trail', "",
      <Checkbox value='1' {...register("roles.view.trip_trail")} checked={watch("roles.view.trip_trail").toString().includes(view_value)} />,
      "", ""),
    tabData('By vehicle', "",
      <Checkbox value='1' {...register("roles.view.by_vehicle")} checked={watch("roles.view.by_vehicle").toString().includes(view_value)} />,
      "", ""),
    tabData('By group', "",
      <Checkbox value='1' {...register("roles.view.by_group")} checked={watch("roles.view.by_group").toString().includes(view_value)} />,
      "", ""),
    tabData('Schedule', "",
      <Checkbox value='1' {...register("roles.view.schedule")} checked={watch("roles.view.schedule").toString().includes(view_value)} />,
      "", ""),
    tabData('Panic status', "",
      <Checkbox value='1' {...register("roles.view.panic_status")} checked={watch("roles.view.panic_status").toString().includes(view_value)} />,
      "", ""),
    tabData('Panic sos', "",
      <Checkbox value='1' {...register("roles.view.panic_sos")} checked={watch("roles.view.panic_sos").toString().includes(view_value)} />,
      "", ""),
    tabData('Entity', "",
      <Checkbox value='1' {...register("roles.view.entity")} checked={watch("roles.view.entity").toString().includes(view_value)} />,
      "", ""),
    tabData('Group', "",
      <Checkbox value='1' {...register("roles.view.group")} checked={watch("roles.view.group").toString().includes(view_value)} />,
      "", ""),
    tabData('Audit trail', "",
      <Checkbox value='1' {...register("roles.view.audit_trail")} checked={watch("roles.view.audit_trail").toString().includes(view_value)} />,
      "", ""),
    tabData('Route List', "",
      <Checkbox value='1' {...register("roles.view.route_list")} checked={watch("roles.view.route_list").toString().includes(view_value)} />,
      "", ""),
    tabData('Route Assign', "",
      <Checkbox value='1' {...register("roles.view.route_assign")} checked={watch("roles.view.route_assign").toString().includes(view_value)} />,
      "", ""),
    tabData('Geofence configuration', "",
      <Checkbox value='1' {...register("roles.view.geofence_configuration")} checked={watch("roles.view.geofence_configuration").toString().includes(view_value)} />,
      "", ""),
    tabData('Alarm configuration', "",
      <Checkbox value='1' {...register("roles.view.alarm_configuration")} checked={watch("roles.view.alarm_configuration").toString().includes(view_value)} />,
      "", ""),
    tabData('Alarm log', "",
      <Checkbox value='1' {...register("roles.view.alarm_log")} checked={watch("roles.view.alarm_log").toString().includes(view_value)} />,
      "", ""),
    tabData('User', "",
      <Checkbox value='1' {...register("roles.view.user")} checked={watch("roles.view.user").toString().includes(view_value)} />,
      "", ""),
    tabData('Role', "",
      <Checkbox value='1' {...register("roles.view.role")} checked={watch("roles.view.role").toString().includes(view_value)} />,
      "", ""),
    tabData('Control Panel', "",
      <Checkbox value='1' {...register("roles.view.control_panel")} checked={watch("roles.view.control_panel").toString().includes(view_value)} />,
      "", ""),
  ];

  return (
    <TableContainer component={Paper} className="Role-table">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Menu</TableCell>
            <TableCell align="right">View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tabrows.map((row) => (
            <TableRow
              key={row.menu}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.menu}
              </TableCell>
              <TableCell align="right">{row.read}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


