import * as React from "react";
import { Button, useGetList, useListController } from "react-admin";
import { Card, Box, Typography } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import TrainIcon from "@mui/icons-material/TrainOutlined";
import GpsIcon from "@mui/icons-material/MyLocationOutlined";
import ViewmapIcon from "../../images/Viewmap.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { Popover, Whisper } from "rsuite";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import L from "leaflet";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexRow: { display: "flex", flexDirection: "row" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const steps = ["", "", ""];

const OngoingTrips = () => {
  const [age, setAge] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;


  const handleChange = (event) => {
    setAge(event.target.value);
  };


  const convertdate = (dvalue) => {
    return ""
  }

  const { data, isLoading, ...listContext } = useGetList("ongoingtrips");

  return (
    <Card
      sx={{
        minHeight: 52,
        marginTop: "0px",
        display: "flex",
        flexDirection: "column",
        marginBottom:"30px",
        marginRight:"0px !important",
        flex: "1",
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
      <Box
        sx={{
          overflow: "inherit",
          padding: "16px",
          height: "504px",
          background: (theme) =>
            `url(${theme.palette.mode === "dark" ? "" : ""}) no-repeat`,

          justifyContent: "space-between",
          alignItems: "center",
          "& .icon": {
            color: (theme) =>
              theme.palette.mode === "dark" ? "inherit" : "#dc2440",
          },
        }}
        className="ongoing-left"
      >
        <Typography color="textSecondary" component="h2">
          Ongoing Trips
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Grid container spacing={2}>
            <Grid item xs={4} className="pr-0">
              <Search className="serach-field">
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Grid>
            <Grid item xs={3} className="pl-0">
            </Grid>
            <Grid item xs={4} className="pl-0">
            </Grid>
            <Grid item xs={1} className="pl-0">
              <Box
                sx={{ padding: "0px 0px 0 16px", marginLeft: "12px" }}
                textAlign="center"
              >
                <Typography color="textSecondary" component="h2">
                  {data?.length > 0 ? data?.length : 0}
                </Typography>
                <Typography color="textSecondary" component="p">
                  Total
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="ong-box">
          {data?.length > 0 ? (
            data?.map((trip, index) => {
              return (
                <Box key={trip?.tripId} sx={{ padding: "10px" }} className="ongoing-progress">
                  <Box sx={{ padding: "10px" }}>
                    <div style={styles.flex}>
                      <div style={styles.leftCol}>
                        <Typography
                          color="textSecondary"
                          variant="h"
                          component="h4"
                          className="Card-Title"
                          sx={{
                            marginBottom: "15px",
                          }}
                        >
                          {trip?.vehicleNo}
                          <span>
                          ({(trip?.groupName != null && trip?.category != null) ? trip?.groupName + "," + trip?.category : trip?.groupName != null ? trip?.groupName : trip?.category})
                          </span>
                        </Typography>
                        <Box className="box-P">
                          <Typography
                            color="textSecondary"
                            variant="h"
                            component="p"
                          >
                           {trip.permitHolderName} - {trip.permitHolderContact}
                          </Typography>
                        </Box>
                      </div>
                      <div style={styles.rightCol}>
                      <Link to={'/vehicles'} state={{ type: 'map', status: trip, title: 'trip'}}>
                        <Button
                          variant="contained"
                          className="Card-Icons"
                          sx={{
                            borderColor: "blue",
                            marginTop: "0!important",
                            marginRight: "0px!important",
                            marginLeft: "10px",
                            border: "none !important",
                          }}
                        >
                          <img src={ViewmapIcon} />
                        </Button>
                        </Link>
                      </div>
                    </div>
                  </Box>
                 
                  <Box sx={{ width: "100%" }} className="ongoing-stepper">
                    
                  <Stepper >
                    
                      {
                        trip.tripLegSummarys.map((leg, index)=>{
                          return(
                          
                                <Step completed={leg.status === "finished"? true : false} active={leg.status === "finished"? true : false}>
                                  <Whisper
                              followCursor
                              speaker={
                                <Popover className="pop-over">
                                  <p> Start Time :<span> {new Date(leg.startTime).toISOString().slice(11, 19)} </span></p>
                                  <p> Distance Traveled :<span> {leg.distanceTraveled} </span></p>
                                  <p> Duration Traveled :<span> {leg.durationTraveled} </span></p>
                                  <p> Finish Time :<span> {new Date(leg.finishTime).toISOString().slice(11, 19)} </span></p>
                                  <p> Status :<span> {leg.status} </span></p>
                                  <p> Planned Time :<span> {new Date(leg.plannedTime).toISOString().slice(11, 19)} </span></p>
                                  <p> Planned Distance :<span> {leg.plannedDistance} </span></p>
                                  <p> Leg Index :<span> {leg.legIndex} </span></p>
                                  <p> Name From :<span> {trip.routeLegs[(leg.legIndex*1)-1].nameFrom}  </span></p>
                                  <p> Name To :<span> {trip.routeLegs[(leg.legIndex*1)-1].nameTo} </span></p>
                                </Popover>
                                  }
                                >
                                  <StepLabel ></StepLabel>
                               

                              </Whisper>
                                    
                            </Step>
                            
                            
                          )
                        })
                      }
                     {trip.tripLegSummarys.length === 1 &&  
                     <Step completed={trip.tripLegSummarys[0].status === "finished"? true : false} active={trip.tripLegSummarys[0].status === "finished"? true : false}>
                       <Whisper
                              followCursor
                             speaker={
                                <Popover className="pop-over">
                                  <p> Start Time :<span> {new Date(trip.tripLegSummarys[0].startTime).toISOString().slice(11, 19)} </span></p>
                                  <p> Distance Traveled :<span> {trip.tripLegSummarys[0].distanceTraveled} </span></p>
                                  <p> Duration Traveled :<span> {new Date(trip.tripLegSummarys[0].durationTraveled).toISOString().slice(11, 19)} </span></p>
                                  <p> Finish Time :<span> {new Date(trip.tripLegSummarys[0].finishTime).toISOString().slice(11, 19)} </span></p>
                                  <p> Status :<span> {trip.tripLegSummarys[0].status} </span></p>
                                  <p> Planned Time :<span> {new Date(trip.tripLegSummarys[0].plannedTime).toISOString().slice(11, 19)} </span></p>
                                  <p> Planned Distance :<span> {trip.tripLegSummarys[0].plannedDistance} </span></p>
                                  <p> Leg Index :<span> {trip.tripLegSummarys[0].legIndex} </span></p>
                                  <p> Name From :<span> {trip.routeLegs[(trip.tripLegSummarys[0].legIndex*1)-1].nameFrom}  </span></p>
                                  <p> Name To :<span> {trip.routeLegs[(trip.tripLegSummarys[0].legIndex*1)-1].nameTo} </span></p>
                                </Popover>
                                 }
                               >
                                  <StepLabel ></StepLabel>
                                 </Whisper>
                      </Step>}
                  </Stepper>
                </Box>
                  <div style={styles.flex}>
                    <div style={styles.leftCol}>
                      <Box className="location-box">
                        <Typography
                          color="textSecondary"
                          variant="h"
                          component="h4"
                          className="Card-Title"
                          sx={{
                            marginBottom: "15px",
                          }}
                        >
                          {trip?.routeLegs[0]?.nameFrom}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="h"
                          component="p"
                        >
                          {trip?.routeLegs[0]?.startTime}
                        </Typography>
                      </Box>
                    </div>

                    <div style={styles.rightCol}>
                      <Box className="location-box" textAlign="right">
                        <Typography
                          color="textSecondary"
                          variant="h"
                          component="h4"
                          className="Card-Title"
                          sx={{
                            marginBottom: "15px",
                          }}
                        >
                          {trip?.routeLegs[trip?.routeLegs.length - 1]?.nameTo}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="h"
                          component="p"
                        >
                          {trip?.routeLegs[trip?.routeLegs.length - 1]?.endTime}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                </Box>
              );
            })
          ) : (
            <Typography variant="h6" style={{ textAlign: "center" }} className="no-message">
              No Ongoing Trips
            </Typography>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default OngoingTrips;
