import React from 'react';
import { useRecordContext } from 'react-admin';
import Icon2 from '@mui/icons-material/GpsNotFixedOutlined';
import geofence from '../views/geofence/index'

 const IconTextField = ( props ) => {
  const { source } = props;
  const record = useRecordContext();
  return (<><geofence.icon /><span>{record && record[source]}</span></>);
};

export default IconTextField;