function initialize(mmiToken, loadCallback) {
  try {
    if (mmiToken) {
      let count = 0;
      let mapSDK_url = "https://apis.mappls.com/advancedmaps/api/" + mmiToken + "/map_sdk?layer=vector&v=3.0";
      let plugins_url = "https://apis.mappls.com/advancedmaps/api/" + mmiToken + "/map_sdk_plugins?v=3.0";

      var scriptArr = [mapSDK_url, plugins_url];

      const recursivelyAddScript = (script) => {
        if (count < script.length) {
          const el = document.createElement('script')
          el.src = script[count]
          el.async = true;
          el.type = 'text/javascript';
          document.getElementsByTagName('head')[0].appendChild(el);
          count = count + 1;
          el.onload = function () { recursivelyAddScript(script) }
        } else
          return loadCallback();
      }
      recursivelyAddScript(scriptArr);
    }

    else console.erro("Please! pass a token inside initialize()")

  }
  catch (e) {
    console.error(String(e));
  }

};

export { initialize };
export const MMI_KEY = "4d9db97e8e30430ef7ad6015af16965c";
