import React, { useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import { Chart, BarSeries } from "@devexpress/dx-react-chart-material-ui";
import { Typography, Box, Card } from "@mui/material";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MoreIcon from "@mui/icons-material/MoreHorizOutlined";
import {
  Button,
  useGetList,
  useInfiniteGetList,
  useListController,
} from "react-admin";
import { ArgumentAxis, ValueAxis } from "@devexpress/dx-react-chart";
import ViewmapIcon from "../images/Viewmap.svg";
import MapView from "../components/mapview";
import moment from "moment";
import { getVehicleLocation } from "../providers/dataProvider";
import { Link } from "react-router-dom";
import { useCallback } from "react";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexRow: { display: "flex", flexDirection: "row" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DistanceChart = () => {
  const [distanceData, setDistanceData] = useState([]);
  const [Mapopen, setMapopen] = React.useState(false);
  const [bounds, setBounds] = useState();
  const [record, setRecord] = useState(null);
  const [panicNotify, setPanicNotify] = useState([]);
  const listInnerRef = useRef();

  const OpenMapView = () => setMapopen(true);
  const MapClose = () => setMapopen(false);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteGetList("panicsos", {
      pagination: { page: 1, perPage: 10 },
      filter: { type: "dashboard" },
    });

  const observerElem = useRef(null);

  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage]
  );

  useEffect(() => {
    const element = observerElem.current;
    if (!element) return;
    const option = { threshold: 0 };
    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [fetchNextPage, hasNextPage, handleObserver]);

  const formatMonth = (data) => {
    const distData = data?.map((obj, index) => {
      if (obj?.tcmonth == 1) {
        obj["tcmonth"] = "Jan";
        obj["tcdistance"] = obj?.tcdistance;
      }
      if (obj?.tcmonth == 2) {
        obj["tcmonth"] = "Feb";
        obj["tcdistance"] = obj?.tcdistance;
      }
      if (obj?.tcmonth == 3) {
        obj["tcmonth"] = "Mar";
        obj["tcdistance"] = obj?.tcdistance;
      }
      if (obj?.tcmonth == 4) {
        obj["tcmonth"] = "Apr";
        obj["tcdistance"] = obj?.tcdistance;
      }
      if (obj?.tcmonth == 5) {
        obj["tcmonth"] = "May";
        obj["tcdistance"] = obj?.tcdistance;
      }
      if (obj?.tcmonth == 6) {
        obj["tcmonth"] = "Jun";
        obj["tcdistance"] = obj?.tcdistance;
      }
      if (obj?.tcmonth == 7) {
        obj["tcmonth"] = "Jul";
        obj["tcdistance"] = obj?.tcdistance;
      }
      if (obj?.tcmonth == 8) {
        obj["tcmonth"] = "Aug";
        obj["tcdistance"] = obj?.tcdistance;
      }
      if (obj?.tcmonth == 9) {
        obj["tcmonth"] = "Sept";
        obj["tcdistance"] = obj?.tcdistance;
      }
      if (obj?.tcmonth == 10) {
        obj["tcmonth"] = "Oct";
        obj["tcdistance"] = obj?.tcdistance;
      }
      if (obj?.tcmonth == 11) {
        obj["tcmonth"] = "Nov";
        obj["tcdistance"] = obj?.tcdistance;
      }
      if (obj?.tcmonth == 12) {
        obj["tcmonth"] = "Dec";
        obj["tcdistance"] = obj?.tcdistance;
      }
      return obj;
    });
    setDistanceData(distData);
  };

  const handleAddress = async (record) => {
    const address = {};
    if (!record?.latitude && !record?.longitude) return;
    await getVehicleLocation(record, address);
    document
      .getElementById(`addr_${record?.id}`)
      ?.classList?.remove("address_styling");
    document.getElementById(`addr_${record?.id}`).innerText = address?.address;
  };

  return (
    <Paper className="distance-paper">
      <Box
        sx={{
          overflow: "inherit",
          background: (theme) =>
            `url(${theme.palette.mode === "dark" ? "" : ""}) no-repeat`,
          justifyContent: "space-between",
          alignItems: "center",
          "& .icon": {
            color: (theme) =>
              theme.palette.mode === "dark" ? "inherit" : "#dc2440",
          },
        }}
      >
        <Typography
          color="textSecondary"
          variant="h"
          component="h4"
          className="Card-Title"
          sx={{
            marginBottom: "15px",
          }}
        >
          Panic Messages
          {data?.pages[0]?.data?.length != 0 && (
            <Link
              to={"/PanicDetails"}
              className="viewall"
              state={{ status: "panic", type: "map" }}
            >
              View All
            </Link>
          )}
          {/* <a href="" className="viewall">View all</a> */}
          <span className="message-count">
            {data?.pages[0]?.data?.length == 0
              ? "(0 Messages Found)"
              : data?.pages[0]?.data?.length > 0
                ? `(${data?.pages[0]?.total} Messages Found)`
                : "(0 Messages Found)"}
          </span>
        </Typography>

        <Box className="ong-box">
          {data?.pages?.length > 0 ? (
            data?.pages?.map((page) => {
              return page?.data?.map((panic) => (
                <Box
                  key={panic?.id}
                  sx={{ padding: "10px" }}
                  className="panic-messages"
                >
                  <Box sx={{ padding: "0 10px" }}>
                    <div style={styles.flex}>
                      <div style={styles.leftCol}>
                        <Typography
                          color="textSecondary"
                          variant="h"
                          component="h4"
                          className="Card-Title"
                          sx={{
                            marginBottom: "15px",
                          }}
                        >
                          {panic?.vehicleNo ? panic?.vehicleNo : "Device"}
                        </Typography>
                        <Box className="box-P">
                          <Typography
                            color="textSecondary"
                            variant="h"
                            component="p"
                            className="panic-mloc"
                          >
                            {panic?.location == null ? (
                              <div>
                                <span>{`Latitude: ${panic?.latitude}`}</span>
                                <span>{`Longitude: ${panic?.longitude}`}</span>
                              </div>
                            ) : (
                              panic?.location
                            )}

                          </Typography>
                          <Typography
                            color="textSecondary"
                            variant="h"
                            component="h4"
                            className="Card-Title"
                            sx={{
                              marginBottom: "15px",
                            }}
                          >
                            {panic?.permitHolder
                              ? panic?.permitHolder
                              : "Owner"}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            variant="h"
                            component="p"
                          >
                            {panic?.remark}
                          </Typography>
                          <p>
                            {moment(panic?.eventTime)
                              .format("DD MMM YYYY, HH:mm:ss A")}
                          </p>
                        </Box>
                      </div>
                      <Link
                        to={"/vehicles"}
                        state={{
                          type: "map",
                          status: panic,
                          title: "panicMessage",
                        }}
                      >
                        <Button
                          variant="contained"
                          className="Card-Icons"
                          sx={{
                            borderColor: "blue",
                            marginTop: "0!important",
                            marginRight: "10px!important",
                            marginLeft: "10px",
                            border: "none !important",
                          }}
                        >
                          <img src={ViewmapIcon} />
                        </Button>
                      </Link>
                    </div>
                  </Box>
                </Box>
              ));
            })
          ) : (
            <Typography
              variant="h8"
              style={{ textAlign: "center" }}
              className="no-message"
            >
              NO MESSAGES
            </Typography>
          )}
          {data?.pages[0]?.data?.length > 0 ? (
            <Typography ref={observerElem} variant="body2" color="grey.500">
              {isFetchingNextPage && hasNextPage
                ? "Loading..."
                : "No search left"}
            </Typography>
          ) : (
            <Typography
              variant="h6"
              style={{ textAlign: "center" }}
              className="no-message"
            >
              No Panic Messages
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default DistanceChart;
