import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Avatar, Button, Card, CardActions, TextField } from "@mui/material";
import "./header.css";
import Logo from "../../images/logo.svg";
import TransportLogo from "../../images/transport_department_logo.jpeg";
import Grid from "@mui/material/Grid";
import { Form, TextInput } from "react-admin";
import Box from "@mui/material/Box";
import ArrowIcon from "@mui/icons-material/ArrowCircleLeft";
import SendNotification from "../../utils/sendNotification";
import { updatePassword } from "../../providers/dataProvider";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const errorstyle = {
  color: "#d32f2f",
  fontSize: "12px",
  display: "block"
};

const ResetPassword = () => {
  const [notifyData, setNotifyData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [formError, setFormError] = useState({
    A: {
      new_password: true,
      confirm_password: true,
    },
    B: {
      invalid: true,
    },
  });
  const [formValues, setFormValues] = useState({
    new_password: "",
    confirm_password: "",
  });

  const [pwdError, setPwdError] = useState('')

  useEffect(() => {
    if (location?.state == "SUCCESS") {
      setNotifyData({
        open: true,
        message: "OTP VERIFIED SUCCESSFULLY!",
        severity: "success",
        duration: 4000,
      });
    }
  }, []);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
    if (e.target.name == "new_password")
    {
        var pass = e.target.value;
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,15})");
      if(strongRegex.test(pass)) {
        setPwdError('');
        }
      else{
        if (pass.length == 0){
          setPwdError('');
        }
        else setPwdError('Must contain at least one number and one uppercase and lowercase letter, and at least 8 to 15 characters')
      }
    }

  };

  useEffect(() => {
    const ferror = { ...formError };
    Object.keys(formValues).some((key) => {
      ferror.A[key] = false;
      if (formValues[key] == "" || formValues[key] == null)
        ferror.A[key] = true;
    });
    ferror.B["invalid"] = false;
    if (
      formValues?.new_password !== "" &&
      formValues?.confirm_password !== ""
    ) {
      if (formValues?.new_password !== formValues?.confirm_password)
        ferror.B["invalid"] = true;
    }
    setFormError(ferror);
  }, [formValues]);

  const handleSubmit = async () => {
    const values = {
      token: localStorage?.getItem("passwordToken"),
      password: formValues?.new_password,
    };
    let result = {};
    await updatePassword(values, result);
    if (result?.res?.status == 200) {
      navigate("/login", { state: "SUCCESS" });
    } else if (result?.res == "FAILED" || result?.res?.status == 404) {
      setNotifyData({
        open: true,
        message: "FAILED TO UPDATE PASSWORD !",
        severity: "warning",
        duration: 4000,
      });
    }
  };

  const handleRedirect = () =>{
    navigate("/otp-verfication");
  }

  return (
    <>
      <Form>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "flex-start",
            flexGrow: 1,
          }}
          className="login-sec forgotpwd"
        >
          <Grid container spacing={2}>
            <Grid item xs={7}>
              
              <Card
                sx={{ height: "60vh", marginTop: "6em", boxShadow: "none" }}
                className="bg-image"
              ></Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                sx={{
                  minWidth: 382,
                  marginTop: "2em",
                  padding: "18px 42px !important",
                }}
              >
                <Box
                  sx={{
                    margin: "1em",
                    justifyContent: "center",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={1}>
                        <div style={{ display: "flex" }} className="backtopage" onClick={handleRedirect}>
                        <ArrowIcon className="arrow-icon" />
                        <Typography color="textSecondary" variant="h" component="h4">
                          Back
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={10} className="logo-box">
                      <img src={TransportLogo} />
                      <Typography variant="h5" component="h2" align="center">
                        Vehicle Tracking System
                      </Typography>
                      <Typography variant="p" component="p" align="center">
                        Transport Department, Chhattisgarh
                      </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    margin: "1em",
                    justifyContent: "center",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Typography variant="h5" component="h4" align="center">
                        Enter new password
                      </Typography>
                      <Typography
                        variant="p"
                        component="p"
                        align="center"
                        className="f-text"
                      >
                        Set the new password for your account
                      </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Box>
                <Box sx={{ padding: "0 1em 1em 1em" }}>
                  <Box sx={{ marginTop: "2em", paddingTop: "10px" }}>
                    <TextField
                      type="password"
                      name="new_password"
                      error={pwdError}
                      helperText={
                        pwdError
                      }
                      value={formValues?.new_password}
                      inputProps={{ maxLength: 15 }}
                      onChange={handleChange}
                      fullWidth
                    />
                    
                  </Box>
                  <Box sx={{ marginTop: "1em" }}>
                    <TextField
                      type="password"
                      name="confirm_password"
                      error={
                        formError?.A["confirm_password"] ||
                        formError?.B["invalid"]
                      }
                      helperText={
                        formError?.A["confirm_password"]
                          ? "Enter Confirm Password"
                          : formError?.B["invalid"]
                          ? "Passwords do not match"
                          : ""
                      }
                      value={formValues?.confirm_password}
                      inputProps={{ maxLength: 15 }}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Box>
                </Box>
                <CardActions sx={{ padding: "0 1em 1em 1em" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={
                      formError.A["new_password"] ||
                      formError.A["confirm_password"] ||
                      formError.B["invalid"]
                    }
                    className={
                      formError.A["new_password"] ||
                      formError.A["confirm_password"] ||
                      formError.B["invalid"]
                        ? "layout-disable"
                        : ""
                    }
                    fullWidth
                  >
                    Reset Password
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Box>
      </Form>
      <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
    </>
  );
};

export default ResetPassword;
