import React, { useEffect, useState } from "react";
import {
  Typography,
  Modal,
  Box,
  Grid,
  FormLabel,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import FormButton from "@mui/material/Button";
import FormField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";
import { useGetList, useRefresh } from "react-admin";
import moment from "moment";
import vtsDataProvider from "../providers/dataProvider";
import SendNotification from "../utils/sendNotification";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import IconButton from "@mui/material/IconButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

const errorstyle = {
  color: "red",
  fontSize: "12px",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function AddReportSchedule({
  scheduleOpen,
  handleScheduleClose,
}) {
  const defaultValues = {
    reporttype: null,
    groupid: null,
    vehicleno: null,
    reportfrom: null,
    reportto: null,
    email: null,
    emailsubject: null,
    report_period: null,
    format: null,
    day: null,
    disabled: false,
    createdon: new Date().toISOString(),
    lastupdate: new Date().toISOString(),
  };

  const [notifyData, setNotifyData] = useState({});
  let todate = new Date();
  let prvDate = new Date(
    todate.getFullYear(),
    todate.getMonth() - 1,
    todate.getDate()
  );
  const [groupData, setGroupData] = useState([]);
  const [formValues, setFormValues] = useState(defaultValues);
  const [openGroup, setOpenGroup] = useState(false);
  const [openVehicle, setOpenVehicle] = useState(false);
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [vehicleData, setVehicleData] = useState([]);
  const [dateRange, setDateRange] = React.useState([prvDate, todate]);
  const [startDate, endDate] = dateRange;
  const datePickerRef = React.useRef(null);
  const refresh = useRefresh();

  const [filteredData, setFilteredData] = useState([
    {
      label: "Select a Group to proceed!",
      value: "0",
      showlabel: "Select a Group to proceed!",
    },
  ]);

  const reportTypes = [
    {
      id: 1,
      name: "Route Summary Report",
    },
    {
      id: 2,
      name: "Summary Report",
    },
    {
      id: 3,
      name: "Trips Summary Report",
    },
    {
      id: 4,
      name: "Stops Summary Report",
    },
    {
      id: 5,
      name: "Events Report",
    },
  ];

  const reportFormats = [
    {
      id: 1,
      name: "PDF",
      value: "pdf",
    },
    {
      id: 2,
      name: "Excel Sheet",
      value: "xlsx",
    },
  ];

  const [groupValue, setGroupValue] = useState(null);
  const [vehicleValue, setVehicleValue] = useState("0");
  const [openGroupDrp, setOpenGroupDrp] = useState(false);
  const [openVehicleDrp, setOpenVehicleDrp] = useState(false);
  const loadingGroup = openGroup && groupData?.length === 0;
  const loadingVehicle = openVehicle && filteredData?.length === 0;

  const handleDatePicker = () => {
    const dp = datePickerRef.current;
    dp.setOpen(true);
  };

  const { data: groupsdata } = useGetList("groupsdata");
  const { data: vehicledata } = useGetList("vehicledata");

  useEffect(() => {
    const getGroupData = () => {
      if (groupsdata) {
        const list = format_drpdown(groupsdata);
        setGroupData(list);
      }
    };
    getGroupData();
  }, [groupsdata]);

  const formatVehicle = (list) => {
    var te = list?.map((data) => ({
      label: data?.name,
      value: data?.name,
      showlabel: data?.name,
      groupId: data?.group_id,
    }));
    return te;
  };

  useEffect(() => {
    const getVehicleData = () => {
      if (vehicledata) {
        const list = formatVehicle(vehicledata);
        setVehicleData(list?.length ? list : "0");
      }
    };
    getVehicleData();
  }, [vehicledata]);

  const format_drpdown = (list) => {
    var te = list?.map((data, index) => ({
      label: data?.name,
      value: data?.id,
      showlabel: data?.name,
    }));
    return te;
  };

  useEffect(() => {
    if (scheduleOpen?.row != null) {
      const values_ = {
        id: scheduleOpen?.row?.id,
        reporttype: scheduleOpen?.row?.reporttype,
        groupid: scheduleOpen?.row?.groupid,
        vehicleno: scheduleOpen?.row?.vehicleno,
        reportfrom: null,
        reportto: null,
        email: scheduleOpen?.row?.email,
        emailsubject: scheduleOpen?.row?.emailsubject,
        report_period: scheduleOpen?.row?.report_period,
        format: scheduleOpen?.row?.format,
        day: scheduleOpen?.row?.day,
        disabled: scheduleOpen?.row?.disabled,
        createdon: scheduleOpen?.row?.createdon
          ? scheduleOpen?.row?.createdon
          : new Date().toISOString(),
        lastupdate: scheduleOpen?.row?.lastupdate
          ? scheduleOpen?.row?.lastupdate
          : new Date().toISOString(),
        readOnly: scheduleOpen?.readOnly,
      };

      setFormValues(values_);
    }
  }, [scheduleOpen?.row]);

  useEffect(() => {
    if (!formValues?.id) return;

    setDateRange([
      new Date(scheduleOpen?.row?.reportfrom),
      new Date(scheduleOpen?.row?.reportto),
    ]);

    var value = groupData?.filter((item) => item.value == formValues?.groupid);
    if (value && value[0]) setGroupValue(value[0]);
    else
      setGroupValue({
        label: "Select One",
        value: "0",
        showlabel: "Select one",
      });

    var val = filteredData?.filter(
      (item) => item.label === formValues?.vehicleno
    );
    if (val) setVehicleValue(val[0]);
    else
      setVehicleValue({
        label: "Select Vehicle",
        value: "0",
        showlabel: "Select Vehicle",
      });
  }, [groupData, filteredData, formValues]);

  const handleChange = (e) => {
    let formCopy = { ...formValues };
    if (e.target.name === "report_period" || e.target.name === "day") {
      formCopy[e.target.name] = parseInt(e.target.value);
    } else formCopy[e.target.name] = e.target.value;
    setFormValues(formCopy);
  };

  const validate = (values) => {
    const errors = {};
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (formValues?.reporttype == null || formValues?.reporttype == "")
      errors.reporttype = "Report Type is required!";
    if (formValues?.groupid == null) errors.group = "Group required!";
    if (formValues?.vehicleno == null) errors.device = "Device required!";
    if (formValues?.vehicleno == 0)
      errors.device = "Select a Group with Vehicles!";
    if (vehicleValue == undefined)
      errors.device = "Select a Device to proceed!";
    if (formValues?.report_period == null)
      errors.reportperiod = "Report Time Period required!";
    if (formValues?.format == null) errors.format = "Format required!";
    if (formValues?.day == null) errors.day = "Day required!";
    if (formValues?.email == null) errors.email = "Email required!";
    if (formValues?.emailsubject == null || formValues?.emailsubject == "")
      errors.emailsubject = "EmailSubject required!";
    if (formValues?.email !== null) {
      if (!formValues?.email.match(regexEmail))
        errors.email = "This is not a valid email format!";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      if (formValues?.id > 0) {
        delete formValues["readOnly"];
        let formData = { ...formValues };
        let formatFromDate = moment(dateRange[0]).format("YYYY-MM-DD hh:mm");
        let formatToDate = moment(dateRange[1]).format("YYYY-MM-DD hh:mm");
        formData["reportfrom"] = moment.utc(formatFromDate).format();
        formData["reportto"] = moment.utc(formatToDate).format();
        vtsDataProvider.update(
          "reportsschedule",
          formData,
          setNotifyData,
          "Reports Schedule updated successfully",
          "Failed to update reports schedule"
        );
      } else {
        let formData = { ...formValues };
        let formatFromDate = moment(dateRange[0]).format("YYYY-MM-DD hh:mm");
        let formatToDate = moment(dateRange[1]).format("YYYY-MM-DD hh:mm");
        formData["reportfrom"] = moment.utc(formatFromDate).format();
        formData["reportto"] = moment.utc(formatToDate).format();
        vtsDataProvider.create(
          "reportsschedule",
          formData,
          setNotifyData,
          "Reports Schedule created successfully",
          "Failed to create Reports Schedule"
        );
      }

      handleClose();
    } else {
      setIsSubmit(false);
    }
  }, [formError]);

  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormError(validate(formValues));
    setIsSubmit(true);
  };

  const handleClose = () => {
    setFormValues(defaultValues);
    setFormError({});
    setIsSubmit(false);
    setDateRange([prvDate, todate]);
    setGroupValue(null);
    setVehicleValue("0");
    setFilteredData([
      {
        label: "Select a Group to proceed!",
        value: "0",
        showlabel: "Select a Group to proceed!",
      },
    ]);
    handleScheduleClose();
  };

  var date = new Date();
  var formatedDate = `${date.getDate()}/${date.getMonth() + 1
    }/${date.getFullYear()}`;
  var StaringDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
  var placeholderDate = formatedDate + " - " + formatedDate;

  useEffect(() => {
    if (formValues?.groupid != null) {
      const vh = vehicleData?.filter(
        (obj) => obj?.groupId == formValues?.groupid
      );
      if (vh?.length == 0) {
        const cc = [
          {
            label: "NO VEHICLES IN THIS GROUP!",
            value: "0",
            showlabel: "NO VEHICLES IN THIS GROUP!",
          },
        ];

        setVehicleValue("0");
        setFilteredData(cc);
      } else {
        setVehicleValue("0");
        setFilteredData(vh);
      }
    }
  }, [formValues?.groupid]);



  return (
    <>
      <Modal
        open={scheduleOpen?.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="AddRoleModal Schedule-modal"
      >
        <Box sx={style} className="modal-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {scheduleOpen?.row == null
              ? "Add Report Schedule"
              : scheduleOpen?.readOnly
                ? "View Report Schedule"
                : "Edit Report Schedule"}
          </Typography>
          <span className="close-btn" onClick={handleClose}>
            <CloseIcon />
          </span>
          <form onSubmit={handleSubmit}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ paddingTop: "6px !important" }}>
                  <Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={5}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <FormLabel>Report Type</FormLabel>
                          <Select
                            name="reporttype"
                            value={formValues?.reporttype}
                            onChange={handleChange}
                            displayEmpty
                            renderValue={
                              formValues?.reporttype !== null
                                ? undefined
                                : () => <span>Select Report Type</span>
                            }
                            disabled={formValues?.readOnly}
                          >
                            {reportTypes?.map((action, index) => (
                              <MenuItem key={index} value={action?.name}>
                                {action?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <div style={errorstyle}>{formError?.reporttype}</div>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Box className="date-range">
                            <FormLabel>Duration</FormLabel>
                            <FormControl>
                              <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                  setDateRange(update);
                                }}
                                isClearable={true}
                                ref={datePickerRef}
                                dateFormat="dd/MM/yyyy"
                                disabled={formValues?.readOnly}
                                maxDate={new Date()}
                              />
                              <IconButton
                                className="cal-icon"
                                onClick={handleDatePicker}
                              >
                                <CalendarIcon />
                              </IconButton>
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: "18px" }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={5}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <FormControl className="select-component">
                            <FormLabel>Group</FormLabel>
                            <Autocomplete
                              isOptionEqualToValue={(option, value) =>
                                option.value === value?.value
                              }
                              disablePortal
                              id="groupid"
                              name="groupid"
                              className="select-auto"
                              options={groupData}
                              renderOption={(props, option) => (
                                <li {...props} key={option.value}>
                                  {option.showlabel}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Group"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {loadingGroup ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                              open={openGroupDrp}
                              onOpen={() => {
                                setOpenGroupDrp(true);
                              }}
                              onClose={() => {
                                setOpenGroupDrp(false);
                              }}
                              loading={loadingGroup}
                              getOptionLabel={(option) => option.showlabel}
                              onChange={(event, newValue) => {
                                setGroupValue(newValue);
                                setFormValues({
                                  ...formValues,
                                  groupid: newValue.value,
                                });
                              }}
                              value={groupValue}
                              disabled={formValues?.readOnly}
                            />
                          </FormControl>
                          <div style={errorstyle}>{formError?.group}</div>
                        </Grid>
                        <Grid item xs={7}>
                          <FormLabel>Frequency</FormLabel>
                          <Box
                            sx={{ display: "flex" }}
                            className="checkbox-label"
                          >
                            <Box sx={{ marginTop: "10px", marginRight: "8px" }}>
                              <input
                                type="radio"
                                name="report_period"
                                value="7"
                                checked={formValues?.report_period == "7"}
                                onChange={handleChange}
                                disabled={formValues?.readOnly}
                              />
                              <FormLabel>Weekly</FormLabel>
                            </Box>
                            <Box sx={{ marginTop: "10px", marginRight: "8px" }}>
                              <input
                                type="radio"
                                name="report_period"
                                value="14"
                                checked={formValues?.report_period == "14"}
                                onChange={handleChange}
                                disabled={formValues?.readOnly}
                              />
                              <FormLabel>Biweekly</FormLabel>
                            </Box>
                            <Box sx={{ marginTop: "10px", marginRight: "8px" }}>
                              <input
                                type="radio"
                                name="report_period"
                                value="30"
                                checked={formValues?.report_period == "30"}
                                onChange={handleChange}
                                disabled={formValues?.readOnly}
                              />
                              <FormLabel>Monthly</FormLabel>
                            </Box>
                          </Box>
                          <div style={errorstyle}>
                            {formError?.reportperiod}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: "27px" }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={5}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <FormControl className="select-component">
                            <FormLabel>Vehicle</FormLabel>
                            <Autocomplete
                              isOptionEqualToValue={(option, value) =>
                                option.value === value?.value
                              }
                              disablePortal
                              id="vehicleno"
                              name="vehicleno"
                              className="select-auto"
                              options={filteredData}
                              renderOption={(props, option) => (
                                <li {...props} key={option.value}>
                                  {option.showlabel}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Vehicle"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {loadingVehicle ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                              open={openVehicleDrp}
                              onOpen={() => {
                                setOpenVehicleDrp(true);
                              }}
                              onClose={() => {
                                setOpenVehicleDrp(false);
                              }}
                              loading={loadingVehicle}
                              getOptionLabel={(option) =>
                                option?.showlabel ? option?.showlabel : ""
                              }
                              onChange={(event, newValue) => {
                                setVehicleValue(newValue);
                                setFormValues({
                                  ...formValues,
                                  vehicleno: newValue.value,
                                });
                              }}
                              value={vehicleValue ? vehicleValue : ""}
                              disabled={formValues?.readOnly}
                            />
                          </FormControl>
                          <div style={errorstyle}>{formError?.device}</div>
                        </Grid>
                        <Grid item xs={7}>
                          <FormLabel>Day</FormLabel>
                          <Box
                            sx={{ display: "flex" }}
                            className="checkbox-label"
                          >
                            <Box sx={{ marginTop: "10px", marginRight: "8px" }}>
                              <input
                                type="radio"
                                name="day"
                                value="1"
                                onChange={handleChange}
                                disabled={formValues?.readOnly}
                                checked={formValues?.day == 1}
                              />
                              <FormLabel>Sun</FormLabel>
                            </Box>
                            <Box sx={{ marginTop: "10px", marginRight: "8px" }}>
                              <input
                                type="radio"
                                name="day"
                                value="2"
                                onChange={handleChange}
                                checked={formValues?.day == 2}
                                disabled={formValues?.readOnly}
                              />
                              <FormLabel>Mon</FormLabel>
                            </Box>
                            <Box sx={{ marginTop: "10px", marginRight: "8px" }}>
                              <input
                                type="radio"
                                name="day"
                                value="3"
                                onChange={handleChange}
                                disabled={formValues?.readOnly}
                                checked={formValues?.day == 3}
                              />
                              <FormLabel>Tue</FormLabel>
                            </Box>
                            <Box sx={{ marginTop: "10px", marginRight: "8px" }}>
                              <input
                                type="radio"
                                name="day"
                                value="4"
                                onChange={handleChange}
                                disabled={formValues?.readOnly}
                                checked={formValues?.day == 4}
                              />
                              <FormLabel>Wed</FormLabel>
                            </Box>
                            <Box sx={{ marginTop: "10px", marginRight: "8px" }}>
                              <input
                                type="radio"
                                name="day"
                                value="5"
                                onChange={handleChange}
                                disabled={formValues?.readOnly}
                                checked={formValues?.day == 5}
                              />
                              <FormLabel>Thu</FormLabel>
                            </Box>
                            <Box sx={{ marginTop: "10px", marginRight: "8px" }}>
                              <input
                                type="radio"
                                name="day"
                                value="6"
                                onChange={handleChange}
                                disabled={formValues?.readOnly}
                                checked={formValues?.day == 6}
                              />
                              <FormLabel>Fri</FormLabel>
                            </Box>
                            <Box sx={{ marginTop: "10px", marginRight: "8px" }}>
                              <input
                                type="radio"
                                name="day"
                                value="7"
                                onChange={handleChange}
                                disabled={formValues?.readOnly}
                                checked={formValues?.day == 7}
                              />
                              <FormLabel>Sat</FormLabel>
                            </Box>
                          </Box>
                          <div style={errorstyle}>{formError?.day}</div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>

                  <Box sx={{ marginTop: "27px" }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={5}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <FormLabel>Email Id</FormLabel>
                          <FormField
                            name="email"
                            placeholder="Enter Email id"
                            type="text"
                            value={formValues?.email}
                            onChange={handleChange}
                            disabled={formValues?.readOnly}
                          />
                          <div style={errorstyle}>{formError?.email}</div>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <FormLabel>Format</FormLabel>
                          <Select
                            name="format"
                            value={formValues?.format}
                            onChange={handleChange}
                            displayEmpty
                            renderValue={
                              formValues?.format !== null
                                ? undefined
                                : () => <span>Select Format</span>
                            }
                            disabled={formValues?.readOnly}
                          >
                            {reportFormats?.map((item, index) => (
                              <MenuItem key={index} value={item?.value}>
                                {item?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <div style={errorstyle}>{formError?.format}</div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: "27px" }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={5}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <FormLabel>Email Subject</FormLabel>
                          <FormField
                            name="emailsubject"
                            placeholder="Enter Email Subject"
                            type="text"
                            value={formValues?.emailsubject}
                            onChange={handleChange}
                            disabled={formValues?.readOnly}
                          />
                          <div style={errorstyle}>
                            {formError?.emailsubject}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <div style={{ marginTop: "1em" }} className="modal-footer">
                    {!scheduleOpen?.readOnly && (
                      <FormButton
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {scheduleOpen?.row == null ? "Create" : "Update"}
                      </FormButton>
                    )}
                    <FormButton
                      variant="contained"
                      className="cancel-btn"
                      color="primary"
                      onClick={handleClose}
                      sx={{ marginRight: "1em" }}
                    >
                      Cancel
                    </FormButton>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Modal>
      <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
    </>
  );
}
