import { useEffect, useState } from "react";
import { initialize, MMI_KEY } from './../utils/initializeMap'
import moment from "moment";
import CloseIcon from '@mui/icons-material/Close';
import L from 'leaflet';

export const GeofenceMap = ({ drawData, setPosition, circlePoints, buttonChange, drawmode, setFormValues, setDrawMode }) => {
  const styleMap = { width: '99%', height: '69vh', display: 'inline-block' }
  const [isloadMap, setLoadMap] = useState(false);
  const [mapObject, setmapObject] = useState(null);
  const [layerstyle, setLayerStyle] = useState("standard-day");
  const [circleobject, setcircleobject] = useState(null);
  const [maplayerobj, setmaplayerobj] = useState(null);
  const [mapcircleobj, setmapcircleobj] = useState(null);
  var mapObj;
  var polylineobj;
  var polygonobj;
  var circleobj;
  var MarkerObj;
  useEffect(() => {
    initialize(MMI_KEY, () => {
      //Action after script has been loaded completely
      afterScriptsLoaded();
    })
  }, [])

  useEffect(() => {

    if (mapObject) {
      create_Shape();
    }
  }, [mapObject, circlePoints, buttonChange])

  useEffect(() => {

    if (mapObject) {
      loadDataOnlyOnce();
      clickLayer();
    }
  }, [mapObject])

  function clickLayer() {
    // if(mapObject){
    document.getElementById('map_default').onclick = function (e) {
      setLayers('standard-day')
    }
    document.getElementById('grey-day').onclick = function (e) {
      setLayers('grey-day')
    }
    document.getElementById('standard-night').onclick = function (e) {
      setLayers('standard-night')
    }
    document.getElementById('map_hybrid').onclick = function (e) {
      setLayers('standard-hybrid')
    }
  }

  const loadDataOnlyOnce = () => {
    var container = document.querySelector(".expand-map-control")
    var layer_id = document.getElementById('layerId')
    if (!layer_id)
      container.innerHTML += '<li id="myBtn" class="map-layers"><a style="height: 36px;line-height: 32px;" title=" Map Layers" nofollow="" > <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LayersIcon"><path d="m11.99 18.54-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z"></path></svg></a>\
        <ul class="clearfix map-layer" id="map-layer" style="display:none">\
        <span class="" id="layer-close"> X </span>\
        <li id="map_default" class="dyLayerMap">\
            <a href="javascript:void(0)" class="mapStyleBlock active">\
              <span class="layer-item-img">\
                  <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" />\
                  <img src="https://apis.mapmyindia.com/vector_map/thumb/style.jpg" alt="" class="mode-img" />\
               </span>\
               <p>Default Map </p>\
            </a>\
         </li><li id="grey-day" class="dyLayerMap">\
         <a href="javascript:void(0)" class="mapStyleBlock">\
            <span class="layer-item-img">\
               <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
               <img src="https://www.mappls.com/images/gray.jpg" alt="" class="mode-img" style={{width: "82px",height: "56px",right: 0,bottom: 0}} />\
            </span>\
            <p>Grey Mode </p>\
         </a>\
      </li><li id="standard-night" class="dyLayerMap">\
         <a href="javascript:void(0)" class="mapStyleBlock" >\
            <span class="layer-item-img">\
               <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
               <img src="https://www.mappls.com/images/nightmode.jpg" alt="" class="mode-img" />\
            </span>\
            <p>Night Mode </p>\
         </a></li>\
         <li id="map_hybrid" class="dyLayerMap">\
         <a href="javascript:void(0)" class="mapStyleBlock" >\
            <span class="layer-item-img">\
               <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
               <img src="https://www.mappls.com/images/maplayer/hy_map.png" alt="" class="mode-img" />\
            </span>\
            <p>Satellite </p>\
         </a></li>\
      </ul>\
        </li>'

  };

  useEffect(() => {

    if (mapObject) {


      if (maplayerobj) window.mappls.remove({ map: mapObject, layer: maplayerobj });
      window.mappls.setStyle(layerstyle);
      create_Shape();
    }
  }, [layerstyle])

  function afterScriptsLoaded() {
    mapObj = window.mappls.Map('map', {
      center: [21.35516, 81.28199],
      zoom: 7,
      zoomControl: true,
      location: true,
    })

    setmapObject(mapObj);
  }

  const create_Shape = () => {

    var options = {
      strokeColor: 'blue'
    }


    if (mapObject) {

      if (maplayerobj) window.mappls.remove({ map: mapObject, layer: maplayerobj });
      if (mapcircleobj) window.mappls.remove({ map: mapObject, layer: mapcircleobj });
      if (drawData.geofencetype === "circle" && circlePoints.type === "circle") {

        circleobj = window.mappls.Circle({
          map: mapObject,
          center: { "lat": circlePoints.lat, "lng": circlePoints.lng },
          radius: circlePoints.radius,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "red",
          fillOpacity: 0.35,
          strokeColor: "red",
        });
        setmaplayerobj(circleobj);
        setPosition({ lat: circlePoints.lat, lng: circlePoints.lng, radius: circlePoints.radius });
        mapObject.setCenter({ lat: circlePoints.lat, lng: circlePoints.lng })
        var MarkerObj = window.mappls.Marker({
          map: mapObject,
          position: {
            "lat": circlePoints.lat,
            "lng": circlePoints.lng
          },
          popupHtml: 'Drag the circle',
          draggable: true
        });

        setmapcircleobj(MarkerObj);
        MarkerObj.on('dragend', function (event) {
          var latlngc = event.target._lngLat;
          setFormValues({ ...drawData, latitude: latlngc.lat, longitude: latlngc.lng, area: "", geofencetype: drawData.geofencetype })
        });
      }

      mapObject.addListener('click', function (e) {
        if (drawData.geofencetype === "circle") {
          var latlngc = e.lngLat;
          setFormValues({ ...drawData, latitude: latlngc.lat, longitude: latlngc.lng, area: "", geofencetype: drawData.geofencetype })
        }
        else {
          setFormValues({ ...drawData, latitude: "", longitude: "", area: "", geofencetype: drawData.geofencetype })
        }
      });
      if (drawmode === true && drawData.geofencetype !== "circle" && circlePoints.type !== "circle") {
        window.mappls.draw({
          map: mapObject,
          type: drawData.geofencetype,
          fitbounds: false,
          callback: draw_callback,
          options: options
        })
      }
      else {

        if (drawData.id > 0) {
          if (drawData.geofencetype === "polyline") {

            polylineobj = window.mappls.Polyline({
              map: mapObject,
              paths: circlePoints.coordarray,
              strokeColor: 'blue',
              fitbounds: true,
              editable: true
            });
            setmaplayerobj(polylineobj);
            setPosition(circlePoints.coordarray);
            polylineobj.addListener((('mousemove')), function () {
              let arraypos = polylineobj.getPath();
              setPosition(arraypos);
            });


          }
          else if (drawData.geofencetype === "polygon") {
            if (polygonobj) {
              window.mappls.remove({ map: mapObject, layer: polygonobj });
            }


            polygonobj = window.mappls.Polygon({
              map: mapObject,
              paths: circlePoints.coordarray,
              fillColor: "blue",
            });
            polygonobj.setEditable(true)
            setmaplayerobj(polygonobj);
            setPosition(circlePoints.coordarray);

            polygonobj.addListener((('mousemove')), function () {
              let arraypos = polygonobj.getPath();
              setPosition(arraypos[0]);
            });

          }
        }
      }
    }
  }

  if (mapObject) {

  }

  function draw_callback(data) {
    setmaplayerobj(data);
    var polygon1 = data;
    var path1 = polygon1.getPath();
    if (path1) {
      if (Array.isArray(path1[0])) {
        setPosition(path1[0]);
      }
      else {
        setPosition(path1);
      }

    }

  }


  const setLayers = (id) => {
    setLayerStyle(id)
  }

  return (
    <div>
      <div id="map" style={styleMap}></div>
    </div>

  );
};
