import React, { useEffect, useContext, useState } from 'react';
import { useListContext } from "react-admin";
import { exists, validateLatLong, containsLatLong, isEmpty } from "../utils/validate";
import "../../src/views/layout/mapview.css";
import { initialize, MMI_KEY } from './../utils/initializeMap';
import { MapContext, getMarkerPopup, mapIcon } from '../utils/mapContext';
import icon from '../../src/images/GTruck.png';
import urls from '../constants';
import usePosition from '../hooks/usePosition';
import VibrateIcon from "../../src/images/vibrate.png";

let DefaultIcon = icon
let VibrateIconImg = VibrateIcon

const MapView = (props) => {

  let wsUrl = urls.WEBSOCKET_URL.replace(/http/, 'ws');
  const { mapData, setMapData } = useContext(MapContext);
  const [movemarker, setmovemarker] = useState([]);
  const [markerarr, setmarkerarr] = useState([]);
  const [clustermarker, setClustermarker] = useState(null);
  const [mapDatamarker, setmapDataMarker] = useState([]);
  const [positions, setPositions, setSocketUrl] = usePosition();
  var marker;
  const [previous_latlng, setprevious_latlng] = useState("");
  const [movingmarker, setmovingmarker] = useState(null);
  const [oncecluster, setoncecluster] = useState(false);


  const styleMap = { width: '99%', height: '69vh', display: 'inline-block' }
  var mapObj;

  const { data, isLoading } = useListContext();
  const [markers, setMarkers] = useState([]);
  let newMarkers = [];
  const { bounds, cbBounds, vehicleStatus, ispanclick } = props;
  const [mapDetails, setMapDetails] = useState([]);
  const [layerstyle, setLayerStyle] = useState("standard-day");

  const [mapObject, setmapObject] = useState(null)
  useEffect(() => {
    setmapObject(null);
    initialize(MMI_KEY, () => {
      afterScriptsLoaded();
    })
  }, [])

  useEffect(() => {
    if (props?.record == null && compareTwoArrayOfObjects(mapDetails, data) === true) return;
    props?.record == null
      ? setMapDetails(data)
      : setMapDetails([props?.record]);
  }, [data, props?.record]);


  let compareTwoArrayOfObjects = (
    first_array_of_objects,
    second_array_of_objects
  ) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.device_id === element_2.device_id
        )
      )
    );
  };

  useEffect(() => {
    if (mapDetails?.length > 0) {
      setMarkers([]);
      setmapDataMarker([]);
      mapDetails?.filter((item) => {
        return validateLatLong(item.latitude, item.longitude);
      })
        .map((item) => {
          newMarkers.push({
            lat: item.latitude,
            long: item.longitude,
            id: item.device_id,
            type: item.type,
            speed: item?.speed ? item?.speed?.toFixed(2) : 0,
            highlight: false,
            status: item.status,
            course: item.course,
            vehicle_no: item?.uniqueidno ? item?.uniqueidno : 0,
            chasis_no: item?.chasisno ? item?.chasisno : '',
            imei_no: item?.imei ? item?.imei : '',
            permit_holder: item?.permit_holder ? item?.permit_holder : '',
            contact: item?.contact ? item?.contact : 0,
            rto: item?.rto_code ? item?.rto_code : 0,
            company: item?.make ? item?.make : '',
            name: item?.name ? item?.name : item?.vehicleNo,
            time: item?.eventTime,
            address: item?.location,
          });
        });
      setMarkers(newMarkers);
    }
    else setMarkers([]);
  }, [mapDetails]);

  useEffect(() => {
    if (mapData?.markers?.length > 0) {
      setmapDataMarker([]);
      setMarkers([]);
      newMarkers = [];
      mapData?.markers?.filter((item) => {
        return validateLatLong(item.latitude, item.longitude);
      })
        .map((item) => {
          newMarkers.push({
            lat: item.latitude,
            long: item.longitude,
            id: item.device_id,
            type: item.type,
            speed: item?.speed ? item?.speed?.toFixed(2) : 0,
            highlight: item?.highlight,
            status: item.status,
            course: item.course,
            vehicle_no: item?.uniqueidno ? item?.uniqueidno : 0,
            chasis_no: item?.chasisno ? item?.chasisno : '',
            imei_no: item?.imei ? item?.imei : '',
            permit_holder: item?.permit_holder ? item?.permit_holder : '',
            contact: item?.contact ? item?.contact : 0,
            rto: item?.rto_code ? item?.rto_code : 0,
            company: item?.make ? item?.make : '',
            name: item?.name ? item?.name : item?.vehicleNo,
            time: item?.eventTime,
            address: item?.location,
          });
        });
      setmapDataMarker(newMarkers);
    }
    else setmapDataMarker([]);
  }, [mapData]);

  useEffect(() => {
    if (mapObject) {
      if (isEmpty(mapDatamarker) === true) setoncecluster(false);
      setmapObject(null);
      afterScriptsLoaded();
      window.mappls.setStyle(layerstyle)
    }
  }, [layerstyle])

  useEffect(() => {
    if (mapObject) {
      loadDataOnlyOnce();
      clickLayer();
    }
  }, [mapObject])

  function clickLayer() {
    document.getElementById('map_default').onclick = function (e) {
      setLayers('standard-day')
    }
    document.getElementById('grey-day').onclick = function (e) {
      setLayers('grey-day')
    }
    document.getElementById('standard-night').onclick = function (e) {
      setLayers('standard-night')
    }
    document.getElementById('map_hybrid').onclick = function (e) {
      setLayers('standard-hybrid')
    }
  }


  const loadDataOnlyOnce = () => {
    var container = document.querySelector(".expand-map-control")
    var layer_id = document.getElementById('layerId')
    if (!layer_id)
      container.innerHTML += '<li id="myBtn" class="map-layers"><a style="height: 36px;line-height: 32px;" title=" Map Layers" nofollow="" > <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LayersIcon"><path d="m11.99 18.54-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z"></path></svg></a>\
          <ul class="clearfix map-layer" id="map-layer" style="display:none">\
            <span class="" id="layer-close"> X </span>\
            <li id="map_default" class="dyLayerMap">\
                <a href="javascript:void(0)" class="mapStyleBlock active">\
                  <span class="layer-item-img">\
                      <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" />\
                      <img src="https://apis.mapmyindia.com/vector_map/thumb/style.jpg" alt="" class="mode-img" />\
                  </span>\
                  <p>Default Map </p>\
                </a>\
            </li><li id="grey-day" class="dyLayerMap">\
            <a href="javascript:void(0)" class="mapStyleBlock">\
                <span class="layer-item-img">\
                  <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
                  <img src="https://www.mappls.com/images/gray.jpg" alt="" class="mode-img" style={{width: "82px",height: "56px",right: 0,bottom: 0}} />\
                </span>\
                <p>Grey Mode </p>\
            </a>\
          </li><li id="standard-night" class="dyLayerMap">\
            <a href="javascript:void(0)" class="mapStyleBlock" >\
                <span class="layer-item-img">\
                  <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
                  <img src="https://www.mappls.com/images/nightmode.jpg" alt="" class="mode-img" />\
                </span>\
                <p>Night Mode </p>\
            </a></li>\
            <li id="map_hybrid" class="dyLayerMap">\
            <a href="javascript:void(0)" class="mapStyleBlock" >\
               <span class="layer-item-img">\
                  <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
                  <img src="https://www.mappls.com/images/maplayer/hy_map.png" alt="" class="mode-img" />\
               </span>\
               <p>Satellite </p>\
            </a></li>\
          </ul>\
          </li>'
  };



  function afterScriptsLoaded() {
    mapObj = window.mappls.Map('map', {
      center: [21.35516, 81.28199],
      zoomControl: true,
      zoom: 8,
      location: true,
      hybrid: true,
      search: true
    })
    window.mappls.setStyle(layerstyle)

    setmapObject(mapObj)
  }

  const setLayers = (id) => {
    setLayerStyle(id)
  }

  useEffect(() => {
    if (ispanclick !== null) {
      setmapObject(null);
      afterScriptsLoaded();
    }
  }, [ispanclick])

  function randomNumber(min, max) {
    return Math.random() * (max - min) + min
  }

  function angle(lat1, lon1, lat2, lon2) {
    var p1 = { x: lat1, y: lon1 };
    var p2 = { x: lat2, y: lon2 };
    /*angle in radians */
    var angleRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);
    /*angle in degrees*/
    var angleDeg = Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI;
    return angleDeg;
  }

  function angleFromCoordinate(lat1, lon1, lat2, lon2) {
    var p1 = { x: lat1, y: lon1 };
    var p2 = { x: lat2, y: lon2 };
    // angle in radians
    var angleRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);
    // angle in degrees
    var angleDeg = Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI;
    return angleDeg;
  }

  function angleFromCoordinates(lat1, long1, lat2, long2) {
    let dLon = (long2 - long1);
    let y = Math.sin(dLon) * Math.cos(lat2);
    let x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1)
      * Math.cos(lat2) * Math.cos(dLon);

    let brng = Math.atan2(y, x);
    brng = brng * 180 / Math.PI;
    brng = (brng + 360) % 360;

    return brng;
  }

  function offsetValue(deg) {
    let yval = 0;

    yval = (deg - 90) % 90;
    yval = 90 - yval;

    if (deg > 180) {
      yval = -yval
    }

    return yval;
  }

  useEffect(() => {
    if (clustermarker) setoncecluster(true);
  }, [clustermarker])

  const fclustermarker = () => {
    var all_data = [];
    if (mapObject) {
      if (!isEmpty(markers)) {
        if (clustermarker) {
          window.mappls.remove({ map: mapObject, layer: clustermarker });
        }
        if (movemarker) {
          movemarker.map((mvmarker) => {
            window.mappls.remove({ map: mapObject, layer: mvmarker });
          })
        }
        if (movingmarker) {
          window.mappls.remove({ map: mapObject, layer: movingmarker });
          setmovingmarker(null);
        }

        let center = null;
        let firstpoint = null;
        markers.map((item, id) => {
          let pos = [item.lat, item.long];
          DefaultIcon = mapIcon(item)

          firstpoint = { lat: pos[0], lng: pos[1] }
          var obj = {
            "type": "Feature",
            "properties": {
              "htmlPopup": getMarkerPopup(item),
              "icon": DefaultIcon,
            },
            "geometry": {
              "type": "Point",
              "coordinates": [pos[0], pos[1]]
            }
          }
          all_data.push(obj);

        });
        mapObject.addListener('load', function () {
          var marker = window.mappls.Marker({
            map: mapObject,
            position: {
              "id": "clustermarker",
              "type": "FeatureCollection",
              "features": all_data
            },
            clusters: true,
            fitbounds: true,
            fitboundOptions: { padding: 1120, duration: 1000 },
          });

          setClustermarker(marker);

          mapObject.setCenter(firstpoint);
          mapObject.setZoom(3.232625493948281);
        });

        if (oncecluster === true) {
          var marker = window.mappls.Marker({
            map: mapObject,
            position: {
              "id": "clustermarker",
              "type": "FeatureCollection",
              "features": all_data
            },
            clusters: true,
            fitbounds: true,
            fitboundOptions: { padding: 1120, duration: 1000 },
          });

          setClustermarker(marker);

          mapObject.setCenter(firstpoint);
          mapObject.setZoom(17);
        }

        mapObject.on('moveend', () => {
          cbBounds(mapObject.getBounds());
        })
      }
    }

  }
  useEffect(() => {
    if (mapObject) {
      if (isEmpty(mapDatamarker)) {
        fclustermarker();
      }
      else {
        fHighlightMarker();
      }
    }

  }, [mapObject, markers, mapDatamarker])


  const fHighlightMarker = () => {

    if (mapObject) {
      if (!isEmpty(mapDatamarker)) {
        let center = null;
        let firstpoint = null;
        let arrdataMarker = [];
        if (clustermarker) window.mappls.remove({ map: mapObject, layer: clustermarker });
        if (movemarker) {
          movemarker.map((mvmarker) => {
            window.mappls.remove({ map: mapObject, layer: mvmarker });
          })
        }
        mapDatamarker.map((item, id) => {
          let pos = [item.lat, item.long];
          let marker = null;
          var hed;
          DefaultIcon = mapIcon(item)

          if (item.highlight === true) {
            setSocketUrl(wsUrl.concat('?deviceId=' + item.id));

            positions?.map((element, index) => {
              if (element.deviceId === item.id) {
                if (previous_latlng?.lat === "" || previous_latlng?.lat === undefined) {
                  setprevious_latlng({ lat: pos[0], lng: pos[1] });
                }

                hed = angleFromCoordinate(previous_latlng.lat, previous_latlng.lng, element.latitude, element.longitude);
                setprevious_latlng({ lat: element.latitude, lng: element.longitude });
                pos = [element.latitude, element.longitude];
                item.course = element.course;
              }
            })

            if (movingmarker) window.mappls.remove({ map: mapObject, layer: movingmarker });

            var marker1 = window.mappls.Marker({
              map: mapObject,
              position: { lat: pos[0], lng: pos[1] },
              html: "<img class='moving-icon' id='movingmarkerid" + item.id + "'  style='transform: rotate(" + hed + "deg)'   src='" + DefaultIcon + "'/>",
              fitbounds: true,
              popupHtml: getMarkerPopup(item),

            });
            setmovingmarker(marker1);
            center = { lat: pos[0], lng: pos[1] }
          }
          else {

            var marker1 = window.mappls.Marker({
              map: mapObject,
              position: { lat: pos[0], lng: pos[1] },
              html: "<img src='" + DefaultIcon + "' />",
              popupHtml: getMarkerPopup(item),
            });
            arrdataMarker.push(marker1)

          }
        });

        if (center) {
          mapObject.setCenter({ lat: center.lat, lng: center.lng });

        }
        cbBounds(mapObject.getBounds());
        setmovemarker(arrdataMarker)

        mapObject.on('moveend', () => {
          cbBounds(mapObject.getBounds());
        })

      }
      return (() => {
      });
    }
  }

  useEffect(() => {
    fHighlightMarker();
  }, [positions])

  return (
    <div id="map" style={styleMap}>
    </div>
  );
}

export default MapView;
