import { useEffect, useState } from "react";
import { Button } from "react-admin";
import { initialize } from './../utils/initializeMap'
import Grid from '@mui/material/Grid';
import TrainIcon from '@mui/icons-material/TrainOutlined';
import GpsIcon from '@mui/icons-material/MyLocationOutlined';
import { getMMIToken } from './../providers/dataProvider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Trailchart from './Trailchart'
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from "@mui/material/FormLabel";
import ArrowIcon from '@mui/icons-material/ArrowBackOutlined';
import moment from 'moment';
import {
  Box, Typography,
  Card
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? 'blue' : 'blue',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#67748E' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function TrailsRouting({ playRoutesData, playTrailsBack, reportsRoute, TrailsRouteView, stopPoints }) {
  const styleMap = { width: '99%', height: '69vh', display: 'inline-block' }
  var mapObj;
  var polyline;
  const [routepts, setroutepts] = useState([]);
  const [markerpts, setmarkerpts] = useState([]);
  const [snappoints, setSnapPoints] = useState("");
  const [changepoints, setChangePoints] = useState([]);
  const [routeData, setRouteData] = useState([]);
  const [layerstyle, setLayerStyle] = useState("standard-day");
  const [mapObject, setMapObject] = useState(null);
  const [carpolyline, setCarpolyline] = useState(null);
  const [speedinc, setSpeedInc] = useState(5);
  const [movemarker, setmovemarker] = useState(null);
  const [mapreloaded, setMapReloaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [linecheck, setLineCheck] = useState(false);
  const [token, setToken] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getMMIToken(setToken);
  }, [])

  useEffect(() => {
    initialize(token?.access_token, () => {
      afterScriptsLoaded();
    })
  }, [token])

  const Dateformat = (source) => {
    const dt = (source !== null) ? moment(source).format("DD MMM YYYY hh:mm:ss") : "";
    return dt;
  };

  const msToTime = (duration) => {
    var milliseconds = parseInt((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  }

  useEffect(() => {
    if (TrailsRouteView === false || TrailsRouteView === undefined) {
      setroutepts([]);
      setmarkerpts([]);
      setChangePoints([]);
      setCarpolyline(null);
    }
  }, [TrailsRouteView])

  useEffect(() => {
    if (reportsRoute) {
      if (reportsRoute.length > 0) {
        var arrmarker = [];
        var arrroute = [];
        var arrRouteData = [];
        var snaproute = "";
        reportsRoute.map((reports, index) => {
          if (index === 0 || index === ((reportsRoute.length * 1) - 1)) {
            let objmarker = {
              "type": "Feature",
              "geometry": {
                "type": "Point",
                "coordinates": [reports.latitude, reports.longitude]
              }
            }

            arrmarker.push(objmarker);
          }

          let objroute = {
            lat: reports.latitude,
            lng: reports.longitude
          }

          arrRouteData.push({
            deviceTime: reports.deviceTime,
            latitude: reports.latitude,
            longitude: reports.longitude,
            speed: reports.speed,
            course: reports.course
          })
          arrroute.push(objroute)
        })

        if (arrRouteData) setRouteData(arrRouteData);
        if (arrroute) setroutepts(arrroute);
        if (arrmarker) setmarkerpts(arrmarker);
      }
    }

  }, [reportsRoute])

  const setLayers = (id) => {
    setLayerStyle(id)
  }

  function afterScriptsLoaded() {
    mapObj = window.mappls.Map('map', {
      center: [21.35516, 81.28199],
      zoom: 7,
      zoomControl: true,
    })
    setMapObject(mapObj)
  }

  useEffect(() => {
    if (mapObject) {
      setCarpolyline(null);
      window.mappls.setStyle(layerstyle);
    }
  }, [speedinc, layerstyle, linecheck])

  useEffect(() => {
    getTrailsMap();
  }, [mapObject])

  const getTrailsMap = () => {
    if (mapObject) {
      window.mappls.setStyle(layerstyle);
      setIsLoading(false);

      if (markerpts && routepts) {
        if (markerpts.length > 0 && routepts.length > 0) {

          var marker1 = window.mappls.Marker({
            map: mapObject,
            position: routepts[0],
            icon_url: 'https://apis.mapmyindia.com/map_v3/1.png',
          });
          setmovemarker(marker1);

          window.mappls.Marker({
            map: mapObject,
            position: routepts[(routepts.length * 1) - 1],
            icon_url: 'https://apis.mapmyindia.com/map_v3/1.png',
          });

          if (stopPoints) {
            if (stopPoints.length > 0) {
              stopPoints.map((stops, index) => {
                let alarmtype = ""
                if (stops.type === "alarm") alarmtype = " (" + stops?.attributes?.alarm + " )"

                window.mappls.Marker({
                  map: mapObject,
                  position: { lat: stops.latitude, lng: stops.longitude },
                  popupHtml: "<div><b>Type</b> : " + stops.type + alarmtype + "</div><div><b>Event Time</b> : " + moment(stops.eventTime).format("DD MMM YYYY hh:mm:ss") + "</div><div><b>coordinate's </b> : (" + stops.latitude + " , " + stops.longitude + ")</div>",
                  icon_url: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
                });
              })

            }
          }

          if (linecheck === false) {
            if (carpolyline) window.mappls.remove({ map: mapObject, layer: carpolyline });
            mapObject.addListener('load', function () {

              var tracking_option = {
                map: mapObject,
                data: routeData,
                routeColor: "green",
                strokeWidth: 5,
                ccpIcon: 'https://jmcweblink.blob.core.windows.net/jmcfilelink/images/vts/Car.png',
                ccpIconWidth: 40,
                fitBounds: true,
                speedText: true,
              }
              var tracking_plugin = window.mappls.trackingTimeline(tracking_option);

            })
          }
          else {
            if (polyline) window.mappls.remove({ map: mapObject, layer: polyline });
            mapObject.setZoom(14);
            mapObject.panTo(routepts[0]);
            setIsLoading(false);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (mapObject) {
      loadDataOnlyOnce();
      clickLayer();
    }
  }, [mapObject])

  function clickLayer() {
    document.getElementById('map_default').onclick = function (e) {
      setLayers('standard-day')
    }
    document.getElementById('grey-day').onclick = function (e) {
      setLayers('grey-day')
    }
    document.getElementById('standard-night').onclick = function (e) {
      setLayers('standard-night')
    }
    document.getElementById('map_hybrid').onclick = function (e) {
      setLayers('standard-hybrid')
    }
  }

  const loadDataOnlyOnce = () => {
    var container = document.querySelector(".expand-map-control")
    var layer_id = document.getElementById('layerId')
    if (!layer_id)
      container.innerHTML += '<li id="myBtn" class="map-layers"><a style="height: 36px;line-height: 32px;" title=" Map Layers" nofollow="" > <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LayersIcon"><path d="m11.99 18.54-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z"></path></svg></a>\
        <ul class="clearfix map-layer" id="map-layer" style="display:none">\
        <span class="" id="layer-close"> X </span>\
        <li id="map_default" class="dyLayerMap">\
            <a href="javascript:void(0)" class="mapStyleBlock active">\
              <span class="layer-item-img">\
                  <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" />\
                  <img src="https://apis.mapmyindia.com/vector_map/thumb/style.jpg" alt="" class="mode-img" />\
               </span>\
               <p>Default Map </p>\
            </a>\
         </li><li id="grey-day" class="dyLayerMap">\
         <a href="javascript:void(0)" class="mapStyleBlock">\
            <span class="layer-item-img">\
               <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
               <img src="https://www.mappls.com/images/gray.jpg" alt="" class="mode-img" style={{width: "82px",height: "56px",right: 0,bottom: 0}} />\
            </span>\
            <p>Grey Mode </p>\
         </a>\
      </li><li id="standard-night" class="dyLayerMap">\
         <a href="javascript:void(0)" class="mapStyleBlock" >\
            <span class="layer-item-img">\
               <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
               <img src="https://www.mappls.com/images/nightmode.jpg" alt="" class="mode-img" />\
            </span>\
            <p>Night Mode </p>\
         </a></li>\
         <li id="map_hybrid" class="dyLayerMap">\
         <a href="javascript:void(0)" class="mapStyleBlock" >\
            <span class="layer-item-img">\
               <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
               <img src="https://www.mappls.com/images/maplayer/hy_map.png" alt="" class="mode-img" />\
            </span>\
            <p>Satellite </p>\
         </a></li>\
      </ul>\
        </li>'

  };


  return (
    <div>
      {isLoading ?

        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box> : ""}
      {playRoutesData && <div style={{ display: "flex", paddingTop: "12px" }}>
        <ArrowIcon className='arrow-icon' onClick={() => playTrailsBack()} sx={{ fontSize: "18px" }} />
        <Box className='line-point'>
          <FormLabel className='line'>Line</FormLabel>
          <FormControlLabel
            control={<IOSSwitch sx={{ m: 1 }}
              checked={linecheck}
              onChange={() => setLineCheck(!linecheck)}
              name="line"
            />}
            label="Point" className='Switchbtn'
          />
        </Box>
      </div>}
      <Box className="map-content play-trail-map">
        <div id="map" style={styleMap}></div>
        <>

          {playRoutesData && <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', position: 'relative', marginTop: '-200px' }}>
            <Card
              sx={{
                minHeight: 52,
                minWidth: 250,
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                marginRight: '10px',
                '& a': {
                  textDecoration: 'none',
                  color: 'inherit',
                },
              }}
            >
              <Box sx={{ padding: '10px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={4} sx={{ borderRight: '2px solid #eee' }}>

                    <Box sx={{ display: 'flex', flexDirection: 'row', padding: "10px" }}>
                      <Button
                        variant="contained" className='Card-Icons' sx={{
                          borderColor: 'blue', marginTop: '0!important', marginRight: '10px!important'
                        }}
                        startIcon={<TrainIcon sx={{ fill: "#252F3F !important;" }} />}></Button>
                      <Typography color="textSecondary" variant="h" component="h4" className='Card-Title'
                        sx={{
                          marginBottom: '15px',
                        }}>{playRoutesData?.deviceName}

                      </Typography>
                      <Button
                        variant="contained" className='Card-Icons two' sx={{
                          borderColor: 'blue', marginTop: '0!important', marginRight: '10px!important', marginLeft: '10px', border: 'none !important'
                        }}
                        startIcon={<GpsIcon sx={{ fill: "#4646F2 !important" }} />}></Button>
                    </Box>
                    <div className="left-playtrail">
                      <ul class="progress">

                        <li class="progress__item progress__item--completed">
                          <p class="progress__title">{Dateformat(playRoutesData?.startTime)}</p>
                          <p class="progress__info">{playRoutesData?.startAddress}</p>
                        </li>
                        <li class="progress__item progress__item--active">
                          <p class="progress__title">{Dateformat(playRoutesData?.endTime)}</p>
                          <p class="progress__info">{playRoutesData?.endAddress}</p>
                        </li>
                      </ul>

                      <Box className='trail-playback'>
                        <Box sx={{ display: 'flex', padding: "10px" }}>
                          <Typography color="textSecondary" variant="h" component="h4"
                            sx={{ fontSize: '14px', width: "46px" }}>Speed
                          </Typography>
                          <Typography color="textSecondary" variant="h" component="p"
                            sx={{
                              marginLeft: '53px'
                            }}>{playRoutesData?.maxSpeed}
                            <p style={{ fontSize: "12px", fontWeight: '500', margin: '0' }}>Km/h</p>
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', padding: "10px" }}>
                          <Typography color="textSecondary" variant="h" component="h4"
                            sx={{ fontSize: '14px', width: "46px" }}>Distance
                          </Typography>
                          <Typography color="textSecondary" variant="h" component="p"
                            sx={{
                              marginLeft: '53px'
                            }}>{(playRoutesData?.distance) !== undefined ? ((playRoutesData?.distance) / 1000).toFixed(2) : 0}
                            <p style={{ fontSize: "12px", fontWeight: '500', margin: '0' }}>Kms</p>
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', padding: "10px" }}>
                          <Typography color="textSecondary" variant="h" component="h4"
                            sx={{ fontSize: '14px', width: "46px" }}>Time
                          </Typography>
                          <Typography color="textSecondary" variant="h" component="p"
                            sx={{
                              marginLeft: '53px'
                            }}>{msToTime(playRoutesData?.duration)}
                            <p style={{ fontSize: "12px", fontWeight: '500', margin: '0' }}>Hrs mins</p>
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Box className="trail-linechart">
                      <Trailchart reportsRoute={reportsRoute} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>}</>
      </Box>
    </div>

  );
}
