import React, { useEffect, useState } from "react";
import {useRefresh} from 'react-admin';
import { useGetOne} from "react-admin";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import vtsDataProvider from './../providers/dataProvider';
import FormTextField from '@mui/material/TextField';
import SendNotification from "../utils/sendNotification";
import CloseIcon from '@mui/icons-material/HighlightOffOutlined';
import "../views/group/group.css";

let date = new Date();

const defaultValues = {
    id: 0,
    name: "",
    attributes: {},
    lastupdate : date,
    groupId: 0
};

export const EditGroup = ({ EditGroupopen, handleEditClose, listContext, EditGroupId }) => {
    const [formValues, setFormValues] = useState();
    const [notifyData, setNotifyData] = useState({});

    const refresh = useRefresh();
    const { data: EditData, isLoading, error } = useGetOne(
        'groupsdata',
        { id: EditGroupId },
        { enabled: (EditGroupId !== 0) }
    );

    useEffect(() => {
        const getEditGroup = () => {
            if (EditData) {
                setFormValues({
                    ...formValues,
                    id: EditGroupId,
                    name: EditData?.name,
                    createdon: EditData?.createdon,
                    lastupdate: date
                })
                EditGroupId = 0;
            }
        }
        getEditGroup();
    }, [EditData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });

    };

    const handleGroupSubmit = (event) => {
        formValues.lastupdate = new Date();
        vtsDataProvider.update('groupsdata', formValues, setNotifyData, "Group Updated Successfully", "Failed to Update Group");
        setFormValues(defaultValues)
        handleEditClose();
    }

  useEffect(()=>
  {
    if(notifyData?.severity === "success")
    {
      refresh();
    }
  },[notifyData])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (<>
        <Modal
            open={EditGroupopen}
            onClose={handleEditClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description" className="AddNewModal addgroup"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Edit Group
                </Typography>
                <span className="close-btn"><CloseIcon onClick={handleEditClose} /></span>
                <form onSubmit={handleGroupSubmit}>
                    <Box sx={{ flexGrow: 1, marginTop: '2em' }}>

                        <Grid container spacing={2}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={10}>
                                <Box sx={{ marginTop: '1em' }}>
                                    <FormControl>
                                        <FormLabel>Group Name</FormLabel>
                                        <FormTextField
                                            name="name"
                                            placeholder="Enter Group Name"
                                            type="text"
                                            value={formValues?.name}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={8}>
                                <div style={{ marginTop: '1em' }} className="modal-footer">
                                    <Button variant="contained" color="primary" type="submit">
                                        Update
                                    </Button>
                                    <Button onClick={handleEditClose} variant="contained" className="cancel-btn" color="primary" sx={{ marginRight: '1em' }}>
                                        Cancel
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Box>
        </Modal>
        <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
    </>
    );
}
