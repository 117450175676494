import { fetchUtils } from 'react-admin';
import { Base64 } from 'js-base64';
import { exists } from './validate';

 const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    else if(options.headers == 'password') options.headers = new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      })
    else options.headers = new Headers({ Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const token = localStorage.getItem('authToken');
    if(exists(token)){
        options.headers.set('Authorization', 'Token ' + token);
    }else {
    }
    
    return fetchUtils.fetchJson(url, options);
};



export default httpClient;