import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {SITE_URL} from './constants';


let isAuth = localStorage.getItem('authToken')
let userauth = localStorage.getItem('auth') 

if(isAuth && isAuth === 'undefined' || isAuth === null || userauth === null) 
window.location.href = SITE_URL + "/login"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
