import * as React from "react";
import DollarIcon from "@mui/icons-material/AttachMoney";
import { useListController, useTranslate } from "react-admin";

import CardWithIcon from "./CardwithIcon";

const StoppedVehicle = ({ overviewData }) => {
    
  const statusValues = overviewData ? overviewData[0] : null;
  const translate = useTranslate();

  return (
    <>
      <CardWithIcon
        to="/vehicles"
        title={translate(`Stopped Vehicle`)}
        subtitle={statusValues?.vehicle_stopped}
        status="Stopped"
        icon={DollarIcon}
        statusValues={statusValues}
      />
    </>
  );
};

export default StoppedVehicle;
