import React, { useState, useEffect } from "react";
import {
  Datagrid,
  TextField,
  useRecordContext,
  DateField,
  Button,
  useListController,
  ListContextProvider,
  TextInput,
  Pagination,
  ListToolbar,
  SET_FILTER,
} from "react-admin";
import moment from "moment";
import { Box, Typography, Card } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import { LinearProgress } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField2 from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import AuditRecords from "./AuditRecords";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexRow: { display: "flex", flexDirection: "row" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

export const AuditTrials = () => {
  const [componentValue, setComponent] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [filter, setFilter] = useState({
    search: "",
    from: "",
    to: "",
    component: "",
  });
  const compchoices = [
    { value: 1, label: "command" },
    { value: 2, label: "device" },
    { value: 3, label: "driver" },
    { value: 4, label: "event" },
    { value: 5, label: "geofence" },
    { value: 6, label: "group" },
    { value: 7, label: "maintenance" },
    { value: 8, label: "notification" },
    { value: 9, label: "order" },
    { value: 10, label: "permission" },
    { value: 11, label: "position" },
    { value: 12, label: "report" },
    { value: 13, label: "route" },
    { value: 14, label: "schedulereport" },
    { value: 15, label: "server" },
    { value: 16, label: "session" },
    { value: 17, label: "sos" },
    { value: 18, label: "trip" },
    { value: 19, label: "user" },
    { value: 20, label: "userrole" },
    { value: 21, label: "vehiclereg" },
  ];
  let todate = new Date();
  let fromdate = addDays(new Date(), -2);

  const [viewReport, setViewReport] = useState({
    data: {},
    expanded: false,
  });

  const [formError, setFormError] = useState({
    disabled: true,
  });

  const [dateRange, setDateRange] = React.useState([fromdate, todate]);
  const [startDate, endDate] = dateRange;
  const datePickerRef = React.useRef(null);

  const handleDatePicker = () => {
    const dp = datePickerRef.current;
    dp.setOpen(true);
  };

  const defaultValues = {
    search: "",
    component: null,
  };

  const [formValues, setFormValues] = useState(defaultValues);

  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  useEffect(() => {
    var queryvalue = document.querySelectorAll(".list-content");
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const formSearchSubmit = () => {
    let filterCopy = { ...filter };
    filterCopy["from"] = "";
    filterCopy["to"] = "";
    filterCopy["component"] = "";
    filterCopy["search"] = formValues.search;
    setFilter(filterCopy);
    formValues["component"] = "";
    setDateRange([null, null]);
    setComponent(null);
  };

  const formshowSubmit = (e) => {
    e.preventDefault();
    let filterCopy = { ...filter };
    filterCopy["from"] = new Date(convert(startDate)).toISOString();
    filterCopy["to"] = new Date(convert(endDate)).toISOString();
    filterCopy["component"] = componentValue?.label;
    filterCopy["search"] = null;
    formValues["search"] = "";

    setFormValues({ ...formValues, component: componentValue?.label });

    setViewReport({
      data: filterCopy,
      expanded: true,
    });
  };

  useEffect(() => {
    const fe = { ...formError };
    if (componentValue != null) {
      fe["disabled"] = false;
      setFormError(fe);
    }
    if (
      formValues?.component != null &&
      componentValue?.label != formValues?.component
    ) {
      setViewReport({
        data: [],
        expanded: true,
      });
    }
  }, [componentValue]);

  const matches = useMediaQuery("(min-width:1800px)");

  const perPage = 10;
  const sort = { field: "createdon", order: "DESC" };

  var date = new Date();
  var formatedDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  var StaringDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
  var placeholderDate = StaringDate + " - " + formatedDate;

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt =
      record[source] !== null
        ? moment(record[source]).format("DD/MM/YYYY, HH:mm:ss")
        : "";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Box style={styles.flex} className="list-search">
          <FormControl sx={{ width: "25ch" }}>
            <OutlinedInput
              placeholder="Search"
              value={formValues.search}
              name="search"
              onChange={handleInputChange}
            />
          </FormControl>
          <IconButton size="small" className="search-btn">
            <SearchIcon fontSize="inherit" onClick={formSearchSubmit} />
          </IconButton>
        </Box>

        <Box
          sx={{ display: "flex", flexDirection: "row", height: "100%" }}
          className="audit-trails-tab"
        >
          <Card
            sx={{
              minHeight: 52,
              minWidth: 250,
              display: "flex",
              width: "25%",
              height: "69.3vh",
              flexDirection: "column",
              "& a": {
                textDecoration: "none",
                color: "inherit",
              },
            }}
          >
            <Box
              sx={{
                overflow: "inherit",
                padding: "16px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "& .icon": {
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "inherit" : "#dc2440",
                },
              }}
            >
              <Box
                textAlign="left"
                className="audit-left audit-trail"
                sx={{ height: "300px" }}
              >
                <Typography
                  color="textSecondary"
                  variant="h"
                  component="h4"
                  className="Card-Title"
                  sx={{
                    marginBottom: "15px",
                  }}
                >
                  Audit Trails
                </Typography>
                <Box sx={{ height: "380px" }}>
                  <Box className="date-range" sx={{ marginTop: "1em" }}>
                    <FormControl>
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          setDateRange(update);
                        }}
                        placeholderText={placeholderDate}
                        isClearable={true}
                        ref={datePickerRef}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                      />
                      <IconButton
                        className="cal-icon"
                        onClick={handleDatePicker}
                      >
                        <CalendarIcon />
                      </IconButton>
                    </FormControl>
                  </Box>
                  <Box sx={{ marginTop: "1em" }} className="select-component">
                    <FormLabel>Select Component</FormLabel>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      disablePortal
                      id="component"
                      name="component"
                      options={compchoices}
                      renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField2
                          {...params}
                          label="Select..."
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        setComponent(newValue);
                      }}
                      value={componentValue}
                    />
                  </Box>
                  <Box sx={{ marginTop: "1em" }}>
                    <Button
                      type="submit"
                      className={`${
                        formError["disabled"] ? "disable" : ""
                      } show-btn`}
                      disabled={formError["disabled"]}
                      onClick={(e) => formshowSubmit(e)}
                    >
                      Show
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
          {viewReport?.expanded && (
            <AuditRecords
              viewReport={viewReport?.data}
              setViewReport={setViewReport}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
