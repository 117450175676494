import "./App.css";
import * as React from "react";
import { Admin, CustomRoutes, Resource, combineDataProviders } from "react-admin";
import { Route } from 'react-router';
import { UserList } from "./components/UsersList";
import { VehicleList } from "./views/vehicles/vehicles";
import { ReportList } from "./views/reports/reports";
import { PanicDetails, PanicList } from "./views/panic/panic";
import authProvider from "./providers/authProvider";
import vtsDataProvider from "./providers/dataProvider";
import { UserroleList } from "./components/UserRoleList";

import Layout from "./views/layout/Layout";
import Login from "./views/layout/Login";
import ForgotPassword from "./views/layout/ForgotPassword";
import Otpverfication from "./views/layout/Otpverfication";
import ResetPassword from "./views/layout/ResetPassword";
import NewRegister from "./views/layout/NewRegister";
import SearchVehicle from "./views/layout/SearchVehicle";
import { GeofencesList } from "./views/geofence/geofence";
import { TrailsList } from "./views/trails/trails";
import { GroupsList } from "./views/group/group";
import { RouteModule } from "./views/route/route";
import { AlarmList } from "./views/alarm/alarm";
import { Dashboard } from "./views/overview/overview";
import { UsersDetails } from "./views/users/users";
import { EditUserRole } from "./components/EditUserRole";
import { PanicSOSList } from "./components/PanicSOSList";
import { PanicChart } from "./components/PanicChart";
import AlarmLogList from "./components/AlarmLogList";
import { AlarmShow } from "./components/AlarmConfigurationList";
import { useNavigate } from "react-router-dom";
import { TitleContextProvider } from "./utils/TitleContext";
import ReportsVehicle from "./components/ReportsVehicle";
import ReportsGroup from "./components/ReportsGroup";
import ReportsSchedule from "./components/ReportsSchedule";
import PanicStatus from "./views/overview/panicStatus";
import { AuditTrials } from "./views/group/auditList";
import reportProvider from "./providers/reportProvider";
import ControlPanelList from "./views/cpanel/ControlPanelList";
import { RouteAssignList } from "./components/RouteAssignList";
import { GroupContextProvider } from "./utils/GroupContext";
import { StatisticContextProvider } from "./utils/statisticContext";
import { Account } from "./views/layout/Account";
import { Notification } from "./views/layout/Notification";
import Otpverficationregister from "./views/layout/Otpverficationregister";
import { VehicleDetails } from "./views/layout/VehicleDetails"
import Vehiclemapshare from "./views/layout/vehiclemapshare"
import NotFound from './components/PageNotAvailable';

//We may need multiple data providers
const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case "users":
    case "vehicles":
    case "reports":
    case "trails":
    case "panic":
    case "alarm":
    case "userrole":
    case "geofences":
    case "panicsos":
    case "alarmlog":
    case "alarmtype":
    case "alarmnotificators":
    case "reportsschedule":
    case "groupsdata":
    case "vehicledata":
    case "overview":
    case "panicstatus":
    case "alarmstatus":
    case "groups":
    case "deviceinfovahan":
    case "audittrails":
    case "otpsend":
    case "devicedownload":
    case "communicationstatus":
    case "controlpanel":
    case "cpanelpermissions":
    case "vehiclesummary":
    case "trailsreport":
    case "devices":
    case "route":
    case "routeassign":
    case "geofencesdrop":
    case "stoptype":
    case "routeaddress":
    case "routedropdown":
    case "notifypopup":
    case "account":
    case "notification":
    case "state":
    case "vehiclemake":
    case "rtocode":
    case "simprovider":
    case "vehiclecategory":
    case "simdata":
    case "ongoingtrips":
    case "roledrop":
    case "makedrop":
    case "groupslist":
      return vtsDataProvider;

    case "reporttypes":
    case "reportsvehicle":
    case "reportsgroup":
    case "reportsexport":
    case "miscellaneous":
      return reportProvider;
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});

const App = () => (
  <StatisticContextProvider>
    <GroupContextProvider>
      <TitleContextProvider>
        <Admin
          dataProvider={dataProvider}
          authProvider={authProvider}
          loginPage={Login}
          layout={Layout}
          dashboard={Dashboard}
        >
          <CustomRoutes noLayout>
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/otp-verfication" element={<Otpverfication />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/new-register" element={<NewRegister />} />
            <Route exact path="/search-vehicle" element={<SearchVehicle />} />
            <Route exact path="/VehicleDetails" element={<VehicleDetails />} />
            <Route exact path="/vehiclemapshare" element={<Vehiclemapshare />} />
            <Route exact path="/otp-verfication-register" element={<Otpverficationregister />} />
          </CustomRoutes>

          <Resource name="overview" list={Dashboard} />
          <Resource
            name="UsersDetails"
            list={UsersDetails}
            edit={EditUserRole}
          />
          <Resource name="PanicDetails" list={PanicDetails} />
          <Resource name="reports" list={ReportList} />
          <Resource name="users" list={UserList} />
          <Resource name="vehicles" list={VehicleList} />
          <Resource name="trails" list={TrailsList} />
          <Resource name="groups" list={GroupsList} />
          <Resource name="deviceinfovahan" />
          <Resource name="geofences" list={GeofencesList} />
          <Resource name="panic" list={PanicChart} />
          <Resource name="route" list={RouteModule} />
          <Resource name="alarm" list={AlarmList} show={AlarmShow} />
          <Resource name="alarmlog" list={AlarmLogList} />
          <Resource name="userrole" list={UserroleList} />
          <Resource name="panicsos" list={PanicSOSList} />
          <Resource name="alarmtype" />
          <Resource name="alarmnotificators" />
          <Resource name="reportsvehicle" />
          <Resource name="reportsgroup" />
          <Resource name="reportsschedule" list={ReportsSchedule} />
          <Resource name="groupsdata" />
          <Resource name="groupslist" />

          <Resource name="vehicledata" />
          <Resource name="panicstatus" list={PanicStatus} />
          <Resource name="alarmstatus" />
          <Resource name="reporttypes" />
          <Resource name="audittrails" list={AuditTrials} />
          <Resource name="otpsend" />
          <Resource name="devicedownload" />
          <Resource name="devices" />
          <Resource name="routeassign" list={RouteAssignList} />

          <Resource name="communicationstatus" />
          <Resource name="reportsexport" />
          <Resource name="controlpanel" list={ControlPanelList} />
          <Resource name="cpanelpermissions" />
          <Resource name="vehiclesummary" />
          <Resource name="trailsreport" />
          <Resource name="geofencesdrop" />
          <Resource name="stoptype" />
          <Resource name="routeaddress" />
          <Resource name="routedropdown" />
          <Resource name="notifypopup" />
          <Resource name="vehiclemake" />
          <Resource name="state" />
          <Resource name="rtocode" />
          <Resource name="simprovider" />
          <Resource name="vehiclecategory" />
          <Resource name="simdata" />
          <Resource name="ongoingtrips" />
          <Resource name="makedrop" />
          <Resource name="account" list={Account} />
          <Resource name="notification" list={Notification} />
          <Resource name="roledrop" />
          <Resource name="miscellaneous" />
          <CustomRoutes>
            <Route path="*" element={<NotFound />} />
          </CustomRoutes>
        </Admin>
      </TitleContextProvider>
    </GroupContextProvider>
  </StatisticContextProvider>
);

export default App;
