import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import ModalCloseIcon from "@mui/icons-material/HighlightOffOutlined";
import CheckIcon from '@mui/icons-material/TaskAltOutlined';
import TimeIcon from '@mui/icons-material/ScheduleOutlined';
import { Tabs, Tab, Box, Typography, Card } from "@mui/material";
import useNotification from '../hooks/useNotification';
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const Vehiclecommand = ({ responseStatus, handleCommentClose }) => {
  const [notifimessage, setNotiMessage] = useState("Waiting for respose from device");
  const [notificationerr, notification, setNotification, setSocketUrl] = useNotification();

  useEffect(() => {
    if (notification?.length > 0) {
      if (notification[0].deviceId === responseStatus.deviceId && notification[0]?.type === "commandResult") {
        setNotiMessage("Response received")
      }
    }
  }, [notification])

  const get12hoursformat = (date1) => {
    var date = new Date(date1);
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? "PM" : "AM";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var time = hours + ":" + minutes + ":" + seconds + " " + am_pm;
    return time;
  }

  return (
    <>
      <Modal
        open={responseStatus?.open}
        onClose={handleCommentClose}
        className="command-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-box comment-modal-box">
          <span className="close-btn" onClick={handleCommentClose}>
            <ModalCloseIcon />
          </span>
          <Box className="succ-comment" >
            <Box className="successIcon">
              <CheckIcon />
            </Box>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {responseStatus?.status}
            </Typography>
            <Typography variant="h6" component="p" className="comment-p">
              {responseStatus?.msg}
            </Typography>
            <Box sx={{ width: "100%", overflowX: "auto", paddingBottom: "10px" }}>
              <Typography variant="h6" component="p" className="waiting-p">
                {notifimessage === "Waiting for respose from device" ? <TimeIcon /> : ""}{notifimessage}</Typography>
              {
                notification?.length > 0 ?
                  notification[0].deviceId === responseStatus.deviceId && notification[0]?.type === "commandResult" ?
                    <>
                      <p class="comment-response">Vehicle RegNo : {notification[0]?.attributes?.vehicleRegNo}</p>
                      <p class="comment-response">Result : {notification[0]?.attributes?.result}</p>
                      <p class="comment-response">DeviceIMEI : {notification[0]?.attributes?.deviceIMEI}</p>
                    </>
                    : ""
                  : ""
              }
            </Box>
          </Box>
        </Box>
      </Modal></>
  )
}
