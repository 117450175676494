import React, { useState, useEffect } from 'react';
import { useRefresh, List, useGetList, Datagrid, useGetOne, DateTimeInput, ListContextProvider, TextInput, Pagination, ListToolbar, TopToolbar, Button } from "react-admin";
import { Tabs, Tab, Box, Typography } from '@mui/material';
import {
    Grid,
    Card
} from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ViewIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import "../views/route/route.css";
import AddButton from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import RouteIcon from '@mui/icons-material/RouteOutlined';
import ArrowIcon from '@mui/icons-material/ArrowBackOutlined';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import SelectedList from './../components/Accordian';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableRowsIcon from '@mui/icons-material/TableRowsOutlined';
import DistanceIcon from '@mui/icons-material/RouteOutlined';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';
import PointTypeIcon from '@mui/icons-material/PinDropOutlined';
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import CreateRouteMap from './../components/CreateRouteMap'
import vtsDataProvider, { getRouteAddress, getRoutelinePoints, getVehicleLocation } from './../providers/dataProvider';
import moment from 'moment';
import SendNotification from "../utils/sendNotification";


const errorstyle = {
    color: "red",
    fontSize: "12px",
};

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    flexRow: { display: 'flex', flexDirection: 'row' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const defaultValues = {
    routeId: "",
    deviceId: "",
    initialOdometer: "",
    closingOdometer: "",
    remarks: "",
    closingType: ""
}

export const RouteAssign = ({ setAssignExpand, RAssignId }) => {
    const [notifyData, setNotifyData] = useState({});
    const [devices, setDevices] = React.useState([]);
    const [DeviceValue, setDeviceValue] = useState(null);
    const [RouteValue, setRouteValue] = useState(null);
    const [closureType, setClosureType] = useState(null);
    const [EditRAssignId, setRAssignId] = useState(RAssignId)
    const [routes, setRoutes] = useState([]);
    const [droproutes, setdroproutes] = useState([]);
    const [formValues, setFormValues] = useState(defaultValues);
    const [open, setOpen] = useState(false);
    const loading = open && devices?.length === 0;
    const [Ropen, setROpen] = useState(false);
    const Rloading = Ropen && droproutes?.length === 0;
    const [legDetails, setlegDetails] = useState([])
    const [RAssignDetails, setRAssignDetails] = useState({});
    const [EditRAssignDetails, setEditRAssignDetails] = useState({});
    const filterClosureType = { filter: { type: "ClosureTypeField" } }
    const [formErrors, setFormError] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const refresh = useRefresh();
    const { data: devicelist } = useGetList('devices');
    const { data: routelist } = useGetList('routedropdown');
    const { data: closureTypelist } = useGetList('stoptype', filterClosureType);
    const { data: EditRAssign, isLoading, error } = useGetOne(
        'routeassign',
        { id: EditRAssignId },
        { enabled: (EditRAssignId !== 0) }
    );

    useEffect(() => {
        const getRAssignDetails = () => {
            if (EditRAssign) {
                setFormValues({
                    routeId: EditRAssign?.trip?.routeId,
                    deviceId: EditRAssign?.trip?.deviceId,
                    initialOdometer: EditRAssign?.trip?.initialOdometer,
                    closingOdometer: EditRAssign?.trip?.closingOdometer,
                    remarks: EditRAssign?.trip?.remarks,
                    closingType: EditRAssign?.trip?.closingType
                })

                setDeviceValue(
                    {
                        label: (EditRAssign?.device?.id).toString() + " - " + (EditRAssign?.device?.name).toString(),
                        value: EditRAssign?.device?.id,
                        showlabel: EditRAssign?.device?.name
                    })
                setRouteValue(
                    {
                        label: (EditRAssign?.route?.id).toString() + " - " + (EditRAssign?.route?.name).toString(),
                        value: EditRAssign?.route?.id,
                        showlabel: EditRAssign?.route?.name
                    })
            }
        }
        getRAssignDetails();
    }, [EditRAssign, devicelist, routelist, closureTypelist])


    useEffect(() => {
        if (devicelist) {
            const list = formatDevices(devicelist);
            setDevices(list);
        }
    }, [loading]);

    useEffect(() => {
        if (routelist) {
            var value = routelist.filter(function (item) {
                return item.numStoppage > 0
            })
            const list = formatDevices(value);
            setdroproutes(list);
        }
    }, [Rloading]);


    useEffect(() => {
        if (routelist) {
            setRoutes(routelist);
        }
        if (closureTypelist) {
            setClosureType(closureTypelist[0]);
        }
    }, [routelist, closureTypelist])

    const formatDevices = (list) => {
        var te = list?.map((data) => ({
            label: (data?.id).toString() + " - " + data?.name.toString(),
            value: data?.id,
            showlabel: data?.name,
        }));
        return te;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues
            ({
                ...formValues,
                [name]: value,
            })
    }

    useEffect(() => {

        let selectedroute = routes.find((route) => route.id === RouteValue?.value);

        if (selectedroute) {
            let legs = [];
            let legsSub = [];
            selectedroute.legs.map(async (legpoint, index) => {

                let details = {}
                details = {
                    name: legpoint.nameTo,
                    latitude: legpoint.latitudeTo * 1,
                    longitude: legpoint.longitudeTo * 1,
                    radius: legpoint.radiusTo * 1,
                    address: "",
                    distance: legpoint.estDistanceFromOrigin,
                    plandate: moment(addtimeslot(legpoint.estDurationFromOrigin)).format("DD MMM YYYY hh:mm:ss"),
                    userindex: legpoint.routeIndex,
                    subRoutePoints: selectedroute
                };

                legs.push(details)


                const filter = { filter: { details } }
                await getRouteAddress("routeaddress", filter, legs, index)

                if (index === 0) {
                    let pointsobj = {}
                    let address = {};
                    const record = { latitude: legpoint.latitudeFrom * 1, longitude: legpoint.longitudeFrom * 1 }
                    await getVehicleLocation(record, address);


                    pointsobj = {
                        name: legpoint.nameFrom,
                        latitude: legpoint.latitudeFrom * 1,
                        longitude: legpoint.longitudeFrom * 1,
                        radius: legpoint.radiusFrom * 1,
                        address: address?.address,
                        distance: legpoint.estDistanceFromOrigin,
                        plandate: moment(new Date()).format("DD MMM YYYY hh:mm:ss"),
                        userindex: legpoint.routeIndex,
                        subRoutePoints: {}
                    };

                    legsSub.push(pointsobj)


                }
            })
            const timer = setTimeout(() => {
                // setlegSubDetails(legsSub)
                setlegDetails(legsSub.concat(legs))
            }, 2000);
        }
        else { setlegDetails([]) }
    }, [RouteValue])


    const addtimeslot = (time) => {
        let arrtime = time.split(":")
        var curDate = new Date();
        curDate.setHours(curDate.getHours() + (arrtime[0] * 1))
        curDate.setMinutes(curDate.getMinutes() + (arrtime[1] * 1))
        curDate.setSeconds(curDate.getSeconds() + (arrtime[2] * 1))
        return curDate;
    }

    const resetForm = () => {
        setFormValues(defaultValues);
        setDeviceValue(null);
        setRouteValue(null);
    }

    const validate = (values) => {
        const errors = {};
        let regexnumber = /\+?\d+/;
        if (!RouteValue) errors.route = "Select Route!";
        if (!DeviceValue) errors.device = "Select Device!";
        if (values?.initialOdometer !== "") {
            if (!String(values?.initialOdometer).match(regexnumber))
                errors.initialOdometer = "Numbers only allowed!";
        }
        if (values?.closingOdometer !== "") {
            if (!String(values?.closingOdometer).match(regexnumber))
                errors.closingOdometer = "Numbers only allowed!";
        }
        return errors;
    };


    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            if (EditRAssignId > 0) {
                setEditRAssignDetails(
                    {
                        id: EditRAssignId,
                        routeId: RouteValue.value,
                        deviceId: DeviceValue.value,
                        initialOdometer: formValues.initialOdometer,
                        closingOdometer: formValues.closingOdometer,
                        remarks: formValues.remarks,
                        closingType: formValues.closingType
                    }
                )
            } else {
                setRAssignDetails(
                    {
                        routeId: RouteValue.value,
                        deviceId: DeviceValue.value,
                        initialOdometer: formValues.initialOdometer,
                        closingOdometer: formValues.closingOdometer,
                        remarks: formValues.remarks,
                        closingType: formValues.closingType
                    }
                )
            }

        }
    }, [formErrors])

    const handleRAssignSubmit = (event) => {
        event.preventDefault();
        setFormError(validate(formValues));
        setIsSubmit(true);
    };

    useEffect(() => {
        if (EditRAssignDetails.routeId !== "" && typeof (EditRAssignDetails.routeId) !== "undefined") {
            vtsDataProvider.update('routeassign', EditRAssignDetails, setNotifyData, "Route Assign Updated Successfully", "Failed to Update Route Assign");
            setFormValues(defaultValues)
            setEditRAssignDetails(defaultValues)
            setRAssignId(0)
            const timer = setTimeout(() => {
                setAssignExpand(false);
            }, 5000);

        }
    }, [EditRAssignDetails])

    useEffect(() => {
        if (RAssignDetails.routeId !== "" && typeof (RAssignDetails.routeId) !== "undefined") {
            vtsDataProvider.create('routeassign', RAssignDetails, setNotifyData, "Route Assign Successfully", "Failed to Assign Route");
            setFormValues(defaultValues)
            setRAssignDetails(defaultValues)
            const timer = setTimeout(() => {
                setAssignExpand(false);
            }, 5000);

        }
    }, [RAssignDetails])

    useEffect(() => {
        if (notifyData?.severity === "success") {
            refresh();
        }
    }, [notifyData])

    return (
        <>
            <div style={{ display: "flex" }} className="backtopage" onClick={() => { setRAssignId(0); setAssignExpand(false); }}>
                <ArrowIcon className='arrow-icon' />
                <Typography color="textSecondary" variant="h" component="h4" className='Card-Title'>Back to Route Assign
                </Typography>
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }} className="route-list-sec">
                <Card
                    sx={{
                        minHeight: 52,
                        minWidth: 250,
                        display: 'flex',
                        width: '25%',
                        height: '73vh',
                        overflowY: 'auto',
                        flexDirection: 'column',
                        '& a': {
                            textDecoration: 'none',
                            color: 'inherit',
                        },
                    }}
                    className="first-card"
                >

                    <form onSubmit={handleRAssignSubmit}>
                        <Box textAlign="left" className='audit-left'>
                            <Typography color="textSecondary" variant="h" component="h4" className='Card-Title'
                                sx={{
                                    marginBottom: '15px'
                                }}>Assign new Route
                            </Typography>
                            <Box sx={{ marginTop: '1em' }} style={styles.flexColumn}>
                                <FormControl sx={{ width: "100%" }}>
                                    <FormLabel>Route</FormLabel>
                                    <Autocomplete
                                        // disablePortal
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        id="routeId"
                                        name="routeId"
                                        className='auto-select'
                                        options={droproutes}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.value}>
                                                {option.showlabel}
                                            </li>
                                        )}
                                        required
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Route"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {Rloading ? (
                                                                <CircularProgress color="inherit" size={20} />
                                                            ) : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                        open={Ropen}
                                        onOpen={() => {
                                            setROpen(true);
                                        }}
                                        onClose={() => {
                                            setROpen(false);
                                        }}
                                        loading={Rloading}
                                        getOptionLabel={(option) => option.showlabel}
                                        onChange={(event, newValue) => {
                                            setRouteValue(newValue);
                                        }}
                                        value={RouteValue}
                                    />

                                    <div style={errorstyle}>
                                        {formErrors.route}
                                    </div>
                                </FormControl>
                            </Box>
                            <Box sx={{ marginTop: '1em' }}>
                                <FormLabel>Vehicle</FormLabel>
                                <Autocomplete
                                    isOptionEqualToValue={(option, value) =>
                                        option.value === value.value
                                    }
                                    id="deviceId"
                                    name="deviceId"
                                    className='auto-select'
                                    options={devices}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.value}>
                                            {option.showlabel}
                                        </li>
                                    )}
                                    required
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Device"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? (
                                                            <CircularProgress color="inherit" size={20} />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                    open={open}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    loading={loading}
                                    getOptionLabel={(option) => option.showlabel}
                                    onChange={(event, newValue) => {
                                        setDeviceValue(newValue);
                                    }}
                                    value={DeviceValue}
                                />
                                <div style={errorstyle}>
                                    {formErrors.device}
                                </div>
                            </Box>
                            <Box sx={{ marginTop: '1em' }}>
                                <FormControl>
                                    <FormLabel>Initial Odometer</FormLabel>
                                    <TextField
                                        name="initialOdometer"
                                        placeholder="Enter"
                                        type="text"
                                        value={formValues.initialOdometer}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                                <div style={errorstyle}>
                                    {formErrors.initialOdometer}
                                </div>
                            </Box>
                            <Box sx={{ marginTop: '1em' }}>
                                <FormControl>
                                    <FormLabel>Closing Odometer</FormLabel>
                                    <TextField
                                        name="closingOdometer"
                                        placeholder="Enter"
                                        type="text"
                                        value={formValues.closingOdometer}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                                <div style={errorstyle}>
                                    {formErrors.closingOdometer}
                                </div>
                            </Box>
                            <Box sx={{ marginTop: '1em' }}>
                                <FormControl>
                                    <FormLabel>Remarks</FormLabel>
                                    <TextareaAutosize
                                        name="remarks"
                                        aria-label="empty textarea"
                                        style={{
                                            background: "#f0f0ff",
                                            height: "150px",
                                        }}
                                        value={formValues.remarks}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ marginTop: '1em' }}>
                                <FormControl>
                                    <FormLabel>Closure Type Field</FormLabel>
                                    <Select sx={{ background: "#f0f0ff" }}
                                        placeholder="Select"
                                        name="closingType"
                                        value={formValues.closingType}
                                        onChange={handleInputChange}
                                    >
                                        {closureType ?
                                            closureType?.configurations ?
                                                Object.values(closureType?.configurations).map((closureType, index) => (
                                                    <MenuItem key={index} value={closureType}>
                                                        {closureType}
                                                    </MenuItem>
                                                )) : "" : ""
                                        }
                                    </Select>
                                </FormControl>
                            </Box>

                            {
                                legDetails.length > 0 ?
                                    legDetails.map((legs, index) => {
                                        let strclass = "borderLeft:'1.4px dotted #252F40',paddingLeft:'18px'";
                                        index === 0 ? strclass = "borderLeft:'1.4px dotted #252F40',paddingLeft:'18px'"
                                            : strclass = "paddingLeft:'18px'"

                                        return (
                                            <><Box className="accordian-class route-assign-acc">
                                                <AccordionSummary
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography sx={{ flexShrink: 0 }} className="rounded-num">
                                                        {(index * 1) + 1}
                                                    </Typography>
                                                    <Typography variant="h" component="h4" sx={{ color: 'text.secondary' }}>{legs.name}</Typography>

                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box sx={{ strclass }}>
                                                        <Typography>
                                                            {legs.address}
                                                        </Typography>

                                                        <Box sx={{ marginTop: '1.3rem', flexGrow: 1 }}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6}>
                                                                    <DistanceIcon />
                                                                    <Typography variant="h6" component="h4">
                                                                        Distance(Km)
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TimeIcon />
                                                                    <Typography variant="h6" component="h4">
                                                                        Planned Time
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <FormLabel>{legs.distance}</FormLabel>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <FormLabel>{legs.plandate}</FormLabel>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </AccordionDetails>
                                            </Box></>

                                        )
                                    }) : ""
                            }
                            <Box sx={{ flexGrow: 1, marginTop: '1em' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Button onClick={resetForm} className='refreshbtn' sx={{ textTransform: "capitalize", bottom: "0 !important" }}>
                                            Reset
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>

                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button type="submit" className='show-btn'>
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Box>
                    </form>
                    <SendNotification data={notifyData} onClose={() => setNotifyData({})} />

                </Card>
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: '73vh',
                        marginBottom: '25px',
                        marginLeft: '10px',
                        '& a': {
                            textDecoration: 'none',
                            color: 'inherit',
                        },
                    }}
                    className="first-card"
                >
                    <CreateRouteMap legDetails={legDetails} />
                </Card>
            </Box>
        </>


    );
}
