import React, { useEffect, useState } from "react";
import { usePermissions } from "react-admin";
import { SITE_URL } from "../constants";

export default function useTabPermissions(resName) {

  const auth = usePermissions();

  let isAuth = localStorage.getItem('authToken')
  let userauth = localStorage.getItem('auth') 


  if (!isAuth && isAuth === 'undefined' || isAuth === null || userauth === null){
    window.location.href = SITE_URL + "/login";
  }

  if (!auth?.isLoading) {

    const readpervalue = "4,12,15,6,5,14,13,7";
    const importvalue = "12,14,13,15"
    let permObj = {};
    const tabPermissions = auth?.permissions?.user_role?.roles?.view;
    const ReadPermissions = auth?.permissions?.user_role?.roles?.permission;
    const reportPermissions = auth?.permissions?.user_role?.roles?.report;

    switch (resName) {
      case "trails":
        if (tabPermissions["trails"] == 1) {
          permObj = {
            ...permObj,
            viewTrails: true,
            readTrails: true,
          };
        }
        if (tabPermissions["trip_trail"] == 1) {
          permObj = {
            ...permObj,
            viewTripTrails: true,
            readTripTrails: true,
          };
        }
        return permObj;

      case "vehicles":
        if (tabPermissions["vehicle_list"] == 1) {
          permObj = {
            ...permObj,
            viewVehicleList: true,
          };
        }
        if (tabPermissions["vehicle_map"] == 1) {
          permObj = {
            ...permObj,
            viewVehicleMap: true,
          };
        }
        return permObj;

      case "users":
        if (tabPermissions["user"] == 1) {
          permObj = {
            ...permObj,
            viewUser: true,
          };
        }
        if (tabPermissions["role"] == 1) {
          permObj = {
            ...permObj,
            viewUserRole: true,
          };
        }
        if (ReadPermissions["user"] === 0) {
          permObj = {
            ...permObj,
            readUser: false,
          };
        }
        if (ReadPermissions["user"] === 4) {
          permObj = {
            ...permObj,
            readUser: true,
            viewUser: true,
          };
        }

        if (ReadPermissions["user"] === 12) {
          permObj = {
            ...permObj,
            createUser: true,
            readUser: true,
            viewUser: true,
          };
        }
        if (ReadPermissions["user"] === 6) {
          permObj = {
            ...permObj,
            updateUser: true,
            readUser: true,
            viewUser: true,
          };
        }
        if (ReadPermissions["user"] === 5) {
          permObj = {
            ...permObj,
            deleteUser: true,
            readUser: true,
            viewUser: true,
          };
        }
        if (ReadPermissions["user"] === 14) {
          permObj = {
            ...permObj,
            createUser: true,
            updateUser: true,
            readUser: true,
            viewUser: true,
          };
        }
        if (ReadPermissions["user"] === 15) {
          permObj = {
            ...permObj,
            createUser: true,
            updateUser: true,
            deleteUser: true,
            readUser: true,
            viewUser: true,
          };
        }
        if (ReadPermissions["user"] === 13) {
          permObj = {
            ...permObj,
            createUser: true,
            deleteUser: true,
            readUser: true,
            viewUser: true,
          };
        }
        if (ReadPermissions["user"] === 7) {
          permObj = {
            ...permObj,
            updateUser: true,
            deleteUser: true,
            readUser: true,
            viewUser: true,
          };
        }
        if (ReadPermissions["role"] === 0) {
          permObj = {
            ...permObj,
            readUserRole: false,
          };
        }
        if (ReadPermissions["role"] === 4) {
          permObj = {
            ...permObj,
            readUserRole: true,
            viewUserRole: true,
          };
        }
        if (ReadPermissions["role"] === 12) {
          permObj = {
            ...permObj,
            createUserRole: true,
            readUserRole: true,
            viewUserRole: true,
          };
        }
        if (ReadPermissions["role"] === 6) {
          permObj = {
            ...permObj,
            updateUserRole: true,
            readUserRole: true,
            viewUserRole: true,
          };
        }
        if (ReadPermissions["role"] === 5) {
          permObj = {
            ...permObj,
            deleteUserRole: true,
            readUserRole: true,
            viewUserRole: true,
          };
        }
        if (ReadPermissions["role"] === 14) {
          permObj = {
            ...permObj,
            createUserRole: true,
            updateUserRole: true,
            readUserRole: true,
            viewUserRole: true,
          };
        }
        if (ReadPermissions["role"] === 15) {
          permObj = {
            ...permObj,
            createUserRole: true,
            updateUserRole: true,
            deleteUserRole: true,
            readUserRole: true,
            viewUserRole: true,
          };
        }
        if (ReadPermissions["role"] === 13) {
          permObj = {
            ...permObj,
            createUserRole: true,
            deleteUserRole: true,
            readUserRole: true,
            viewUserRole: true,
          };
        }
        if (ReadPermissions["role"] === 7) {
          permObj = {
            ...permObj,
            updateUserRole: true,
            deleteUserRole: true,
            readUserRole: true,
            viewUserRole: true,
          };
        }
        return permObj;

      case "panic":
        if (tabPermissions["panic_status"] == 1) {
          permObj = {
            ...permObj,
            viewPanic: true,
            readPanic: true,
          };
        }
        if (tabPermissions["panic_sos"] == 1) {
          permObj = {
            ...permObj,
            viewPanicSOS: true,
          };
        }
        if (ReadPermissions["sos"] === 0) {
          permObj = {
            ...permObj,
            readPanicSOS: false,
          };
        }
        if (readpervalue.includes(ReadPermissions["sos"])) {
          permObj = {
            ...permObj,
            readPanicSOS: true,
            viewPanicSOS: true,
          };
        }
        return permObj;

      case "reports":
        if (tabPermissions["by_vehicle"] == 1) {
          permObj = {
            ...permObj,
            viewReportVehicle: true,
          };
        }
        if (tabPermissions["by_group"] == 1) {
          permObj = {
            ...permObj,
            viewReportGroup: true,
          };
        }
        if (tabPermissions["schedule"] == 1) {
          permObj = {
            ...permObj,
            viewReportSchedule: true,
          };
        }
        if (tabPermissions["audit_trail"] == 1) {
          permObj = {
            ...permObj,
            viewReportTrails: true,
          };
        }

        if (ReadPermissions["schedule"] === 0) {
          permObj = {
            ...permObj,
            readReportSchedule: false,
          };
        }
        if (readpervalue.includes(ReadPermissions["schedule"])) {
          permObj = {
            ...permObj,
            readReportSchedule: true,
            viewReportSchedule: true,
          };
        }
        if (ReadPermissions["audit_trail"] === 0) {
          permObj = {
            ...permObj,
            readReportTrails: false,
          };
        }
        if (readpervalue.includes(ReadPermissions["audit_trail"])) {
          permObj = {
            ...permObj,
            readReportTrails: true,
            viewReportTrails: true,
          };
        }
        if (readpervalue.includes(reportPermissions["geofence"])) {
          permObj = {
            ...permObj,
           misgeofencereport: true,
          };
        }
        if (readpervalue.includes(reportPermissions["panic"])) {
          permObj = {
            ...permObj,
           mispanicreport: true,
          };
        }
        if (readpervalue.includes(reportPermissions["reportedPermitViolation"])) {
          permObj = {
            ...permObj,
           misrepvioreport: true,
          };
        }
        if (readpervalue.includes(reportPermissions["generatedPanicViolation"])) {
          permObj = {
            ...permObj,
           misgenpanreport: true,
          };
        }
        if (readpervalue.includes(reportPermissions["deviceStatistics"])) {
          permObj = {
            ...permObj,
           misdevstareport: true,
          };
        }
        if (readpervalue.includes(reportPermissions["deviceHistoryLogs"])) {
          permObj = {
            ...permObj,
           misdevhisreport: true,
          };
        }
        if (readpervalue.includes(reportPermissions["vltdOem"])) {
          permObj = {
            ...permObj,
           misvltdOemreport: true,
          };
        }

        return permObj;

        case "groups":
          if (tabPermissions["entity"] == 1){
            permObj = {
              ...permObj,
              viewEntity: true
            };
          }
        if (tabPermissions["group"] == 1){
          permObj = {
            ...permObj,
            viewGroup: true 
          };
        }
        if (ReadPermissions["device"] === 0){
          permObj = {
            ...permObj,
            readEntity: false
          };
        }
        if (ReadPermissions["device"] === 4){
          permObj = {
            ...permObj,
            readEntity: true,
            viewEntity: true
          };
        }
        if (ReadPermissions["device"] === 12){
          permObj = {
            ...permObj,
            createEntity: true, 
            readEntity: true,
            viewEntity: true
          };
        }
        if (ReadPermissions["device"] === 6){
          permObj = {
            ...permObj,
            updateEntity: true, 
            readEntity: true,
            viewEntity: true
          };
        }
        if (ReadPermissions["device"] === 5){
          permObj = {
            ...permObj,
            deleteEntity: true, 
            readEntity: true,
            viewEntity: true
          };
        }
        if (ReadPermissions["device"] === 14){
          permObj = {
            ...permObj,
            createEntity: true, 
            updateEntity: true, 
            readEntity: true,
            viewEntity: true
          };
        }
        if (ReadPermissions["device"] === 15){
          permObj = {
            ...permObj,
            createEntity: true, 
            updateEntity: true, 
            deleteEntity: true, 
            readEntity: true,
            viewEntity: true
          };
        }
        if (ReadPermissions["device"] === 13){
          permObj = {
            ...permObj,
            createEntity: true, 
            deleteEntity: true, 
            readEntity: true,
            viewEntity: true
          };
        }
        if (ReadPermissions["device"] === 7){
          permObj = {
            ...permObj,
            updateEntity: true, 
            deleteEntity: true, 
            readEntity: true,
            viewEntity: true
          };
        }
        if (ReadPermissions["group"] === 0){
          permObj = {
            ...permObj,
            readGroup: false 
          };
        }
        if (ReadPermissions["group"] === 4){
          permObj = {
            ...permObj,
            readGroup: true,
            viewGroup: true
          };
        }
        if (ReadPermissions["group"] === 12){
          permObj = {
            ...permObj,
            createGroup: true, 
            readGroup: true ,
            viewGroup: true
          };
        }
        if (ReadPermissions["group"] === 6){
          permObj = {
            ...permObj,
            updateGroup: true, 
            readGroup: true ,
            viewGroup: true
          };
        }
        if (ReadPermissions["group"] === 5){
          permObj = {
            ...permObj,
            deleteGroup: true, 
            readGroup: true,
            viewGroup: true
          };
        }
        if (ReadPermissions["group"] === 14){
          permObj = {
            ...permObj,
            createGroup: true, 
            updateGroup: true, 
            readGroup: true,
            viewGroup: true
          };
        }
        if (ReadPermissions["group"] === 15){
          permObj = {
            ...permObj,
            createGroup: true, 
            updateGroup: true, 
            deleteGroup: true, 
            readGroup: true,
            viewGroup: true
          };
        }
        if (ReadPermissions["group"] === 13){
          permObj = {
            ...permObj,
            createGroup: true, 
            deleteGroup: true, 
            readGroup: true,
            viewGroup: true
          };
        }
        if (ReadPermissions["group"] === 7){
          permObj = {
            ...permObj,
            updateGroup: true, 
            deleteGroup: true, 
            readGroup: true,
            viewGroup: true
          };
        }

        if (ReadPermissions["vehicle_registration"] === 0) {
          permObj = {
            ...permObj,
            importExcel: false,
          };
        }
        if (importvalue.includes(ReadPermissions["vehicle_registration"])) {
          permObj = {
            ...permObj,
            importExcel: true,
          };
        }

        return permObj;

        case "route":
          if (tabPermissions["route_list"] == 1){
            permObj = {
              ...permObj,
              viewRoute: true
            };
          }
        if (tabPermissions["route_assign"] == 1){
          permObj = {
            ...permObj,
            viewRouteAssign: true
          };
        }
        if (ReadPermissions["route"] === 0){
          permObj = {
            ...permObj,
            readRoute: false
          };
        }
        if (ReadPermissions["route"] === 4){
          permObj = {
            ...permObj,
            readRoute: true,
            viewRoute: true
          };
        }         
        if (ReadPermissions["route"] === 12){
          permObj = {
            ...permObj,
            createRoute: true, 
            readRoute: true,
            viewRoute: true
          };
        }
        if (ReadPermissions["route"] === 6){
          permObj = {
            ...permObj,
            updateRoute: true, 
            readRoute: true,
            viewRoute: true
          };
        }
        if (ReadPermissions["route"] === 5){
          permObj = {
            ...permObj,
            deleteRoute: true, 
            readRoute: true ,
            viewRoute: true
          };
        }
        if (ReadPermissions["route"] === 14){
          permObj = {
            ...permObj,
            createRoute: true, 
            updateRoute: true, 
            readRoute: true,
            viewRoute: true
          };
        }
        if (ReadPermissions["route"] === 15){
          permObj = {
            ...permObj,
            createRoute: true, 
            updateRoute: true, 
            deleteRoute: true, 
            readRoute: true,
            viewRoute: true
          };
        }
        if (ReadPermissions["route"] === 13){
          permObj = {
            ...permObj,
            createRoute: true, 
            deleteRoute: true, 
            readRoute: true,
            viewRoute: true
          };
        }
        if (ReadPermissions["route"] === 7){
          permObj = {
            ...permObj,
            updateRoute: true, 
            deleteRoute: true, 
            readRoute: true,
            viewRoute: true
          };
        }
        if (ReadPermissions["trip"] === 0){
          permObj = {
            ...permObj,
            readRouteAssign: false
          };
        }
        if (ReadPermissions["trip"] === 4){
          permObj = {
            ...permObj,
            readRouteAssign: true,
            viewRouteAssign: true
          };
        }
        if (ReadPermissions["trip"] === 12){
          permObj = {
            ...permObj,
            createRouteAssign: true, 
            readRouteAssign: true,
            viewRouteAssign: true
          };
        }
        if (ReadPermissions["trip"] === 6){
          permObj = {
            ...permObj,
            updateRouteAssign: true, 
            readRouteAssign: true,
            viewRouteAssign: true
          };
        }
        if (ReadPermissions["trip"] === 5){
          permObj = {
            ...permObj,
            deleteRouteAssign: true, 
            readRouteAssign: true ,
            viewRouteAssign: true
          };
        }
        if (ReadPermissions["trip"] === 14){
          permObj = {
            ...permObj,
            createRouteAssign: true, 
            updateRouteAssign: true, 
            readRouteAssign: true,
            viewRouteAssign: true
          };
        }
        if (ReadPermissions["trip"] === 15){
          permObj = {
            ...permObj,
            createRouteAssign: true, 
            updateRouteAssign: true, 
            deleteRouteAssign: true, 
            readRouteAssign: true,
            viewRouteAssign: true
          };
        }
        if (ReadPermissions["trip"] === 13){
          permObj = {
            ...permObj,
            createRouteAssign: true, 
            deleteRouteAssign: true, 
            readRouteAssign: true,
            viewRouteAssign: true
          };
        }
        if (ReadPermissions["trip"] === 7){
          permObj = {
            ...permObj,
            updateRouteAssign: true, 
            deleteRouteAssign: true, 
            readRouteAssign: true,
            viewRouteAssign: true
          };
        }

        return permObj;

        case "geofence":
        if (tabPermissions["geofence_configuration"] == 1){
          permObj = {
            ...permObj,
            viewGeofence: true
          };
        }
        if (ReadPermissions["geofence"] === 0){
          permObj = {
            ...permObj,
            readGeofence: false
          };
        }
        if (ReadPermissions["geofence"] === 4){
          permObj = {
            ...permObj,
            readGeofence: true,
            viewGeofence: true
          };
        }         
        if (ReadPermissions["geofence"] === 12){
          permObj = {
            ...permObj,
            createGeofence: true, 
            readGeofence: true,
            viewGeofence: true
          };
        }         
        if (ReadPermissions["geofence"] === 6){
          permObj = {
            ...permObj,
            updateGeofence: true, 
            readGeofence: true,
            viewGeofence: true
          };
        }         
        if (ReadPermissions["geofence"] === 5){
          permObj = {
            ...permObj,
            deleteGeofence: true, 
            readGeofence: true ,
            viewGeofence: true
          };
        }      
        if (ReadPermissions["geofence"] === 14){
          permObj = {
            ...permObj,
            createGeofence: true, 
            updateGeofence: true, 
            readGeofence: true,
            viewGeofence: true
          };
        }
        if (ReadPermissions["geofence"] === 15){
          permObj = {
            ...permObj,
            createGeofence: true, 
            updateGeofence: true, 
            deleteGeofence: true, 
            readGeofence: true ,
            viewGeofence: true
          };
        }
        if (ReadPermissions["geofence"] === 13){
          permObj = {
            ...permObj,
            createGeofence: true, 
            deleteGeofence: true, 
            readGeofence: true ,
            viewGeofence: true
          };
        }
        if (ReadPermissions["geofence"] === 7){
          permObj = {
            ...permObj,
            updateGeofence: true, 
            deleteGeofence: true, 
            readGeofence: true ,
            viewGeofence: true
          };
        }

        return permObj;

        case "alarm":

        if (tabPermissions["alarm_configuration"] == 1){
          permObj = {
            ...permObj,
            viewAlarmConfig: true
          };
        }        
        if (tabPermissions["alarm_log"] == 1){
          permObj = {
            ...permObj,
            viewAlarmLog: true, 
            readAlarmLog: true
          };
        }
        if (ReadPermissions["notification"] === 0){
          permObj = {
            ...permObj,
            readAlarmConfig: false 
          };
        }        
        if (ReadPermissions["notification"] === 4){
          permObj = {
            ...permObj,
            readAlarmConfig: true,
            viewAlarmConfig: true
          };
        }
        if (ReadPermissions["notification"] === 12){
          permObj = {
            ...permObj,
            createAlarm: true, 
            readAlarmConfig: true,
            viewAlarmConfig: true
          };
        }
        if (ReadPermissions["notification"] === 6){
          permObj = {
            ...permObj,
            updateAlarm: true, 
            readAlarmConfig: true,
            viewAlarmConfig: true
          };
        }
        if (ReadPermissions["notification"] === 5){
          permObj = {
            ...permObj,
            deleteAlarm: true, 
            readAlarmConfig: true,
            viewAlarmConfig: true
          };
        }
        if (ReadPermissions["notification"] === 14){
          permObj = {
            ...permObj,
            createAlarm: true, 
            updateAlarm: true, 
            readAlarmConfig: true,
            viewAlarmConfig: true
          };
        }
        if (ReadPermissions["notification"] === 15){
          permObj = {
            ...permObj,
            createAlarm: true, 
            updateAlarm: true, 
            deleteAlarm: true, 
            readAlarmConfig: true,
            viewAlarmConfig: true
          };
        }
        if (ReadPermissions["notification"] === 13){
          permObj = {
            ...permObj,
            createAlarm: true, 
            deleteAlarm: true, 
            readAlarmConfig: true,
            viewAlarmConfig: true
          };
        }
        if (ReadPermissions["notification"] === 7){
          permObj = {
            ...permObj,
            updateAlarm: true, 
            deleteAlarm: true, 
            readAlarmConfig: true,
            viewAlarmConfig: true
          };
        }

        return permObj;

        case "ControlPanel":
          if (tabPermissions["control_panel"] == 1){
            permObj = {
              ...permObj,
              viewControlPanel: true
            };
          } 
          if (ReadPermissions["control_panel"] === 0){
            permObj = {
              ...permObj,
              readControlPanel: false
            };
          }          
          if (ReadPermissions["control_panel"] === 8){
            permObj = {
              ...permObj,
              readControlPanel: true,
              viewControlPanel: true
            };
          }
          if (ReadPermissions["control_panel"] === 1){
            permObj = {
              ...permObj,
              readControlPanel: true,
              viewControlPanel: true
            };
          }            
          if (ReadPermissions["control_panel"] === 9){
            permObj = {
              ...permObj,
              readControlPanel: true,
              viewControlPanel: true
            };
          }
        
          return permObj;

          case "dashboard":
            if (tabPermissions["dashboard_overall"] == 1){
              permObj = {
                ...permObj,
                viewOverall: true
              };
            }
            if (tabPermissions["dashboard_vehicle_summary"] == 1){
              permObj = {
                ...permObj,
                viewVehicleSummary: true
              };
            }   
            if (ReadPermissions["dashboard"] === 0){
              permObj = {
                ...permObj,
                readOverall: false, 
                readVehicleSummary: false
              };
            }              
            if (readpervalue.includes(ReadPermissions["dashboard"])){
              permObj = {
                ...permObj,
                readOverall: true, 
                readVehicleSummary: true,
                viewOverall: true,
                viewVehicleSummary: true
              };
            }
             
            return permObj;

            default:
              return null

    }
  }
}
