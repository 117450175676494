import * as React from "react";
import DollarIcon from "@mui/icons-material/AttachMoney";
import { useListController, useTranslate } from "react-admin";

import CardWithIcon from "./CardwithIcon";

const OtherVehicle = ({ overviewData }) => {

  const statusValues = overviewData ? overviewData[0] : null;
  const translate = useTranslate();
  return (
    <>
      <CardWithIcon
        to="/vehicles"
        title={translate(`Other Vehicle`)}
        subtitle={statusValues?.no_data}
        status="other"
        icon={DollarIcon}
        statusValues={statusValues}
      />
    </>
  );
};

export default OtherVehicle;
