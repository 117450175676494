import React, { useEffect, useState } from 'react'
import {
    useGetOne, useRefresh,
    useGetList,
} from "react-admin";
import Modal from '@mui/material/Modal';
import FormButton from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import vtsDataProvider from '../providers/dataProvider';
import { Box, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import Switch from '@mui/material/Switch';
import SendNotification from "../utils/sendNotification";
import CloseIcon from '@mui/icons-material/HighlightOffOutlined';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

let date = new Date();
const formdefaultValues = {
    EditAlarmId: 0,
    type: "",
    notificators: "",
    web: false,
    email: false,
    sms: false,
    lastupdate: date,
    attributes: {},
    calendarId: 0,
    always: false,
    createdon: null
};

const defaultValues = {
    id: 0,
    type: "",
    notificators: "",
    lastupdate: date,
    attributes: {},
    calendarId: 0,
    always: false,
    createdon: null
};

export default function EditAlarm({ EditAlarmopen, handleEditAlarmClose, EditAlarmId }) {
    const [notifyData, setNotifyData] = useState({});
    const [formValues, setFormValues] = useState(formdefaultValues);
    const [alarmtypes, setAlarmType] = useState([]);
    const [alarmnotificator, setAlarmNotificator] = useState([]);
    const [statenotificator, setStateNotificator] = useState(defaultValues);

    const refresh = useRefresh();

    const { data: alarmtype } = useGetList('alarmtype');
    const { data: alarmnotificators } = useGetList('alarmnotificators');

    const { data: EditData, isLoading, error } = useGetOne(
        'alarm',
        { id: EditAlarmId },
        { enabled: (EditAlarmId !== 0) }
    );

    useEffect(() => {
        const getAlarm = () => {
            if (alarmtype)
                setAlarmType(alarmtype);
            if (alarmnotificators) {
                const alarmnotificatorasc = [...alarmnotificators].sort((a, b) => a.id - b.id);
                setAlarmNotificator(alarmnotificatorasc)
            }
        }
        getAlarm();
    }, [alarmtype, alarmnotificators]);

    useEffect(() => {
        const getEditAlarm = () => {
            if (EditData) {
                const notific = EditData.notificators;
                setFormValues({
                    EditAlarmId: EditAlarmId,
                    createdon: EditData.createdon,
                    type: EditData.type,
                    notificators: EditData.notificators,
                    always: EditData.always
                })

                const alarm_check = alarmnotificator.map((item, idx) => {
                    let { isChecked } = "";
                    if (notific?.includes(item.type)) {
                        isChecked = true;
                    }
                    else {
                        isChecked = false;
                    }
                    return { ...item, isChecked };
                });
                setAlarmNotificator(alarm_check)

                EditAlarmId = 0;
            }
        }
        getEditAlarm();
    }, [EditData]);

    useEffect(() => {

        if (statenotificator.type !== "") {
            vtsDataProvider.update('alarm', statenotificator, setNotifyData, "Alarm Updated Successfully", "Failed to Update Alarm");
            setFormValues(formdefaultValues)
            setStateNotificator(defaultValues)
            handleEditAlarmClose();
        }
    }, [statenotificator])

    useEffect(() => {
        if (notifyData?.severity === "success") {
            refresh();
        }
    }, [notifyData])

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: type === 'checkbox' ? checked : value,
        });

    };


    const handleEditAlarmSubmit = (event) => {
        event.preventDefault();

        var value = alarmnotificator.filter(function (item) {
            return item.isChecked === true
        });

        var notifi = value.map((result) => {
            return result.type
        })

        setStateNotificator({
            ...statenotificator,
            id: formValues.EditAlarmId,
            always: formValues.always,
            createdon: formValues.createdon,
            type: event.target.type.value,
            notificators: notifi.toString()
        })
    };

    const toggleSwitch = (e) => {
        const alarm_check = alarmnotificator.map((item, idx) => {
            let { isChecked } = "";
            if (item.type === e.target.name) {
                isChecked = e.target.checked;
            }
            else {
                if (item.isChecked === undefined)
                    isChecked = false;
                else
                    isChecked = item.isChecked
            }
            return { ...item, isChecked };
        });
        setAlarmNotificator(alarm_check)
    };

    const NotSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 22,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? 'green' : 'green',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 18,
            height: 18,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#a5a5a5' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));


    return (<>
        <Modal
            open={EditAlarmopen}
            onClose={handleEditAlarmClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description" className="AddRoleModal alarm-modal edit"
        >
            <Box sx={style} className="modal-box">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Edit Alarm
                </Typography>
                <span className="close-btn"><CloseIcon onClick={handleEditAlarmClose} /></span>
                <form onSubmit={handleEditAlarmSubmit}>
                    <Box sx={{ flexGrow: 1, marginTop: '2em' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ marginTop: '0px' }}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                                                <FormLabel>Alarm Type</FormLabel>
                                                <Select
                                                    name="type"
                                                    placeholder="Alarm Type"
                                                    value={formValues.type}
                                                    onChange={handleInputChange}
                                                >
                                                    {
                                                        alarmtypes.map((alarm) => (
                                                            <MenuItem key={alarm.type} value={alarm.type}>
                                                                {alarm.type}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>

                                                <FormLabel sx={{ marginTop: '20px' }}>Always</FormLabel>
                                                <input name="always" checked={formValues.always} className='alarm-check'
                                                    type="checkbox" onChange={handleInputChange}></input>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </Box>

                                <Box sx={{ marginTop: '15px' }}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} >
                                                <FormLabel>Additional Notification</FormLabel>
                                                <Box sx={{ display: "flex", flexDirection: "row" }}>

                                                    {alarmnotificator.map((item, idx) => (
                                                        <FormControlLabel
                                                            control={<NotSwitch sx={{ m: 1 }}
                                                                checked={item.isChecked === undefined ? false : item.isChecked}
                                                                name={item.type}
                                                                onChange={toggleSwitch} />}
                                                            label={item.type} className='Switchbtn'
                                                        />
                                                    ))}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} ></Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <div style={{ marginTop: '1em' }} className="modal-footer">
                                    <FormButton variant="contained" color="primary" type="submit">
                                        Edit Alarm
                                    </FormButton>
                                    <FormButton variant="contained" onClick={handleEditAlarmClose} className="cancel-btn" color="primary" sx={{ marginRight: '1em' }}>
                                        Cancel
                                    </FormButton>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Box>
        </Modal>
        <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
    </>
    )
}

