import React, { useState } from "react";
  
export const StatisticContext = React.createContext({});
export const StatisticContextProvider = ({ children }) => {
    const [statistic, setStatistic] = useState(0);
  
    return (
        <StatisticContext.Provider value={{ statistic, setStatistic }}>
            {children}
        </StatisticContext.Provider>
    );
};