import React, { useState } from "react";
import { Card, Box, Typography, Grid, Button } from "@mui/material";
import {
    Form,
    TextInput,
} from 'react-admin';
import EditIcon from "@mui/icons-material/EditOutlined";
import Notif1 from '../../images/Notif1.svg';
import Notif2 from '../../images/Notif2.svg';
import Notif3 from '../../images/Notif3.svg';
import Notif4 from '../../images/Notif4.svg';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
export const Notification = () => {

    return (
        <Box sx={{ width: "100%", typography: "body1" }} className="notification-det">
            <div class="notification-heading">
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <h4 class="menu-title">01 Notifications</h4>
                    </Grid>
                    <Grid item xs={4}>
                        <h4 class="menu-title pull-right">Mark all as read</h4>
                    </Grid>
                </Grid>
            </div>
            <Box className="mapview-content">
                <Card sx={{ minWidth: 382, marginTop: '1em', padding: '18px 22px !important', marginRight: "20px", boxShadow: "none !important" }}>
                    <Form>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <img src={Notif1} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="h6" component="h4" className="notify-title">
                                        Deviceoffline
                                    </Typography>
                                    <p class="item-info">d ad in non qui. Est ullamco nostrud ullamco veniam ut dolor nostrud amet excepteur. Reprehenderit laborum ullamco ipsum eu minim ex anim ad aliquip deserunt ea. Veniam adipisicing nisi cupidatat sunt occaecat quis velit aliquip ipsum eu.</p>
                                    <p class="item-date">16-Aug-2022 <span class="item-date">4:22 PM</span></p>
                                </Grid>
                                <Grid item xs={1}>
                                    <span style={{ float: "right" }}>
                                        <DeleteIcon />
                                    </span>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                </Card>
                <Card sx={{ minWidth: 382, marginTop: '4px', padding: '18px 22px !important', marginRight: "20px", boxShadow: "none !important" }}>
                    <Form>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <img src={Notif2} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="h6" component="h4" className="notify-title">
                                        Sos Categories
                                    </Typography>
                                    <p class="item-info">d ad in non qui. Est ullamco nostrud ullamco veniam ut dolor nostrud amet excepteur. Reprehenderit laborum ullamco ipsum eu minim ex anim ad aliquip deserunt ea. Veniam adipisicing nisi cupidatat sunt occaecat quis velit aliquip ipsum eu.</p>
                                    <p class="item-date">16-Aug-2022 <span class="item-date">4:22 PM</span></p>
                                </Grid>
                                <Grid item xs={1}>
                                    <span style={{ float: "right" }}>
                                        <DeleteIcon />
                                    </span>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                </Card>
                <Card sx={{ minWidth: 382, marginTop: '4px', padding: '18px 22px !important', marginRight: "20px", boxShadow: "none !important" }}>
                    <Form>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <img src={Notif3} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="h6" component="h4" className="notify-title">
                                        Route Categories
                                    </Typography>
                                    <p class="item-info">d ad in non qui. Est ullamco nostrud ullamco veniam ut dolor nostrud amet excepteur. Reprehenderit laborum ullamco ipsum eu minim ex anim ad aliquip deserunt ea. Veniam adipisicing nisi cupidatat sunt occaecat quis velit aliquip ipsum eu.</p>
                                    <p class="item-date">16-Aug-2022 <span class="item-date">4:22 PM</span></p>
                                </Grid>
                                <Grid item xs={1}>
                                    <span style={{ float: "right" }}>
                                        <DeleteIcon />
                                    </span>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                </Card>
                <Card sx={{ minWidth: 382, marginTop: '4px', padding: '18px 22px !important', marginRight: "20px", boxShadow: "none !important" }}>
                    <Form>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <img src={Notif4} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="h6" component="h4" className="notify-title">
                                        Geofence Enter
                                    </Typography>
                                    <p class="item-info">d ad in non qui. Est ullamco nostrud ullamco veniam ut dolor nostrud amet excepteur. Reprehenderit laborum ullamco ipsum eu minim ex anim ad aliquip deserunt ea. Veniam adipisicing nisi cupidatat sunt occaecat quis velit aliquip ipsum eu.</p>
                                    <p class="item-date">16-Aug-2022 <span class="item-date">4:22 PM</span></p>
                                </Grid>
                                <Grid item xs={1}>
                                    <span style={{ float: "right" }}>
                                        <DeleteIcon />
                                    </span>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                </Card>
            </Box>
        </Box>
    );
};