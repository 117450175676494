// import * as React from "react";
import React, { useEffect, useState } from "react";
import {
  Datagrid,
  useRefresh,
  TextInput,
  useListController,
  EmailField,
  ReferenceField,
  useRecordContext,
  TextField,
  ListContextProvider,
  Pagination,
  Confirm, TopToolbar
} from "react-admin";
import { AddUserDetails } from "../components/AddUser";
import ActionField from "../components/actionField";
import Modal from "@mui/material/Modal";
import "../views/users/users.css";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import vtsDataProvider from "../providers/dataProvider";
import SendNotification from "../utils/sendNotification";
import VTSListToolbar from '../components/VTSListToolbar';
import { Box, LinearProgress } from '@mui/material';
import moment from "moment";
import { TitleContext } from "../utils/TitleContext";
import MButton from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import useMediaQuery from '@mui/material/useMediaQuery';

const Buttonstyle = {
  position: "absolute",
  background: "#4646F2",
  color: "#fff",
  zIndex: 1,
  textTransform: "capitalize",
  width: "115px",
  border: "1px solid #4646F2",
  padding: "5px 0",
};

export const UserList = ({ createper, updateper, deleteper }) => {
  const [notifyData, setNotifyData] = useState({});
  const [filter, setFilter] = useState("");
  const [EditUserId, setEditUserId] = useState(0);
  const [Accountopen, setAccountOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const refresh = useRefresh();
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    userId: null,
  });
  const handleAccountClose = () => setAccountOpen(false);

  const { setTitle } = React.useContext(TitleContext);

  const matches = useMediaQuery('(min-width:1800px)');

  const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(parseInt(event.target.value, 10));
     setPerPage(event.target.value);
   };
   
  useEffect(() => {
    setTitle('User/Roles')
  }, [])

  useEffect(() => {
    var queryvalue = document.querySelectorAll('.list-content');
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const filters = [<TextInput label="Search" source="q" alwaysOn />];

  const { data, isLoading, page, setPage, setPerPage, ...listContext } = useListController({
    resource: 'users'
  });


  const setFilters = (filter) => setFilter(filter.q);
  const filterValues = { q: filter };

  const handleEditClick = async (event, userId) => {
    setEditUserId(userId);
    setAccountOpen(true);
  };

  const ManagerField = ({ source }) => {
    const record = useRecordContext();
    if (record[source] === true) return <span>Yes</span>;
    else return <span>No</span>;
  };

  const handleDeleteClick = (event, deleteId) => {
    setDeleteDialog({
      open: true,
      deleteId: deleteId
    });
  };

  const handleConfirmDelete = () => {
    if (deleteDialog.deleteId !== null) {
      vtsDataProvider.delete('users', { id: deleteDialog.deleteId }, setNotifyData, "User Deleted Succesfully", "Failed to Delete");
      handleConfirmClose();
    }
  };


  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])

  const handleConfirmClose = () => {
    setDeleteDialog({
      open: false,
      deleteId: null,
    });
    refresh();
  };

  const CustomEditButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <EditIcon onClick={(event) => handleEditClick(event, record[source])} />
      </div>
    );
  };

  const AddUserOpen = () => {
    setAccountOpen(true)
    setEditUserId(0);
  }

  const UserActions = () => (
    <TopToolbar className="user-toolbar">
      {createper && (<><MButton
        className="Adduser"
        onClick={AddUserOpen}
        startIcon={<AddIcon />}
        sx={Buttonstyle}
      >
        Add User
      </MButton></>)}
    </TopToolbar>
  );

  const CustomDeleteButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <DeleteIcon
          onClick={(event) => handleDeleteClick(event, record[source])}
        />
      </div>
    );
  };

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt = (record[source] !== null) ? moment(record[source]).format("DD MMM YYYY, HH:mm:ss") : "";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  const Customuserlimit = ({ source }) => {
    const record = useRecordContext();
    const dt =
      record[source] === -1
        ? "Unlimited"
        : record[source];
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  const Customdevicelimit = ({ source }) => {
    const record = useRecordContext();
    const dt =
      record[source] === -1
        ? "Unlimited"
        : record[source];
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  const CustomEmpty = () => <div className="no-records">No Records</div>;

  if (isLoading) {
    return <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  } else
    return (
      <>
        <ListContextProvider debounce={8000} value={{ data, filterValues, page, setFilters, setPage, setPerPage, ...listContext }}>
          <VTSListToolbar filters={filters} actions={<UserActions />} />
            {matches == true ? (
              <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}  />
              
              ) : (
             <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
  	          )}
              <Box className="list-content">
              
                <Datagrid className="table-grid" bulkActionButtons={false} empty={<CustomEmpty />}>
                  <TextField source="name" label="Name" />
                  <TextField source="phone" label="Phone No" />
                  <EmailField source="email" label="Email" />
                  <ReferenceField label="Role" source="roleid" reference="userrole">
                    <TextField source="rolename" />
                  </ReferenceField>
                  <DateSetter label="Created On" source="createdon" />
                  <Customdevicelimit source="deviceLimit" label="Device Access" />
                  <Customuserlimit source="userLimit" label="User Access" />
                  <span label="Action" className="action-icons">
                    {updateper && (<><CustomEditButton source="id" /></>)}
                    {deleteper && (<><CustomDeleteButton source="id" /></>)}
                  </span>
                </Datagrid>
              </Box>
              
            {/* </>
          } */}
        </ListContextProvider>
        <AddUserDetails listContext={listContext} Accountopen={Accountopen} EditUserId={EditUserId} handleAccountClose={handleAccountClose}></AddUserDetails>

        <Confirm
          isOpen={deleteDialog?.open}
          title="Delete User"
          content="Are you sure you want to delete the user?"
          onConfirm={handleConfirmDelete}
          onClose={handleConfirmClose}
        />

        <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
      </>
    );
};
