import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Alert, Stack } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

export default function SendNotification({ data, onClose }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={data?.open}
      onClose={onClose}
      autoHideDuration={data?.duration ? data?.duration : 5000}
    >
      {data?.open && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert variant="filled" severity={data?.severity}>{data?.message}</Alert>
        </Stack>
      )}
    </Snackbar>
  );
}
