import React from 'react';
import {useRecordContext } from "react-admin";
import {secondsToHms} from "../utils/time";
import moment from "moment";

const errorstyle = {
    color: "red",
    fontSize: "12px",
  };

export const PanelRoutelist = () => {
  const record = useRecordContext();
    return(
        Array.isArray(record?.legs) === true && record?.legs?.length>0?
        <div>
        <table
          id="expand-table"
          class="display table-bordered"
          cellspacing="0"
          width="100%"
        >
          <thead>
            <tr>
              <th></th>
              <th>Sequence</th>
              <th>Name</th>
              <th>Distance from origin</th>
              <th>Duration from origin</th>
            </tr>
          </thead>
          <tbody>
            {
                record?.legs?.map((route,index)=>{
                   return(
                    <tr>
                    <td></td>
                    <td>{index}</td>
                    <td>{route?.nameFrom} - {route?.nameTo}</td>
                    <td>{route?.estDistanceFromOrigin} (km)</td>
                    <td>{route?.estDurationFromOrigin}</td>
                  </tr>
                   )
                })
            }
          </tbody>
        </table>
        </div>:<div style={errorstyle} className="no-records">No Records</div>
    );
}


export const PanelRAssign = () => {
    const record = useRecordContext();

    const DateSetter = (datesource) => {
      const dt = (datesource !== null && datesource !== "" && datesource !== undefined) ? moment(datesource).utc().format('DD MMM YYYY, HH:mm:ss') : "";
      return (
        <>
          <span>{dt}</span>
        </>
      );
    };

      return(
          Array.isArray(record?.route?.legs) === true && record?.route?.legs?.length>0?
          <div>
          <table
            id="expand-table"
            class="display table-bordered"
            cellspacing="0"
            width="100%"
          >
            <thead>
            <tr>
                <th></th>
                <th>Point</th>
                <th>Entity</th>
                <th>Name</th>
                <th>Planned Time</th>
                <th>Planned Distance</th>
                <th>Planned Duration</th>
                <th>Distance Travelled </th>
                <th>Duration Travelled </th>
                <th>Status </th>
            </tr>
            </thead>
            <tbody>
              {
                  record?.trip?.tripLegs?.map((trip,index)=>{
                     return(
                      <tr>
                      <td></td>
                      <td>{trip?.legIndex}</td>
                      <td>{record?.trip?.vehicleNo}</td>
                      <td>{record?.route?.legs[index]?.nameFrom} - {record?.route?.legs[index]?.nameTo}</td>
                      <td>{new Date(trip?.plannedTime).toISOString().slice(11, 19)}</td>
                      <td>{trip?.plannedDistance}</td>
                      <td>{new Date(trip?.plannedDuration).toISOString().slice(11, 19)}</td>
                      <td>{trip?.distanceTraveled}</td>
                      <td>{new Date(trip?.durationTraveled).toISOString().slice(11, 19)}</td>
                      <td>{trip?.status}</td>
                    </tr>
                     )
                  })
              }
            </tbody>
          </table>
          </div>:<div style={errorstyle} className="no-records">No Records</div>
      );
  }
