import React from "react";
import { useRecordContext } from "react-admin";
import { secondsToHms } from "./time";

const errorstyle = {
  color: "red",
  fontSize: "12px",
};

export const ViolationList = () => {
  const record = useRecordContext();
  let myKeys = Object.entries(record?.violations);
  let myKeysArr = [];

  myKeys.forEach(([key, val]) => {
    myKeysArr.push({ key, val });
  });

  return Array.isArray(myKeysArr) === true && myKeysArr.length > 0 ? (
    <div>
      <table
        id="expand-table"
        class="display table-bordered"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th></th>
            <th>Violation ID</th>
            <th>Violation</th>
          </tr>
        </thead>
        <tbody>
          {myKeysArr.map((itm, index) => {
            return (
              <tr>
                <td></td>
                <td>{itm?.key}</td>
                <td>{itm?.val}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <div style={errorstyle} className="no-records">
      No Records
    </div>
  );
};
