import React, { useState, useEffect, useRef } from "react";
import {
  List,
  TextField,
  useGetList,
  Datagrid,
  DateTimeInput,
  ListContextProvider,
  TextInput,
  Pagination,
  ListToolbar,
  TopToolbar,
  Button,
  useListController,
  useRecordContext,
  DateField,
  DateInput,
  LinearProgress,
  Toolbar,
  useListContext,
  useRefresh,
  SelectInput,
} from "react-admin";
import { Tabs, Tab, Box, Typography, FormControl } from "@mui/material";
import "../views/panic/panic.css";
import FormLabel from "@mui/material/FormLabel";
import PanicAction from "../views/panic/panicAction";
import moment from "moment";
import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditPanicDialog from "./EditPanicDialog";
import VTSListToolbar from "./VTSListToolbar";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { getVehicleLocation } from "../providers/dataProvider";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import useMediaQuery from '@mui/material/useMediaQuery';


export const PanicSOSList = ({ stat, setStat }) => {
  const [filter, setFilter] = useState("");
  const [panicOpen, setPanicOpen] = useState(false);
  const refresh = useRefresh();
  const [selected, setSelected] = useState("all");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [dateRange, setDateRange] = useState([undefined, undefined]);
  const [startDate, endDate] = dateRange;

  const datePickerRef = useRef(null);
  const selectRef = useRef(null);

  const handleDatePicker = () => {
    const dp = datePickerRef.current;
    dp.setOpen(true);
  };

  var date = new Date();
  var formatedDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  var StaringDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
  var placeholderDate = StaringDate + " - " + formatedDate;

  const handleChange = (e) => {
    selectRef.current.value = e.target.value;
  };

  const matches = useMediaQuery('(min-width:1800px)');

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPerPage(event.target.value);
  };

  const statusTypes = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Resolved",
      value: "resolved",
    },
    {
      label: "Cancelled",
      value: "cancelled",
    },
    {
      label: "Acknowledged",
      value: "acknowledged",
    },
  ];

  const perPage = 10;
  
  const filters = [
    <Box className="status-b" style={{ marginLeft: "12px" }} alwaysOn>
      <FormLabel>Status</FormLabel>
      <Select
        name="status"
        onChange={handleChange}
        ref={selectRef}
        displayEmpty
        renderValue={
          selected !== null ? undefined : () => <span>Select Status</span>
        }
      >
         <MenuItem>
            Select
          </MenuItem>
        {statusTypes?.map((status, index) => (
          <MenuItem key={index} value={status?.value}>
            {status?.label}
          </MenuItem>
        ))}
      </Select>
    </Box>,
    <Box className="date-range" alwaysOn>
      <FormControl>
        <DatePicker
          name="date"
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
          }}
          placeholderText={placeholderDate}
          isClearable={true}
          ref={datePickerRef}
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
        />
        <IconButton className="cal-icon" onClick={handleDatePicker}>
          <CalendarIcon />
        </IconButton>
      </FormControl>
    </Box>,
    <TextInput label="Search" source="q" size="small" alwaysOn />,
  ];

  const { data, isLoading, page, setPage, setPerPage, ...listContext } =
    useListController({
      resource: "panicsos",
      filter: {
        selected:
          selectRef?.current?.value == undefined
            ? stat
            : selectRef?.current?.value,
      },
    });

  useEffect(() => {
    var queryvalue = document.querySelectorAll(".list-content");
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const setFilters = (filters) => setFilter(filters.q);
  const sort = { field: "id", order: "ASC" };
  const filterValues = { q: filter };

  const handlePanicClose = () => {
    setPanicOpen(false);
    refresh();
  };

  const [currentPanic, setCurrentPanic] = useState({
    id: null,
    deviceId: null,
    type: null,
    eventTime: null,
    positionId: null,
    geofenceId: null,
    maintenanceId: null,
    location: null,
    remark: null,
    action: null,
    status: null,
    attributes: {
      alarm: "sos",
    },
  });

  const StatusField = ({ source }) => {
    const record = useRecordContext();
    return record ? <span>{record["status"]}</span> : null;
  };

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt = moment(record[source]).format("DD MMM YYYY, HH:mm:ss");
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  const CustomEditButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <EditIcon onClick={(event) => handleEditClick(event, record, false)} />
      </div>
    );
  };

  const CustomViewButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <ViewIcon onClick={(event) => handleEditClick(event, record, true)} />
      </div>
    );
  };

  const handleEditClick = async (event, row, readOnly) => {
    setCurrentPanic({
      id: row?.id,
      deviceId: row?.deviceId,
      eventTime: row?.eventTime,
      positionId: row?.positionId,
      geofenceId: row?.geofenceId,
      maintenanceId: row?.maintenanceId,
      location: row?.location,
      remark: row?.remark,
      status: row?.status,
      type: row?.type,
      action: row?.action,
      attributes: {
        alarm: "sos",
      },
      readOnly: readOnly,
    });
    setPanicOpen(true);
  };

  const CustomEmpty = () => <div className="no-records">No Records</div>;

  const handleAddress = async (record) => {
    const address = {};
    if (!record?.latitude && !record?.longitude) return;
    await getVehicleLocation(record, address);
    document
      .getElementById(`addr_${record?.id}`)
      ?.classList?.remove("address_styling");
    document.getElementById(`addr_${record?.id}`).innerText = address?.address;
  };

  const AddressField = ({ source }) => {
    const record = useRecordContext();
    return (
      <>
        {record?.latitude != 0 && record?.longitude != 0 && (
          <span
            id={`addr_${record?.id}`}
            className="address_styling"
            onClick={() => handleAddress(record)}
          >
            Show Address
          </span>
        )}
      </>
    );
  };

  if (isLoading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  } else
    return (
      <>
        <ListContextProvider
          value={{
            data,
            page,
            setPage,
            setPerPage,
            filterValues,
            setFilters,
            ...listContext,
          }}
        >
          <VTSListToolbar
            filters={filters}
            date={datePickerRef}
            select={selectRef}
            className="sos-toolbar"
          />
          {matches == true ? (
              <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}  />
              
              ) : (
             <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
          )}
          <Box className="list-content">
            <Datagrid empty={<CustomEmpty />}>
              <TextField label="Serial No" source="id" />
              <TextField label="Vehicle No" source="vehicleNo" />
              <DateSetter label="Time" source="eventTime" />
              <AddressField label="Location" />
              <TextField label="Latitude" source="latitude" />
              <TextField label="Longitude" source="longitude" />
              <TextField label="SOS Action" source="action" />
              <StatusField source="status" />
              <TextField label="Remark" source="remark" />
              <span label="Action" className="action-icons">
                <CustomViewButton source="id" />
                <CustomEditButton source="id" />
              </span>
            </Datagrid>
          </Box>
          
        </ListContextProvider>
        <EditPanicDialog
          panicOpen={panicOpen}
          handlePanicClose={handlePanicClose}
          currentPanic={currentPanic}
          refetch={listContext?.refetch}
        />
      </>
    );
};
