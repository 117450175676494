import { useContext, useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import urls from '../constants';
import { setCookie } from '../utils/cookies';
import { PositionContext } from '../utils/PositionContext';
import { exists } from '../utils/validate';
import useThrottle from '../hooks/useThrottle';

function useNotification() {
  let wsUrl = urls.NOTIFICATION_WEBSOCKET_URL.replace(/http/, 'ws');

  const [socketUrl, setSocketUrl] = useState(wsUrl);
  const { lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl);
  const [notification, setNotification] = useState([]);
  const [notificationerr, setNotificationerr] = useState({});

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(() => {
    if (exists(lastMessage)) {
      let obj = JSON.parse(lastMessage.data);
      if (exists(obj) && Array.isArray(obj.events)) {
        setNotification(obj?.events)
      }
    } else {
      console.log("Websocket " + socketUrl + " in state:" + connectionStatus);
      if (connectionStatus === "Open" || connectionStatus === "Connecting") {
        setNotificationerr({ errmessage: "Connecting with device ..." })
      }
      else {
        setNotificationerr({ errmessage: "Websocket connection is " + connectionStatus })
      }
    }
  }, [lastMessage, socketUrl]);
  return [notificationerr, notification, setNotification, setSocketUrl];
}

export default useNotification;