import React, { useState, useEffect } from "react";
import {
  TextField,
  useListController,
  Datagrid,
  useRecordContext,
  ListContextProvider,
  TextInput,
  Pagination,
  TopToolbar,
  useRefresh,
  Confirm,
  Button,
  ExportButton
} from "react-admin";
import { Tabs, Tab, Box, Typography, Card } from "@mui/material";
import AddButton from "@mui/material/Button";
import "./geofence.css";
import FormLabel from "@mui/material/FormLabel";
import GeoAction from "./geoAction";
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import UploadIcon from "@mui/icons-material/FileUploadOutlined";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LinearProgress } from "@mui/material";
import VTSListToolbar from "../../components/VTSListToolbar";
import moment from "moment";
import { Creategeofence } from "../../components/Creategeofence";
import { EditGeofence } from "../../components/EditGeofence";
import { GeofenceMapView } from "../../components/GeofenceMapView";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SendNotification from "../../utils/sendNotification";
import vtsDataProvider, { UploadExcelFile } from "../../providers/dataProvider";
import "leaflet/dist/leaflet.css";
import PolylineIcon from "@mui/icons-material/Polyline";
import RectangleIcon from "@mui/icons-material/Rectangle";
import CircleIcon from "@mui/icons-material/Circle";
import { Tooltip } from "@mui/material";
import useTabPermissions from "../../hooks/useTabPermissions";
import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import XLIcon from '@mui/icons-material/TextSnippetOutlined';
import { TitleContext } from '../../utils/TitleContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import urls from "../../constants";

const errorstyle = {
  color: "red",
  fontSize: "12px",
};

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexRow: { display: "flex", flexDirection: "row" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};
const Buttonstyle = {
  position: "absolute",
  background: "#4646F2",
  color: "#fff",
  zIndex: 1,
  textTransform: "capitalize",
  width: "150px",
  border: "1px solid #4646F2",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: '0px 20px 27px #00000029',
  borderRadius: '12px',
  p: 4,
};

const defaultValues = {
  map: false,
};

const TabPanel = (props) => {
  const { index, value, children } = props;
  return (
    <React.Fragment>
      {value === index && <Typography>{children}</Typography>}
    </React.Fragment>
  );
};

export const GeofencesList = () => {
  const [notifyData, setNotifyData] = useState({});
  const [value, setValue] = React.useState(0);
  const [checked, setChecked] = React.useState(false);
  const [formValues, setFormValues] = useState(defaultValues);
  const [Geofopen, setGeofopen] = React.useState(false);
  const [EditGeofopen, setEditGeofopen] = React.useState(false);
  const [EditGeofenceId, setEditGeofenceId] = useState(0);
  const [open, setOpen] = React.useState(false);

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileUploading, setfileUploading] = useState(false);
  const [UpdateStatus, setUpdateStatus] = useState("");
  const [ErrorMessage, setUploadErrors] = useState("")
  const { setTitle } = React.useContext(TitleContext);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const refresh = useRefresh();
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    deleteId: null,
  });

  const [successDialog, setSuccessDialog] = useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleGeofOpen = () => setGeofopen(true);
  const handleGeofClose = () => setGeofopen(false);

  const handleEditGeofOpen = () => setEditGeofopen(true);
  const handleEditGeofClose = () => setEditGeofopen(false);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [filter, setFilter] = useState("");

  const tabView = useTabPermissions('geofence')

  const downloadSamplefile = () => {
    window.location.href = urls.GEOFENCE_SAMPLE_FILE_URL
  }

  useEffect(() => { setTitle("Geofence") }, [])


  useEffect(() => {
    var queryvalue = document.querySelectorAll('.list-content');
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const matches = useMediaQuery('(min-width:1800px)');

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPerPage(event.target.value);
  };

  const { data, isLoading, page, setPage, setPerPage, ...listContext } =
    useListController({
      resource: "geofences",
    });

  const totalgeo =
    typeof listContext?.total === "undefined"
      ? 0
      : listContext?.total?.toString();
  const filters = [
    <TextInput label="Search" source="q" size="small" alwaysOn />,
  ];
  const filterValues = { q: filter };

  const setFilters = (filters) => setFilter(filters.q);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChecked = () => {
    setChecked((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
    setChecked(checked);
  };

  const handleEditClick = (event, EditGeofenceId) => {
    setEditGeofenceId(EditGeofenceId);
    handleEditGeofOpen();
  };

  const CustomEditButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <EditIcon onClick={(event) => handleEditClick(event, record[source])} />
      </div>
    );
  };

  const CustomDeleteButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <DeleteIcon
          onClick={(event) => handleDeleteClick(event, record[source])}
        />
      </div>
    );
  };
  const handleDeleteClick = (event, deleteId) => {
    setDeleteDialog({
      open: true,
      deleteId: deleteId,
    });
  };

  const handleConfirmDelete = () => {
    if (deleteDialog.deleteId !== null) {
      vtsDataProvider.delete(
        "geofences",
        { id: deleteDialog.deleteId },
        setNotifyData,
        "Geofence Deleted Succesfully",
        "Failed to Delete"
      );
      handleConfirmClose();
    }
  };

  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])

  const handleConfirmClose = () => {
    setDeleteDialog({
      open: false,
      deleteId: null,
    });
  };

  const handlefileChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name)
  }

  const UploadFile = async () => {
    if (fileName === "") {
      // alert("Please Upload File");
      setUploadErrors("Please Upload File");
      return;
    }
    setUploadErrors("");
    setfileUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    const response = await UploadExcelFile("geofence", formData, setUpdateStatus)
    FileupSuccess();
    const timer = setTimeout(() => {
      setFileName("")
      setfileUploading(false);
      setFile(null);
    }, 800);
  }

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#67748E" : "#67748E",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#a5a5a5" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const PanicActions = () => (
    <TopToolbar className="Button-toolbar">

      <FormLabel className="list">List</FormLabel>
      <FormControlLabel
        control={
          <IOSSwitch
            sx={{ m: 1 }}
            checked={formValues.map}
            name="map"
            onChange={handleInputChange}
            expand={checked}
            aria-expanded={checked}
          />
        }
        label="Map"
        className="Switchbtn"
      />
      {tabView && (tabView?.createGeofence) && (<><AddButton className="uploadbtn" startIcon={<UploadIcon />} onClick={handleOpen}>Upload Bulk</AddButton>
        <AddButton
          className="Addentity"
          startIcon={<AddIcon />}
          sx={Buttonstyle}
          onClick={handleGeofOpen}
        >
          Create Geofence
        </AddButton></>)}
    </TopToolbar>
  );

  const CustomRadius = ({ source }) => {
    const record = useRecordContext();
    return (
      <>
        <span>{getRadius(record[source])}</span>
      </>
    );
  };

  const FileupSuccess = () => {
    setOpen(false);
    setSuccessDialog(true);
  }

  const FileupClose = () => {
    setSuccessDialog(false);
  };


  const getRadius = (area) => {
    let radius = "";
    let startpoint = area.indexOf("(");
    let endpoint = area.indexOf(")");
    let geofencetype = area.substring(0, startpoint).trim();
    geofencetype = geofencetype.toLowerCase();

    let latlngs = area.substring(startpoint + 1, endpoint).trim();

    if (geofencetype === "circle") {
      const radarray = latlngs.split(",");
      radius = radarray[1].trim();
    } else {
      radius = "";
    }

    return radius;
  };

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt =
      record[source] !== null
        ? moment(record[source]).format("DD MMM YYYY, HH:mm:ss")
        : "";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  const SpeedField = ({ source }) => {
    const record = useRecordContext();
    const speed = record?.attributes?.speedLimit
      ? record?.attributes?.speedLimit
      : 0;
    return (
      <>
        <span>{speed}</span>
      </>
    );
  };

  const TypeField = ({ source }) => {
    const record = useRecordContext();
    let startpoint = record?.area?.indexOf("(");
    const geofenceType = record?.area?.substring(0, startpoint).trim();
    const [hover, setHover] = useState(false);
    return (
      <>
        <Tooltip
          title={
            geofenceType && geofenceType == "CIRCLE"
              ? "CIRCLE"
              : geofenceType == "POLYGON"
                ? "POLYGON"
                : geofenceType == "LINESTRING"
                  ? "POLYLINE"
                  : ""
          }
          open={hover}
          onClose={() => setHover(false)}
          onOpen={() => setHover(true)}
        >
          <span>
            {geofenceType && geofenceType == "CIRCLE" ? (
              <CircleIcon />
            ) : geofenceType == "POLYGON" ? (
              <RectangleIcon />
            ) : geofenceType == "LINESTRING" ? (
              <PolylineIcon />
            ) : (
              ""
            )}
          </span>
        </Tooltip>
      </>
    );
  };

  const CustomEmpty = () => <div className="no-records">No Records</div>;

  if (isLoading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  } else {
    return (
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Tabs value={value} onChange={handleChange}>
          {tabView && (tabView?.viewGeofence) && <Tab label="Geofence Configuration" />}
        </Tabs>
        <TabPanel value={value} index={0}>
          {tabView && (tabView?.readGeofence) && (<><ListContextProvider
            debounce={8000}
            value={{
              data,
              filterValues,
              page,
              setFilters,
              setPage,
              setPerPage,
              ...listContext,
            }}
          >
            <VTSListToolbar filters={filters} actions={<PanicActions />} />
            {formValues.map === false ? (
              <>
                {matches == true ? (
                  <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />

                ) : (
                  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
                )}
                <Box className="list-content">
                  <Datagrid bulkActionButtons={false} empty={<CustomEmpty />}>
                    <TextField label="Name" source="name" />
                    <TextField label="Description" source="description" />
                    <TypeField label="Type" />
                    <SpeedField label="Speed Limit" />
                    <TextField label="Created By" source="createdByUser" />
                    <DateSetter label="Created On" source="createdon" />
                    <TextField label="Modified By" source="modifiedByUser" />
                    <DateSetter label="Modified On" source="lastupdate" />
                    <span label="Action" className="action-icons">
                      {tabView && (tabView?.updateGeofence) && (<><CustomEditButton source="id" /></>)}
                      {tabView && (tabView?.deleteGeofence) && (<><CustomDeleteButton source="id" /></>)}
                    </span>
                  </Datagrid>
                </Box>
              </>
            ) : (<>
              <Box className="map-content">
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "99%",
                    "& a": {
                      textDecoration: "none",
                      color: "inherit",
                    },
                  }}
                >
                  <GeofenceMapView data={data}></GeofenceMapView>
                </Card>
              </Box>
            </>
            )}
          </ListContextProvider>
            <Confirm
              isOpen={deleteDialog?.open}
              title="Delete Geofence"
              content="Are you sure you want to delete the geofence?"
              onConfirm={handleConfirmDelete}
              onClose={handleConfirmClose}
            />
            <Creategeofence
              Geofopen={Geofopen}
              handleGeofClose={handleGeofClose}
            ></Creategeofence>
            <EditGeofence
              EditGeofopen={EditGeofopen}
              listContext={listContext}
              handleEditGeofClose={handleEditGeofClose}
              EditGeofenceId={EditGeofenceId}
            ></EditGeofence>
            <SendNotification
              data={notifyData}
              onClose={() => setNotifyData({})}
            /></>)}
        </TabPanel>

        <Modal
          open={open}
          onClose={handleClose}
          className="bulkup-modal"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Bulk Upload
            </Typography>
            <div style={errorstyle}>{ErrorMessage}</div>
            <form id="file-upload-form" class="uploader">
              <input id="file-upload" type="file" name="fileUpload" onChange={handlefileChange} />
              <label for="file-upload">
                <div class="imagePreview">
                  <div id="start">
                    <UploadIcon />
                    <p>Select a file to import or Drag & Drop here</p>
                  </div>
                  <p>Only .xls or .xlsx format</p>
                  {fileName == "" ? <></> : <span><XLIcon />{fileName}</span>}
                </div>
              </label>
            </form>
            <a
              href={urls.GEOFENCE_SAMPLE_FILE_URL}
              download
              className="normal-a"
            >
              Click here to download sample excel sheet
            </a>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  className="cancel-btn"
                  color="primary"
                  sx={{ marginRight: "1em" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  className="upload-btn"
                  onClick={UploadFile}
                >
                  Upload
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Snackbar open={successDialog} autoHideDuration={6000} onClose={FileupClose} className="sucess-file-alert">
          {(UpdateStatus?.errors?.length > 0 || UpdateStatus.message === "ERROR TO UPLOAD") ?
            <Alert severity="error" sx={{ width: '100%' }}>
              <h4>Status</h4>
              <p>{UpdateStatus.message}</p>
              {
                UpdateStatus?.errors?.length > 0 ?
                  UpdateStatus?.errors?.map((errormsg, index) => {
                    return <p>{errormsg.id}</p>
                  }) : ""
              }
            </Alert>
            :
            <Alert severity="success" sx={{ width: '100%' }}>
              <h4>Status</h4>
              <p>{UpdateStatus.message}</p>
            </Alert>
          }

        </Snackbar>

      </Box>
    );
  }
};
