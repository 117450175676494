import { useContext, useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import urls from '../constants';
import { setCookie } from '../utils/cookies';
import { PositionContext } from '../utils/PositionContext';
import { exists } from '../utils/validate';
import useThrottle from '../hooks/useThrottle';

function usePosition() {
  let wsUrl = urls.WEBSOCKET_URL.replace(/http/, 'ws');

  const [socketUrl, setSocketUrl] = useState(wsUrl + "?deviceId=0");
  const { lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl);
  const { positions, setPositions } = useContext(PositionContext);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(() => {
    if (exists(lastMessage)) {
      let obj = JSON.parse(lastMessage.data);
      if (exists(obj) && Array.isArray(obj.positions)) {
        if (JSON.stringify(obj.positions) !== JSON.stringify(positions)) {
          setPositions(obj.positions);
        }
      }
    } else {
      console.log("Websocket " + socketUrl + " in state:" + connectionStatus);
    }
  }, [lastMessage, socketUrl]);

  return [positions, setPositions, setSocketUrl];
}

export default usePosition;