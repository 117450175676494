import React, { useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import Vehiclemapshare from "./vehiclemapshare";
import { PositionContextProvider } from "../../utils/PositionContext";

export const VehicleDetails = () =>{
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const value = queryParams.get('id');

    return(
        <PositionContextProvider>
       <Vehiclemapshare value={value}/>
        </PositionContextProvider>
    )
      
}
