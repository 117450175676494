import * as React from "react";
import DollarIcon from "@mui/icons-material/AttachMoney";
import { useListController, useTranslate } from "react-admin";

import CardWithIcon from "./CardwithIcon";

const IdleVehicle = ({ overviewData }) => {
  const statusValues = overviewData ? overviewData[0] : null;
  const translate = useTranslate();

  return (
    <>
      <CardWithIcon
        to="/vehicles"
        title={translate(`Idle Vehicle`)}
        subtitle={statusValues?.vehicle_idle}
        status="Idle"
        icon={DollarIcon}
        statusValues={statusValues}
      />
    </>
  );
};

export default IdleVehicle;
