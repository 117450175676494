import React, { useEffect, useState } from "react";
import { Button } from "react-admin";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { Grid, Card } from "@mui/material";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import "./cpanel.css";
import VerifiedIcon from "../../images/resolved.png";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import UsersPanel from "../../components/UsersPanel";
import GroupsPanel from "../../components/GroupsPanel";
import DevicesPanel from "../../components/DevicesPanel";
import useTabPermissions from "../../hooks/useTabPermissions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const selectOptions = [
  { value: "ocean1", label: "Ocean" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
  { value: "yellow", label: "Yellow" },
  { value: "green", label: "Green" },
  { value: "forest", label: "Forest" },
  { value: "slate", label: "Slate" },
  { value: "silver", label: "Silver" },
];

const TabPanel = (props) => {
  const { index, value, children } = props;
  return (
    <React.Fragment>
      {value === index && <Typography>{children}</Typography>}
    </React.Fragment>
  );
};

export default function ControlPanelList() {
  const [value, setValue] = React.useState(0);
  const [sucsopen, setsucessOpen] = useState(false);
  const [optionSelected1, setOptValue1] = React.useState(0);
  const [optionSelected2, setOptValue2] = React.useState(0);
  const [optionSelected3, setOptValue3] = React.useState(0);
  const [optionSelected4, setOptValue4] = React.useState(0);
  const [optionSelected5, setOptValue5] = React.useState(0);
  const [optionSelected6, setOptValue6] = React.useState(0);
  const tabView = useTabPermissions('ControlPanel')
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const SuccessOpen = () => {
    setsucessOpen(true);
  };
  const SuccessClose = () => {
    setsucessOpen(false);
  };
  const optionsChange1 = (selected) => {
    setOptValue1(selected);
  };
  const optionsChange2 = (selected) => {
    setOptValue2(selected);
  };
  const optionsChange3 = (selected) => {
    setOptValue3(selected);
  };
  const optionsChange4 = (selected) => {
    setOptValue4(selected);
  };
  const optionsChange5 = (selected) => {
    setOptValue5(selected);
  };
  const optionsChange6 = (selected) => {
    setOptValue6(selected);
  };

  const selectData = [
    { value: "mobile", label: "Mobile" },
    { value: "laptop", label: "Laptop" },
    { value: "tablet", label: "Tablet" },
    { value: "pc", label: "PC" },
    { value: "computer", label: "Computer" },
    { value: "mac", label: "Mac" },
  ];

  const fields = { text: "Device", value: "Id" };

  return (
    <Box sx={{ width: "100%", typography: "body1" }} className="cpanel-sec">
      {tabView && (tabView?.viewControlPanel) && (<><Tabs value={value} onChange={handleChange} className="tab-top">
        <Tab label="User" />
        <Tab label="Device" />
        <Tab label="Group" />
      </Tabs></>)}
      <Box className="map-content">
        {tabView && (tabView?.readControlPanel) && <><TabPanel value={value} index={0}>
          <UsersPanel />
        </TabPanel>
          <TabPanel value={value} index={1}>
            <DevicesPanel />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <GroupsPanel />
          </TabPanel></>}
      </Box>
    </Box>
  );
}
