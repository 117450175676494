import React, { useState, useEffect, useRef, useCallback } from 'react';
import Menu from "@mui/material/Menu";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import urls from '../constants';
import { setCookie } from '../utils/cookies';
import { Typography, Box, Card } from "@mui/material";
import Notif1 from '../../src/images/Notif1.svg';
import Notif2 from '../../src/images/Notif2.svg';
import Notif3 from '../../src/images/Notif3.svg';
import Notif4 from '../../src/images/Notif4.svg';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { useListController, useGetList, useRedirect, useInfiniteGetList } from "react-admin";
import moment from "moment";
import { Link } from 'react-router-dom';
import { exists } from '../utils/validate';
import useNotification from '../hooks/useNotification';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

export default function NotificationMenu(notify, menuId, isNotifyOpen, notifyMenuClose, setNoticount) {
  const redirect = useRedirect()
  const [notificationpop, setnotificationpop] = useState([])
  const [alarmnotify, setAlarmnotify] = useState([])
  const [loading, setLoading] = React.useState(false);
  function handleClick() {
    setLoading(true);
  }

  const handleNotication = () => {
    redirect('/notification');
  }

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteGetList("notifypopup", {
      pagination: { page: 1, perPage: 10 }
    });


  const [notificationerr, notification, setNotification, setSocketUrl] = useNotification();

  const observerElem = useRef(null);

  const handleObserver = useCallback(
    entries => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage]
  );
  useEffect(() => {
    const element = observerElem.current;
    if (!element) return;
    const option = { threshold: 0 };
    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [fetchNextPage, hasNextPage, handleObserver]);



  useEffect(() => {
    if (data) setAlarmnotify(data);
  }, [data])

  useEffect(() => {
    let notify = 0;
    let notifica = 0

    let temparr = [];
    if (notification) {
      notifica = notification?.length

      notification.map((web) => {
        temparr.push({
          id: web.id,
          devicename: web.deviceName,
          latitude: web.latitude,
          longitude: web.longitude,
          alerttype: web.type,
          eventtime: web.eventTime
        })
      })
    }

    if (alarmnotify) {
      if (alarmnotify?.pages?.length > 0) {
        notify = alarmnotify?.pages[0]?.total * 1
        alarmnotify?.pages?.map((alarmdt, index) => {
          alarmdt?.data?.map((noti) => {
            temparr.push({
              id: noti.id,
              devicename: noti.devicename,
              latitude: noti.latitude,
              longitude: noti.longitude,
              alerttype: noti.alerttype,
              eventtime: noti.eventtime
            })
          })
        })
      }
    }

    setnotificationpop(temparr)
    setNoticount(notify + notifica)

  }, [alarmnotify])



  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const get12hoursformat = (date1) => {
    var date = new Date(date1);
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? "PM" : "AM";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var time = hours + ":" + minutes + ":" + seconds + " " + am_pm;
    return time;
  }

  return (
    <Menu
      anchorEl={notify}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isNotifyOpen}
      onClose={notifyMenuClose}
      className="drop-notification"
    >
      <ul class="dropdown-menu notifications" role="menu">
        <div class="notification-heading" style={{ textAlign: "center" }}><h4 class="menu-title">Notifications</h4>
        </div>

        <li class="divider"></li>


        {

          notificationpop ?
            notificationpop?.map((alarmnoti, index) => {
              let imageicon = "";
              if ((alarmnoti.alerttype).indexOf("device") > -1)
                imageicon = Notif1;
              else if ((alarmnoti.alerttype).indexOf("route") > -1)
                imageicon = Notif3;
              else if ((alarmnoti.alerttype).indexOf("geo") > -1)
                imageicon = Notif4;
              else
                imageicon = Notif2;

              return (
                <>  <div class="notifications-wrapper">
                  <Link to='/alarm' state={{ id: alarmnoti.id }} class="content">

                    <div class="notification-item">
                      <img src={imageicon} />
                      <div className="item-detail">
                        <h4 class="item-title">{alarmnoti.alerttype}</h4>
                        <p class="item-info">Device Name : {alarmnoti.devicename}</p>
                        <p class="item-info">Latitude : {alarmnoti.latitude}</p>
                        <p class="item-info">Longitude : {alarmnoti.longitude}</p>
                        <p class="item-date">{moment(alarmnoti.eventtime).format("DD-MMM-YYYY")} <span class="item-date">{get12hoursformat(alarmnoti.eventtime)}</span></p>
                      </div>
                    </div>
                  </Link>
                </div>
                  <li class="divider"></li></>
              )
            }
            ) : <div>No notification</div>
        }

        {hasNextPage &&
          <div className='load-more'>
            <LoadingButton
              size="small"
              disabled={isFetchingNextPage}
              onClick={() => { handleClick(); fetchNextPage() }}
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >
              <span>Load More</span>
            </LoadingButton>
          </div>
        }

      </ul>
    </Menu>
  );
}