import React, { useState, useEffect } from "react";
import {
  Datagrid,
  TextField,
  useRecordContext,
  DateField,
  Button,
  useListController,
  ListContextProvider,
  TextInput,
  Pagination,
  ListToolbar,
  SET_FILTER,
} from "react-admin";
import moment from "moment";
import { Box, Typography, Card, LinearProgress } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function AuditRecords({ viewReport, setViewReport }) {
  const [filter, setFilter] = useState({
    search: "",
    from: "",
    to: "",
    component: "",
  });

  const {
    data,
    isLoading,
    isFetching,
    page,
    setPage,
    setPerPage,
    ...listContext
  } = useListController({
    resource: "audittrails",
    filter: {
      reportData: viewReport,
    },
  });

  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const matches = useMediaQuery("(min-width:1800px)");

  const totalaudit = listContext?.total?.toString();

  const handleChangeRowsPerPage = (event) => {
  };

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt =
      record[source] !== null
        ? moment(record[source]).format("DD/MM/YYYY, HH:mm:ss")
        : "";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  if (isFetching) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  } else
    return (
      <>
        <Box sx={{ padding: "0 20px", width: "75%" }}>
          <ListContextProvider
            value={{ data, page, setPage, setPerPage, ...listContext }}
          >
            {data === undefined ||
            (Array.isArray(data) === true && data.length === 0) ? (
              <>
                <div className="no-records">NO RECORDS</div>
              </>
            ) :  data?.length == 1 && data[0]['audit'] == 'noData'? (
              <></>
            ) : (
              <>
                {matches == true ? (
                  <Pagination
                    rowsPerPageOptions={[25, 50, 100, 150]}
                    perPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                ) : (
                  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
                )}
                <Box className="list-content">
                  <Datagrid bulkActionButtons={false}>
                    <TextField source="username" label="User name" />
                    <TextField source="component" label="Component" />
                    <TextField source="activity" label="Activity" />
                    <TextField source="statuscode" label="Status code" />
                    <TextField source="platform" label="Platform" />
                    <TextField source="description" label="Description" />
                    <DateSetter source="createdon" label="Created on" />
                    <TextField source="ipaddress" label="ipaddress" />
                  </Datagrid>
                </Box>
              </>
            )}
          </ListContextProvider>
        </Box>
      </>
    );
}
