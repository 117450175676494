import { stringify } from "query-string";
import urls from "../constants";
import httpClient from "../utils/httpclient";
import { exists, isEmpty } from "../utils/validate";

let apiUrl = null;
let options = {};
/**
 * @param {*} obj 
 */
const transformReportType = (json) => {
  const test = [];
  json?.forEach((obj, index) => {
    if (!test[index]) {
      const newObj = {
        ...obj,
        id: Math.floor(Math.random() * 1001),
      };

      test.push(newObj);
    }
  });
  return test;
};

const transformReportData = (json) => {
  const test = [];
  json?.data.forEach((obj, index) => {
    if (!test[index]) {
      const newObj = {
        ...obj,
        id: index + 1,
      };

      test.push(newObj);
    }
  });
  return test;
};

const transformReportRoute = (json, obj, index) => {
  if ("attributes" in obj && exists(obj.attributes)) {
    if ("priority" in obj.attributes) obj["priority"] = obj.attributes.priority;
    if ("sat" in obj.attributes) obj["sat"] = obj.attributes.sat;
    if ("event" in obj.attributes) obj["event"] = obj.attributes.event;
    if ("ignition" in obj.attributes) obj["ignition"] = obj.attributes.ignition;
    if ("motion" in obj.attributes) obj["motion"] = obj.attributes.motion;
    if ("rssi" in obj.attributes) obj["rssi"] = obj.attributes.rssi;
    if ("io200" in obj.attributes) obj["io200"] = obj.attributes.io200;
    if ("io69" in obj.attributes) obj["io69"] = obj.attributes.io69;
    if ("pdop" in obj.attributes) obj["pdop"] = obj.attributes.pdop;
    if ("hdop" in obj.attributes) obj["hdop"] = obj.attributes.hdop;
    if ("power" in obj.attributes) obj["power"] = obj.attributes.power;
    if ("battery" in obj.attributes) obj["battery"] = obj.attributes.battery;
    if ("io68" in obj.attributes) obj["io68"] = obj.attributes.io68;
    if ("operator" in obj.attributes) obj["operator"] = obj.attributes.operator;
    if ("odometer" in obj.attributes) obj["odometer"] = obj.attributes.odometer;
    if ("totalDistance" in obj.attributes)
      obj["totalDistance"] = obj.attributes.totalDistance;
    if ("hours" in obj.attributes) obj["hours"] = obj.attributes.hours;
    obj["attributes"] = {};
  }
  let res = {
    ...obj,
  };
  json[index] = res;
};

const reportProvider = {
  getList: async (resource, params) => {
    if ((
      resource === "miscellaneous" &&
      params?.filter?.reportData?.length == 0
    ) || (resource === "miscellaneous" && params?.filter?.reportData?.logType === "Live")) {
      return { data: [], total: 0 };
    }
    if (
      (resource === "reportsvehicle" &&
        params?.filter?.reportData?.length == 0) ||
      (resource === "reportsgroup" && params?.filter?.reportData?.length == 0)
    ) {
      return { data: [], total: 0 };
    } else {
      const apiUrl = getApiUrl(resource, params);
      const url = `${apiUrl}`;
      return await httpClient(url).then(({ headers, json }) => {
        let data = json?.data;

        if (Array.isArray(data)) data = data.filter((d) => d);
        if (resource === "reporttypes") data = transformReportType(json);
        else if (
          (resource === "reportsvehicle" || resource === "reportsgroup") &&
          (params.filter.reportData?.reporturl === "summary" ||
            params.filter.reportData?.reporturl === "stops")
        )
          data = transformReportData(json);
        else if (
          (resource === "reportsvehicle" || resource === "reportsgroup") &&
          params.filter.reportData?.reporturl === "route"
        )
          data.forEach((obj, index) => transformReportRoute(data, obj, index));
        let total = data?.length;

        if (exists(params.pagination))
          total = json?.noRecords ? json?.noRecords : json?.totalRecord;
        return { data: data, total: total };
      });
    }
  },

  getOne: async (resource, params) => {
    if (resource === "reportsexport") {
      apiUrl = getApiUrl(resource, params);
      const url = `${apiUrl}`;
      return await httpClient(url, (options = { headers: "export" }))
        .then(({ headers, data }) => {
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.setAttribute("download", data);
          document.body.appendChild(link);
          link.click();
          link.remove();
          return { data: { id: 0 } };
        })
        .catch((err) => console.error("#ERR", err));
    }
  },
};

export default reportProvider;

const getApiUrl = (resource, params) => {
  const query = {};
  let eventUrl = null;
  if (resource === "reporttypes" && exists(params.filter.type)) {
    apiUrl = urls.REPORT_TYPES_URL;
    query["dropDownKey"] = params.filter.type;
  } else if (
    resource === "reportsvehicle" &&
    exists(params.filter.reportData)
  ) {
    if (params.filter.reportData?.reporttype == "event") {
      let tempArr = [];
      params.filter.reportData?.selectedOptions.map((item, index) => {
        tempArr.push("type=" + item?.label);
      });

      eventUrl = tempArr.join("&");
      apiUrl = `${urls.REPORTS_LIST_URL}/${params.filter.reportData?.reporturl}/`;

      query["from"] = params.filter.reportData?.from;
      query["to"] = params.filter.reportData?.to;
      query["deviceId"] = params.filter.reportData?.deviceId;
    } else {
      apiUrl = `${urls.REPORTS_LIST_URL}/${params.filter.reportData?.reporturl}`;
      query["from"] = params.filter.reportData?.from;
      query["to"] = params.filter.reportData?.to;
      query["deviceId"] = params.filter.reportData?.deviceId;
    }
  } else if (resource === "reportsgroup" && exists(params.filter.reportData)) {
    if (params.filter.reportData?.reporttype == "event") {
      let tempArr = [];
      params.filter.reportData?.selectedOptions.map((item, index) => {
        tempArr.push("type=" + item?.label);
      });

      eventUrl = tempArr.join("&");
      apiUrl = `${urls.REPORTS_LIST_URL}/${params.filter.reportData?.reporturl}/`;
      query["from"] = params.filter.reportData?.from;
      query["to"] = params.filter.reportData?.to;
      query["groupId"] = params.filter.reportData?.groupId;
    } else {
      apiUrl = `${urls.REPORTS_LIST_URL}/${params.filter.reportData?.reporturl}`;
      query["from"] = params.filter.reportData?.from;
      query["to"] = params.filter.reportData?.to;
      query["groupId"] = params.filter.reportData?.groupId;
    }
  } else if (
    resource === "reportsexport" &&
    exists(params.meta?.reportData) &&
    params.meta?.reportData?.reporturl
  ) {
    if (params.meta?.reportData?.reporturl === "route")
      apiUrl = `${urls.REPORTS_LIST_URL}/routes/export`;
    else
      apiUrl = `${urls.REPORTS_LIST_URL}/${params.meta?.reportData?.reporturl}/export`;
    const exportParams = params.meta?.reportData?.groupId
      ? "groupId"
      : "deviceId";
    query["from"] = params.meta?.reportData?.from;
    query["to"] = params.meta?.reportData?.to;
    query[exportParams] = params.meta?.reportData?.groupId
      ? params.meta?.reportData?.groupId
      : params.meta?.reportData?.deviceId;
    query["mail"] = false;
    if (params.meta?.reportData?.reporturl === "summary") query["daily"] = true;
  } else if (
    resource === "reportsexport" &&
    exists(params.meta?.reportData) &&
    params.meta?.reportData?.type == "geofences"
  ) {
    apiUrl = `${urls.REPORTS_LIST_URL}/geofences/export`;
    query["geofenceId"] = params.meta.reportData?.geofenceId;
    query["from"] = params.meta?.reportData?.from;
    query["to"] = params.meta?.reportData?.to;
  } else if (
    resource === "reportsexport" &&
    exists(params.meta?.reportData) &&
    params.meta?.reportData?.type == "panics"
  ) {
    apiUrl = `${urls.REPORTS_LIST_URL}/panics/export`;
    query["groupId"] = params.meta.reportData?.groupId;
    query["deviceId"] = params.meta.reportData?.deviceId;
    query["from"] = params.meta?.reportData?.from;
    query["to"] = params.meta?.reportData?.to;
  } else if (
    resource === "reportsexport" &&
    exists(params.meta?.reportData) &&
    params.meta?.reportData?.type == "reportedViolation"
  ) {
    apiUrl = `${urls.REPORTS_LIST_URL}/permit/violations/reported/export`;
    query["from"] = params.meta?.reportData?.from;
    query["to"] = params.meta?.reportData?.to;
  } else if (
    resource === "reportsexport" &&
    exists(params.meta?.reportData) &&
    params.meta?.reportData?.type == "generatedViolation"
  ) {
    apiUrl = `${urls.REPORTS_LIST_URL}/permit/violations/generated/export`;
    query["from"] = params.meta?.reportData?.from;
    query["to"] = params.meta?.reportData?.to;
  } else if (
    resource === "miscellaneous" &&
    exists(params.filter?.reportData)
  ) {
    if (params?.filter?.reportData?.type == "panics") {
      apiUrl = `${urls.REPORTS_LIST_URL}/${params?.filter?.reportData?.type}`;
      query["deviceId"] = params.filter.reportData?.deviceId;
      query["groupId"] = params.filter.reportData?.groupId;
      query["from"] = params.filter?.reportData?.from;
      query["to"] = params.filter?.reportData?.to;
    } else if (params?.filter?.reportData?.type == "geofences") {
      apiUrl = `${urls.REPORTS_LIST_URL}/${params?.filter?.reportData?.type}`;
      query["geofenceId"] = params.filter.reportData?.geofenceId;
      query["from"] = params.filter?.reportData?.from;
      query["to"] = params.filter?.reportData?.to;
    } else if (params?.filter?.reportData?.type == "loginstats") {
      apiUrl = `${urls.USERS_URL}/session/report`;
      query["searchKey"] = params.filter.reportData?.name;
      query["asc"] = true;
      query["sortedField"] = "status";
    } else if (params?.filter?.reportData?.type == "reportedViolation") {
      apiUrl = `${urls.REPORTS_LIST_URL}/permit/violations/reported`;
      query["from"] = params.filter?.reportData?.from;
      query["to"] = params.filter?.reportData?.to;
    } else if (params?.filter?.reportData?.type == "generatedViolation") {
      apiUrl = `${urls.REPORTS_LIST_URL}/permit/violations/generated`;
      query["from"] = params.filter?.reportData?.from;
      query["to"] = params.filter?.reportData?.to;
    } else if (params?.filter?.reportData?.type == "devicelogs") {
      apiUrl = `${urls.DEVICE_LOG_HISTORY}`;
      query["from"] = params.filter?.reportData?.from;
      query["to"] = params.filter?.reportData?.to;
      query["deviceId"] = params.filter.reportData?.deviceId;
    } else {
      apiUrl = `${urls.DEVICE_LIST}`;
      query["vltdMake"] = params.filter.reportData?.vltdMake;
      query["vltdModel"] = params.filter.reportData?.vltdModel;
      query["asc"] = true;
      query["sortedFields"] = "id";
    }
  }

  if (exists(params.pagination)) {
    if ("page" in params.pagination)
      query["currentPage"] = params.pagination.page;
    if ("perPage" in params.pagination)
      query["pageSize"] = params.pagination.perPage;
  }
  if (!isEmpty(query)) {
    if (eventUrl != null) apiUrl = `${apiUrl}?${stringify(query)}&${eventUrl}`;
    else apiUrl = `${apiUrl}?${stringify(query)}`;
  }

  return apiUrl;
};

export const exportDevice = async () => {
  let apiUrl = urls.NON_COMMUNICATION_DEVICES_EXPORT
  const url = `${apiUrl}`;
  window.location.href = url;
};
