import React, { useState, useEffect } from "react";
import "./header.css";
import { Typography } from "@mui/material";
import { Avatar, Button, Card, CardActions, TextField } from "@mui/material";
import Logo from "../../images/logo.svg";
import TransportLogo from "../../images/transport_department_logo.jpeg";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { Form, TextInput } from "react-admin";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import ArrowIcon from "@mui/icons-material/ArrowCircleLeft";
import { sendForgotPasswordOTP } from "../../providers/dataProvider";
import SendNotification from "../../utils/sendNotification";

const ForgotPassword = () => {
  const [notifyData, setNotifyData] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({
    A: {
      email: true,
    },
    B: {
      invalid: true,
    },
  });
  const [formValues, setFormValues] = useState({ email: "" });
  const navigate = useNavigate();
  let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const ferror = { ...formError };
    ferror.A["email"] = false;
    if (formValues["email"] == "" || formValues["email"] == null)
      ferror.A["email"] = true;
    ferror.B["invalid"] = false;
    if (formValues["email"] != "") {
      if (!formValues?.email.match(regexEmail)) ferror.B["invalid"] = true;
    }
    setFormError(ferror);
  }, [formValues]);

  
  const handleSubmit = async () => {
    let result = {};
    setLoading(true);
    await sendForgotPasswordOTP(formValues, result);
    if (result?.res == "SUCCESS") {
      navigate("/otp-verfication", { state: formValues });
      setLoading(false);
    } else if (result?.res == "FAILED") {
      setNotifyData({
        open: true,
        message: "FAILED TO SENT OTP !",
        severity: "warning",
        duration: 4000,
      });
      setLoading(false);
    }
  };

  const handleRedirect = () =>{
    navigate("/login");
  }
  
  return (
    <>
      <Form>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "flex-start",
            flexGrow: 1,
          }}
          className="login-sec forgotpwd"
        >
          <Grid container spacing={2}>
            <Grid item xs={7}>
              
              <Card
                sx={{ height: "60vh", marginTop: "6em", boxShadow: "none" }}
                className="bg-image"
              ></Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                sx={{
                  minWidth: 382,
                  marginTop: "2em",
                  padding: "18px 42px !important",
                }}
              >
                <Box
                  sx={{
                    margin: "1em",
                    justifyContent: "center",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={1}>
                    <div style={{ display: "flex",position: "relative",right: "45px" }} className="backtopage" onClick={handleRedirect}>
                      <ArrowIcon className="arrow-icon" />
                      <Typography color="textSecondary" variant="h" component="h4">
                        Back
                      </Typography>
                    </div>
                    </Grid>
                    <Grid item xs={10} className="logo-box">
                      <img src={TransportLogo} />
                      <Typography variant="h5" component="h2" align="center">
                        Vehicle Tracking System
                      </Typography>
                      <Typography variant="p" component="p" align="center">
                        Transport Department, Chhattisgarh
                      </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    margin: "1em",
                    justifyContent: "center",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Typography variant="h5" component="h4" align="center">
                        Forgot Password
                      </Typography>
                      <Typography
                        variant="p"
                        component="p"
                        align="center"
                        className="f-text"
                      >
                        Enter the email associated with your account, we will
                        send an OTP to your registered mail id.
                      </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Box>
                <Box sx={{ padding: "0 1em 1em 1em" }}>
                  <Box sx={{ marginTop: "2em", paddingTop: "10px" }}>
                    <TextField
                      type="email"
                      name="email"
                      error={formError?.A["email"] || formError?.B["invalid"]}
                      helperText={
                        formError?.A["email"]
                          ? "Email Required!"
                          : formError?.B["invalid"]
                          ? "Please enter a valid Email!"
                          : ""
                      }
                      value={formValues?.email}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Box>
                </Box>
                <CardActions sx={{ padding: "0 1em 1em 1em" }}>
                  <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    variant="outlined"
                    fullWidth
                    disabled={
                      formError.A["email"] || formError.B["invalid"] || loading
                    }
                    className={
                      formError.A["email"] || formError.B["invalid"] || loading
                        ? "layout-disable"
                        : ""
                    }
                    onClick={handleSubmit}
                  >
                    Send Verification Code
                  </LoadingButton>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Box>
      </Form>
      <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
    </>
  );
};

export default ForgotPassword;
