import React, { useEffect, useState } from "react";
import { useGetOne, useGetList, useRefresh } from "react-admin";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import vtsDataProvider, { getData } from "./../providers/dataProvider";
import FormTextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import SendNotification from "../utils/sendNotification";
import { Tabs, Tab, Card } from "@mui/material";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";
import "../views/group/group.css";
import moment from "moment";
import { TabPanel } from "./../utils/TabPanel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

let date = new Date();
const defaultValues = {
  id: 0,
  attributes: {},
  groupId: 0,
  name: "",
  uniqueId: "",
  status: "",
  lastUpdate: "",
  positionId: 0,
  geofenceIds: [],
  phone: "",
  vehicleModel: "",
  contact: "",
  category: "",
  disabled: false,
  vltdmodel: "",
  serialNo: "",
  iccid: "",
  createdon: "",
  vltd_make: "",
  simno1: "",
  network1: "",
  sim1_expiry: "",
  simno2: "",
  network2: "",
  sim2_expiry: "",
  vstate: "",
  rto_code: "",
  vehicleMake: "",
  chasisno: "",
  engineno: "",
  manufacture_year: "",
  permit_holder: "",
  sos_status: false,
  sos_eventid: 0,
  otpverified: false,
  altContactPerson: "",
  altPhone: "",
  groupName: "",
  deviceActivationStatus: "",
  iccIdValidUpto: "",
  activationRcptNo: "",
  deviceActivatedUpto: "",
  deviceActivationDate: "",
  createdBy: 0,
  simProviderName: ""
};

const errorstyle = {
  color: "red",
  fontSize: "12px",
};

export const AddEntity = ({
  Accountopen,
  currentEntity,
  handleEntityClose,
  listContext,
  setCurrentEntity,
}) => {
  const [Entityvalue, setEntityValue] = useState(0);
  const [formValues, setFormValues] = useState(defaultValues);
  const [formError, setFormError] = useState({});
  const [notifyData, setNotifyData] = useState({});
  const [vltdno, setvltdno] = useState("");
  const [statedrp, setState] = useState([]);
  const [StateValue, setStateValue] = useState(null);
  const [makedrp, setMake] = useState([]);
  const [MakeValue, setMakeValue] = useState(null);
  const [rtoCodedrp, setRtoCodedrp] = useState([]);
  const [RtoValue, setRtoValue] = useState(null);
  const [simproviderdrp, setSimprovider] = useState([]);
  const [simprovidValue, setSimprovidValue] = useState(null);
  const [vehicleCategorydrp, setVehicleCategory] = useState([]);
  const [vehiclecategoryValue, setVehiclecategoryValue] = useState(null);
  const [newtab, setNewTab] = useState(0);
  const [notiopen, setNotiOpen] = useState(false);
  const [simData, setSimData] = useState({
    simCompany: "",
    iccId: "",
    uniqueId: "",
  });
  const [SimDetails, setOtpResponse] = useState(null);
  const [ErrorMsg, setErrorMsg] = useState("");
  //const [yeardrp, setYear] = useState([]);
  // const [yearValue, setYearValue] = useState(null);
  const [vahandisabled, setvahandisabled] = useState(false);
  const state = { button: 0 };
  const refresh = useRefresh();
  const [isSubmit, setIsSubmit] = useState(false);
  const [err, setErr] = useState("");

  const { data: stateList } = useGetList("state");
  const { data: vmakeList } = useGetList("vehiclemake");
  const { data: rtocodeList } = useGetList("rtocode");
  const { data: simprovidList } = useGetList("simprovider");
  const { data: vehiclecategoryList } = useGetList("vehiclecategory");

  var today = new Date();
  today = new Date(today.setDate(today.getDate()))
    .toISOString()
    .split("T")[0];



  // useEffect(() => {
  //   const yearlist = yeardropdown();
  //   setYear(yearlist);
  // }, []);

  useEffect(() => {
    if (currentEntity) {
      if (currentEntity.id > 0) {
        setFormValues(currentEntity);

        var Statevalue = statedrp.filter(function (item) {
          return item.showlabel === currentEntity.vstate;
        });

        if (Statevalue) setStateValue(Statevalue[0]);

        var Makevalue = makedrp.filter(function (item) {
          return item.showlabel === currentEntity.vehicleMake;
        });

        if (Makevalue) setMakeValue(Makevalue[0]);

        var rtovalue = rtoCodedrp.filter(function (item) {
          return item.value === currentEntity.rto_code;
        });

        if (rtovalue) setRtoValue(rtovalue[0]);

        var categoryvalue = vehicleCategorydrp.filter(function (item) {
          return item.showlabel === currentEntity.category;
        });

        if (categoryvalue) setVehiclecategoryValue(categoryvalue[0]);

        var simproviName = simproviderdrp.filter(function (item) {
          return item.showlabel === currentEntity.simProviderName;
        });

        if (simproviName) setSimprovidValue(simproviName[0]);

        // var manu_YearValue = yeardrp.filter(function (item) {
        //   return item.value === currentEntity.manufacture_year;
        // });

        // if (manu_YearValue) setYearValue(manu_YearValue[0]);
        setvahandisabled(true);
      } else {
        setFormValues(defaultValues);
        setvahandisabled(false);
      }
    }
  }, [currentEntity]);

  useEffect(() => {
    if (vmakeList) {
      const list = formatdropdown(vmakeList);
      setMake(list);
    }
  }, [vmakeList]);

  useEffect(() => {
    if (stateList) {
      const list = formatdropdown(stateList);
      setState(list);
    }
  }, [stateList]);

  useEffect(() => {
    if (simprovidList) {
      const list = formatdropdown(simprovidList);
      setSimprovider(list);
    }
  }, [simprovidList]);

  useEffect(() => {
    if (vehiclecategoryList) {
      const list = formatdropdown(vehiclecategoryList);
      setVehicleCategory(list);
    }
  }, [vehiclecategoryList]);

  useEffect(() => {
    if (rtocodeList) {
      const list = formatrtodropdown(rtocodeList);
      setRtoCodedrp(list);
    }
  }, [rtocodeList]);

  const formatrtodropdown = (list) => {
    var te = list?.map((data, index) => ({
      label: (data?.id).toString() + " - " + (data?.name).toString(),
      value: data?.code,
      showlabel: (data?.name).toString() + " (" + (data?.code).toString() + ")",
    }));
    return te;
  };

  const formatdropdown = (list) => {
    var te = list?.map((data, index) => ({
      label: (data?.id).toString() + " - " + (data?.name).toString(),
      value: data?.id,
      showlabel: data?.name,
    }));
    return te;
  };

  const yeardropdown = () => {
    const yeararr = [];
    const thisYear = new Date().getFullYear();

    for (let i = 0; i <= 50; i++) {
      const year = thisYear - i;
      yeararr.push(year);
    }

    var te = yeararr?.map((data, index) => ({
      label: data.toString(),
      value: data,
    }));
    return te;
  };

  useEffect(() => {
    if (Accountopen === true) {
      setEntityValue(0);
      setNewTab(0);
      //setFormValues(defaultValues);
    }
  }, [Accountopen]);

  const EntityhandleChange = (event, newValue) => {
    setEntityValue(newValue);
    // setFormError(validate(formValues));
  };

  const checknull = (pvalue) => {
    if (pvalue === "" || pvalue === undefined || pvalue === null) return ""
    return pvalue
  }

  const EntitytabChange = (newValue) => {
    setNewTab(newValue);
    setFormError(validate(formValues));
    // setEntityValue(newValue);
  };

  const {
    data: Vahandata,
    isLoading,
    error,
  } = useGetOne(
    "deviceinfovahan",
    { id: vltdno },
    { enabled: vltdno.length !== 0 }
  );


  useEffect(() => {
    const getSimData = async () => {
      if (simData.iccId !== "" && simData.iccId !== undefined) {
        const responsedata = await getData("simdata", simData, setOtpResponse);
      }
    };
    getSimData();
  }, [simData]);

  useEffect(() => {
    const getSimInfo = () => {
      if (SimDetails) {
        if (SimDetails?.Error) {
          setErrorMsg(SimDetails?.Error);
        }
        else {
          // setFormValues({
          //   ...formValues,
          //   simno1: SimDetails?.primaryMSISDN,
          //   network1: SimDetails?.primaryTSP,
          //   sim1_expiry: SimDetails?.expiredOn
          //     ? moment(SimDetails.expiredOn).format("YYYY-MM-DD")
          //     : null,
          //   simno2: SimDetails?.fallbackMSISDN,
          //   network2: SimDetails?.fallbackTSP,
          //   sim2_expiry: SimDetails?.expiredOn
          //     ? moment(SimDetails.expiredOn).format("YYYY-MM-DD")
          //     : null,
          // });

          setFormValues({
            ...formValues,
            simno1: SimDetails?.simno1,
            network1: SimDetails?.network1,
            sim1_expiry: SimDetails?.sim1_expiry
              ? moment(SimDetails.sim1_expiry).format("YYYY-MM-DD")
              : null,
            simno2: SimDetails?.simno2,
            network2: SimDetails?.network2,
            sim2_expiry: SimDetails?.sim2_expiry
              ? moment(SimDetails.sim2_expiry).format("YYYY-MM-DD")
              : null,
          });


          setErrorMsg("");
        }
        setSimData("");
      }
    };
    getSimInfo();
  }, [SimDetails]);


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name == "simno1" || name == "simno2" || name == "contact" || name == "altPhone") {
      const regex = /^[0-9\b]+$/;
      if (value == "" || regex.test(value)) {
        setFormValues({
          ...formValues,
          [name]: value,
        });
      }
    }
    else if (name == "permit_holder" || name == "altContactPerson" || name == "serialNo") {
      // let reg = /^[A-Za-z\s]*$/
      let reg = /^[A-Za-z0-9-_ ]+$/
      if (value == "" || reg.test(value)) {
        setFormValues({
          ...formValues,
          [name]: value,
        });
      }
    }
    else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }

  };

  useEffect(() => {
    const getVahanInfo = () => {
      if (Vahandata) {
        if (checknull(Vahandata?.error) !== "") {
          setFormError({ ...formError, serialNo: Vahandata?.error })
          setFormValues(defaultValues);
        } else {
          setFormError({ ...formError, serialNo: "" })
          setFormValues({
            ...formValues,
            id: Vahandata?.id,
            groupId: Vahandata?.groupId,
            name: Vahandata?.name === null ? "" : Vahandata?.name,
            uniqueId: Vahandata?.uniqueId === null ? "" : Vahandata?.uniqueId,
            status: Vahandata?.status,
            lastUpdate: Vahandata?.lastUpdate,
            positionId:
              Vahandata?.positionId === null ? 0 : Vahandata?.positionId,
            geofenceIds:
              Vahandata?.geofenceIds === null ? [] : Vahandata?.geofenceIds,
            phone: Vahandata?.phone === null ? "" : Vahandata?.phone,
            vehicleModel:
              Vahandata?.vehicleModel === null ? "" : Vahandata?.vehicleModel,
            contact: Vahandata?.contact,
            category: Vahandata?.category === null ? "" : Vahandata?.category,
            disabled: Vahandata?.disabled,
            vltdmodel: Vahandata?.vltdmodel,
            serialNo: Vahandata?.serialNo,
            iccid: Vahandata?.iccid,
            createdon: Vahandata?.createdon,
            vltd_make: Vahandata?.vltd_make,
            simno1: Vahandata?.simno1,
            network1: Vahandata?.network1,
            sim1_expiry: Vahandata?.sim1_expiry
              ? moment(Vahandata.sim1_expiry).format("YYYY-MM-DD")
              : null,
            simno2: Vahandata?.simno2,
            network2: Vahandata?.network2,
            sim2_expiry: Vahandata?.sim2_expiry
              ? moment(Vahandata.sim2_expiry).format("YYYY-MM-DD")
              : null,
            vstate: Vahandata?.vstate,
            rto_code: Vahandata?.rto_code,
            vehicleMake: Vahandata?.vehicleMake,
            chasisno: Vahandata?.chasisno,
            engineno: Vahandata?.engineno,
            manufacture_year: Vahandata?.manufacture_year
              ? moment(Vahandata.manufacture_year).format("YYYY-MM-DD")
              : null,
            permit_holder: Vahandata?.permit_holder,
            sos_status: Vahandata?.sos_status,
            sos_eventid: Vahandata?.sos_eventid,
            otpverified: Vahandata?.otpverified,
            altContactPerson: Vahandata?.altContactPerson,
            altPhone: Vahandata?.altPhone,
            groupName: Vahandata?.groupName,
            deviceActivationStatus: Vahandata?.deviceActivationStatus,
            iccIdValidUpto: Vahandata?.iccIdValidUpto,
            activationRcptNo: Vahandata?.activationRcptNo,
            deviceActivatedUpto: Vahandata?.deviceActivatedUpto,
            deviceActivationDate: Vahandata?.deviceActivationDate,
            createdBy: Vahandata?.createdBy,
            simProviderName: Vahandata?.simProviderName
          });

          var Statevalue = statedrp.filter(function (item) {
            return item.showlabel === Vahandata?.vstate;
          });

          if (Statevalue) setStateValue(Statevalue[0]);

          var Makevalue = makedrp.filter(function (item) {
            return item.showlabel === Vahandata?.vehicleMake;
          });

          if (Makevalue) setMakeValue(Makevalue[0]);

          var rtovalue = rtoCodedrp.filter(function (item) {
            return item.value === Vahandata?.rto_code;
          });

          if (rtovalue) setRtoValue(rtovalue[0]);

          var categoryvalue = vehicleCategorydrp.filter(function (item) {
            return item.showlabel === Vahandata?.category;
          });

          if (categoryvalue) setVehiclecategoryValue(categoryvalue[0]);

          var simproviName = simproviderdrp.filter(function (item) {
            return item.showlabel === Vahandata?.simProviderName;
          });

          if (simproviName) setSimprovidValue(simproviName[0]);

          // var manu_YearValue = yeardrp.filter(function (item) {
          //   return item.value === Vahandata?.manufacture_year;
          // });

          // if (manu_YearValue) setYearValue(manu_YearValue[0]);


          setvltdno("");

        }
      }
    };
    getVahanInfo();
  }, [Vahandata]);




  const validate = (value) => {
    const errors = {};
    const reg = /^[a-zA-Z0-9]{19}$/;
    const phoneRegex = /^[0-9]{1,16}$/;
    if (checknull(formValues?.serialNo) == "") {
      errors.serialNo = "Vltd No is required!";
    }
    else {
      // if (Entityvalue === 0) {
      if (checknull(formValues?.iccid) == "") {
        errors.iccid = "Iccid is required!";
      }
      if (checknull(formValues?.uniqueId) == "") {
        errors.uniqueId = "IMEI is required!";
      }
      if (checknull(formValues?.vltd_make) == "") {
        errors.vltdMake = "Vltd Make is required!";
      }
      if (checknull(formValues?.vltdmodel) == "") {
        errors.vltdModel = "Vltd Model is required!";
      }
      // }

      // if (!reg.test(formValues?.serialNo)) {
      //   if (formValues?.serialNo?.length < 15)
      //     errors.serialNo = "15 characters required!";
      //   else if (formValues?.serialNo?.length > 19)
      //     errors.serialNo = "Only 19 characters allowed!";
      // }

      // if (Entityvalue === 1) {
      if (checknull(simprovidValue) === null) {
        errors.simprovidValue = "Select Sim Provider!";
      }
      if (checknull(formValues?.simno1) == "") {
        errors.simno1 = "SimNo1 is required!";
      }
      if (checknull(formValues?.simno2) == "") {
        errors.simno2 = "SimNo2 is required!";
      }
      if (checknull(formValues?.simno1) != "") {
        if (phoneRegex.test(formValues?.simno1)) {
          if (formValues?.simno1?.length > 16)
            errors.simno1 = "Only 16 digits allowed!";
        } else {
          errors.simno1 = "Only 16 digits allowed!";
        }
      }
      if (checknull(formValues?.simno2) != "") {
        if (phoneRegex.test(formValues?.simno2)) {
          if (formValues?.simno2?.length > 16)
            errors.simno2 = "Only 16 digits allowed!";
        } else {
          errors.simno2 = "Only 16 digits allowed!";
        }
      }
      // if (formValues.sim1_expiry == "") {
      //   errors.sim1_expiry = "Sim1 Expiry is required!";
      // }
      // if (formValues.sim2_expiry == "") {
      //   errors.sim2_expiry = "Sim2 Expiry is required!";
      // }
      if (checknull(formValues?.network1) == "") {
        errors.network1 = "Network1 is required!";
      }
      if (checknull(formValues?.network2) == "") {
        errors.network2 = "Network2 is required!";
      }
      // }
      // if (Entityvalue === 2) {
      if (checknull(formValues?.chasisno) == "") {
        errors.chasisno = "Chassis No is required!";
      }
      if (checknull(formValues.name) == "") {
        errors.name = "Vehicle Name is required!";
      }
      if (checknull(formValues.vehicleMake) == "") {
        errors.make = "Vehicle Make is required!";
      }
      if (checknull(StateValue) == "") {
        errors.state = "State is required!";
      }
      if (checknull(formValues.engineno) == "") {
        errors.engineno = "Engine No is required!";
      }
      if (checknull(RtoValue) == "") {
        errors.rto = "Rto is required!";
      }

      if (checknull(formValues.manufacture_year) == "") {
        errors.year = "Manufacture year is required!";
      }

      // if (yearValue == null) {
      //   errors.year = "Year is required!";
      // }
      if (checknull(vehiclecategoryValue) == "") {
        errors.category = "Category is required!";
      }
      // }
      // if (Entityvalue === 3) {

      if (checknull(formValues.permit_holder) == "") {
        errors.permitHolder = "Permit Holder is required!";
      }
      if (checknull(formValues.contact) == "") {
        errors.contact = "Contact Number required!";
      }
      if (checknull(formValues.altContactPerson) == "") {
        errors.driverName = "Driver name is required!";
      }
      if (checknull(formValues.altPhone) == "") {
        errors.driverPhone = "Driver Contact Number is required!";
      }
      // }
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      if (currentEntity.id > 0) {
        vtsDataProvider.create(
          "groups",
          formValues,
          setNotifyData,
          "Entity Updated Successfully",
          "Failed to Update Entity"
        );

        setIsSubmit(false);
      } else if (
        formValues?.serialNo != "" &&
        formValues?.iccid != "" &&
        formValues?.uniqueId != ""
      ) {
        vtsDataProvider.create(
          "addentity",
          formValues,
          setNotifyData,
          "Entity Created Successfully",
          "Entity Creation Failed."
        );

        setIsSubmit(false);
      } else {
        setErr("Cannot Add Entity with empty iccid/imei/vltd no");
        return;
      }
      // }
    } else {
      setIsSubmit(false);
      if (
        "serialNo" in formError ||
        "iccid" in formError ||
        "uniqueId" in formError ||
        "vltd_make" in formError ||
        "vltdmodel" in formError
      )
        setEntityValue(0);
      else if (
        "simprovidValue" in formError ||
        "simno1" in formError ||
        "simno2" in formError ||
        "network1" in formError ||
        "network2" in formError
      )
        setEntityValue(1);
      else if (
        "chasisno" in formError ||
        "name" in formError ||
        "make" in formError ||
        "state" in formError ||
        "engineno" in formError ||
        "rto" in formError ||
        "category" in formError
      )
        setEntityValue(2);
      else if (
        "permitHolder" in formError ||
        "contact" in formError ||
        "driverName" in formError ||
        "driverPhone" in formError
      )
        setEntityValue(3);
      else setEntityValue(newtab);
    }
  }, [formError]);

  const handleEntitySubmit = (event) => {
    event.preventDefault();
    if (state.button === 1) {
      setvltdno(formValues.serialNo);
    }
    if (state.button === 3) {
      if (simprovidValue === null) {
        setErrorMsg("Select Sim Provider");
        return;
      }
      if (formValues?.iccid === "" || formValues?.iccid === undefined) {
        setErrorMsg("IccId Value required");
        return;
      }
      if (formValues?.uniqueId === "" || formValues?.uniqueId === undefined) {
        setErrorMsg("uniqueId Value required");
        return;
      }
      setErrorMsg("");
      setSimData({
        simCompany: simprovidValue?.showlabel,
        iccId: formValues?.iccid,
        uniqueId: formValues?.uniqueId,
      });
    }
    if (state.button === 2) {
      setFormError(validate(formValues));
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (checknull(notifyData?.severity) !== "") {
      if (checknull(notifyData.backendmessage) === "") {
        setFormValues(defaultValues);
        setCurrentEntity({});
        handleEntityClose();
        setNotiOpen(true);
      }

      if (notifyData?.severity === "success") {
        setTimeout(() => {
          refresh();
        }, 6000);
      }
    }
    else {
      setNotiOpen(false);
    }
  }, [notifyData]);


  useEffect(() => {
    setFormValues({ ...formValues, vstate: StateValue?.showlabel });
  }, [StateValue]);

  useEffect(() => {
    setFormValues({ ...formValues, vehicleMake: MakeValue?.showlabel });
  }, [MakeValue]);

  useEffect(() => {
    setFormValues({ ...formValues, rto_code: RtoValue?.value });
  }, [RtoValue]);

  useEffect(() => {
    setFormValues({ ...formValues, category: vehiclecategoryValue?.showlabel });
  }, [vehiclecategoryValue]);

  useEffect(() => {
    setFormValues({ ...formValues, simProviderName: simprovidValue?.showlabel });
  }, [simprovidValue]);

  const handleClose = () => {
    setFormValues(defaultValues);
    setFormError({});
    setIsSubmit(false);
    setRtoValue(null);
    setStateValue(null);
    setMakeValue(null);
    setVehiclecategoryValue(null);
    setErrorMsg("");
    setErr("")
    setNotiOpen(false);
    handleEntityClose();
  };

  const EntityBackTabChange = (newValue) => {
    setEntityValue(newValue);
  };

  return (
    <>
      <Modal
        open={Accountopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="new-entitity AddRoleModal"
      >
        <Box sx={style} className="modal-box">
          <form onSubmit={handleEntitySubmit}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {currentEntity.id > 0 ? "Edit Entity" : "Add Entity"}
            </Typography>
            <span className="close-btn" onClick={handleClose}>
              <CloseIcon />
            </span>
            <Tabs
              value={Entityvalue}
              onChange={EntityhandleChange}
              id="progressbar"
            >
              <Tab label="Device Details" />
              <Tab label="Sim Details" />
              <Tab label="Vehicle Details" />
              <Tab label="Permit Holder Details" />
            </Tabs>
            <Box className="right-side">
              <TabPanel value={Entityvalue} index={0}>
                <Box sx={{ flexGrow: 1, marginTop: "2em" }} className="div-box">
                  <Grid container spacing={2}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8} className="entity-details">
                      <Box sx={{ marginTop: "1em" }}>
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={6}
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              <FormLabel>VLTD Serial Number Name</FormLabel>
                              <FormTextField
                                name="serialNo"
                                placeholder="Enter Vltd No."
                                type="text"
                                value={formValues.serialNo}
                                onChange={handleInputChange}
                                sx={{ width: "230px" }}
                                required
                                inputProps={{ maxLength: 19 }}
                              />
                              <div style={errorstyle}>
                                {formError?.serialNo}
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                variant="contained"
                                onClick={() => (state.button = 1)}
                                color="primary"
                                type="submit"
                                disabled={vahandisabled}
                                sx={{
                                  background: "#4646F2",
                                  textTransform: "capitalize",
                                  width: "212px",
                                  marginTop: "40px",
                                  marginLeft: "-34px",
                                  border: "1px solid #4646F2",
                                }}
                              >
                                Fetch Details From Vahan
                              </Button>
                            </Grid>
                            <Grid item xs={2} sx={{ marginTop: "1em" }}>
                              <Typography variant="h6" component="p">
                                VLTD Make
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ marginTop: "1.5em" }}>
                              - <span>{formValues.vltd_make}</span>
                              <div style={errorstyle}>
                                {formError?.vltdMake}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              sx={{
                                marginTop: "1em",
                                paddingLeft: "0 !important",
                              }}
                            >
                              <Typography variant="h6" component="p">
                                ICCID Number
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ marginTop: "1.5em" }}>
                              - <span>{formValues.iccid}</span>
                              <div style={errorstyle}>{formError?.iccid}</div>
                            </Grid>
                            <Grid item xs={2} sx={{ marginTop: "1em" }}>
                              <Typography variant="h6" component="p">
                                VLTD Model
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ marginTop: "1.5em" }}>
                              - <span>{formValues.vltdmodel}</span>
                              <div style={errorstyle}>
                                {formError?.vltdModel}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              sx={{
                                marginTop: "1em",
                                paddingLeft: "0 !important",
                              }}
                            >
                              <Typography variant="h6" component="p">
                                IMEI Number
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ marginTop: "1.5em" }}>
                              - <span>{formValues.uniqueId}</span>
                              <div style={errorstyle}>
                                {formError?.uniqueId}
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2}></Grid>
                  </Grid>
                </Box>
                <div className="modal-footer">
                  <Button
                    variant="contained"
                    onClick={() => EntitytabChange(1)}
                    color="primary"
                  >
                    Next
                  </Button>
                </div>
              </TabPanel>

              <TabPanel value={Entityvalue} index={1}>
                <Box sx={{ flexGrow: 1, marginTop: "2em" }} className="div-box">
                  <Grid container spacing={2}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Box sx={{ marginTop: "2em" }}>
                              <div style={errorstyle}>{ErrorMsg}</div>
                              <FormControl>
                                <FormLabel>SIM Provider</FormLabel>
                                <Autocomplete
                                  isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                  }
                                  disablePortal
                                  id="simprovider"
                                  name="simprovider"
                                  className="select-auto"
                                  options={simproviderdrp}
                                  renderOption={(props, option) => (
                                    <li {...props} key={option.value}>
                                      {option.showlabel}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Sim Provider"
                                      InputProps={{
                                        ...params.InputProps,
                                      }}

                                    />
                                  )}
                                  getOptionLabel={(option) => option.showlabel}
                                  onChange={(event, newValue) => {
                                    setSimprovidValue(newValue);
                                  }}
                                  value={simprovidValue}
                                />
                              </FormControl>
                            </Box>
                            <div style={errorstyle}>
                              {formError?.simprovidValue}
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              className="fetch-btn"
                              color="primary"
                              type="submit"
                              sx={{
                                background: "#4646F2",
                                textTransform: "capitalize",
                                width: "212px",
                                marginTop: "3.5em",
                                marginLeft: "-34px",
                                border: "1px solid #4646F2",
                              }}
                              onClick={() => (state.button = 3)}
                            >
                              Fetch Data
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        sx={{
                          marginTop: "20px",
                          borderTop: "1.2px solid #eee",
                        }}
                      >
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Box sx={{ marginTop: "2em" }}>
                                <FormControl>
                                  <FormLabel>SIM Number 1</FormLabel>
                                  <FormTextField
                                    key="random1"
                                    name="simno1"
                                    placeholder="Enter sim no1"
                                    type="tel"
                                    value={formValues.simno1}
                                    onChange={handleInputChange}
                                    inputProps={{ maxLength: 13 }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </FormControl>
                              </Box>
                              <div style={errorstyle}>{formError?.simno1}</div>
                            </Grid>
                            <Grid item xs={6}>
                              <Box sx={{ marginTop: "2em" }}>
                                <FormControl>
                                  <FormLabel>Network 1</FormLabel>
                                  <FormTextField
                                    name="network1"
                                    placeholder="Enter network1"
                                    type="text"
                                    value={formValues.network1}
                                    onChange={handleInputChange}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </FormControl>
                              </Box>
                              <div style={errorstyle}>
                                {formError?.network1}
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <Box sx={{ marginTop: "17px" }}>
                                <FormControl>
                                  <FormLabel>Sim 1 Expiry</FormLabel>
                                  <FormTextField
                                    name="sim1_expiry"
                                    placeholder="choose sim1 expiry"
                                    type="date"
                                    sx={{ width: "205px", height: "37px" }}
                                    // inputProps={{ min: today }}
                                    value={formValues.sim1_expiry}
                                    onChange={handleInputChange}
                                    disabled
                                  />
                                </FormControl>
                              </Box>
                              <div style={errorstyle}>
                                {formError?.sim1_expiry}
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          marginTop: "20px",
                          borderTop: "1.2px solid #eee",
                        }}
                      >
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Box sx={{ marginTop: "25px" }}>
                                <FormControl>
                                  <FormLabel>SIM Number 2</FormLabel>
                                  <FormTextField
                                    name="simno2"
                                    placeholder="Enter sim no2"
                                    type="tel"
                                    value={formValues.simno2}
                                    inputProps={{ maxLength: 13 }}
                                    onChange={handleInputChange}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </FormControl>
                              </Box>
                              <div style={errorstyle}>{formError?.simno2}</div>
                            </Grid>
                            <Grid item xs={6}>
                              <Box sx={{ marginTop: "25px" }}>
                                <FormControl>
                                  <FormLabel>Network 2</FormLabel>
                                  <FormTextField
                                    name="network2"
                                    placeholder="Enter network2"
                                    type="text"
                                    value={formValues.network2}
                                    onChange={handleInputChange}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </FormControl>
                              </Box>
                              <div style={errorstyle}>
                                {formError?.network2}
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <Box sx={{ marginTop: "17px" }}>
                                <FormControl>
                                  <FormLabel>Sim 2 Expiry</FormLabel>
                                  <FormTextField
                                    name="sim2_expiry"
                                    placeholder="choose sim2 expiry"
                                    type="date"
                                    sx={{ width: "205px", height: "37px" }}
                                    // inputProps={{ min: today }}
                                    value={formValues.sim2_expiry}
                                    onChange={handleInputChange}
                                    disabled
                                  />
                                </FormControl>
                              </Box>
                              <div style={errorstyle}>
                                {formError?.sim2_expiry}
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2}></Grid>
                  </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sx={{ marginTop: "1em" }}>
                      <Button
                        variant="contained"
                        onClick={() => EntityBackTabChange(0)}
                        className="cancel-btn"
                        color="primary"
                        sx={{ marginRight: "1em" }}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item xs={10} sx={{ marginTop: "1em" }}>
                      <div className="modal-footer">
                        <Button
                          variant="contained"
                          onClick={() => EntitytabChange(2)}
                          color="primary"
                        >
                          Next
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>

              <TabPanel value={Entityvalue} index={2}>
                <Box sx={{ flexGrow: 1, marginTop: "2em" }} className="div-box">
                  <Grid container spacing={2}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Box sx={{ marginTop: "1em" }}>
                              <FormControl>
                                <FormLabel>Vehicle Number</FormLabel>
                                <FormTextField
                                  name="name"
                                  type="text"
                                  placeholder="Enter"
                                  value={formValues.name}
                                  onChange={handleInputChange}
                                />
                              </FormControl>
                            </Box>
                            <div style={errorstyle}>{formError?.name}</div>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ marginTop: "1em" }}>
                              <FormControl sx={{ width: "94%" }}>
                                <FormLabel>Vehicle Make</FormLabel>
                                <Autocomplete
                                  isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                  }
                                  disablePortal
                                  id="vehicleMake"
                                  name="vehicleMake"
                                  className="select-auto"
                                  options={makedrp}
                                  renderOption={(props, option) => (
                                    <li {...props} key={option.value}>
                                      {option.showlabel}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Vehicle Make"
                                      InputProps={{
                                        ...params.InputProps,
                                      }}
                                    />
                                  )}
                                  getOptionLabel={(option) => option.showlabel}
                                  onChange={(event, newValue) => {
                                    setMakeValue(newValue);
                                  }}
                                  value={MakeValue}
                                />

                              </FormControl>
                            </Box>
                            <div style={errorstyle}>{formError?.make}</div>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ marginTop: "1em" }}>
                              <FormControl sx={{ width: "94%" }}>
                                <FormLabel>Vehicle Model</FormLabel>
                                <FormTextField
                                  name="vehicleModel"
                                  type="text"
                                  placeholder="Enter Model"
                                  value={formValues.vehicleModel}
                                  onChange={handleInputChange}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ marginTop: "17px" }}>
                              <FormControl>
                                <FormLabel>Chassis No</FormLabel>
                                <FormTextField
                                  name="chasisno"
                                  placeholder="Enter chasis No"
                                  type="text"
                                  value={formValues.chasisno}
                                  onChange={handleInputChange}
                                />
                              </FormControl>
                            </Box>
                            <div style={errorstyle}>{formError?.chasisno}</div>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ marginTop: "17px" }}>
                              <FormControl sx={{ width: "94%" }}>
                                <FormLabel>State</FormLabel>
                                <Autocomplete
                                  isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                  }
                                  disablePortal
                                  id="state"
                                  name="state"
                                  className="select-auto"
                                  options={statedrp}
                                  renderOption={(props, option) => (
                                    <li {...props} key={option.value}>
                                      {option.showlabel}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select State"
                                      InputProps={{
                                        ...params.InputProps,
                                      }}
                                    />
                                  )}
                                  getOptionLabel={(option) => option.showlabel}
                                  onChange={(event, newValue) => {
                                    setStateValue(newValue);
                                  }}
                                  value={StateValue}
                                />
                              </FormControl>
                            </Box>
                            <div style={errorstyle}>{formError?.state}</div>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ marginTop: "17px" }}>
                              <FormControl>
                                <FormLabel>Engine No</FormLabel>
                                <FormTextField
                                  name="engineno"
                                  placeholder="Enter Engine No"
                                  type="text"
                                  value={formValues.engineno}
                                  onChange={handleInputChange}
                                />
                              </FormControl>
                            </Box>
                            <div style={errorstyle}>{formError?.engineno}</div>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ marginTop: "17px" }}>
                              <FormControl>
                                <FormLabel>RTO Code</FormLabel>
                                <Autocomplete
                                  isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                  }
                                  disablePortal
                                  id="rto_code"
                                  name="rto_code"
                                  className="select-auto"
                                  options={rtoCodedrp}
                                  renderOption={(props, option) => (
                                    <li {...props} key={option.value}>
                                      {option.showlabel}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select RTO code"
                                      InputProps={{
                                        ...params.InputProps,
                                      }}
                                    />
                                  )}
                                  getOptionLabel={(option) => option.showlabel}
                                  onChange={(event, newValue) => {
                                    setRtoValue(newValue);
                                  }}
                                  value={RtoValue}
                                />
                              </FormControl>
                            </Box>
                            <div style={errorstyle}>{formError?.rto}</div>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ marginTop: "17px" }}>
                              <FormControl>
                                <FormLabel>Manufacturing Year</FormLabel>
                                <FormTextField
                                  name="manufacture_year"
                                  placeholder="choose manufacture year"
                                  type="date"
                                  sx={{ width: "205px", height: "37px" }}
                                  inputProps={{ max: today }}
                                  value={formValues.manufacture_year}
                                  onChange={handleInputChange}
                                />
                              </FormControl>
                            </Box>
                            <div style={errorstyle}>{formError?.year}</div>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ marginTop: "17px" }}>
                              <FormControl sx={{ width: "94%" }}>
                                <FormLabel>Vehicle Category</FormLabel>
                                <Autocomplete
                                  isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                  }
                                  disablePortal
                                  id="category"
                                  name="category"
                                  className="select-auto"
                                  options={vehicleCategorydrp}
                                  renderOption={(props, option) => (
                                    <li {...props} key={option.value}>
                                      {option.showlabel}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Vehicle Category"
                                      InputProps={{
                                        ...params.InputProps,
                                      }}
                                    />
                                  )}
                                  getOptionLabel={(option) => option.showlabel}
                                  onChange={(event, newValue) => {
                                    setVehiclecategoryValue(newValue);
                                  }}
                                  value={vehiclecategoryValue}
                                />
                              </FormControl>
                            </Box>
                            <div style={errorstyle}>{formError?.category}</div>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={2}></Grid>
                  </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sx={{ marginTop: "1em" }}>
                      <Button
                        variant="contained"
                        onClick={() => EntityBackTabChange(1)}
                        className="cancel-btn"
                        color="primary"
                        sx={{ marginRight: "1em" }}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item xs={10}>
                      <div
                        style={{ marginTop: "1em" }}
                        className="modal-footer"
                      >
                        <Button
                          variant="contained"
                          onClick={() => EntitytabChange(3)}
                          color="primary"
                        >
                          Next
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel value={Entityvalue} index={3}>
                <Box sx={{ flexGrow: 1, marginTop: "2em" }} className="div-box">
                  <Grid container spacing={2} className="name-permit">
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Box sx={{ marginTop: "2em" }}>
                              <FormLabel>Name of Permit Holder</FormLabel>
                              <FormTextField
                                name="permit_holder"
                                placeholder="Enter Name"
                                type="text"
                                value={formValues.permit_holder}
                                onChange={handleInputChange}
                              />
                            </Box>
                            <div style={errorstyle}>
                              {formError?.permitHolder}
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ marginTop: "2rem" }}>
                              <FormLabel>Contact #</FormLabel>
                              <FormTextField
                                name="contact"
                                placeholder="Enter 10 Digit Mobile"
                                type="tel"
                                value={formValues.contact}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 10 }}
                              />
                            </Box>
                            <div style={errorstyle}>{formError?.contact}</div>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ marginTop: "1em" }}>
                              <FormLabel>Driver Name</FormLabel>
                              <FormTextField
                                name="altContactPerson"
                                placeholder="Driver Name"
                                type="text"
                                value={formValues.altContactPerson}
                                onChange={handleInputChange}
                              />
                            </Box>
                            <div style={errorstyle}>
                              {formError?.driverName}
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ marginTop: "1em" }}>
                              <FormLabel>Driver Number</FormLabel>
                              <FormTextField
                                name="altPhone"
                                placeholder="Enter Driver Number"
                                type="tel"
                                value={formValues.altPhone}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 10 }}
                              />
                            </Box>
                            <div style={errorstyle}>
                              {formError?.driverPhone}
                            </div>

                            <div style={errorstyle}>{err}</div>
                          </Grid>
                        </Grid>

                        {(notifyData.backendmessage !== "" && notifyData.backendmessage !== undefined) ?
                          <div style={errorstyle} className="entity-error-msg">
                            <p>Entity creation failed for following reason :- </p>
                            <ul>
                              <li>{notifyData.backendmessage}</li>
                            </ul>
                          </div>
                          : ""}
                      </Box>
                    </Grid>

                  </Grid>
                </Box>

                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sx={{ marginTop: "1em" }}>
                      <Button
                        variant="contained"
                        onClick={() => EntityBackTabChange(2)}
                        className="cancel-btn"
                        color="primary"
                        sx={{ marginRight: "1em" }}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item xs={10} sx={{ marginTop: "1em" }}>
                      <div className="modal-footer">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => (state.button = 2)}
                          type="submit"
                        >
                          {currentEntity.id > 0 ? "Update" : "Submit"}
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleClose}
                          className="cancel-btn"
                          color="primary"
                          sx={{ marginRight: "1em" }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
            </Box>
          </form>
        </Box>
      </Modal>
      {notiopen === true ?
        <SendNotification data={notifyData} onClose={() => setNotifyData({})} /> : ""}
    </>
  );
};
