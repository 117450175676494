import React from 'react'
import noPageJpg from '../images/404.png';
import { Link, useNavigate } from "react-router-dom";

const PageNotAvailable = () => {
    const navigate = useNavigate();
        const handleButtonClick = () => {
            navigate('/');
        };


    return (
        <div className="container-fluid page-not-available-sec pl-5 pr-5">
            <div className="row mt-2">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                    <div className="text-center mt-5">
                        <img src={noPageJpg} alt="404"></img>
                        <h6 className='mt-3'>Maybe the page you are looking for is not found or never existed</h6>
                        <button type='button' className='btn mt-3' onClick={handleButtonClick} >Back to home</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotAvailable