import React from "react";
import Typography from '@mui/material/Typography';

const TabPanel = props => {

    const { index, value, children } = props;
    return (
      <React.Fragment>
        {value === index && (
  
          <Typography>{children}</Typography>
  
        )}
      </React.Fragment>
    )
  }


  export {TabPanel}