import React, { useEffect, useState } from "react";
import { fetchGroupList } from "../providers/dataProvider";
  

export const GroupContext = React.createContext({});
export const GroupContextProvider = ({ children }) => {
    const [groupList, setGroupList] = useState([]);
    return (
        <GroupContext.Provider value={{ groupList, setGroupList }}>
            {children}
        </GroupContext.Provider>
    );
};