import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import AlarmLogList from '../../components/AlarmLogList';
import AlarmConfigurationList from '../../components/AlarmConfigurationList';
import "./alarm.css";
import { useLocation } from "react-router-dom";
import { TitleContext } from '../../utils/TitleContext';
import useTabPermissions from '../../hooks/useTabPermissions';
import { connectProps } from '@devexpress/dx-react-core';


const TabPanel = props => {

  const { index, value, children } = props;
  return (
    <React.Fragment>
      {value === index && (

        <Typography>{children}</Typography>

      )}
    </React.Fragment>
  )
}

export const AlarmList = () => {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const { setTitle } = React.useContext(TitleContext);
  const [notilogId, setnotilogId] = React.useState(0);
  const [status, setStatus] = React.useState(null);

  const tabView = useTabPermissions('alarm')

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 

  useEffect(() => {
    location?.state?.status == 'alarm' && setValue(1);
    setStatus(location?.state?.status)
    if (location?.state?.id) {
      setTitle("Alarm");
      setnotilogId(location?.state?.id)
    }
  }, [location?.state]);

  useEffect(() => { setTitle("Alarm"); }, [])

  useEffect(() => {
    if (notilogId > 0) setValue(1);
  }, [notilogId])

  return (<>
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Tabs value={value} onChange={handleChange}>
        {tabView && (tabView?.viewAlarmConfig) && <Tab label="Configuration" />}
        {tabView && (tabView?.viewAlarmLog) && <Tab label="Logs" />}
      </Tabs>
      <TabPanel value={value} index={0}>
        {tabView && (tabView?.readAlarmConfig) &&
          <AlarmConfigurationList
            createper={tabView.createAlarm}
            updateper={tabView.updateAlarm}
            deleteper={tabView.deleteAlarm} />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {tabView && (tabView?.readAlarmLog) && <AlarmLogList notilogId={notilogId} setnotilogId={setnotilogId} status={status} />}
      </TabPanel>
    </Box>

  </>
  );
}



