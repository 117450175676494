import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material';
import {
  TextField,
  useTranslate,
  DateField,
  useListController,
  FunctionField,
  useRecordContext,
  useGetList,
  Datagrid,
  ListContextProvider,
  TextInput,
  Pagination,
  ListToolbar,
  TopToolbar,
  Button,
  useListContext
} from "react-admin";
import AddButton from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddAlarm from '../components/AddAlarm';
import VTSListToolbar from '../components/VTSListToolbar';
import { Box, LinearProgress } from '@mui/material';
import moment from "moment";
import { Popover, Whisper } from "rsuite";
import Viewmap from '../images/Viewmap.svg';
import vtsDataProvider ,{getVehicleLocation} from './../providers/dataProvider';
import useMediaQuery from '@mui/material/useMediaQuery';

const Buttonstyle = {
  position: 'absolute',
  background: '#4646F2',
  color: '#fff',
  zIndex: 1,
  textTransform: 'capitalize',
  width: '115px',
  border: '1px solid #4646F2'
}

export default function AlarmLogList({ notilogId, setnotilogId, status }) {

  const [Alarmopen, setAlarmopen] = React.useState(false);
  const [logId, setLogId] = React.useState(0);
  const [todayalarm, setTodayAlarm] = React.useState(null);
  const [resourcetype, setResourceType] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [loglist, setLoglist] = React.useState(25);
  const handleAlarmopen = () => setAlarmopen(true);
  const handleAlarmClose = () => setAlarmopen(false);

  const [filter, setFilter] = useState('');
  const translate = useTranslate();

  useEffect(()=>{
    if(status === "alarm")  setTodayAlarm(status)
  },[status])


  useEffect(()=>{
    if(todayalarm === "alarm")
    setResourceType("notifypopup")
    else
    setResourceType("alarmlog")
  },[todayalarm])

  useEffect(() => {
    if (notilogId > 0) {
      setLogId(notilogId);
      listContext.setFilters({});
    }
  }, [notilogId])

  useEffect(() => { setnotilogId(0); }, [logId])

  const perPage = 10;
  const sort = { field: 'createdon', order: 'DESC' };
  let { data, isLoading, page, setPage, setPerPage, ...listContext } = useListController({
    resource: resourcetype,
    filter: { id: logId },
  });

  let totallog = (typeof (listContext?.total) === "undefined") ? 0 : listContext?.total?.toString();

  if (logId > 0) totallog = 1;

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt = (record[source] !== null) ? moment(record[source]).format("DD MMM YYYY, HH:mm:ss") : "";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  useEffect(() => {
    var queryvalue = document.querySelectorAll('.list-content');
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const handleAddress = async (record) => {
    const address = {};
    if (!record?.latitude && !record?.longitude) return;
    await getVehicleLocation(record, address);
    document.getElementById(`addr_${record?.id}`)?.classList?.remove("address_styling");
    document.getElementById(`addr_${record?.id}`)?.classList?.add("address_len");
    document.getElementById(`addr_${record?.id}`).innerText = address?.address;
    document.getElementById(`addr_${record?.id}`).setAttribute("title", address?.address);
  };

  const AddressField = ({ source }) => {
    const record = useRecordContext();
    return (
      <>
        {record?.latitude != 0 && record?.longitude != 0 && <span
          id={`addr_${record?.id}`}
          className="address_styling"
          onClick={() => handleAddress(record)}
        >
          Show Address
        </span>}
      </>
    );
  };

  const filters = [<TextInput label="Search" source="q" size="small" alwaysOn />];
  const filterValues = { q: filter };
  const setFilters = filters => setFilter(filters.q);

  const matches = useMediaQuery('(min-width:1800px)');

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPerPage(event.target.value);
    };




  const PanicActions = () => (
    <TopToolbar className='Alarm-toolbar'>
    </TopToolbar>
  );

  const CustomEmpty = () => <div className="no-records">No Records</div>;

  const AttributeField = () => {
    const record = useRecordContext();
    return (
      <Whisper
        followCursor
        speaker={
          <Popover className="pop-over">
            <p>
              Result:
              <span>
                {record?.attributes?.result !== undefined
                  ? record?.attributes?.result
                  : "_____"}
              </span>
            </p>
            <p>
            Vehicle RegNo:
              <span>
              {record?.attributes?.vehicleRegNo !== undefined
                  ? record?.attributes?.vehicleRegNo
                  : "_____"}
              </span>
            </p>
            <p>
              Device IMEI:
              <span>
              {record?.attributes?.deviceIMEI !== undefined
                  ? record?.attributes?.deviceIMEI
                  : "_____"}
              </span>
            </p>
            <p>
              Alarm:
              <span>
              {record?.attributes?.alarm !== undefined
                  ? record?.attributes?.alarm
                  : "_____"}
              </span>
            </p>
            
          </Popover>
        }
      >
        <span className="address_styling">Show Attributes</span>
      </Whisper>
    );
  };

  if (isLoading) {
    return <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  }
  return (<>
    <ListContextProvider debounce={8000} value={{ data, filterValues, page, setFilters, setPage, setPerPage, ...listContext }}>
      <VTSListToolbar filters={filters} actions={<PanicActions />} />
      {matches == true ? (
              <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}  />

              ) : (
                  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
            )}

      <Box className="list-content">
        <Datagrid bulkActionButtons={false} empty={<CustomEmpty />}>
          <TextField label="Device Name" source="devicename" />
          <TextField label="Alert Type" source="alerttype" />
          <DateSetter label="Date Time" source="eventtime" />
          <TextField label="Latitude" source="latitude" />
          <TextField label="Longitude" source="longitude" />
          <AddressField label="Address" />
          <AttributeField label="Attributes" />
          <TextField label="Status" source="status" />
          <TextField label="Type" source="notificator_type" />
        </Datagrid>
      </Box>
    </ListContextProvider>
    <AddAlarm Alarmopen={Alarmopen} handleAlarmClose={handleAlarmClose}></AddAlarm>
  </>
  )
}
